import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { FilterLabel } from '../../components/DataTable/components/FilterTabs';
import { FormatLinkCell } from '../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../components/DataTable/DataTable';
import { ColumnConfig } from '../../components/DataTable/TableDataModel';
import { ApiTableDataParams, useApiTableData } from '../../components/DataTable/useApiTableData';
import { Page } from '../../components/Page/Page';
import { SelectorOption } from '../../components/Selector/Selector';
import { YearSelector } from '../../components/YearSelector/YearSelector';
import Enums from '../../generated-types/Enums';
import { ForecastAgg } from '../../generated-types/ForecastAgg/ForecastAgg';
import { ForecastPlantAgg } from '../../generated-types/ForecastPlantAgg/ForecastPlantAgg';
import { ForecastStatusAgg } from '../../generated-types/ForecastStatusAgg/ForecastStatusAgg';
import { MonthlyForecastAgg } from '../../generated-types/MonthlyForecastAgg/MonthlyForecastAgg';
import {
  NewProjectForecastSummary,
  ProjectForecastSummary,
} from '../../generated-types/ProjectForecastSummary/ProjectForecastSummary';
import { YearlyForecastAgg } from '../../generated-types/YearlyForecastAgg/YearlyForecastAgg';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { QueryRouteBarrelTypes } from '../../utils/ApiClient';
import { NestedKeyOf } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { ProjectSection } from '../Projects/components/ProjectDrawerSections';
import { ProjectDrawer } from '../Projects/ProjectDrawer';

type ForecastListAggregations = {
  id: string;
  month: { [month: string]: MonthlyForecastAgg };
  year: { [year: string]: YearlyForecastAgg };
  status: { [projectStatusId: string]: ForecastStatusAgg };
  plant: { [plantId: string]: ForecastPlantAgg };
};

const buildApiTableDataModelConfig = (
  selectedYear: number,
): ApiTableDataParams<
  ProjectForecastSummary,
  'GET project forecast summaries',
  QueryRouteBarrelTypes['GET project forecast summaries'],
  ForecastListAggregations,
  'GET forecast aggregations',
  QueryRouteBarrelTypes['GET forecast aggregations']
> => {
  const extractAggregations = (
    stats: ForecastAgg = ForecastAgg.zero(),
  ): { [id: string]: ForecastListAggregations } => ({
    by: {
      id: 'by',
      month: Object.fromEntries(stats.byMonth.map((agg) => [agg.month, agg])),
      year: Object.fromEntries(stats.byYear.map((agg) => [agg.year, agg])),
      status: Object.fromEntries(stats.byStatus.map((agg) => [agg.projectStatusId, agg])),
      plant: Object.fromEntries(stats.byPlant.map((agg) => [agg.plantId, agg])),
    },
  });

  const columns: ColumnConfig<ProjectForecastSummary, NestedKeyOf<ProjectForecastSummary>>[] = [
    {
      id: 'projectName',
      label: 'Project',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row): string | JSX.Element => {
        if (row.projectId === NIL_UUID) {
          return label;
        }
        return FormatLinkCell({ label, link: `/projects/${row.projectId}` });
      },
      formatValueToString: (projectName): string => projectName,
    },
    {
      id: 'plantName',
      label: 'Plant',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'projectStatusName',
      label: 'Status',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'januaryValue',
      label: DateTime.fromObject({ year: selectedYear, month: 1 }).monthShort ?? '',
      type: 'string',
      isDisplayed: true,
      formatValueToString: (value: string) => Number(value).toLocaleString(),
    },
    {
      id: 'februaryValue',
      label: DateTime.fromObject({ year: selectedYear, month: 2 }).monthShort ?? '',
      type: 'string',
      isDisplayed: true,
      formatValueToString: (value: string) => Number(value).toLocaleString(),
    },
    {
      id: 'marchValue',
      label: DateTime.fromObject({ year: selectedYear, month: 3 }).monthShort ?? '',
      type: 'string',
      isDisplayed: true,
      formatValueToString: (value: string) => Number(value).toLocaleString(),
    },
    {
      id: 'aprilValue',
      label: DateTime.fromObject({ year: selectedYear, month: 4 }).monthShort ?? '',
      type: 'string',
      isDisplayed: true,
      formatValueToString: (value: string) => Number(value).toLocaleString(),
    },
    {
      id: 'mayValue',
      label: DateTime.fromObject({ year: selectedYear, month: 5 }).monthShort ?? '',
      type: 'string',
      isDisplayed: true,
      formatValueToString: (value: string) => Number(value).toLocaleString(),
    },
    {
      id: 'juneValue',
      label: DateTime.fromObject({ year: selectedYear, month: 6 }).monthShort ?? '',
      type: 'string',
      isDisplayed: true,
      formatValueToString: (value: string) => Number(value).toLocaleString(),
    },
    {
      id: 'julyValue',
      label: DateTime.fromObject({ year: selectedYear, month: 7 }).monthShort ?? '',
      type: 'string',
      isDisplayed: true,
      formatValueToString: (value: string) => Number(value).toLocaleString(),
    },
    {
      id: 'augustValue',
      label: DateTime.fromObject({ year: selectedYear, month: 8 }).monthShort ?? '',
      type: 'string',
      isDisplayed: true,
      formatValueToString: (value: string) => Number(value).toLocaleString(),
    },
    {
      id: 'septemberValue',
      label: DateTime.fromObject({ year: selectedYear, month: 9 }).monthShort ?? '',
      type: 'string',
      isDisplayed: true,
      formatValueToString: (value: string) => Number(value).toLocaleString(),
    },
    {
      id: 'octoberValue',
      label: DateTime.fromObject({ year: selectedYear, month: 10 }).monthShort ?? '',
      type: 'string',
      isDisplayed: true,
      formatValueToString: (value: string) => Number(value).toLocaleString(),
    },
    {
      id: 'novemberValue',
      label: DateTime.fromObject({ year: selectedYear, month: 11 }).monthShort ?? '',
      type: 'string',
      isDisplayed: true,
      formatValueToString: (value: string) => Number(value).toLocaleString(),
    },
    {
      id: 'decemberValue',
      label: DateTime.fromObject({ year: selectedYear, month: 12 }).monthShort ?? '',
      type: 'string',
      isDisplayed: true,
      formatValueToString: (value: string) => Number(value).toLocaleString(),
    },
  ];

  const makeAggregationArgs = (
    rowsArgs: QueryRouteBarrelTypes['GET project forecast summaries']['args'],
  ): QueryRouteBarrelTypes['GET forecast aggregations']['args'] => {
    const filterBy = (rowsArgs.queryParams?.filterBy ?? []).filter(
      (param) => param.operation !== 'equals' || !(param.name as string).startsWith('category'),
    );
    if (!filterBy.length) {
      return {};
    }
    return { queryParams: { filterBy } };
  };

  return {
    rowsRouteKey: 'GET project forecast summaries',
    extractRows: (forecastList) => forecastList.items,
    initialFilterBy: [
      {
        operation: Enums.FilterOperation.Equals,
        name: 'year',
        value: selectedYear,
      },
    ],
    initialSortBy: {
      direction: Enums.SortDirection.Ascending,
      name: 'projectName',
    },
    aggregationsRouteKey: 'GET forecast aggregations',
    extractAggregations,
    makeColumnConfigs: () => columns,
    makeAggregationArgs,
  };
};

const constructStickyRows = (aggregations: {
  [id: string]: ForecastListAggregations;
}): ProjectForecastSummary[] => {
  if (aggregations.by === undefined) {
    return [];
  }
  return [
    NewProjectForecastSummary({
      projectName: 'Totals',
      unit: Enums.Unit.CuYd,
      januaryValue: aggregations.by.month.Jan?.totalProjectCuyd,
      februaryValue: aggregations.by.month.Feb?.totalProjectCuyd,
      marchValue: aggregations.by.month.Mar?.totalProjectCuyd,
      aprilValue: aggregations.by.month.Apr?.totalProjectCuyd,
      mayValue: aggregations.by.month.May?.totalProjectCuyd,
      juneValue: aggregations.by.month.Jun?.totalProjectCuyd,
      julyValue: aggregations.by.month.Jul?.totalProjectCuyd,
      augustValue: aggregations.by.month.Aug?.totalProjectCuyd,
      septemberValue: aggregations.by.month.Sep?.totalProjectCuyd,
      octoberValue: aggregations.by.month.Oct?.totalProjectCuyd,
      novemberValue: aggregations.by.month.Nov?.totalProjectCuyd,
      decemberValue: aggregations.by.month.Dec?.totalProjectCuyd,
    }),
    NewProjectForecastSummary({
      projectName: 'Budget',
      unit: Enums.Unit.CuYd,
      januaryValue: aggregations.by.month.Jan?.budgetCuyd,
      februaryValue: aggregations.by.month.Feb?.budgetCuyd,
      marchValue: aggregations.by.month.Mar?.budgetCuyd,
      aprilValue: aggregations.by.month.Apr?.budgetCuyd,
      mayValue: aggregations.by.month.May?.budgetCuyd,
      juneValue: aggregations.by.month.Jun?.budgetCuyd,
      julyValue: aggregations.by.month.Jul?.budgetCuyd,
      augustValue: aggregations.by.month.Aug?.budgetCuyd,
      septemberValue: aggregations.by.month.Sep?.budgetCuyd,
      octoberValue: aggregations.by.month.Oct?.budgetCuyd,
      novemberValue: aggregations.by.month.Nov?.budgetCuyd,
      decemberValue: aggregations.by.month.Dec?.budgetCuyd,
    }),
    NewProjectForecastSummary({
      projectName: 'Capacity',
      unit: Enums.Unit.CuYd,
      januaryValue: aggregations.by.month.Jan?.capacityCuyd,
      februaryValue: aggregations.by.month.Feb?.capacityCuyd,
      marchValue: aggregations.by.month.Mar?.capacityCuyd,
      aprilValue: aggregations.by.month.Apr?.capacityCuyd,
      mayValue: aggregations.by.month.May?.capacityCuyd,
      juneValue: aggregations.by.month.Jun?.capacityCuyd,
      julyValue: aggregations.by.month.Jul?.capacityCuyd,
      augustValue: aggregations.by.month.Aug?.capacityCuyd,
      septemberValue: aggregations.by.month.Sep?.capacityCuyd,
      octoberValue: aggregations.by.month.Oct?.capacityCuyd,
      novemberValue: aggregations.by.month.Nov?.capacityCuyd,
      decemberValue: aggregations.by.month.Dec?.capacityCuyd,
    }),
  ];
};

export const ForecastList = (): JSX.Element => {
  const [selectedYear, setSelectedYear] = useState(DateTime.now().year);
  const [initialSection, setInitialSection] = useState<ProjectSection>('Project Information*');
  const [projectId, setProjectId] = useState<string | null>(null);

  const { toastHook: projectToastHook, ...projectDrawerProps } = useDrawerManager({
    baseUrl: '/projects',
    resourceTypeName: 'project',
    drawerProps: {
      resourceId: projectId,
    },
  });

  const tableModel = useApiTableData(buildApiTableDataModelConfig(selectedYear));
  const aggregations = tableModel.aggregations.data ?? { by: null };

  const filterLabels: FilterLabel<ProjectForecastSummary, ForecastListAggregations>[] = [
    {
      label: 'All',
      filter: null,
      additionalLabels: () => [
        Object.entries(aggregations.by?.status ?? {})
          .reduce(
            (sum: number, [, agg]: [string, ForecastStatusAgg]) =>
              sum + Number(agg.totalProjectCuyd),
            0,
          )
          .toLocaleString(),
      ],
      resultCount: () =>
        Object.entries(aggregations.by?.status ?? {}).reduce(
          (sum: number, [, agg]: [string, ForecastStatusAgg]) => sum + Number(agg.projectCount),
          0,
        ),
    },
    ...Object.entries(aggregations.by?.status ?? {}).map(
      ([projectStatusId, agg]): FilterLabel<ProjectForecastSummary, ForecastListAggregations> => ({
        label: agg.projectStatusName,
        filter: {
          name: 'projectStatusId',
          operation: Enums.FilterOperation.Equals,
          value: agg.projectStatusId,
        },
        additionalLabels: () => [
          Number(
            aggregations.by?.status[projectStatusId]?.totalProjectCuyd ?? '0',
          ).toLocaleString(),
        ],
        resultCount: () => Number(aggregations.by?.status[projectStatusId]?.projectCount ?? '0'),
      }),
    ),
  ];

  const yearEntries = Object.entries(aggregations.by?.year ?? {});
  const yearOpts: { year: number; hasValues: boolean }[] =
    yearEntries.length === 0
      ? _.range(new Date().getFullYear() - 5, new Date().getFullYear() + 5).map((year) => ({
          year,
          hasValues: false,
        }))
      : yearEntries.map(([year, yearAgg]) => ({
          year: Number(year),
          hasValues: yearAgg.totalProjectCuyd !== '0',
        }));

  const plantEntries = Object.entries(aggregations.by?.plant ?? {});
  const plantOpts: SelectorOption[] = [
    {
      id: null,
      name: 'All plants',
      subLabel: plantEntries.some(([, plantAgg]) => plantAgg.totalProjectCuyd !== '0')
        ? '- has forecast data'
        : undefined,
    },
    ...plantEntries.map(([plantId, plantAgg]) => ({
      id: plantId,
      name: plantAgg.plantName,
      subLabel: plantAgg.totalProjectCuyd !== '0' ? '- has forecast data' : undefined,
    })),
  ];

  return (
    <Page title='Forecasts'>
      {projectToastHook.toast}
      <ProjectDrawer
        {...projectDrawerProps}
        projectId={projectDrawerProps.resourceId}
        initialSection={initialSection}
        initialForecastYear={selectedYear}
        onSuccess={projectDrawerProps.onSave}
        onClose={(): void => {
          setInitialSection('Project Information*');
        }}
      />

      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Forecasts</Typography>
        <Box display='flex' gap='1rem'>
          <YearSelector
            selectedYear={selectedYear}
            setSelectedYear={(year): void => {
              setSelectedYear(year);
              tableModel.addFilters({
                operation: Enums.FilterOperation.Equals,
                name: 'year',
                value: year,
              });
            }}
            yearOpts={yearOpts}
          />
        </Box>
      </Box>

      <Box height='400px'>
        <DataTable
          tableModel={tableModel}
          tabFilters={filterLabels}
          onEditPress={(row): void => {
            setInitialSection('Forecasts');
            setProjectId(row.projectId);
            projectDrawerProps.setIsOpen(true);
          }}
          constructStickyRows={constructStickyRows}
          includePrint={{ title: 'Forecasts' }}
          plantSelectorOpts={{
            enabled: true,
            onPlantChange: (plantId): void => {
              if (plantId !== null) {
                tableModel.addFilters({
                  operation: Enums.FilterOperation.Equals,
                  name: 'plantId',
                  value: plantId,
                });
              } else {
                tableModel.removeFilters({
                  operation: Enums.FilterOperation.Equals,
                  name: 'plantId',
                  value: plantId,
                });
              }
            },
            plantOpts,
          }}
        />
      </Box>
    </Page>
  );
};
