import ReactPDF, { Text, View } from '@react-pdf/renderer';

type PdfTextWithLineProps = {
  text: string;
  /** @default false */
  underline?: boolean;
  /** @default '40pt' */
  pr?: string;
  /** @default {} */
  textStyle?: ReactPDF.TextProps['style'];
  /** @default '0.75px solid black' */
  borderBottom?: string;
};

/**
 * This helper component will accept a piece of text and then wrap the current View block
 * with a bottom border. This is primarily used for signature sections so we can have them
 * always end at the same spot (most often in a DataTable row).
 */
export const PdfTextWithLine = ({
  text,
  underline = false,
  pr = '40pt',
  textStyle = {},
  borderBottom = '0.75px solid black',
}: PdfTextWithLineProps): JSX.Element => (
  <View style={{ display: 'flex', flexDirection: 'row' }}>
    <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
      <Text
        style={{
          ...textStyle,
          textDecoration: underline ? 'underline' : 'none',
        }}
      >
        {text}
      </Text>
    </View>
    <View style={{ borderBottom, flex: 1 }}>
      <Text>&nbsp;</Text>
    </View>
    <View style={{ width: pr }}>
      <Text>&nbsp;</Text>
    </View>
  </View>
);
