import 'react-quill/dist/quill.snow.css';

import { Box, FormHelperText, Grid, useTheme } from '@mui/material';
import { FormikContextType } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import ReactQuill from 'react-quill';

import { Base64 } from '../../types/Base64';
import { SetFormikValue } from '../../utils/FormikHelpers';
import { ButtonPill } from '../ButtonPill/ButtonPill';
import { Input } from '../Input/Input';
import { MultiEmailInput } from '../MultiEmailinput/MultiEmailinput';
import { EmailFormikType } from './EmailFormik';
import { NylasContext } from './Login/NylasContext';

/**
 * @description EmailComposerProps is the type used for the properties
 * of the exported component EmailComposer
 */
type EmailComposerProps = {
  onChange?: (value: string) => void;
  base64Attachment: Base64;
  formikBag: FormikContextType<EmailFormikType>;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  isSending: boolean;
};

/**
 * @description An EmailComposer is a self-contained component that includes
 * fields for the To, CC, and BCC properties of the SendMessageInput type
 * using a MultiEmailInput field, a field for a subject line, and a rich text editor
 * for the email's body
 */
export const EmailComposer = ({
  onChange,
  base64Attachment,
  formikBag,
  setIsOpen,
  isSending,
}: EmailComposerProps): JSX.Element => {
  const [value, setValue] = useState<string>('');
  const theme = useTheme();

  // TODO: #2543 this should return the trimmed value, then set the `formikBag` state,
  // and then call the optional `onChange` handler at the call-sites.
  const onEditorChange = (newValue: string, editor: ReactQuill.UnprivilegedEditor): void => {
    const isEmpty = editor.getText() === '\n';
    const trimmedValue = isEmpty ? '' : newValue;
    setValue(trimmedValue);
    SetFormikValue(formikBag, 'body', trimmedValue, true);
    formikBag.setFieldTouched('body', true, false);
    onChange?.(trimmedValue);
  };

  if (base64Attachment !== formikBag.values.file?.file) {
    formikBag.setFieldValue('file.file', base64Attachment);
  }

  const { touched, error: bodyError } = formikBag.getFieldMeta('body');
  const hasBodyError = touched && bodyError !== undefined;
  return (
    <NylasContext>
      <Box display='flex' gap='1rem' flexDirection='column'>
        <Grid container spacing={1} columns={2}>
          <Grid item xs={2}>
            <MultiEmailInput name='to' label='To' />
          </Grid>
          <Grid item xs={1}>
            <MultiEmailInput name='cc' label='CC' />
          </Grid>
          <Grid item xs={1}>
            <MultiEmailInput name='bcc' label='BCC' />
          </Grid>
          <Grid item xs={2}>
            <Input name='subject' label='Subject' />
          </Grid>
          <Grid item xs={2}>
            <Box display='flex' flexDirection='column'>
              <Box
                sx={{
                  height: '15rem',
                  paddingBottom: '3rem',
                  '& .quill': {
                    height: '100%',
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.fontSize,
                  },
                  '& .ql-container, & .ql-toolbar': {
                    borderColor: hasBodyError
                      ? theme.palette.error.main
                      : theme.palette.SlabBlue[200],
                    borderWidth: 1,
                    borderStyle: 'solid',
                  },
                }}
              >
                <ReactQuill
                  value={value}
                  onChange={(
                    v: string,
                    d: any,
                    source: any,
                    editor: ReactQuill.UnprivilegedEditor,
                  ): void => onEditorChange(v, editor)}
                  onBlur={(range, source, editor): void => {
                    onEditorChange(editor.getHTML(), editor);
                  }}
                  theme='snow'
                />
              </Box>
              <Box>
                <FormHelperText error>{hasBodyError ? bodyError : ' '}</FormHelperText>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            display='flex'
            sx={{
              '& button': {
                flex: 1,
              },
            }}
            xs={1}
          >
            <ButtonPill
              text='cancel'
              variant='secondary'
              disabled={isSending}
              onClick={(): void => {
                setIsOpen?.(false);
              }}
            />
          </Grid>
          <Grid
            item
            display='flex'
            sx={{
              '& button': {
                flex: 1,
              },
            }}
            xs={1}
          >
            <ButtonPill
              text='send'
              variant='primary'
              disabled={isSending}
              onClick={async (): Promise<void> => formikBag.submitForm()}
            />
          </Grid>
        </Grid>
      </Box>
    </NylasContext>
  );
};
