import { PdfTextBlock, PdfTextBlockProps } from '../../components/PdfTextBlock';

type PdfSpacerProps = {
  height: '0.5in' | '0.25in' | '0.1in';
  textBlockProps?: PdfTextBlockProps;
};

export const PdfSpacer = ({ height, textBlockProps }: PdfSpacerProps): JSX.Element => (
  <PdfTextBlock
    content=' '
    viewStyle={{
      height,
    }}
    {...textBlockProps}
  />
);
