// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class YearlyForecastAgg {
  readonly year: string;
  readonly totalProjectCuyd: string;

  constructor(data: { [key: string]: any } = {}) {
    this.year = data.year === undefined ? '' : data.year;
    this.totalProjectCuyd = data.totalProjectCuyd === undefined ? '0' : data.totalProjectCuyd;
  }

  static zero(): YearlyForecastAgg {
    const zeroValues: ExcludeMethodsDeep<YearlyForecastAgg> = {
      year: '',
      totalProjectCuyd: '0',
    };
    return new YearlyForecastAgg(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewYearlyForecastAgg = (
  props: PartialDeep<YearlyForecastAgg, { recurseIntoArrays: true }>,
): YearlyForecastAgg => new YearlyForecastAgg(props);

export const NewYearlyForecastAggFromDomainObject = (
  props: PartialDeep<DomainObject<YearlyForecastAgg>, { recurseIntoArrays: true }>,
): YearlyForecastAgg => new YearlyForecastAgg(props);
