import { Box, Typography } from '@mui/material';

export const ErrorFullPage = (): JSX.Element => (
  <Box p='4rem' height='100vh' display='flex' alignItems='center' justifyContent='center'>
    <Box
      display='flex'
      flexDirection='column'
      gap='3rem'
      justifyContent='center'
      alignItems='center'
    >
      <Typography variant='h3'>Something has gone wrong!</Typography>
      <img
        src='/burning-building.png'
        style={{
          width: 'auto',
          height: '40vh',
        }}
        alt='burning building'
      />
      <Typography variant='h3'>Please contact a Slabstack admin for support</Typography>
    </Box>
  </Box>
);
