import { DateTime, DateTimeFormatOptions, Duration } from 'luxon';

export const ZERO_DATE_STRING = '0001-01-01T00:00:00Z';
export const ZERO_DURATION_STRING = 'PT0S';

export const DEFAULT_FORECAST_YEAR_FROM = -5;
export const DEFAULT_FORECAST_YEAR_TO = 5;

/**
 * Parse a string or DateTime representation of a date from server JSON as a date ignoring
 * the time component and time zone. The resulting date corresponds to midnight
 * local time.
 */
export const ParseServerDate = (jsDateStr: string | DateTime = ZERO_DATE_STRING): DateTime => {
  if (typeof jsDateStr === 'string') {
    return DateTime.fromISO(jsDateStr.replace(/T.*$/, '')).setZone('default', {
      keepLocalTime: true,
    });
  }
  return jsDateStr;
};

/**
 * Parse a nullable string or DateTime representation of a date from server JSON as a date
 * ignoring the time component and time zone. The resulting date corresponds to
 * midnight local time. If the string is null, returns null.
 */
export const ParseServerNullableDate = (
  jsDateStr: string | DateTime | null | undefined,
): DateTime | null =>
  jsDateStr === null || jsDateStr === undefined ? null : ParseServerDate(jsDateStr);

/**
 * Parse a string or DateTime representation of a date from server JSON as a date including
 * the time component and time zone. The resulting date is converted to local time.
 */
export const ParseServerDateTime = (jsDateStr: string | DateTime = ZERO_DATE_STRING): DateTime => {
  if (typeof jsDateStr === 'string') {
    return DateTime.fromISO(jsDateStr);
  }
  return jsDateStr;
};

/**
 * Parse a string or DateTime representation of a date from server JSON as a date including
 * the time component and time zone. The resulting date is converted to local
 * time. If the string is null, returns null.
 */
export const ParseServerNullableDateTime = (
  jsDateStr: string | DateTime | null | undefined,
): DateTime | null =>
  jsDateStr === null || jsDateStr === undefined ? null : ParseServerDateTime(jsDateStr);

/**
 * Parse a string or Duration representation of a duration from server JSON that is in
 * ISO-8601 format.
 */
export const ParseServerDuration = (
  srvDuration: string | Duration = ZERO_DURATION_STRING,
): Duration => {
  if (typeof srvDuration === 'string') {
    return Duration.fromISO(String(srvDuration));
  }
  return srvDuration;
};

/**
 * Parse a string or Duration representation of a duration from server JSON that is in
 * ISO-8601 format. If the string is null, returns null.
 */
export const ParseServerNullableDuration = (
  srvDuration: string | Duration | null | undefined,
): Duration | null =>
  srvDuration === null || srvDuration === undefined ? null : ParseServerDuration(srvDuration);

/**
 * Coalesces a date, returning the default if the date is invalid, null, or undefined.
 */
export const ValidDateOrDefault = (dt: DateTime | null | undefined, def: DateTime): DateTime => {
  if (dt === null || dt === undefined || !dt.isValid) {
    return def;
  }
  return dt;
};

/**
 * Given a date in JS string format, return it in YYYY-MM-DD string format
 */
export const FormatDateString = (jsDateStr: string): string =>
  DateTime.fromISO(jsDateStr).toISODate() ?? '';

/**
 * Given a JS Date from formik (which is typed a Date, but actuallly string),
 * return it in YYYY-MM-DD string format
 */
export const FormatFormikJSDate = (jsDate: Date): string =>
  DateTime.fromISO(`${jsDate}`).toISODate() ?? '';

/**
 * Given a luxon DateTime from formik (which is typed a DateTime, but actuallly string),
 * return it in YYYY-MM-DD string format
 */
export const FormatFormikDateTime = (luxonDateTime: DateTime): string =>
  DateTime.fromISO(`${luxonDateTime}`).toISODate() ?? '';

/**
 * Given a DateTime, return it in the browser's locale string format
 */
export const FormatDisplayDateTime = (
  luxonDateTime: DateTime,
  format: DateTimeFormatOptions = DateTime.DATE_MED,
): string => luxonDateTime.toLocaleString(format);

/**
 * Given a date in JS string format, return it in the browser's locale string format
 */
export const FormatDisplayDateString = (
  jsDateStr: string,
  format: DateTimeFormatOptions = DateTime.DATE_MED,
): string => FormatDisplayDateTime(DateTime.fromISO(jsDateStr), format);

/**
 * Given a DateTime, return it in the format required by the value/min/max
 * properties of `<input type="datetime-local">`.
 */
export const FormatDateTimeLocal = (luxonDateTime: DateTime): string =>
  luxonDateTime.startOf('minute').toISO({
    includeOffset: false,
    suppressSeconds: true,
    suppressMilliseconds: true,
  }) ?? '';
