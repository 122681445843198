import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Enums from '../../../generated-types/Enums';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { SlabContext } from '../../../SlabContext';

type NylasContextProps = {
  children: ReactNode;
};
export const NylasContext = ({ children }: NylasContextProps): JSX.Element => {
  const [authorized, setAuthorized] = useState<boolean | undefined>();
  const [open, setOpen] = useState<boolean | undefined>();
  const ctx = React.useContext(SlabContext);
  const useNylasV3 = ctx.userInfo.hasFlags([Enums.FeatureFlagName.FeatureFlagNylasV3]);

  const accountInfoQuery = useNylasV3 ? 'GET nylas v3 grant info' : 'GET nylas account info';
  const { error, isError, isSuccess } = useSlabQuery(
    accountInfoQuery,
    {},
    {
      retry: (fc, err) => err.response?.status !== 401 && fc < 4,
    },
  );
  useEffect(() => {
    if (isSuccess) {
      setAuthorized(true);
      setOpen(false);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      const badAuth = !!(error.response?.status === 401);
      if (badAuth) {
        setAuthorized(false);
        setOpen(true);
      }
    }
  }, [isError, error]);

  useEffect(() => {
    setOpen(authorized === false);
  }, [authorized]);

  return (
    <>
      <Dialog
        open={authorized === false && open === true}
        onClose={(): void => {
          setOpen(false);
        }}
      >
        <DialogTitle>Not Authorized</DialogTitle>
        <DialogContent>
          <Box display='flex' flexDirection='column'>
            <Box>
              <Typography>
                You are not currently authorized to use the emailing feature. Please click on the
                &quot;Email Options&quot; menu item or follow{' '}
                <Link to='/emailconfig'>this link</Link> to authorize your email account.
              </Typography>
            </Box>
            <Box flexGrow={1} alignSelf='end'>
              <Button
                onClick={(): void => {
                  setOpen(false);
                }}
              >
                Ok
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {children}
    </>
  );
};
