// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { Address, NewAddress } from '../Address/Address';
import { DispatchCustomer, NewDispatchCustomer } from '../DispatchCustomer/DispatchCustomer';
import Enums from '../Enums';

export class Company {
  readonly id: string;
  readonly name: string;
  readonly alternateID: string | null;
  readonly address: Address;
  readonly category: Enums.CompanyCategory;
  readonly dispatchCustomer: DispatchCustomer | null;
  readonly externalID: string | null;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.alternateID = data.alternateID === undefined ? null : data.alternateID;
    this.address = NewAddress(data.address);
    this.category = data.category === undefined ? Enums.CompanyCategory.Contractor : data.category;
    this.dispatchCustomer =
      // eslint-disable-next-line no-nested-ternary
      (data.dispatchCustomer ?? null) === null ? null : NewDispatchCustomer(data.dispatchCustomer);
    this.externalID =
      // eslint-disable-next-line no-nested-ternary
      (data.externalID ?? null) === null
        ? null
        : data.externalID === undefined
          ? ''
          : data.externalID;
  }

  static zero(): Company {
    const zeroValues: ExcludeMethodsDeep<Company> = {
      id: NIL_UUID,
      name: '',
      alternateID: null,
      address: Address.zero(),
      category: Enums.CompanyCategory.Contractor,
      dispatchCustomer: null,
      externalID: null,
    };
    return new Company(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /**
   * lookupSublabels returns the proper sublabels for company lookups
   * based on the presence of the dispatch customer feature flag.
   */
  lookupSublabels(usesDispatchCustomer: boolean): string[] {
    if (!usesDispatchCustomer) {
      return [];
    }

    const connectivitySublabel = this.dispatchCustomer !== null ? 'Connected' : 'Disconnected';

    const creditDescriptionSublabel =
      this.dispatchCustomer?.creditStatusDescription !== undefined &&
      this.dispatchCustomer.creditStatusDescription !== null
        ? `Credit Status: ${this.dispatchCustomer.creditStatusDescription}`
        : '';

    return [connectivitySublabel, creditDescriptionSublabel];
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewCompany = (props: PartialDeep<Company, { recurseIntoArrays: true }>): Company =>
  new Company(props);

export const NewCompanyFromDomainObject = (
  props: PartialDeep<DomainObject<Company>, { recurseIntoArrays: true }>,
): Company => new Company(props);
