// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import {
  DistanceDurationPair,
  NewDistanceDurationPair,
} from '../DistanceDurationPair/DistanceDurationPair';

export class DistanceResultsByOrigin {
  readonly origin: string;
  readonly resultsForOrigin: DistanceDurationPair[];

  constructor(data: { [key: string]: any } = {}) {
    this.origin = data.origin === undefined ? '' : data.origin;
    this.resultsForOrigin = (data.resultsForOrigin ?? []).map((resultsForOrigin: any) =>
      NewDistanceDurationPair(resultsForOrigin),
    );
  }

  static zero(): DistanceResultsByOrigin {
    const zeroValues: ExcludeMethodsDeep<DistanceResultsByOrigin> = {
      origin: '',
      resultsForOrigin: [],
    };
    return new DistanceResultsByOrigin(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewDistanceResultsByOrigin = (
  props: PartialDeep<DistanceResultsByOrigin, { recurseIntoArrays: true }>,
): DistanceResultsByOrigin => new DistanceResultsByOrigin(props);

export const NewDistanceResultsByOriginFromDomainObject = (
  props: PartialDeep<DomainObject<DistanceResultsByOrigin>, { recurseIntoArrays: true }>,
): DistanceResultsByOrigin => new DistanceResultsByOrigin(props);
