// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { Company, NewCompany } from '../Company/Company';

export class Contact {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly emailAddress: string | null;
  readonly phoneNumber: string | null;
  readonly company: Company;
  readonly jobTitle: string | null;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.firstName = data.firstName === undefined ? '' : data.firstName;
    this.lastName = data.lastName === undefined ? '' : data.lastName;
    this.emailAddress = data.emailAddress === undefined ? null : data.emailAddress;
    this.phoneNumber = data.phoneNumber === undefined ? null : data.phoneNumber;
    this.company = NewCompany(data.company);
    this.jobTitle = data.jobTitle === undefined ? null : data.jobTitle;
  }

  static zero(): Contact {
    const zeroValues: ExcludeMethodsDeep<Contact> = {
      id: NIL_UUID,
      firstName: '',
      lastName: '',
      emailAddress: null,
      phoneNumber: null,
      company: Company.zero(),
      jobTitle: null,
    };
    return new Contact(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewContact = (props: PartialDeep<Contact, { recurseIntoArrays: true }>): Contact =>
  new Contact(props);

export const NewContactFromDomainObject = (
  props: PartialDeep<DomainObject<Contact>, { recurseIntoArrays: true }>,
): Contact => new Contact(props);
