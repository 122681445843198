// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { Duration } from 'luxon';
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import * as DateHelpers from '../../utils/DateHelpers';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class TripDuration {
  readonly value: Duration;
  readonly text: string;

  constructor(data: { [key: string]: any } = {}) {
    this.value = DateHelpers.ParseServerDuration(data.value);
    this.text = data.text === undefined ? '' : data.text;
  }

  static zero(): TripDuration {
    const zeroValues: ExcludeMethodsDeep<TripDuration> = {
      value: DateHelpers.ParseServerDuration(DateHelpers.ZERO_DURATION_STRING),
      text: '',
    };
    return new TripDuration(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewTripDuration = (
  props: PartialDeep<TripDuration, { recurseIntoArrays: true }>,
): TripDuration => new TripDuration(props);

export const NewTripDurationFromDomainObject = (
  props: PartialDeep<DomainObject<TripDuration>, { recurseIntoArrays: true }>,
): TripDuration => new TripDuration(props);
