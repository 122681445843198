// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class UploadFileOutput {
  readonly id: string;
  readonly name: string;
  readonly size: number;
  readonly contentType: string;
  readonly contentDisposition: string;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? '' : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.size = data.size === undefined ? 0 : data.size;
    this.contentType = data.contentType === undefined ? '' : data.contentType;
    this.contentDisposition = data.contentDisposition === undefined ? '' : data.contentDisposition;
  }

  static zero(): UploadFileOutput {
    const zeroValues: ExcludeMethodsDeep<UploadFileOutput> = {
      id: '',
      name: '',
      size: 0,
      contentType: '',
      contentDisposition: '',
    };
    return new UploadFileOutput(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewUploadFileOutput = (
  props: PartialDeep<UploadFileOutput, { recurseIntoArrays: true }>,
): UploadFileOutput => new UploadFileOutput(props);

export const NewUploadFileOutputFromDomainObject = (
  props: PartialDeep<DomainObject<UploadFileOutput>, { recurseIntoArrays: true }>,
): UploadFileOutput => new UploadFileOutput(props);
