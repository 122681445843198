import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { Activity } from '../../generated-types/Activity/Activity';
import { ServerErrorMessage } from '../../hooks/useSlabQuery';
import { EnsureDefinedFunction } from '../../utils/DomainHelpers';
import { ActivityDrawer } from '../ActivityDrawer/ActivityDrawer';
import { ActivityScheduler, ActivitySchedulerView } from '../ActivityScheduler/ActivityScheduler';
import { useToast } from '../Toast/useToast';

export type ActivitiesProps = {
  /** The current date/time, for prefilling the date selections in the activity drawer */
  currentDate: DateTime;

  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;

  ensureDefined?: EnsureDefinedFunction<Activity>;
  activityId: string | null;
  setActivityId: React.Dispatch<React.SetStateAction<string | null>>;

  activities?: Activity[];
  drawerOnly?: boolean;

  /** The selected date in the scheduler */
  date?: DateTime;
  /** @default DateTime.now() */
  defaultDate?: DateTime;
  onDateChange?: (dt: DateTime) => void;

  /** The selected view mode in the scheduler */
  view?: ActivitySchedulerView;
  /** @default agenda */
  defaultView?: ActivitySchedulerView;
  onViewChange?: (v: ActivitySchedulerView) => void;
};

export const Activities = ({
  currentDate,
  isDrawerOpen,
  setIsDrawerOpen,
  ensureDefined,
  activityId,
  setActivityId,
  activities = [],
  drawerOnly = false,
  date,
  defaultDate = DateTime.now(),
  onDateChange,
  view,
  defaultView = 'agenda',
  onViewChange,
}: ActivitiesProps): JSX.Element => {
  const { showToast, toast } = useToast('activity');

  const [selectedView, setSelectedView] = useState<ActivitySchedulerView>(view ?? defaultView);
  const [selectedDate, setSelectedDate] = useState<DateTime>(date ?? defaultDate);

  return (
    <Box>
      {toast}
      <ActivityDrawer
        currentDate={currentDate}
        activityId={activityId}
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        onSuccess={(): void => {
          showToast('success');
          setIsDrawerOpen(false);
        }}
        onError={(err): void => showToast('error', ServerErrorMessage(err))}
        ensureDefined={ensureDefined}
      />
      {drawerOnly ? null : (
        <Box height='600px'>
          <ActivityScheduler
            activities={activities}
            date={date ?? selectedDate}
            view={view ?? selectedView}
            onSelect={(act: Activity | null): void => {
              if (!isDrawerOpen && act !== null) {
                setActivityId(act.id);
                setIsDrawerOpen(true);
              }
            }}
            onDateChange={(dt): void => {
              onDateChange?.(dt);
              setSelectedDate(dt);
            }}
            onViewChange={(v): void => {
              onViewChange?.(v);
              setSelectedView(v);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
