// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NewProduct, Product } from '../Product/Product';

export class QuoteConfigProduct {
  readonly product: Product;

  constructor(data: { [key: string]: any } = {}) {
    this.product = NewProduct(data.product);
  }

  static zero(): QuoteConfigProduct {
    const zeroValues: ExcludeMethodsDeep<QuoteConfigProduct> = {
      product: Product.zero(),
    };
    return new QuoteConfigProduct(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewQuoteConfigProduct = (
  props: PartialDeep<QuoteConfigProduct, { recurseIntoArrays: true }>,
): QuoteConfigProduct => new QuoteConfigProduct(props);

export const NewQuoteConfigProductFromDomainObject = (
  props: PartialDeep<DomainObject<QuoteConfigProduct>, { recurseIntoArrays: true }>,
): QuoteConfigProduct => new QuoteConfigProduct(props);
