import ReactPDF, { Text, View } from '@react-pdf/renderer';

import { PdfList } from '../../../components/PdfList';
import { PdfSpacer } from '../../components/PdfSpacer';

const termFont: ReactPDF.TextProps['style'] = {
  fontSize: '10pt',
  lineHeight: '1.25pt',
};

export const PdfTerms = (): JSX.Element => (
  <View>
    <Text style={{ fontSize: '14pt', textDecoration: 'underline' }}>
      Terms and Technical Notes:
    </Text>
    <PdfSpacer height='0.25in' />
    <PdfList
      order='numbered'
      items={[
        'Net payment due 30 days following delivery, no retainage',
        <View>
          <Text style={termFont}>The attached listed price do not include:</Text>
          <PdfList
            order='alphabetical'
            items={[
              'Applicable sales taxes except as noted.',
              'Measures to control concrete temperature such as hot water or ice. These are listed as separate line items and priced accordingly. Should the purchased choose not to purchase temperature control measures, the Purchaser shall assume all liability for rejected concrete due to non-compliant concrete temperatures. Additionally, these temperature control measures are implemented solely as an attempt to achieve a target temperature range. Joseph P. Carrara and Sons, inc. (JPC) assumes no liability for minimum and maximum temperatures due to circumstances beyond the control of JPC.',
            ]}
            textStyle={termFont}
          />
        </View>,
        <View>
          <Text style={termFont}>The attached listed price(s) are based upon:</Text>
          <PdfList
            order='alphabetical'
            items={[
              "Safe and clear access roads for delivery of full truck load quantities utilizing the delivery truck's own power. If Purchaser requests delivery beyond the curb line or on other than paved street, Purchaser releases JPC from liability for damages to curb, sidewalk, driveway, power or telephone line, or any other property of contractor or owner, or injury to any person whatsoever.",
              'Acceptance of the above listed price(s) within 30 days from the date shown above after which the same price(s) are subject to confirmation by JPC.',
              'Capacity loads with the exception of one partial load to complete the workday. Additional charges will apply for ordered loads that are less than five cubic yards.',
              'Deliveries during normal business hours, which are Monday-Friday 7am-4pm.',
            ]}
            textStyle={termFont}
          />
        </View>,
        'Seller shall not be liable for defaults of delays due to Acts of God or the public enemy, acts or demands of any Government or any governmental agency, strikes, fires, floods, accidents, or other unforeseeable causes beyond its control and not due to its fault or negligence',
        'Ready Mix concrete deliveries are subject to availability of raw materials. JPC will not be held liable for any delays due to the inability to obtain Portland cement, fine or course aggregate or any other materials deemed necessary in the production of concrete.',
        'All samples for acceptance shall be conducted at the point of discharge from the concrete delivery truck and in accordance with applicable ASTM Standards.',
        'The following referenced mix designs and prices are quoted as per customer supplied information. Prices are subject to change if additional information or specification changes are subsequently provided.',
        'Added ingredients: Where any material(s) is/are added to the concrete by the Purchaser or their agent, the customer assumes full responsibility for the performance or acceptability of the concrete so treated.',
        'Additional water added to the concrete will reduce its strength. The customer assumes full responsibility for the performance of the concrete where maximum allowable water tolerances are exceeded.',
        'Customer assumes total responsibility for concrete placement, finishing, initial and final curing, spacing of joints at proper spacing and any aesthetic concerns that may arise in the plastic or hardened state.',
        'Seller makes no claim or back charge the amount of claim shall be no greater than the value of the product supplied.',
        'Seller makes no claim to incentive monies and accepts no responsibility for disincentive charges',
        "It is the contractor's responsibility to get the concrete from the end of the chute to point of placement. Seller will not be responsible for concrete that is rejected for time because of inadequate access or conveyance system or failure of conveyance system.",
      ]}
      textStyle={termFont}
    />
  </View>
);
