// This file is generated by webgen. DO NOT EDIT.

import { Box, Typography } from '@mui/material';

import { DataTable } from '../../components/DataTable/DataTable';
import { ColumnConfig } from '../../components/DataTable/TableDataModel';
import { useApiTableDataWithoutAggregations } from '../../components/DataTable/useApiTableData';
import { Page } from '../../components/Page/Page';
import { NestedKeyOf } from '../../utils/Types';
import Enums from '../Enums';
import { ProjectStatus } from './ProjectStatus';

const projectStatusColumns: ColumnConfig<ProjectStatus, NestedKeyOf<ProjectStatus>>[] = [
  { id: 'id', label: 'ID', type: 'string', isDisplayed: false },
  { id: 'name', label: 'Name', type: 'string', isDisplayed: true },
  { id: 'priority', label: 'Priority', type: 'number', isDisplayed: true },
  { id: 'isArchived', label: 'Is Archived', type: 'boolean', isDisplayed: true },
];

type ProjectStatusListParams = {
  tenantID: string;
};

export const ProjectStatusList = ({ tenantID }: ProjectStatusListParams): JSX.Element => {
  const tableModel = useApiTableDataWithoutAggregations({
    rowsRouteKey: 'GET project statuses as admin',
    rowsPathParams: { tenantID },
    extractRows: (list) => list.items,
    initialSortBy: {
      direction: Enums.SortDirection.Ascending,
      name: 'id',
    },
    makeColumnConfigs: () => [...projectStatusColumns],
  });

  return (
    <Page title='Project Statuses'>
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Project Statuses</Typography>
      </Box>
      <DataTable tableModel={tableModel} />
    </Page>
  );
};
