import ReactPDF, { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

import { randomUUID } from '../../utils/UUID';

/** 97 is the ascii code for the letter 'a'. */
const START_OF_ALPHABET = 97;

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
});

type PdfListProps = {
  items: (JSX.Element | string)[];
  order: 'unordered' | 'numbered' | 'alphabetical';
  /** @default {} */
  textStyle?: ReactPDF.TextProps['style'];
  /** @default '16pt' */
  paddingLeft?: string;
};

/** Given an index and `order`, return the appropriate delimiter icon. */
const delimiterIcon = (order: PdfListProps['order'], idx: number): string => {
  switch (order) {
    case 'numbered':
      return `${idx + 1}. `;
    case 'alphabetical':
      return `${String.fromCharCode(START_OF_ALPHABET + idx)}. `;
    case 'unordered':
    default:
      return '\u2022 ';
  }
};

/**
 * Given an array of items, generate an ordered or unordered list.
 */
export const PdfList = ({
  order,
  items,
  textStyle = {},
  paddingLeft = '16pt',
}: PdfListProps): JSX.Element => (
  <View style={{ paddingLeft }}>
    {items.map((item, idx) => (
      <View key={randomUUID()} style={styles.row}>
        <View>
          <Text style={textStyle}>{delimiterIcon(order, idx)}</Text>
        </View>
        {React.isValidElement(item) ? item : <Text style={textStyle}>{item}</Text>}
      </View>
    ))}
  </View>
);
