import { Image, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

export type PdfImageProps = {
  src: string;
  viewStyle?: Style;
  imgStyle?: Style;
};

export const PdfImage = ({ src, viewStyle, imgStyle }: PdfImageProps): JSX.Element => (
  <View style={viewStyle}>
    <Image src={src} style={imgStyle} />
  </View>
);
