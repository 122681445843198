// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import Enums from '../Enums';

export class Scope {
  readonly accessType: Enums.AccessType;
  readonly domainType: Enums.DomainType;

  constructor(data: { [key: string]: any } = {}) {
    this.accessType = data.accessType === undefined ? Enums.AccessType.Read : data.accessType;
    this.domainType = data.domainType === undefined ? Enums.DomainType.Dashboards : data.domainType;
  }

  static zero(): Scope {
    const zeroValues: ExcludeMethodsDeep<Scope> = {
      accessType: Enums.AccessType.Read,
      domainType: Enums.DomainType.Dashboards,
    };
    return new Scope(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewScope = (props: PartialDeep<Scope, { recurseIntoArrays: true }>): Scope =>
  new Scope(props);

export const NewScopeFromDomainObject = (
  props: PartialDeep<DomainObject<Scope>, { recurseIntoArrays: true }>,
): Scope => new Scope(props);
