import LogRocket from 'logrocket';
import React, { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react';
import { ErrorBoundaryProps, withErrorBoundary } from 'react-error-boundary';

/**
 * A slabstack wrapper for react-error-boundary's withErrorBoundary wrapper.
 * This will handle the `onError` function by default, but is allowed to be
 * overridden for more precise control from callers.
 *
 * @example
 * export const SlabRouter = withSlabErrorBoundary(UnboundedSlabRouter, { FallbackComponent: ErrorFullPage });
 */
export const withSlabErrorBoundary = <T extends object>(
  Component: React.ComponentType<T>,
  errorBoundaryProps: ErrorBoundaryProps,
): ForwardRefExoticComponent<PropsWithoutRef<T> & RefAttributes<any>> =>
  withErrorBoundary(Component, {
    onError(error) {
      LogRocket.captureException(error);
    },
    ...errorBoundaryProps,
  });
