// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class Distance {
  readonly meters: number;
  readonly text: string;

  constructor(data: { [key: string]: any } = {}) {
    this.meters = data.meters === undefined ? 0 : data.meters;
    this.text = data.text === undefined ? '' : data.text;
  }

  static zero(): Distance {
    const zeroValues: ExcludeMethodsDeep<Distance> = {
      meters: 0,
      text: '',
    };
    return new Distance(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /** Get the distance in miles instead of meters */
  miles(): number {
    return Math.round(this.meters / 1609.344);
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewDistance = (props: PartialDeep<Distance, { recurseIntoArrays: true }>): Distance =>
  new Distance(props);

export const NewDistanceFromDomainObject = (
  props: PartialDeep<DomainObject<Distance>, { recurseIntoArrays: true }>,
): Distance => new Distance(props);
