import { QueryClient } from '@tanstack/react-query';

// SlabQueryClient uses our default settings to always hit the correct Go API we serve
export const SlabQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Refetch after at least 60 minutes (unless invalidated)
      staleTime: 60 * (60 * 1000),
    },
  },
});
