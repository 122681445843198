import { Box, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { SlabDrawer } from '../../components/SlabDrawer/SlabDrawer';
import { QuoteConfig } from '../../generated-types/QuoteConfig/QuoteConfig';
import { SharedDrawerOverrideProps } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { DefaultEnsureDefined } from '../../utils/DomainHelpers';
import { FormErrorNotification } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import {
  QuoteConfigDrawerSections,
  QuoteConfigSection,
} from './components/QuoteConfigDrawerSections';
import { FormikQuoteConfig, QuoteConfigSchema } from './QuoteConfigFormik';

type QuoteConfigDrawerProps = Omit<
  SharedDrawerOverrideProps<QuoteConfig>,
  'resourceId' | 'onSave'
> & {
  quoteConfigId: string | null;
  onSuccess: (quoteConfig: QuoteConfig) => void;

  /** @default 'QuoteConfig Information' */
  initialSection?: QuoteConfigSection;
};

const QuoteConfigDrawerPage = ({
  quoteConfigId,
  setIsOpen,
  initialSection = 'Details*',
  onSuccess,
  onError,
  onClose,
  ensureDefined = DefaultEnsureDefined,
}: QuoteConfigDrawerProps): JSX.Element | null => {
  const isQuoteConfigIdNull = quoteConfigId === null;

  const createNew = useSlabMutation('POST quote config', {
    onSuccess,
    onError,
  });
  const updateExisting = useSlabMutation('PUT quote config by ID', {
    onSuccess,
    onError,
  });

  const {
    isLoading: isLoadingQuoteConfig,
    isError: isErrorQuoteConfig,
    data: maybeQuoteConfig,
  } = useSlabQuery(
    'GET quote config by ID',
    {
      pathParams: {
        id: quoteConfigId ?? '',
      },
    },
    { enabled: !isQuoteConfigIdNull },
  );

  if (isLoadingQuoteConfig) {
    return <LoadingSpinner />;
  }

  if (isErrorQuoteConfig) {
    return <div>ERROR</div>;
  }

  const quoteConfig = ensureDefined(maybeQuoteConfig);

  const formikQuoteConfig = FormikQuoteConfig(quoteConfig);

  const isNew = formikQuoteConfig.id === NIL_UUID;

  return (
    <Formik
      validationSchema={QuoteConfigSchema}
      initialValues={formikQuoteConfig}
      onSubmit={async (values): Promise<void> => {
        // TODO #2219: Need a NewQuoteConfigFromForm wrapper for this case
        const wireQuoteConfig = new QuoteConfig(_.merge(QuoteConfig.zero(), values));
        if (formikQuoteConfig.id === NIL_UUID) {
          createNew.mutate({
            args: {
              body: wireQuoteConfig,
            },
            schema: QuoteConfigSchema,
          });
        } else {
          updateExisting.mutate({
            args: {
              pathParams: {
                id: formikQuoteConfig.id,
              },
              body: wireQuoteConfig,
            },
            schema: QuoteConfigSchema,
          });
        }
      }}
    >
      {(formikBag): JSX.Element => (
        <Box padding='5rem 3.5rem 5rem 3.5rem'>
          <FormErrorNotification />
          <Typography variant='h1' fontSize='2rem'>
            {isNew ? 'Create ' : 'Edit '}a quote config
          </Typography>
          <QuoteConfigDrawerSections initialSection={initialSection} />
          <Grid pt='1rem' container spacing={2} sx={{ '& button': { width: '100%' } }}>
            <Grid item xs={4}>
              <ButtonPill
                text='cancel'
                variant='secondary'
                onClick={(): void => {
                  formikBag.resetForm({ values: formikQuoteConfig });
                  setIsOpen(false);
                  onClose?.();
                }}
                disabled={createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='reset'
                variant='secondary'
                onClick={(): void => formikBag.resetForm({ values: formikQuoteConfig })}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='save'
                variant='primary'
                onClick={formikBag.submitForm}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
};

export const QuoteConfigDrawer = (props: QuoteConfigDrawerProps): JSX.Element => (
  <SlabDrawer isOpen={props.isOpen}>
    <QuoteConfigDrawerPage {...props} />
  </SlabDrawer>
);
