import hyphen from 'hyphen';
import pattern from 'hyphen/patterns/en-us';

// @react-pdf/textkit has no exported typed option for `wordHyphenation`, so this is taken
//   from it, and properly Typed.

// Taken from https://github.com/diegomura/react-pdf/blob/master/packages/fns/src/isNil.js
const isNil = (value: any): boolean => value === null || value === undefined;

// Taken from https://github.com/diegomura/react-pdf/blob/master/packages/textkit/src/engines/wordHyphenation/index.js
const SOFT_HYPHEN = '\u00ad';
const hyphenator = hyphen(pattern, { async: false }) as hyphen.HyphenationFunctionSync;
const splitHyphen = (word: string): string[] => word.split(SOFT_HYPHEN);

const cache: Record<string, string[]> = {};

const getParts = (word: string): string[] => {
  const base = word.includes(SOFT_HYPHEN) ? word : hyphenator(word);
  return splitHyphen(base);
};

const defaultHyphenation =
  () =>
  (word: string): string[] => {
    const cacheKey = `_${word}`;

    if (isNil(word)) return [];
    if (Object.keys(cache).includes(cacheKey)) return cache[cacheKey];

    cache[cacheKey] = getParts(word);

    return cache[cacheKey];
  };

export default defaultHyphenation;
