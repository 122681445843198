import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { Activities } from '../../../components/Activities/Activities';
import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Activity } from '../../../generated-types/Activity/Activity';
import { Company } from '../../../generated-types/Company/Company';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { IncludeReference } from '../../../utils/DomainHelpers';

export type CompanyActivitiesProps = {
  company: Company;
};

export const CompanyActivities = ({ company }: CompanyActivitiesProps): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activityId, setActivityId] = useState<string | null>(null);

  const {
    isLoading,
    isError,
    data: activities,
  } = useSlabQuery('GET activities by company ID', {
    pathParams: {
      id: company.id,
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || activities === undefined) {
    return <div>ERROR</div>;
  }

  const currentDate = DateTime.now();

  const addCompany = (act: Activity | undefined): Partial<Activity> => ({
    ...act,
    companies: IncludeReference(act?.companies, company),
  });

  return (
    <Box paddingTop='1rem'>
      <Box display='flex' justifyContent='flex-end' paddingBottom='1.5rem'>
        <ButtonPill
          text='add activity'
          variant='secondary'
          size='small'
          onClick={(): void => {
            setActivityId(null);
            setIsDrawerOpen(true);
          }}
          icon='add'
        />
      </Box>
      <Activities
        currentDate={currentDate}
        activities={activities}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        ensureDefined={addCompany}
        activityId={activityId}
        setActivityId={setActivityId}
      />
    </Box>
  );
};
