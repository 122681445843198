import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { Page } from '../../components/Page/Page';

export const NotFound = (): JSX.Element => (
  <Page title='Slabstack'>
    <Box
      display='flex'
      flexDirection='column'
      gap='3rem'
      justifyContent='center'
      alignItems='center'
    >
      <Typography variant='h3'>Page not found</Typography>
      <img
        src='/404.png'
        style={{
          width: '70%',
          height: 'auto',
        }}
        alt='404'
      />
      <Link to='/admin' style={{ textDecoration: 'none', color: 'inherit' }}>
        <ButtonPill text='Return home' variant='primary' onClick={(): void => {}} />
      </Link>
    </Box>
  </Page>
);
