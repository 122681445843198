// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { Currency, NewCurrency } from '../Currency/Currency';
import Enums from '../Enums';
import { Material, NewMaterial } from '../Material/Material';

export class MixMaterial {
  readonly material: Material;
  readonly quantity: string;
  readonly batchUnit: Enums.Unit;
  readonly extendedCost: Currency;
  readonly incompatibleBatchUnit: boolean;

  constructor(data: { [key: string]: any } = {}) {
    this.material = NewMaterial(data.material);
    this.quantity = data.quantity === undefined ? '0' : data.quantity;
    this.batchUnit = data.batchUnit === undefined ? Enums.Unit.CuYd : data.batchUnit;
    this.extendedCost = NewCurrency(data.extendedCost);
    this.incompatibleBatchUnit =
      data.incompatibleBatchUnit === undefined ? false : data.incompatibleBatchUnit;
  }

  static zero(): MixMaterial {
    const zeroValues: ExcludeMethodsDeep<MixMaterial> = {
      material: Material.zero(),
      quantity: '0',
      batchUnit: Enums.Unit.CuYd,
      extendedCost: Currency.zero(),
      incompatibleBatchUnit: false,
    };
    return new MixMaterial(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewMixMaterial = (
  props: PartialDeep<MixMaterial, { recurseIntoArrays: true }>,
): MixMaterial => new MixMaterial(props);

export const NewMixMaterialFromDomainObject = (
  props: PartialDeep<DomainObject<MixMaterial>, { recurseIntoArrays: true }>,
): MixMaterial => new MixMaterial(props);
