import { Box, Button, Grid, useTheme } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { Add, Trash } from 'iconsax-react';
import { useContext } from 'react';

import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { ApiLookupInput } from '../../../components/LookupInput/ApiLookupInput';
import {
  ConstructListQueryParams,
  DEFAULT_LOOKUP_LENGTH,
  LookupInputOption,
} from '../../../components/LookupInput/LookupInputSharedComponents';
import { CompanySummary } from '../../../generated-types/CompanySummary/CompanySummary';
import { ContactSummary } from '../../../generated-types/ContactSummary/ContactSummary';
import Enums from '../../../generated-types/Enums';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { SlabContext } from '../../../SlabContext';
import { ListURLParams, QueryRouteBarrelTypes } from '../../../utils/ApiClient';
import { lookups } from '../../../utils/DomainHelpers';
import { ClearFormikFields, SetFormikValue } from '../../../utils/FormikHelpers';
import { EmptyFormikProject, ProjectFormikType } from '../ProjectFormik';

type DynamicCompanyLookupProps = {
  sectionType: 'contractors' | 'otherCompanies';
};

export const DynamicCompanyLookup = ({ sectionType }: DynamicCompanyLookupProps): JSX.Element => {
  const theme = useTheme();
  const ctx = useContext(SlabContext);
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);

  const formikBag = useFormikContext<ProjectFormikType>();

  const {
    isLoading: isLoadingCompanyStats,
    isError: isErrorCompanyStats,
    data: companyStats,
  } = useSlabQuery('GET company category aggregations', {});

  if (isLoadingCompanyStats) {
    return <LoadingSpinner />;
  }

  if (isErrorCompanyStats || companyStats === undefined) {
    return <div>An error occurred</div>;
  }

  const contractorCount = companyStats
    .filter((ccAgg) => ccAgg.companyCategory === Enums.CompanyCategory.Contractor)
    .reduce((total, ccAgg) => ccAgg.count + total, 0);
  const nonContractorCount = companyStats
    .filter((ccAgg) => ccAgg.companyCategory !== Enums.CompanyCategory.Contractor)
    .reduce((total, ccAgg) => ccAgg.count + total, 0);

  const formikCompanies =
    sectionType === 'contractors' ? formikBag.values.contractors : formikBag.values.otherCompanies;

  return (
    <FieldArray
      name={sectionType}
      render={(arrayHelpers): JSX.Element => (
        <>
          {formikCompanies.map((currentProjectCompany, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={`project-company-grid-${sectionType}-${index}`} container spacing={2}>
              <Grid item xs={5}>
                <ApiLookupInput
                  formState={formikBag.values}
                  name={`${sectionType}.${index}.company`}
                  label='Company*'
                  route={{
                    barrel: 'GET companies',
                    args: (inputText): QueryRouteBarrelTypes['GET companies']['args'] => {
                      const args = ConstructListQueryParams(inputText);
                      const filterBy: ListURLParams<CompanySummary>['filterBy'] = [
                        ...(args.queryParams?.filterBy ?? []),
                        {
                          name: 'category',
                          operation:
                            sectionType === 'contractors'
                              ? Enums.FilterOperation.Equals
                              : Enums.FilterOperation.NotEquals,
                          value: Enums.CompanyCategory.Contractor,
                        },
                      ];
                      return {
                        ...args,
                        queryParams: {
                          ...args.queryParams,
                          perPage: args.queryParams.perPage + 40,
                          filterBy,
                        },
                      };
                    },
                    transform: (companyList): LookupInputOption[] => {
                      const currentIDs = formikCompanies.map((mm) => mm.company.id ?? '');
                      const curSelectedCompanies = companyList.items.filter((c) =>
                        currentIDs.includes(c.id),
                      );
                      const filteredCompanies = companyList.items
                        .filter((c) => !currentIDs.includes(c.id))
                        .slice(0, DEFAULT_LOOKUP_LENGTH);
                      return lookups({
                        items: filteredCompanies,
                        label: (c) => c.name,
                        sublabels: (c) => c.lookupSublabels(usesDispatchCustomer),
                        // The count of selectable items excluding currently selected materials.
                        count: companyList.count - curSelectedCompanies.length,
                      });
                    },
                  }}
                  onMatchChange={(companyId): void => {
                    if (companyId === null) {
                      SetFormikValue(formikBag, `${sectionType}.${index}.contact`, {
                        id: null,
                        option: null,
                      });

                      const prevFormikCompanyId = currentProjectCompany.company.id;
                      if (formikBag.values.winningCompany?.company?.id === prevFormikCompanyId) {
                        ClearFormikFields(formikBag, EmptyFormikProject, 'winningCompany');
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <ApiLookupInput
                  formState={formikBag.values}
                  name={`${sectionType}.${index}.contact`}
                  label='Contact'
                  route={{
                    barrel: 'GET contacts',
                    args: (inputText): QueryRouteBarrelTypes['GET contacts']['args'] => {
                      const args = ConstructListQueryParams(inputText);
                      const filterBy: ListURLParams<ContactSummary>['filterBy'] = [
                        ...(args.queryParams?.filterBy ?? []),
                        {
                          name: 'companyId',
                          operation: Enums.FilterOperation.Equals,
                          value: currentProjectCompany.company.id ?? '',
                        },
                      ];
                      return {
                        ...args,
                        queryParams: {
                          ...args.queryParams,
                          filterBy,
                        },
                      };
                    },
                    transform: (contactList): LookupInputOption[] =>
                      lookups({
                        ...contactList,
                        label: (c) => c.fullName(),
                      }),
                    options: { enabled: currentProjectCompany.company.id !== null },
                  }}
                  disabled={currentProjectCompany.company.id === null}
                />
              </Grid>
              <Grid item xs={2}>
                <Box paddingTop='0.75rem'>
                  <Button
                    onClick={(): void => {
                      const maybeCurrentCompanyId = formikBag.getFieldMeta(
                        `${sectionType}.${index}.company.id`,
                      ).value as string | null | undefined;
                      const currentWinnerId = formikBag.getFieldMeta('winningCompany.company.id')
                        .value as string | null | undefined;
                      arrayHelpers.remove(index);

                      // If current selected company was current winner, clear that value
                      if (maybeCurrentCompanyId === currentWinnerId) {
                        ClearFormikFields(formikBag, EmptyFormikProject, 'winningCompany');
                      }
                    }}
                  >
                    <Trash variant='TwoTone' color={theme.palette.SlabChart.Indigo[300]} />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          ))}
          <Button
            type='button'
            disabled={
              (sectionType === 'contractors' && formikCompanies.length >= contractorCount) ||
              (sectionType === 'otherCompanies' && formikCompanies.length >= nonContractorCount)
            }
            onClick={(): void =>
              arrayHelpers.push({
                company: { id: null, option: null },
                contact: { id: null, option: null },
              })
            }
            sx={{
              textTransform: 'none',
              color: theme.palette.SlabChart.Indigo[300],
            }}
          >
            <Box display='flex' gap='0.5rem'>
              <Add color={theme.palette.SlabChart.Indigo[300]} />
              Add {formikCompanies.length === 0 ? 'company' : 'another'}
            </Box>
          </Button>
        </>
      )}
    />
  );
};
