import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';

import Enums from '../../generated-types/Enums';
import { QuotePriceEscalation } from '../../generated-types/QuotePriceEscalation/QuotePriceEscalation';
import { DomainObject } from '../../utils/ApiClient';
import { Input } from '../Input/Input';
import { InputSwitch } from '../InputSwitch/InputSwitch';

type PriceEscalationInputProps = {
  /** The formik name of the high-level price escalation. */
  name: string;
};

export const PriceEscalationInput = ({ name }: PriceEscalationInputProps): JSX.Element => {
  const formikBag = useFormikContext();

  const formikKind = formikBag.getFieldMeta<DomainObject<QuotePriceEscalation>>(name).value.kind;
  const isPercentageSelected = formikKind === Enums.EscalationKind.Percentage;

  return (
    <Grid container spacing={2} alignItems='flex-end'>
      <Grid item xs={4}>
        <Input name={`${name}.escalationDate`} label='Escalation date*' type='date' />
      </Grid>
      <Grid item xs={8}>
        <InputSwitch
          switchFormikName={`${name}.kind`}
          leftLabel='Percentage Change'
          rightLabel='Flat Amount'
          leftValue={Enums.EscalationKind.Percentage}
          rightValue={Enums.EscalationKind.Flat}
          leftInputProps={{
            name: `${name}.changeRatio`,
            type: 'number',
            label: `Percentage Change${isPercentageSelected ? '*' : ''}`,
            precision: 1,
            endAdornment: '%',
          }}
          rightInputProps={{
            name: `${name}.flatChange`,
            type: 'currency',
            label: `Flat Amount${isPercentageSelected ? '' : '*'}`,
            startAdornment: '$',
          }}
        />
      </Grid>
    </Grid>
  );
};
