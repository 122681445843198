export class List<T> {
  readonly items: T[];
  readonly count: number;

  constructor(
    itemInstantiator: (d: { [key: string]: any }) => T,
    data: { [key: string]: any } = {},
  ) {
    this.count = data.count === undefined ? 0 : data.count;
    this.items = (data.items ?? []).map((items: any) => itemInstantiator(items));
  }

  static zero(): List<any> {
    return new List(() => ({}), {});
  }
}
