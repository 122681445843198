// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import Enums from '../Enums';
import { NewPdfPart, PdfPart } from '../PdfPart/PdfPart';

export class PdfTemplate {
  readonly id: string;
  readonly type: Enums.PdfTemplateType;
  readonly template: string;
  readonly name: string;
  readonly parts: PdfPart[];

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.type = data.type === undefined ? Enums.PdfTemplateType.Code : data.type;
    this.template = data.template === undefined ? '' : data.template;
    this.name = data.name === undefined ? '' : data.name;
    this.parts = (data.parts ?? []).map((parts: any) => NewPdfPart(parts));
  }

  static zero(): PdfTemplate {
    const zeroValues: ExcludeMethodsDeep<PdfTemplate> = {
      id: NIL_UUID,
      type: Enums.PdfTemplateType.Code,
      template: '',
      name: '',
      parts: [],
    };
    return new PdfTemplate(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewPdfTemplate = (
  props: PartialDeep<PdfTemplate, { recurseIntoArrays: true }>,
): PdfTemplate => new PdfTemplate(props);

export const NewPdfTemplateFromDomainObject = (
  props: PartialDeep<DomainObject<PdfTemplate>, { recurseIntoArrays: true }>,
): PdfTemplate => new PdfTemplate(props);
