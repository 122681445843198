import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { FormikContextType, useFormikContext } from 'formik';
import _ from 'lodash';
import { useState } from 'react';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import { Input } from '../../../components/Input/Input';
import { ApiLookupInput } from '../../../components/LookupInput/ApiLookupInput';
import { LocalLookupInput } from '../../../components/LookupInput/LocalLookupInput';
import {
  ConstructListQueryParams,
  DEFAULT_LOOKUP_LENGTH,
  LookupInputOption,
} from '../../../components/LookupInput/LookupInputSharedComponents';
import { Currency, NewCurrency } from '../../../generated-types/Currency/Currency';
import Enums from '../../../generated-types/Enums';
import { Material } from '../../../generated-types/Material/Material';
import { Mix } from '../../../generated-types/Mix/Mix';
import { Plant } from '../../../generated-types/Plant/Plant';
import { NewProduct, Product } from '../../../generated-types/Product/Product';
import { QueryRouteBarrelTypes } from '../../../utils/ApiClient';
import {
  CurrencyString,
  FormattedNullableYupCurrency,
  FormattedYupCurrency,
  NullableCurrencyString,
} from '../../../utils/Currency';
import { lookups } from '../../../utils/DomainHelpers';
import { SetFormikValue } from '../../../utils/FormikHelpers';
import { UnitFromString, UnitOptions } from '../../../utils/UnitHelpers';
import { randomUUID } from '../../../utils/UUID';
import { YupSchemaNullableReferenceType, YupSchemaReferenceType } from '../../../utils/YupHelpers';
import { ProductDrawerProps } from '../ProductDrawer';
import { FormikProduct, ProductFormikType } from '../ProductFormik';

type InitialProductDrawerStepProps = Pick<ProductDrawerProps, 'setIsOpen' | 'onClose'> & {
  onNext: () => void;
};

export const InitialProductDrawerStep = ({
  setIsOpen,
  onClose,
  onNext,
}: InitialProductDrawerStepProps): JSX.Element => {
  const formikBag = useFormikContext<ProductFormikType>();

  return (
    <Box padding='5rem 3.5rem 5rem 3.5rem'>
      <Typography variant='h1' fontSize='2rem'>
        Select the type of product
      </Typography>
      <FormControl component='fieldset' sx={{ paddingTop: '1.5rem' }}>
        <RadioGroup
          name='category'
          value={formikBag.values.category}
          onChange={(event): void => {
            SetFormikValue(formikBag, 'category', event.currentTarget.value);
            formikBag.setFieldTouched('category', true);
          }}
          sx={{
            gap: '0.75rem',
          }}
        >
          {Object.values(Enums.ProductCategory).map((pcKey) => (
            <FormControlLabel
              key={randomUUID()}
              value={pcKey}
              control={<Radio />}
              label={`${Enums.ProductCategory[pcKey]} product`}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Grid container paddingTop='2rem' spacing={2} sx={{ '& button': { width: '100%' } }}>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <ButtonPill
            text='cancel'
            variant='secondary'
            onClick={(): void => {
              setIsOpen(false);
              onClose?.();
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <ButtonPill
            text='continue'
            variant='primary'
            onClick={(): void => {
              if (formikBag.values.category === Enums.ProductCategory.Mix) {
                SetFormikValue(formikBag, 'cuydVolume', 1);
              }
              onNext();
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

type RecalculateDisplayFieldsType = {
  formikBag: FormikContextType<ProductFormikType>;

  plant: Plant | undefined;
  mix: Mix | undefined;
  material: Material | undefined;

  plantId?: string | null;
  mixId?: string | null;
  materialId?: string | null;
  materialCostOverrideNumber?: string;
  otherCostOverrideNumber?: string;
  targetMarginNumber?: string;
  listPriceNumber?: string;
  measurementUnit?: Enums.Unit;
};

/**
 * Check the current formik values to update the display fields via formik
 * to have the correct values. If any value is omitted (undefined), then
 * do not change the previous formik value.
 */
export const RecalculateDisplayFields = ({
  formikBag,

  plant,
  mix,
  material,

  plantId,
  mixId,
  materialId,
  materialCostOverrideNumber,
  otherCostOverrideNumber,
  targetMarginNumber,
  listPriceNumber,
  measurementUnit,
}: RecalculateDisplayFieldsType): ProductFormikType => {
  const {
    mix: formikMix,
    resaleMaterial: formikResaleMaterial,
    listPrice: formikListPrice,
  } = formikBag.values;

  // This formik product is used to "clear" values so they match the initial formik state.
  const emptyFormikProduct = FormikProduct(undefined);

  const hasPlantChanged = plantId !== undefined;
  const hasMixChanged = mixId !== undefined;
  const hasMaterialChanged = materialId !== undefined;

  // If plant changes, `mix` and `material` will always be `undefined`.
  const mixForCalculation = mix ?? null;
  const materialForCalculation = material ?? null;

  // Used for margin calculations, so consider if mix/material have changed
  const materialCostOverrideMargin = ((): ProductFormikType['materialCostOverride'] => {
    // User changed the material cost override number to a non-zero value
    if (materialCostOverrideNumber !== undefined && materialCostOverrideNumber !== '') {
      return {
        number: materialCostOverrideNumber,
        currency: 'USD',
      };
    }

    // A core object (plant/mix/material) has changed, or the user has cleared the materialCostOverride
    if (
      hasPlantChanged ||
      hasMixChanged ||
      hasMaterialChanged ||
      materialCostOverrideNumber === ''
    ) {
      // No plant is selected so clear the value
      if (plant === undefined) {
        return null;
      }
      // A mix exists, so use its value
      if (mixForCalculation !== null) {
        return mixForCalculation.materialCostOverride ?? mixForCalculation.materialCost;
      }
      // A material exists, so use its value
      if (materialForCalculation !== null) {
        // The current measurementUnit is not equal to the inProductCost unit, so consider it empty
        if (
          (measurementUnit ?? formikBag.values.measurementUnit) !==
          materialForCalculation.inProductCost?.unit
        ) {
          return null;
        }
        return materialForCalculation.inProductCost.amount;
      }
      // A plant is selected, but no mix/material, and the override is empty so consider it empty
      return null;
    }

    // Nothing changed about the materialCostOverride so use the current formik value.
    // If it is empty, set it to null since this is for calculations.
    if (
      formikBag.values.materialCostOverride === null ||
      formikBag.values.materialCostOverride.number === ''
    ) {
      return null;
    }
    // Make sure to remove commas, which are stored in the formik state.
    return {
      number: formikBag.values.materialCostOverride.number.replaceAll(',', ''),
      currency: formikBag.values.materialCostOverride.currency,
    };
  })();

  const materialCostOverrideFormik = ((): ProductFormikType['materialCostOverride'] => {
    // If we switch plant, mix, or material, clear the override formik field.
    if (
      hasPlantChanged ||
      hasMixChanged ||
      hasMaterialChanged ||
      materialCostOverrideNumber === ''
    ) {
      return emptyFormikProduct.materialCostOverride;
    }

    // The user did not make a change, so return the current value, or empty if current value does not exist
    if (materialCostOverrideNumber === undefined) {
      return (formikBag.values.materialCostOverride?.number ?? '') === ''
        ? emptyFormikProduct.materialCostOverride
        : formikBag.values.materialCostOverride;
    }

    // A user has made a change so use that value
    return {
      number: materialCostOverrideNumber,
      currency: 'USD',
    };
  })();

  // Since this is used just for calculations, if it is "empty" make it null.
  const otherCostOverrideMargin = ((): ProductFormikType['otherCostOverride'] => {
    if (hasPlantChanged && plant === undefined) {
      return null;
    }
    const otherCost =
      otherCostOverrideNumber !== undefined
        ? NewCurrency({
            number: otherCostOverrideNumber,
            currency: formikBag.values.otherCostOverride?.currency ?? undefined,
          })
        : NewCurrency({
            number: (formikBag.values.otherCostOverride?.number ?? '').replaceAll(',', ''),
            currency: formikBag.values.otherCostOverride?.currency,
          });

    if (otherCost === null || otherCost.number === undefined || otherCost.number === '') {
      return null;
    }
    return otherCost;
  })();

  const otherCostOverrideFormik = ((): ProductFormikType['otherCostOverride'] => {
    // If we switch plant, mix, or material, clear the override formik field.
    if (hasPlantChanged || hasMixChanged || hasMaterialChanged || otherCostOverrideNumber === '') {
      return emptyFormikProduct.otherCostOverride;
    }

    // The user did not make a change, so return the current value, or empty if current value does not exist
    if (otherCostOverrideNumber === undefined) {
      return (formikBag.values.otherCostOverride?.number ?? '') === ''
        ? emptyFormikProduct.otherCostOverride
        : formikBag.values.otherCostOverride;
    }

    // A user has made a change so use that value
    return {
      number: otherCostOverrideNumber,
      currency: 'USD',
    };
  })();

  const targetMargin = ((): string => {
    // No current plant selected
    if (plant === undefined) {
      return '';
    }
    // A new plant was selected
    if (hasPlantChanged && plant !== undefined) {
      const tm = plant.readyMixConstants?.targetMargin;
      return tm === undefined ? '' : String(parseFloat((parseFloat(tm) * 100).toFixed(1)));
    }
    // Use the new target margin, or the current set target margin
    return targetMarginNumber ?? formikBag.values.targetMargin;
  })();

  const minimumMargin = ((): string => {
    // No current plant selected
    if (plant === undefined) {
      return '';
    }
    // A new plant was selected
    if (hasPlantChanged && plant !== undefined) {
      const tm = plant.readyMixConstants?.minimumMargin;
      return tm === undefined ? '' : String(parseFloat((parseFloat(tm) * 100).toFixed(1)));
    }
    // Use the current set minimum margin
    return formikBag.values.minimumMargin;
  })();

  const measurementUnitTempProduct = ((): string => {
    // A new material has been selected
    if (hasMaterialChanged && materialForCalculation !== null) {
      return materialForCalculation.inProductCost.unit;
    }
    // A new measurementUnit has been selected
    if (measurementUnit !== undefined) {
      return measurementUnit;
    }
    // No change was made so use the current value
    return formikBag.values.measurementUnit;
  })();

  // Instantiate a temporary product class to utilize class methods
  // TODO #2219 Need a NewProductFromForm wrapper for this case
  const tempProduct = new Product(
    _.merge(Product.zero(), {
      plant,
      measurementUnit: measurementUnitTempProduct,
      category: formikBag.values.category,
      mix: mixForCalculation,
      resaleMaterial: materialForCalculation,
      materialCostOverride: materialCostOverrideMargin,
      otherCostOverride: otherCostOverrideMargin,
      targetMargin: targetMargin === '' ? '' : (parseFloat(targetMargin) / 100).toFixed(3),
    }),
  );

  const otherCostForCalculation = ((): string | null => {
    const otherCost = tempProduct.calculateOtherCost();
    if (otherCost === null) return null;
    return CurrencyString({ cur: otherCost });
  })();
  const suggestedOtherCostDisplay = ((): string | null => {
    const otherCost = tempProduct.calculateSuggestedOtherCost();
    if (otherCost === null) return null;
    return CurrencyString({ cur: otherCost });
  })();
  const suggestedPriceDisplay = NullableCurrencyString({
    cur: plant === undefined ? null : tempProduct.calculateSuggestedPrice(),
    omitNull: false,
  });

  const { plantMinimumMargin, plantTargetMargin } = ((): {
    plantMinimumMargin: string;
    plantTargetMargin: string;
  } => {
    if (plant === undefined || plant.readyMixConstants === null) {
      return {
        plantMinimumMargin: '',
        plantTargetMargin: '',
      };
    }

    const newMinimumMargin = String(
      parseFloat((parseFloat(plant.readyMixConstants.minimumMargin) * 100).toFixed(3)),
    );
    const newTargetMargin = String(
      parseFloat((parseFloat(plant.readyMixConstants.targetMargin) * 100).toFixed(3)),
    );

    return {
      plantMinimumMargin: newMinimumMargin,
      plantTargetMargin: newTargetMargin,
    };
  })();

  const newPlantReference = ((): YupSchemaReferenceType => {
    if (hasPlantChanged) {
      return {
        id: plantId,
        option:
          plantId === null
            ? null
            : {
                value: plantId,
                label: plant?.name ?? '',
              },
      };
    }
    return formikBag.values.plant;
  })();

  // Plant has changed which clears mix/material, so set default empty values and set margins appropriately
  if (hasPlantChanged) {
    const newFormikValues: ProductFormikType = {
      ...formikBag.values,
      minimumMargin: plantMinimumMargin,
      targetMargin: plantTargetMargin,
      plant: newPlantReference,

      materialCostOverride: emptyFormikProduct.materialCostOverride,
      otherCostOverride: emptyFormikProduct.otherCostOverride,
      listPrice: emptyFormikProduct.listPrice,
      mix: emptyFormikProduct.mix,
      resaleMaterial: emptyFormikProduct.resaleMaterial,
      displayOnly: {
        ...emptyFormikProduct.displayOnly,

        otherCost: suggestedOtherCostDisplay,
        suggestedPrice: suggestedPriceDisplay,
        minimumMargin: plantMinimumMargin === '' ? null : plantMinimumMargin,
        targetMargin: plantTargetMargin === '' ? null : plantTargetMargin,
      },
    };

    return newFormikValues;
  }

  const materialCostDisplay =
    mixForCalculation !== null || materialForCalculation !== null
      ? tempProduct.suggestedMaterialCostDisplay()
      : null;
  const listPrice = ((): ProductFormikType['listPrice'] => {
    // Plant/mix/material changed, so fill listPrice with suggested price if exists
    if (hasPlantChanged || hasMixChanged || hasMaterialChanged) {
      return suggestedPriceDisplay === null
        ? Currency.zero()
        : { number: suggestedPriceDisplay.replace('$', '').replace(',', ''), currency: 'USD' };
    }

    // User passed in a value, so use it
    if (listPriceNumber !== undefined) {
      return listPriceNumber === ''
        ? null
        : {
            number: listPriceNumber,
            currency: 'USD',
          };
    }

    // Use the current formik listPrice
    return formikListPrice;
  })();

  // Instantiate a temp product for margin calculations. This includes overrides from the tempProduct
  // as well as formatted materialCost, otherCost, and listPrice values.
  const productForMargin = NewProduct({
    ...tempProduct,
    materialCost: {
      number: materialCostDisplay === null ? '0' : materialCostDisplay.replaceAll('$', '') ?? '',
      currency: 'USD',
    },
    otherCost: {
      number:
        otherCostForCalculation === null ? '0' : otherCostForCalculation.replaceAll('$', '') ?? '',
      currency: 'USD',
    },
    listPrice: {
      number: listPrice === null ? '0' : listPrice.number,
      currency: 'USD',
    },
  });

  const newMixReference = ((): YupSchemaNullableReferenceType => {
    // No change to plant or mix so it is the current formik mix
    if (!hasPlantChanged && !hasMixChanged) {
      return formikMix;
    }
    // A new mix was selected
    if (hasMixChanged && mix !== undefined) {
      return {
        id: mixId,
        option: {
          value: mixId,
          label: mix.name,
        },
      };
    }
    // Plant changed so clear mix
    return {
      id: null,
      option: null,
    };
  })();

  const newMaterialReference = ((): YupSchemaNullableReferenceType => {
    // No change to plant or material so it is the current formik material
    if (!hasPlantChanged && !hasMaterialChanged) {
      return formikResaleMaterial;
    }
    // A new material was selected
    if (hasMaterialChanged && material !== undefined) {
      return {
        id: materialId,
        option: {
          value: materialId,
          label: material.name,
        },
      };
    }
    // Plant changed so clear material
    return {
      id: null,
      option: null,
    };
  })();

  const newFormikValues: ProductFormikType = {
    ...formikBag.values,
    measurementUnit: tempProduct.measurementUnit,
    materialCostOverride:
      materialCostOverrideFormik === null
        ? emptyFormikProduct.materialCostOverride
        : FormattedYupCurrency({
            cur: materialCostOverrideFormik,
          }),
    otherCostOverride:
      otherCostOverrideFormik === null
        ? emptyFormikProduct.otherCostOverride
        : FormattedNullableYupCurrency({
            cur: otherCostOverrideFormik,
          }),
    listPrice: FormattedNullableYupCurrency({
      cur: listPrice,
    }),
    minimumMargin,
    targetMargin,
    plant: newPlantReference,
    mix: newMixReference,
    resaleMaterial: newMaterialReference,
    displayOnly: {
      ...formikBag.values.displayOnly,
      minimumMargin: plantMinimumMargin === '' ? null : plantMinimumMargin,
      targetMargin: plantTargetMargin === '' ? null : plantTargetMargin,
      actualMargin: productForMargin.marginDisplay(),
      marginOverMaterials: productForMargin.marginOverMaterialDisplay(),
      materialCost: materialCostDisplay,
      otherCost: suggestedOtherCostDisplay,
      suggestedPrice: suggestedPriceDisplay,
      materialName: materialForCalculation?.name ?? null,
      mixName: mixForCalculation?.name ?? null,
    },
  };

  if (newFormikValues.measurementUnit !== Enums.Unit.Ea && newFormikValues.cuydVolume !== null) {
    newFormikValues.cuydVolume = 1;
  }

  return newFormikValues;
};

type MainProductDrawerContentProps = {
  initialProduct?: Product;
};

const MainProductDrawerContent = ({
  initialProduct,
}: MainProductDrawerContentProps): JSX.Element => {
  const formikBag = useFormikContext<ProductFormikType>();
  const [curPlant, setCurPlant] = useState<undefined | Plant>(initialProduct?.plant);
  const [curMix, setCurMix] = useState<undefined | Mix>(initialProduct?.mix ?? undefined);
  const [curMaterial, setCurMaterial] = useState<undefined | Material>(
    initialProduct?.resaleMaterial ?? undefined,
  );

  const curSelectedPlantID = formikBag.values.plant.id;

  const includeSection = ((): 'mix' | 'material' | undefined => {
    if (formikBag.values.category === Enums.ProductCategory.Mix) {
      return 'mix';
    }
    if (formikBag.values.category === Enums.ProductCategory.Resale) {
      return 'material';
    }
    return undefined;
  })();

  const mixSection =
    includeSection === 'mix' ? (
      <Grid item xs={6}>
        <ApiLookupInput
          formState={formikBag.values}
          name='mix'
          label='Select mix design'
          route={{
            barrel: 'GET mixes',
            args: (inputText): QueryRouteBarrelTypes['GET mixes']['args'] => {
              const args: QueryRouteBarrelTypes['GET mixes']['args'] = {
                queryParams: {
                  page: 0,
                  perPage: DEFAULT_LOOKUP_LENGTH,
                  filterBy: [
                    {
                      name: 'plantId',
                      operation: Enums.FilterOperation.Equals,
                      value: formikBag.values.plant.id ?? '',
                    },
                  ],
                },
              };
              if (inputText.trim() !== '' && args.queryParams?.filterBy !== undefined) {
                args.queryParams.filterBy.push({
                  operation: Enums.FilterOperation.Lookup,
                  value: inputText,
                });
              }
              return args;
            },
            transform: (ml): LookupInputOption[] =>
              lookups({
                ...ml,
                label: (m) => m.name,
              }),
            options: {
              enabled: formikBag.values.plant.id !== null,
            },
          }}
          disabled={curSelectedPlantID === null}
          onMatchChange={(mixId, ml): void => {
            const maybeMix = (ml?.items ?? []).find((m) => m.id === mixId);
            const newFormikValues = RecalculateDisplayFields({
              mixId,
              mix: maybeMix,

              formikBag,
              plant: curPlant,
              material: curMaterial,
            });
            if (formikBag.values !== newFormikValues) {
              formikBag.setValues(newFormikValues);
            }
            setCurMix(maybeMix);
          }}
        />
      </Grid>
    ) : null;

  const materialSection =
    includeSection === 'material' ? (
      <Grid item xs={6}>
        <ApiLookupInput
          formState={formikBag.values}
          name='resaleMaterial'
          label='Select material'
          route={{
            barrel: 'GET materials',
            args: (inputText): QueryRouteBarrelTypes['GET materials']['args'] => {
              const args: QueryRouteBarrelTypes['GET materials']['args'] = {
                queryParams: {
                  page: 0,
                  perPage: DEFAULT_LOOKUP_LENGTH,
                  filterBy: [
                    {
                      name: 'plantId',
                      operation: Enums.FilterOperation.Equals,
                      value: formikBag.values.plant.id ?? '',
                    },
                  ],
                },
              };
              if (inputText.trim() !== '' && args.queryParams?.filterBy !== undefined) {
                args.queryParams.filterBy.push({
                  operation: Enums.FilterOperation.Lookup,
                  value: inputText,
                });
              }
              return args;
            },
            transform: (ml): LookupInputOption[] =>
              lookups({
                ...ml,
                label: (m) => m.name,
              }),
            options: {
              enabled: formikBag.values.plant.id !== null,
            },
          }}
          disabled={curSelectedPlantID === null}
          onMatchChange={(materialId, ml): void => {
            const maybeMaterial = (ml?.items ?? []).find((m) => m.id === materialId);
            const newFormikValues = RecalculateDisplayFields({
              materialId,
              material: maybeMaterial,

              formikBag,
              plant: curPlant,
              mix: curMix,
            });
            if (formikBag.values !== newFormikValues) {
              formikBag.setValues(newFormikValues);
            }
            setCurMaterial(maybeMaterial);
          }}
        />
      </Grid>
    ) : null;

  return (
    <Box display='flex' flexDirection='column' gap='1rem'>
      <Box display='flex' alignItems='center' paddingTop='1.25rem' gap='1.25rem'>
        <Typography variant='h2'>Product details</Typography>
        <Chip label={formikBag.values.category} color='primary' />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='alternateID' label='Product ID*' />
        </Grid>
        <Grid item xs={6}>
          <Input name='name' label='Product name*' />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ApiLookupInput
            formState={formikBag.values}
            name='plant'
            label='Plant*'
            route={{
              barrel: 'GET plants',
              args: ConstructListQueryParams,
              transform: (pl): LookupInputOption[] =>
                lookups({
                  ...pl,
                  label: (p) => p.name,
                }),
            }}
            onMatchChange={(plantId, pl): void => {
              const maybePlant = (pl?.items ?? []).find((p) => p.id === plantId);
              const newFormikValues = RecalculateDisplayFields({
                plantId,
                plant: maybePlant,

                formikBag,
                material: undefined,
                mix: undefined,
              });
              if (formikBag.values !== newFormikValues) {
                formikBag.setValues(newFormikValues);
              }
              setCurPlant(maybePlant);
              if (maybePlant === undefined) {
                setCurMix(undefined);
                setCurMaterial(undefined);
              }
            }}
          />
        </Grid>
        {mixSection}
        {materialSection}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <LocalLookupInput
            formState={formikBag.values}
            name='measurementUnit'
            label='Unit of sale*'
            disabled={formikBag.values.category === Enums.ProductCategory.Mix}
            options={UnitOptions}
            grouped
            onMatchChange={(measurementUnit): void => {
              const newFormikValues = RecalculateDisplayFields({
                measurementUnit: UnitFromString(measurementUnit)?.value,

                formikBag,
                plant: curPlant,
                material: curMaterial,
                mix: curMix,
              });
              if (formikBag.values !== newFormikValues) {
                formikBag.setValues(newFormikValues);
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {formikBag.values.measurementUnit === Enums.Unit.Ea && (
            <Input
              name='cuydVolume'
              label='CUYD per unit'
              type='number'
              disabled={formikBag.values.category === Enums.ProductCategory.Mix}
            />
          )}
        </Grid>
      </Grid>
      <Divider />
      <Typography variant='h2' paddingTop='0.25rem'>
        Costs
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='displayOnly.materialCost' label='Cost of raw materials' disabled />
        </Grid>
        <Grid item xs={6}>
          <Input
            name='materialCostOverride'
            label='Override'
            type='currency'
            startAdornment='$'
            onInputChange={async (materialCostOverrideNumber): Promise<void> => {
              const newFormikValues = RecalculateDisplayFields({
                materialCostOverrideNumber,

                formikBag,
                plant: curPlant,
                material: curMaterial,
                mix: curMix,
              });
              if (formikBag.values !== newFormikValues) {
                formikBag.setValues(newFormikValues);
              }
            }}
            disabled={curSelectedPlantID === null}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='displayOnly.otherCost' label='Other costs' disabled />
        </Grid>
        <Grid item xs={6}>
          <Input
            name='otherCostOverride'
            label='Override'
            type='currency'
            startAdornment='$'
            onInputChange={async (otherCostOverrideNumber): Promise<void> => {
              const newFormikValues = RecalculateDisplayFields({
                otherCostOverrideNumber,

                formikBag,
                plant: curPlant,
                material: curMaterial,
                mix: curMix,
              });
              if (formikBag.values !== newFormikValues) {
                formikBag.setValues(newFormikValues);
              }
            }}
            disabled={curSelectedPlantID === null}
          />
        </Grid>
      </Grid>
      <Divider />
      <Typography variant='h2' paddingTop='0.25rem'>
        Suggested Pricing
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            name='displayOnly.minimumMargin'
            label='Suggested minimum margin'
            endAdornment='%'
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            name='minimumMargin'
            label='Minimum margin*'
            type='number'
            endAdornment='%'
            disabled={curSelectedPlantID === null}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            name='displayOnly.targetMargin'
            label='Suggested target margin'
            endAdornment='%'
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            name='targetMargin'
            label='Target margin*'
            type='number'
            endAdornment='%'
            onInputChange={async (targetMarginNumber): Promise<void> => {
              const newFormikValues = RecalculateDisplayFields({
                targetMarginNumber,

                formikBag,
                plant: curPlant,
                material: curMaterial,
                mix: curMix,
              });
              if (formikBag.values !== newFormikValues) {
                formikBag.setValues(newFormikValues);
              }
            }}
            disabled={curSelectedPlantID === null}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='displayOnly.suggestedPrice' label='Suggested Price' disabled />
        </Grid>
        <Grid item xs={6}>
          <Input
            name='listPrice'
            label='List Price'
            type='currency'
            startAdornment='$'
            onInputChange={async (listPriceNumber): Promise<void> => {
              const newFormikValues = RecalculateDisplayFields({
                listPriceNumber,

                formikBag,
                plant: curPlant,
                material: curMaterial,
                mix: curMix,
              });
              if (formikBag.values !== newFormikValues) {
                formikBag.setValues(newFormikValues);
              }
            }}
            disabled={curSelectedPlantID === null}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='displayOnly.marginOverMaterials' label='Margin over materials' disabled />
        </Grid>
        <Grid item xs={6}>
          <Input name='displayOnly.actualMargin' label='Total margin' disabled />
        </Grid>
      </Grid>
    </Box>
  );
};

type MainProductDrawerStepProps = Pick<ProductDrawerProps, 'setIsOpen' | 'onClose'> & {
  /** Necessary to reset the form without changing the current category */
  formikProduct: ProductFormikType;
  isNew: boolean;
} & MainProductDrawerContentProps;

export const MainProductDrawerStep = ({
  setIsOpen,
  onClose,
  formikProduct,
  isNew,
  initialProduct,
}: MainProductDrawerStepProps): JSX.Element => {
  const formikBag = useFormikContext<ProductFormikType>();

  const isCategoryOnlyTouchedField =
    formikBag.touched.category !== undefined && Object.keys(formikBag.touched).length === 1;

  return (
    <Box padding='5rem 3.5rem 5rem 3.5rem'>
      <Typography variant='h1' fontSize='2rem'>
        {isNew ? 'Create ' : 'Edit '}a product
      </Typography>
      <MainProductDrawerContent initialProduct={initialProduct} />
      <Grid container spacing={2} sx={{ '& button': { width: '100%' } }}>
        <Grid item xs={4}>
          <ButtonPill
            text='cancel'
            variant='secondary'
            onClick={(): void => {
              formikBag.resetForm({ values: formikProduct });
              setIsOpen(false);
              onClose?.();
            }}
            disabled={formikBag.isSubmitting}
          />
        </Grid>
        <Grid item xs={4}>
          <ButtonPill
            text='reset'
            variant='secondary'
            // Reset must not reset the product category
            onClick={(): void => {
              const curCategory = formikBag.values.category;
              formikBag.resetForm({
                values: {
                  ...formikProduct,
                  category: curCategory,
                },
              });
            }}
            disabled={isCategoryOnlyTouchedField || !formikBag.dirty || formikBag.isSubmitting}
          />
        </Grid>
        <Grid item xs={4}>
          <ButtonPill
            text='save'
            variant='primary'
            onClick={formikBag.submitForm}
            disabled={isCategoryOnlyTouchedField || !formikBag.dirty || formikBag.isSubmitting}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
