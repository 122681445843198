import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Activities } from '../../components/Activities/Activities';
import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Page } from '../../components/Page/Page';
import Enums from '../../generated-types/Enums';
import { Project } from '../../generated-types/Project/Project';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { ProjectDrawer } from './ProjectDrawer';
import { ProjectTable } from './ProjectTable';

export const ProjectList = (): JSX.Element => {
  const { userInfo } = useContext(SlabContext);
  const navigate = useNavigate();

  const [projectId, setProjectId] = useState<string | null>(null);
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false);
  const [activityId, setActivityId] = useState<string | null>(null);

  const { toastHook: projectToastHook, ...projectDrawerProps } = useDrawerManager({
    baseUrl: '/projects',
    resourceTypeName: 'project',
    drawerProps: {
      resourceId: projectId,
    },
  });

  const {
    isLoading: isLoadingStatuses,
    isError: isErrorStatuses,
    data: statusList,
  } = useSlabQuery('GET project statuses', {});

  const {
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    data: userList,
  } = useSlabQuery('GET users', {});

  const isLoading = isLoadingStatuses || isLoadingUsers;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isErrorStatuses || statusList === undefined || isErrorUsers || userList === undefined) {
    return <div>ERROR</div>;
  }

  const currentDate = DateTime.now();

  return (
    <Page title='Projects'>
      {projectToastHook.toast}
      <ProjectDrawer
        {...projectDrawerProps}
        projectId={projectDrawerProps.resourceId}
        onSuccess={(project: Project): void => {
          if (projectId === null) {
            navigate(`/projects/${project.id}`);
          } else {
            projectDrawerProps.setIsOpen(false);
            projectToastHook.showToast('success');
          }
        }}
        ensureDefined={(project): Partial<Project> => {
          if (projectId === null) {
            return {
              ...project,
              user: userInfo.userIfRoles([Enums.RoleName.Salesperson]),
            };
          }
          return { ...project };
        }}
      />
      <Activities
        currentDate={currentDate}
        isDrawerOpen={isActivityDrawerOpen}
        setIsDrawerOpen={setIsActivityDrawerOpen}
        activityId={activityId}
        setActivityId={setActivityId}
        drawerOnly
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Projects</Typography>
        <Box display='flex' gap='1rem'>
          <ButtonPill
            text='add activity'
            variant='secondary'
            onClick={(): void => {
              setActivityId(null);
              setIsActivityDrawerOpen(true);
            }}
            icon='add'
          />
          <ButtonPill
            text='create project'
            variant='primary'
            onClick={(): void => {
              setProjectId(null);
              projectDrawerProps.setIsOpen(true);
            }}
            icon='add'
          />
        </Box>
      </Box>

      <Box height='400px'>
        <ProjectTable
          typeAndReferences={{ type: 'all projects' }}
          onEditPress={(row): void => {
            setProjectId(row.id);
            projectDrawerProps.setIsOpen(true);
          }}
          includeTabs
          includePrint={{ title: 'Projects' }}
          marketSelectorOpts={{ enabled: true }}
          plantSelectorOpts={{ enabled: true }}
          filterCheckboxes={[
            {
              label: 'Hide archived projects',
              filter: {
                name: 'isArchived',
                operation: Enums.FilterOperation.Equals,
                value: false,
              },
              defaultChecked: true,
            },
          ]}
        />
      </Box>
    </Page>
  );
};
