// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.
/* eslint-disable camelcase */ // webgen:keep
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import {
  AutocompleteMatchedSubstring,
  NewAutocompleteMatchedSubstring,
} from '../AutocompleteMatchedSubstring/AutocompleteMatchedSubstring';

export class AutocompleteStructuredFormatting {
  readonly main_text?: string;
  readonly main_text_matched_substrings?: AutocompleteMatchedSubstring[];
  readonly secondary_text?: string;

  constructor(data: { [key: string]: any } = {}) {
    this.main_text = data.main_text;
    this.main_text_matched_substrings = (data.main_text_matched_substrings ?? []).map(
      (main_text_matched_substrings: any) =>
        NewAutocompleteMatchedSubstring(main_text_matched_substrings),
    );
    this.secondary_text = data.secondary_text;
  }

  static zero(): AutocompleteStructuredFormatting {
    const zeroValues: ExcludeMethodsDeep<AutocompleteStructuredFormatting> = {};
    return new AutocompleteStructuredFormatting(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewAutocompleteStructuredFormatting = (
  props: PartialDeep<AutocompleteStructuredFormatting, { recurseIntoArrays: true }>,
): AutocompleteStructuredFormatting => new AutocompleteStructuredFormatting(props);

export const NewAutocompleteStructuredFormattingFromDomainObject = (
  props: PartialDeep<DomainObject<AutocompleteStructuredFormatting>, { recurseIntoArrays: true }>,
): AutocompleteStructuredFormatting => new AutocompleteStructuredFormatting(props);
