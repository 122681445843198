import { Box, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { Input } from '../../../components/Input/Input';
import { YearSelector } from '../../../components/YearSelector/YearSelector';
import { ParseServerDate } from '../../../utils/DateHelpers';
import { ProjectFormikType } from '../ProjectFormik';

type ForecastsSectionProps = {
  initialForecastYear: number;
};

export const ForecastsSection = ({ initialForecastYear }: ForecastsSectionProps): JSX.Element => {
  const formikBag = useFormikContext<ProjectFormikType>();
  const [selectedYear, setSelectedYear] = useState(initialForecastYear);

  // If the initialForecastYear prop changes, set it to the selected year.
  useEffect(() => {
    setSelectedYear(initialForecastYear);
  }, [initialForecastYear]);

  const possibleYears = _.uniqBy(
    formikBag.values.forecasts,
    (f) => ParseServerDate(f.intervalStart).year,
  ).map((yr) => ({
    year: ParseServerDate(yr.intervalStart).year,
    hasValues: formikBag.values.forecasts.some(
      (f) =>
        f.value !== '' &&
        ParseServerDate(f.intervalStart).year === ParseServerDate(yr.intervalStart).year,
    ),
  }));

  return (
    <Box display='flex' flexDirection='column' gap='1rem'>
      <YearSelector
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        yearOpts={possibleYears}
        selectWidth='fullWidth'
      />

      <Box pt='1rem'>
        {((): (JSX.Element | null)[] => {
          const filteredForecasts = formikBag.values.forecasts
            .map((f, formikIdx) => ({ ...f, formikIdx }))
            .filter((f) => ParseServerDate(f.intervalStart).year === selectedYear);
          return filteredForecasts.map((ff, ffi) => {
            if (ffi % 2 === 0) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Grid key={`forecast-container-${ffi}`} container spacing={2}>
                  <Grid item xs={6}>
                    <Input
                      name={`forecasts.${filteredForecasts[ffi].formikIdx}.value`}
                      label={ParseServerDate(filteredForecasts[ffi].intervalStart).monthLong ?? ''}
                      type='number'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name={`forecasts.${filteredForecasts[ffi + 1].formikIdx}.value`}
                      label={
                        ParseServerDate(filteredForecasts[ffi + 1].intervalStart).monthLong ?? ''
                      }
                      type='number'
                    />
                  </Grid>
                </Grid>
              );
            }
            return null;
          });
        })()}
      </Box>
    </Box>
  );
};
