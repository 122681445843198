import { Auth0User, LogoutOptions, Tenant } from '@auth0/auth0-react';
import { ExpandMore } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Chip,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { Logout, Profile2User, Setting2 } from 'iconsax-react';
import LogRocket from 'logrocket';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { SlabContext } from '../../SlabContext';

type TenantSelectionMenuProps = {
  auth0User: Auth0User;
  logout: (options?: LogoutOptions) => void;
};

export const TenantSelectionMenu = ({
  auth0User,
  logout,
}: TenantSelectionMenuProps): JSX.Element => {
  const { userInfo } = useContext(SlabContext);
  const [{ AuthOverride: authOverride }, setCookie, deleteCookie] = useCookies([
    'AuthOverride',
    'User',
  ]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  // Handle the event when the user clicks the user button to open this menu.
  const handleMenuButtonClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  // Handle the event when the user selects a menu item in this menu.
  const handleMenuItemClick = (_event: React.MouseEvent<HTMLElement>, tenant: Tenant): void => {
    setAnchorEl(null);
    if (tenant.userID !== auth0User.activeTenant.userID) {
      setCookie('User', tenant.userID, {
        expires: DateTime.now().plus({ years: 1 }).toJSDate(),
        sameSite: 'strict',
        path: '/',
      });
      window.location.reload();
    }
  };

  // Handle the event when the menu closes.
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const tenants = auth0User.tenants ?? [];
  const isImpersonating = auth0User.admin && (authOverride ?? '') !== '';

  // Render identifying information for the logged-in user as a dropdown button
  // that opens the tenant selection menu when clicked.
  const renderUserButton = (): JSX.Element => (
    <ListItemButton
      id='tenant-button'
      key='tenant-picker'
      aria-haspopup='listbox'
      aria-controls='tenant-menu'
      aria-label='current tenant'
      aria-expanded={open ? 'true' : undefined}
      onClick={handleMenuButtonClick}
    >
      <Box paddingRight='1rem' display='flex' alignItems='center'>
        {auth0User.picture !== undefined ? (
          <Avatar alt='profile image' src={auth0User.picture} />
        ) : (
          <Avatar>{auth0User.given_name?.charAt(0)}</Avatar>
        )}
      </Box>
      <ListItemText>
        <Typography>{auth0User.name}</Typography>
        {isImpersonating ? (
          <Chip
            color='warning'
            label={`as ${userInfo.user.fullName()} (${userInfo.tenant.name})`}
            size='small'
            sx={{ paddingY: 0 }}
          />
        ) : (
          <Typography fontSize='0.875rem' color={theme.palette.SlabBlue[400]}>
            {userInfo.tenant.name}
          </Typography>
        )}
      </ListItemText>
      <ExpandMore sx={{ marginLeft: '1rem' }} />
    </ListItemButton>
  );

  // Render a separator line between menu items.
  const renderSeparator = (): JSX.Element => (
    <Box
      sx={{
        borderTop: `1px solid ${theme.palette.SlabBlue[200]}`,
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
      }}
    />
  );

  // Render information about the currently-selected tenant.
  const renderCurrentTenant = (): JSX.Element => (
    <Box
      sx={{
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        flexDirection: 'column',
        alignItems: 'start',
      }}
    >
      {isImpersonating ? (
        <Typography fontSize='0.75rem'>Impersonating {userInfo.user.fullName()}:</Typography>
      ) : (
        <Typography fontSize='0.75rem'>Logged in as:</Typography>
      )}
      {userInfo.tenant.name}
      {userInfo.user.roles.map((role) => (
        <Typography
          key={role.id}
          fontSize='0.875rem'
          color={theme.palette.SlabBlue[400]}
          sx={{ paddingLeft: '1rem' }}
        >
          {role.name}
        </Typography>
      ))}
    </Box>
  );

  // Render a list of menu items for the other tenants available to the
  // logged-in Auth0 user.
  const renderTenantList = (): JSX.Element | null => {
    if (isImpersonating) {
      return (
        <Box
          sx={{
            fontSize: '0.875rem',
            color: theme.palette.SlabBlue[400],
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            width: '16rem',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          Impersonation does not support fast switching to other tenants.
        </Box>
      );
    }
    if (tenants.length === 0) {
      return null;
    }
    return (
      <Box>
        <Box>
          <Typography
            fontSize='0.75rem'
            sx={{ lineHeight: 2, paddingTop: '0.5rem', paddingLeft: '1rem' }}
          >
            Switch to:
          </Typography>
        </Box>
        {tenants.map((tenant) =>
          tenant.userID === auth0User.activeTenant.userID ? null : (
            <MenuItem
              key={tenant.tenantID}
              onClick={(event): void => handleMenuItemClick(event, tenant)}
              sx={{
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              {tenant.tenantName}
              {tenant.roleNames.map((roleName) => (
                <Typography
                  key={`${tenant.tenantID}|${roleName}`}
                  fontSize='0.875rem'
                  color={theme.palette.SlabBlue[400]}
                  sx={{ paddingLeft: '1rem' }}
                >
                  {roleName}
                </Typography>
              ))}
            </MenuItem>
          ),
        )}
      </Box>
    );
  };

  // Render a menu item that disables impersonation if it is currently active,
  // or otherwise links to the admin-only AuthOverride cookie configuration page.
  const renderAdminPortalItem = (): JSX.Element => {
    if (isImpersonating) {
      return (
        <MenuItem
          onClick={(): void => {
            deleteCookie('AuthOverride', { sameSite: 'strict', path: '/' });
            // Setting location.href instead of using `navigate` to redirect
            // back into the Admin Portal because we need a full page load to
            // propagate the change in auth identity.
            window.location.href = '/_slabstack_staff';
          }}
        >
          <ListItemIcon>
            <Profile2User />
          </ListItemIcon>
          Stop Impersonating
        </MenuItem>
      );
    }
    return (
      <MenuItem
        onClick={(): void => {
          navigate('/_slabstack_staff');
        }}
      >
        <ListItemIcon>
          <Setting2 />
        </ListItemIcon>
        Admin Portal
      </MenuItem>
    );
  };

  // Render a menu item that logs the user out of Auth0.
  const renderLogoutItem = (): JSX.Element => (
    <MenuItem
      onClick={(): void => {
        LogRocket.startNewSession();
        logout({ logoutParams: { returnTo: window.location.origin } });
      }}
    >
      <ListItemIcon>
        <Logout />
      </ListItemIcon>
      Log Out
    </MenuItem>
  );

  return (
    <Box>
      <List component='nav' aria-label='Tenant selection' sx={{ padding: 0 }}>
        {renderUserButton()}
      </List>
      <Menu
        id='tenant-menu'
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'tenant-button',
          role: 'listbox',
        }}
      >
        <Box>
          {renderCurrentTenant()}
          {renderTenantList()}
        </Box>
        {auth0User.admin && (
          <Box>
            {renderSeparator()}
            {renderAdminPortalItem()}
          </Box>
        )}
        {renderSeparator()}
        {renderLogoutItem()}
      </Menu>
    </Box>
  );
};
