import { useTheme } from '@mui/material';
import LogRocket from 'logrocket';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { ButtonPill } from './components/ButtonPill/ButtonPill';
import { ErrorFullPage } from './components/Error/ErrorFullPage';
import { LoadingSpinner } from './components/LoadingSpinner/LoadingSpinner';
import { MaintenanceNotice } from './components/MaintenanceNotice/MaintenanceNotice';
import { useSlabAuth } from './hooks/useSlabAuth';
import { Intercom } from './Intercom';
import { SlabConfig } from './utils/SlabConfig';
import { withSlabErrorBoundary } from './utils/withSlabErrorBoundary';

// Conditionally displays authentication-required content or login page based on a user's authentication state.
const UnboundSlabAuthProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const theme = useTheme();

  // This is the only entry point of useSlabAuth where `user` can be undefined.
  const {
    isLoading,
    isAuthenticated,
    error: authError,
    loginWithPopup,
    user: auth0User,
  } = useSlabAuth();

  useEffect(() => {
    if (isAuthenticated && auth0User !== undefined) {
      LogRocket.identify(auth0User.activeTenant.userID, {
        name: auth0User.name ?? '',
        email: auth0User.email ?? '',
        tenant: auth0User.activeTenant.tenantID,
      });
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return <LoadingSpinner size={15} unit='rem' sx={{ height: '100vh' }} />;
  }

  if (authError?.name !== undefined) {
    // TODO: #546 This should be handled more gracefully...
    return <ErrorFullPage />;
  }

  if (!isAuthenticated) {
    // A user is not authenticated, so boot up our Intercom CS integration without providing user metadata.
    if (SlabConfig.intercom?.apiBase !== undefined && SlabConfig.intercom?.appID !== undefined) {
      Intercom('boot', {
        api_base: SlabConfig.intercom.apiBase,
        app_id: SlabConfig.intercom.appID,

        action_color: theme.palette.SlabYellow[500],
        background_color: theme.palette.SlabBlue[800],
      });
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'between',
          height: '100vh',
          padding: '3rem',
        }}
      >
        <div style={{ display: 'flex', flexBasis: '5rem', flexGrow: 0, alignItems: 'start' }}>
          <MaintenanceNotice />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flexGrow: 1,
            padding: '3rem 3rem 6rem',
          }}
        >
          <img
            src='/logo-words-grey.png'
            alt='Slabstack'
            height='80'
            style={{ paddingRight: '24px' }}
          />
          <ButtonPill
            text='Log In'
            variant='primary'
            onClick={(): void => {
              loginWithPopup({
                authorizationParams: {
                  audience: SlabConfig.auth0.audience,
                },
              }).then(() => window.location.reload());
            }}
          />
        </div>
        <div style={{ display: 'flex', flexBasis: '5rem', flexGrow: 0, alignItems: 'end' }}>
          <a target='_blank' rel='noreferrer' href='https://slabstack.com/terms-and-conditions'>
            Privacy policy
          </a>
        </div>
      </div>
    );
  }

  // A user is authenticated, so boot up our Intercom CS integration with user metadata from Auth0.
  if (
    SlabConfig.intercom?.apiBase !== undefined &&
    SlabConfig.intercom?.appID !== undefined &&
    auth0User !== undefined
  ) {
    Intercom('boot', {
      api_base: SlabConfig.intercom.apiBase,
      app_id: SlabConfig.intercom.appID,

      name: auth0User.name,
      email: auth0User.email,
      user_id: auth0User.activeTenant.userID,

      action_color: theme.palette.SlabYellow[500],
      background_color: theme.palette.SlabBlue[800],
    });
  }

  // React.ReactNode allows us to wrap children with components,
  // but `withSlabErrorBoundary` expects an element so we cast it to JSX.Element.
  return children as JSX.Element;
};

const UnboundSlabAuthProviderThatExtendsUserCookie = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  // We'll be accessing the User cookie, but leave the array of cookie names blank so that we won't automatically
  // re-render every time we re-set the cookie (to extend its expiration every time we mount this component).
  // Since the typings rely on the provided array of cookie names, this requires some type system shenanigans :-(
  const [untypedCookies, untypedSetCookie] = useCookies([]);
  const cookies: { User?: any } = untypedCookies;

  // Extend tenant-selection cookie to make sure it won't expire anytime soon. This can't be part of
  // UnboundSlabAuthProvider because it needs to happen *before* we mount any components that depend on useSlabAuth
  // (or otherwise trigger re-renderings when this cookie is set).
  if (cookies.User !== undefined && cookies.User !== '') {
    untypedSetCookie('User' as never, cookies.User, {
      expires: DateTime.now().plus({ years: 1 }).toJSDate(),
      sameSite: 'strict',
      path: '/',
    });
  }

  return <UnboundSlabAuthProvider>{children}</UnboundSlabAuthProvider>;
};

export const SlabAuthProvider = withSlabErrorBoundary(
  UnboundSlabAuthProviderThatExtendsUserCookie,
  {
    FallbackComponent: ErrorFullPage,
  },
);
