// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import Enums from '../Enums';

export class FeatureFlag {
  readonly isEnabled: boolean;
  readonly name: Enums.FeatureFlagName;

  constructor(data: { [key: string]: any } = {}) {
    this.isEnabled = data.isEnabled === undefined ? false : data.isEnabled;
    this.name = data.name === undefined ? Enums.FeatureFlagName.TestDummy : data.name;
  }

  static zero(): FeatureFlag {
    const zeroValues: ExcludeMethodsDeep<FeatureFlag> = {
      isEnabled: false,
      name: Enums.FeatureFlagName.TestDummy,
    };
    return new FeatureFlag(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewFeatureFlag = (
  props: PartialDeep<FeatureFlag, { recurseIntoArrays: true }>,
): FeatureFlag => new FeatureFlag(props);

export const NewFeatureFlagFromDomainObject = (
  props: PartialDeep<DomainObject<FeatureFlag>, { recurseIntoArrays: true }>,
): FeatureFlag => new FeatureFlag(props);
