import _ from 'lodash';
import { PartialDeep } from 'type-fest';

/**
 * Like the built-in type `Omit`, but it separately omits the property from each of the component types in a
 * discriminated type union.
 */
export type ExcludeFromUnion<T, K extends PropertyKey> = T extends any ? Omit<T, K> : never;

export type ExcludeMethods<T> = Pick<
  T,
  {
    [Key in keyof T]: T[Key] extends Function ? never : Key;
  }[keyof T]
>;

export type ExcludeMethodsDeep<T> = T extends (infer Elem)[]
  ? ExcludeMethodsDeep<Elem>[]
  : T extends object
    ? Pick<
        // Pick from this object type after applying the same logic to sub-properties.
        { [Key in keyof T]: ExcludeMethodsDeep<T[Key]> },
        // List of properties to pick includes all properties except function properties.
        { [Key in keyof T]: T[Key] extends Function ? never : Key }[keyof T]
      >
    : T;

type stripNull<T> = T extends null ? never : T;

type stripNullDeep<T> = T extends (infer Elem)[]
  ? stripNullDeep<Elem>[]
  : T extends object
    ? { [Key in keyof T]: stripNullDeep<T[Key]> }
    : stripNull<T>;

export function RemoveNullProperties<T extends Record<string, any>>(
  obj: T,
): PartialDeep<stripNullDeep<T>> {
  return _.mapValues(obj, (value) => {
    if (value === null || value === undefined) {
      return undefined;
    }
    if (Array.isArray(value)) {
      // Only try to remove null properties from objects (including arrays and class instances).
      return value.map((v: unknown) => (v instanceof Object ? RemoveNullProperties(v) : v));
    }
    if (typeof value === 'object') {
      return RemoveNullProperties(value);
    }
    return value;
  }) as PartialDeep<stripNullDeep<T>>;
}

// See https://dev.to/pffigueiredo/typescript-utility-keyof-nested-object-2pa3#comment-1nm58
type FieldValues = Record<string, any>;

type Primitive = null | undefined | string | number | boolean | symbol | bigint;

type IsTuple<T extends ReadonlyArray<any>> = number extends T['length'] ? false : true;
type TupleKey<T extends ReadonlyArray<any>> = Exclude<keyof T, keyof any[]>;
type ArrayKey = number;

type PathImpl<K extends string | number, V> = V extends Primitive
  ? `${K}`
  : // eslint-disable-next-line no-use-before-define
    `${K}` | `${K}.${Path<V>}`;

export type Path<T> =
  T extends ReadonlyArray<infer V>
    ? IsTuple<T> extends true
      ? {
          [K in TupleKey<T>]-?: PathImpl<K & string, T[K]>;
        }[TupleKey<T>]
      : PathImpl<ArrayKey, V>
    : {
        [K in keyof T]-?: T[K] extends React.Component | React.ReactNode
          ? `${K & string}`
          : PathImpl<K & string, T[K]>;
      }[keyof T];

export type NestedKeyOf<TFieldValues extends FieldValues> = Path<TFieldValues>;

type ArrayPathImpl<K extends string | number, V> = V extends Primitive
  ? never
  : V extends ReadonlyArray<infer U>
    ? U extends Primitive
      ? never
      : // eslint-disable-next-line no-use-before-define
        `${K}` | `${K}.${ArrayPath<V>}`
    : // eslint-disable-next-line no-use-before-define
      `${K}.${ArrayPath<V>}`;

type ArrayPath<T> =
  T extends ReadonlyArray<infer V>
    ? IsTuple<T> extends true
      ? {
          [K in TupleKey<T>]-?: ArrayPathImpl<K & string, T[K]>;
        }[TupleKey<T>]
      : ArrayPathImpl<ArrayKey, V>
    : {
        [K in keyof T]-?: ArrayPathImpl<K & string, T[K]>;
      }[keyof T];

export type PathValue<T, P extends Path<T> | ArrayPath<T>> = T extends any
  ? P extends `${infer K}.${infer R}`
    ? K extends keyof T
      ? R extends Path<T[K]>
        ? PathValue<T[K], R>
        : never
      : K extends `${ArrayKey}`
        ? T extends ReadonlyArray<infer V>
          ? PathValue<V, R & Path<V>>
          : never
        : never
    : P extends keyof T
      ? T[P]
      : P extends `${ArrayKey}`
        ? T extends ReadonlyArray<infer V>
          ? V
          : never
        : never
  : never;

export type NestedKeyOfValue<
  TFieldValues extends FieldValues,
  TFieldPath extends NestedKeyOf<TFieldValues>,
> = PathValue<TFieldValues, TFieldPath>;
