import { Text } from '@react-pdf/renderer';

import { randomUUID } from '../../../../utils/UUID';
import { PdfColumnConfig, PdfColumnFromConfig, PdfTable } from '../../../components/PdfTable';
import { PdfTextWithLine } from '../../components/PdfTextWithLine';

const formatValueForPdf = (value: string | JSX.Element): JSX.Element =>
  typeof value === 'string' ? (
    <Text style={{ fontFamily: 'Helvetica', fontSize: '10pt' }}>{value}</Text>
  ) : (
    value
  );

type SignatureRow = {
  id: string;
  first: string | JSX.Element;
  second: string | JSX.Element;
  third: string | JSX.Element;
};

export const PdfSignatures = (): JSX.Element => {
  const signatureRows: SignatureRow[] = [
    {
      id: randomUUID(),
      first: <PdfTextWithLine text='' underline />,
      second: <PdfTextWithLine text='' underline />,
      third: <PdfTextWithLine text='' underline />,
    },
    {
      id: randomUUID(),
      first: 'NAME (Please Print)',
      second: 'Signature',
      third: 'Date',
    },
  ];

  const signatureCols: PdfColumnConfig<SignatureRow>[] = [
    {
      id: 'first',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf,
    },
    {
      id: 'second',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf,
    },
    {
      id: 'third',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf,
    },
  ];

  return (
    <PdfTable columns={signatureCols.map((col) => PdfColumnFromConfig(col))} rows={signatureRows} />
  );
};
