import { Font } from '@react-pdf/renderer';

// Steps to adding fonts:
// - go to https://gwfh.mranftl.com/fonts
// - search for the font you would like to install
// - select the font styles you'd like to install.
//   See https://react-pdf.org/fonts for reference on 'bold'-to-font weight
// - Download the font files
// - Remove any non-ttf files from the directory
// - Add it to public/fonts & it is good to use

// Note to future devs: thinking of installing any of the following fonts? Prefer these:
//
// * Looking for Arial? Use Helvetica.

export const RegisterFonts = (): void => {
  Font.register({
    family: 'DancingScript',
    fonts: [
      {
        src: '/fonts/dancing-script-v24-latin/dancing-script-v24-latin-regular.ttf',
      },
      {
        src: '/fonts/dancing-script-v24-latin/dancing-script-v24-latin-500.ttf',
        fontStyle: 'medium',
      },
      {
        src: '/fonts/dancing-script-v24-latin/dancing-script-v24-latin-600.ttf',
        fontStyle: 'semi-bold',
      },
      {
        src: '/fonts/dancing-script-v24-latin/dancing-script-v24-latin-700.ttf',
        fontStyle: 'bold',
      },
    ],
  });
  Font.register({
    family: 'OpenSans',
    fonts: [
      {
        src: '/fonts/open-sans-v34-latin/open-sans-v34-latin-regular.ttf',
      },
      {
        src: '/fonts/open-sans-v34-latin/open-sans-v34-latin-700.ttf',
        fontStyle: 'bold',
      },
    ],
  });
  Font.register({
    family: 'Balthazar',
    fonts: [
      {
        src: '/fonts/balthazar-v17-latin/balthazar-v17-latin-regular.ttf',
      },
    ],
  });
  Font.register({
    family: 'InknutAntiqua',
    fonts: [
      {
        src: '/fonts/inknut-antiqua-v14-latin/inknut-antiqua-v14-latin-regular.ttf',
      },
      {
        src: '/fonts/inknut-antiqua-v14-latin/inknut-antiqua-v14-latin-700.ttf',
        fontStyle: 'bold',
      },
    ],
  });
  Font.register({
    family: 'Calibri',
    fonts: [
      {
        src: '/fonts/calibri-font-family/calibri-regular.ttf',
      },
      {
        src: '/fonts/calibri-font-family/calibri-bold.ttf',
        fontStyle: 'bold',
      },
    ],
  });
};
