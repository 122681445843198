import { Style } from '@react-pdf/types';

import Enums from '../../../../generated-types/Enums';
import { Quote } from '../../../../generated-types/Quote/Quote';
import { PdfProductsTable } from '../../components/PdfProductsTable';
import { customDauphinaisUnitLabels } from './PdfMainProductTable';

const tableHeaderViewStyle: Style = {
  textAlign: 'center',
  backgroundColor: '#808080',
  color: 'white',
};

const tableRowViewStyle: Style = {
  textAlign: 'center',
};

const cellTextStyle: Style = {
  fontFamily: 'Helvetica',
  fontSize: '9pt',
};

export const PdfAdditionalProductTable = ({ quote }: { quote: Quote }): JSX.Element => (
  <PdfProductsTable
    products={quote.filterProducts(Enums.QuoteProductKind.Additional)}
    displayHeaders
    columnConfigs={[
      {
        id: 'name',
        label: 'Description',
        type: 'string',
        headerViewStyle: tableHeaderViewStyle,
        rowViewStyle: tableRowViewStyle,
        headerTextStyle: cellTextStyle,
        rowTextStyle: cellTextStyle,
        width: '80%',
      },
      {
        id: 'measurementUnit',
        label: 'UOM',
        type: 'string',
        headerViewStyle: tableHeaderViewStyle,
        rowViewStyle: tableRowViewStyle,
        headerTextStyle: cellTextStyle,
        rowTextStyle: cellTextStyle,
        width: '7%',
        formatValueToString: customDauphinaisUnitLabels,
      },
      {
        id: 'price',
        label: 'Price',
        type: 'Currency',
        headerViewStyle: tableHeaderViewStyle,
        rowViewStyle: tableRowViewStyle,
        headerTextStyle: cellTextStyle,
        rowTextStyle: cellTextStyle,
        width: '13%',
      },
    ]}
    bordered
  />
);
