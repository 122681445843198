import { View } from '@react-pdf/renderer';
import React from 'react';

import { PdfImage, PdfImageProps } from './PdfImage';
import { PdfTextBlock, PdfTextBlockProps } from './PdfTextBlock';

export type PdfHeaderProps = {
  content?: PdfTextBlockProps;
  image?: PdfImageProps;
};

export const PdfHeader = ({ content, image }: PdfHeaderProps): JSX.Element => (
  <View fixed>
    {image !== undefined && <PdfImage {...image} />}
    {content !== undefined && <PdfTextBlock {...content} />}
  </View>
);
