import { Cost } from '../generated-types/Cost/Cost';
import { Currency } from '../generated-types/Currency/Currency';
import { DeliveryCost } from '../generated-types/DeliveryCost/DeliveryCost';
import Enums from '../generated-types/Enums';
import { Product } from '../generated-types/Product/Product';
import { ReadyMixConstants } from '../generated-types/ReadyMixConstants/ReadyMixConstants';

type CalculateOtherCostParams = {
  product: Product;
  otherCostOverride?: Currency | null;
  deliveryCosts?: DeliveryCost | null;
};

/**
 * Combines the specified delivery costs with the fixed costs of the product's plant to calculate the non-material
 * cost for a product.
 *
 * For now, the other cost calculation is only defined for Mix products whose costs are all specified in CuYd.
 * Otherwise, this simply returns otherCostOverride (if provided, otherwise null).
 */
export const CalculateOtherCost = ({
  product,
  deliveryCosts = null,
  otherCostOverride = product.otherCostOverride,
}: CalculateOtherCostParams): Currency | null => {
  if (otherCostOverride !== null) {
    return otherCostOverride;
  }

  const readyMixConstants = product.plant.readyMixConstants ?? ReadyMixConstants.zero();
  if (
    product.category !== Enums.ProductCategory.Mix ||
    readyMixConstants.operatingCost.unit !== Enums.Unit.CuYd
  ) {
    return null;
  }

  // Cost.add() returns null if units are mismatched (on non-zero values), so checking that operatingCost is CuYd
  // above is sufficient to ensure that all non-zero costs are in CuYd.
  const totalCost = readyMixConstants.operatingCost.add(
    readyMixConstants.sgaCost,
    deliveryCosts?.totalDeliveryCost ?? Cost.zero(),
  );
  return totalCost?.amount ?? null;
};

/**
 * Combines a product's material and other costs with the specified delivery costs and the product's target margin to
 * calculate a suggested price for the product.
 */
export const CalculateSuggestedPrice = ({
  product,
  deliveryCosts = null,
  otherCostOverride = product.otherCostOverride,
}: CalculateOtherCostParams): Currency | null => {
  const materialCost =
    product.materialCostOverride ?? product.suggestedMaterialCostCurrency() ?? Currency.zero();
  const otherCost = CalculateOtherCost({ product, deliveryCosts, otherCostOverride });
  const totalCost = otherCost === null ? materialCost : materialCost.add(otherCost);
  return totalCost?.divide(1 - Number(product.targetMargin)) ?? null;
};
