// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class AuthorizationTokenOutput {
  readonly success: boolean;

  constructor(data: { [key: string]: any } = {}) {
    this.success = data.success === undefined ? false : data.success;
  }

  static zero(): AuthorizationTokenOutput {
    const zeroValues: ExcludeMethodsDeep<AuthorizationTokenOutput> = {
      success: false,
    };
    return new AuthorizationTokenOutput(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewAuthorizationTokenOutput = (
  props: PartialDeep<AuthorizationTokenOutput, { recurseIntoArrays: true }>,
): AuthorizationTokenOutput => new AuthorizationTokenOutput(props);

export const NewAuthorizationTokenOutputFromDomainObject = (
  props: PartialDeep<DomainObject<AuthorizationTokenOutput>, { recurseIntoArrays: true }>,
): AuthorizationTokenOutput => new AuthorizationTokenOutput(props);
