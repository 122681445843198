import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import { randomUUID } from '../../utils/UUID';

type YearSelectorProps = {
  selectedYear: number;
  setSelectedYear: React.Dispatch<React.SetStateAction<number>>;
  yearOpts: {
    year: number;
    hasValues: boolean;
  }[];
  /** @default '15rem' */
  selectWidth?: string;
};

export const YearSelector = ({
  selectedYear,
  setSelectedYear,
  yearOpts,
  selectWidth = '15rem',
}: YearSelectorProps): JSX.Element => (
  <FormControl sx={selectWidth === 'fullWidth' ? {} : { width: selectWidth }}>
    <InputLabel>Select year</InputLabel>
    <Select
      value={selectedYear}
      onChange={(e): void => {
        setSelectedYear(Number(e.target.value));
      }}
      label='Select year'
      fullWidth={selectWidth === 'fullWidth'}
    >
      {yearOpts.map((yr) => (
        <MenuItem key={randomUUID()} value={yr.year}>
          <Box>
            <Typography variant='body2'>
              {yr.year}
              {yr.hasValues && (
                <Typography className='sublabel' variant='body3'>
                  {' '}
                  - has forecast data
                </Typography>
              )}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
