import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { DateTime } from 'luxon';
import { useContext, useState } from 'react';

import { Activities, ActivitiesProps } from '../../../components/Activities/Activities';
import { ActivitySchedulerView } from '../../../components/ActivityScheduler/ActivityScheduler';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { User } from '../../../generated-types/User/User';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { SlabContext } from '../../../SlabContext';
import { NIL_UUID, randomUUID } from '../../../utils/UUID';

const sortUsers = (a: User, b: User): number => {
  const fullA = a.fullName();
  const fullB = b.fullName();
  if (fullA < fullB) {
    return -1;
  }
  if (fullB < fullA) {
    return 1;
  }
  return 0;
};

export type UserActivitiesProps = Pick<
  ActivitiesProps,
  'currentDate' | 'isDrawerOpen' | 'setIsDrawerOpen' | 'activityId' | 'setActivityId'
>;

export const UserActivities = ({
  currentDate,
  isDrawerOpen,
  setIsDrawerOpen,
  activityId,
  setActivityId,
}: UserActivitiesProps): JSX.Element => {
  const { userInfo } = useContext(SlabContext);
  const [userId, setUserId] = useState(userInfo.user.id);

  const [selectedView, setSelectedView] = useState<ActivitySchedulerView>('agenda');
  const [selectedDate, setSelectedDate] = useState<DateTime>(currentDate);

  const {
    isLoading: isLoadingActivities,
    isError: isErrorActivities,
    data: activityList,
  } = useSlabQuery(
    'GET activities',
    {},
    {
      enabled: userId === NIL_UUID,
    },
  );

  const {
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    data: userList,
  } = useSlabQuery('GET users', {});

  const {
    isLoading: isLoadingUserActivities,
    isError: isErrorUserActivities,
    data: userActivities,
  } = useSlabQuery(
    'GET activities by user ID',
    { pathParams: { id: userId } },
    { enabled: userId !== NIL_UUID },
  );

  const isLoading = isLoadingActivities || isLoadingUsers || isLoadingUserActivities;

  if (isLoading) {
    return <LoadingSpinner />;
  }
  const isError = isErrorActivities || isErrorUsers || isErrorUserActivities;

  const isDataUndefined = userList === undefined;

  if (isError || isDataUndefined) {
    return <div>ERROR</div>;
  }

  const activities = userId === NIL_UUID ? activityList?.items : userActivities;

  return (
    <Box>
      <Grid item paddingY='1rem' xs={4}>
        <FormControl fullWidth>
          <InputLabel id='activity-assignee-select-label'>Assignee</InputLabel>
          <Select
            labelId='activity-assignee-select-label'
            value={userId}
            onChange={(e): void => {
              setUserId(e.target.value);
            }}
            label='Assignee'
          >
            <MenuItem key={randomUUID()} value={NIL_UUID}>
              All assignees
            </MenuItem>
            {userList.items
              .filter((user) => user.isSelectable())
              .sort(sortUsers)
              .map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.fullName()}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Box height='100%'>
        <Activities
          currentDate={currentDate}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          activityId={activityId}
          setActivityId={setActivityId}
          activities={activities}
          date={selectedDate}
          view={selectedView}
          onDateChange={setSelectedDate}
          onViewChange={setSelectedView}
        />
      </Box>
    </Box>
  );
};
