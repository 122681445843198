// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { DateTime } from 'luxon';
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import * as DateHelpers from '../../utils/DateHelpers';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { Company, NewCompany } from '../Company/Company';
import { Contact, NewContact } from '../Contact/Contact';
import Enums from '../Enums';
import { NewProject, Project } from '../Project/Project';
import { NewUser, User } from '../User/User';

export class Activity {
  readonly id: string;
  readonly name: string;
  readonly description: string | null;
  readonly category: Enums.ActivityCategory;
  readonly status: Enums.ActivityStatus;
  readonly startTime: DateTime;
  readonly endTime: DateTime;
  readonly project: Project | null;
  readonly companies: Company[];
  readonly contacts: Contact[];
  readonly assignees: User[];
  readonly creator: User;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.description = data.description === undefined ? null : data.description;
    this.category = data.category === undefined ? Enums.ActivityCategory.Task : data.category;
    this.status = data.status === undefined ? Enums.ActivityStatus.Planned : data.status;
    this.startTime = DateHelpers.ParseServerDateTime(data.startTime);
    this.endTime = DateHelpers.ParseServerDateTime(data.endTime);
    this.project =
      // eslint-disable-next-line no-nested-ternary
      (data.project ?? null) === null ? null : NewProject(data.project);
    this.companies = (data.companies ?? []).map((companies: any) => NewCompany(companies));
    this.contacts = (data.contacts ?? []).map((contacts: any) => NewContact(contacts));
    this.assignees = (data.assignees ?? []).map((assignees: any) => NewUser(assignees));
    this.creator = NewUser(data.creator);
  }

  static zero(): Activity {
    const zeroValues: ExcludeMethodsDeep<Activity> = {
      id: NIL_UUID,
      name: '',
      description: null,
      category: Enums.ActivityCategory.Task,
      status: Enums.ActivityStatus.Planned,
      startTime: DateHelpers.ParseServerDateTime(DateHelpers.ZERO_DATE_STRING),
      endTime: DateHelpers.ParseServerDateTime(DateHelpers.ZERO_DATE_STRING),
      project: null,
      companies: [],
      contacts: [],
      assignees: [],
      creator: User.zero(),
    };
    return new Activity(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  isDone(): boolean {
    return (
      this.status === Enums.ActivityStatus.Completed ||
      this.status === Enums.ActivityStatus.Canceled
    );
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewActivity = (props: PartialDeep<Activity, { recurseIntoArrays: true }>): Activity =>
  new Activity(props);

export const NewActivityFromDomainObject = (
  props: PartialDeep<DomainObject<Activity>, { recurseIntoArrays: true }>,
): Activity => new Activity(props);
