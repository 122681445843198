// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class LatLong {
  readonly lat: number;
  readonly lng: number;

  constructor(data: { [key: string]: any } = {}) {
    this.lat = data.lat === undefined ? 0 : data.lat;
    this.lng = data.lng === undefined ? 0 : data.lng;
  }

  static zero(): LatLong {
    const zeroValues: ExcludeMethodsDeep<LatLong> = {
      lat: 0,
      lng: 0,
    };
    return new LatLong(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewLatLong = (props: PartialDeep<LatLong, { recurseIntoArrays: true }>): LatLong =>
  new LatLong(props);

export const NewLatLongFromDomainObject = (
  props: PartialDeep<DomainObject<LatLong>, { recurseIntoArrays: true }>,
): LatLong => new LatLong(props);
