import { Box } from '@mui/material';

import { BreadCrumbs } from '../BreadCrumbs/BreadCrumbs';

type PageProps = {
  title: string;
  children: React.ReactNode;
};

export const Page = ({ title, children }: PageProps): JSX.Element => (
  <>
    <title>{title}</title>
    <main style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
      <Box paddingBottom='1rem'>
        <BreadCrumbs title={title} />
      </Box>
      {children}
    </main>
  </>
);
