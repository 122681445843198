import { Box, Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { Add, Trash } from 'iconsax-react';
import { DateTime } from 'luxon';

import { PriceEscalationInput } from '../../../components/PriceEscalationInput/PriceEscalationInput';
import Enums from '../../../generated-types/Enums';
import { QuoteFormikType } from '../QuoteFormik';

export const newEmptyPriceEscalation: QuoteFormikType['priceEscalations'][number] = {
  changeRatio: null,
  escalationDate: DateTime.now().plus({ year: 1 }).startOf('year').toISO(),
  flatChange: null,
  kind: Enums.EscalationKind.Percentage,
};

export const PriceEscalationSection = (): JSX.Element => {
  const theme = useTheme();
  const formikBag = useFormikContext<QuoteFormikType>();

  return (
    <Box>
      <Typography color={theme.palette.error.main}>
        {typeof formikBag.errors.priceEscalations === 'string'
          ? formikBag.errors.priceEscalations
          : '\u00A0'}
      </Typography>
      <FieldArray
        name='priceEscalations'
        // Sees 2x performance benefit at no behavior compromise.
        validateOnChange={false}
        render={(arrayHelpers): JSX.Element => (
          <>
            {formikBag.values.priceEscalations.map((_, index) => (
              <Box
                // eslint-disable-next-line react/no-array-index-key
                key={`quote-price-escalation-${formikBag.values.id}-${index}`}
                paddingY='1.25rem'
                display='flex'
                flexDirection='column'
                gap='1rem'
              >
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <PriceEscalationInput name={`priceEscalations.${index}`} />
                  </Grid>
                  <Grid item display='flex' justifyContent='center' alignItems='center' xs={1}>
                    <Button
                      onClick={(): void => {
                        arrayHelpers.remove(index);
                      }}
                    >
                      <Trash variant='TwoTone' color={theme.palette.SlabChart.Indigo[300]} />
                    </Button>
                  </Grid>
                </Grid>
                <Divider />
              </Box>
            ))}
            <Button
              onClick={(): void => {
                arrayHelpers.push(newEmptyPriceEscalation);
              }}
              sx={{
                textTransform: 'none',
                color: theme.palette.SlabChart.Indigo[300],
              }}
            >
              <Box display='flex' gap='0.5rem'>
                <Add color={theme.palette.SlabChart.Indigo[300]} />
                Add{' '}
                {formikBag.values.priceEscalations.length === 0 ? 'price escalation' : 'another'}
              </Box>
            </Button>
          </>
        )}
      />
    </Box>
  );
};
