import CloseIcon from '@mui/icons-material/Close';
import { AlertTitle, useTheme } from '@mui/material';
import Alert, { AlertColor } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

export type ToastProps = {
  header: string;
  body: string;

  setShow: (arg0: boolean) => void;
  show: boolean;
  /** Dictates inherited icon + color. Default 'success'. */
  severity?: AlertColor;
  /**
   * Duration ms to hide toast after displaying.
   * To not auto-hide it, set to undefined.
   * Default 3000.
   */
  hideAfter?: number | undefined;
};

export const Toast = ({
  header,
  body,
  setShow,
  show,
  severity = 'success',
  hideAfter = 3000,
}: ToastProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'fixed',
        right: '5%',
        top: '0.5rem',
        zIndex: theme.zIndex.drawer + 3,
      }}
    >
      <Collapse
        in={show}
        addEndListener={(): void => {
          if (hideAfter !== undefined) {
            setTimeout((): void => setShow(false), hideAfter);
          }
        }}
      >
        <Alert
          severity={severity}
          action={
            <IconButton aria-label='close alert' size='small' onClick={(): void => setShow(false)}>
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
          sx={{
            div: {
              display: 'flex',
              alignItems: 'center',
              mb: 0,
            },
          }}
        >
          <AlertTitle sx={{ paddingRight: 1 }}>{header}</AlertTitle>
          <div>{body}</div>
        </Alert>
      </Collapse>
    </Box>
  );
};
