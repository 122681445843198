import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { ButtonPill } from '../ButtonPill/ButtonPill';

export const ErrorPage = (): JSX.Element => (
  <Box
    height='60vh'
    display='flex'
    flexDirection='column'
    gap='3rem'
    justifyContent='center'
    alignItems='center'
    pt='20vh'
  >
    <Typography variant='h3'>Something has gone wrong!</Typography>
    <img
      src='/burning-building.png'
      style={{
        width: 'auto',
        height: '50%',
      }}
      alt='burning building'
    />
    <Typography variant='h3'>Please contact a Slabstack admin for support</Typography>
    <Link to='/admin' style={{ textDecoration: 'none', color: 'inherit' }}>
      <ButtonPill text='Return home' variant='primary' onClick={(): void => {}} />
    </Link>
  </Box>
);
