import { Box } from '@mui/material';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import { FormatLinkCell } from '../../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../../components/DataTable/DataTable';
import { ColumnConfig } from '../../../components/DataTable/TableDataModel';
import {
  InitialTableData,
  useLocalTableData,
} from '../../../components/DataTable/useLocalTableData';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { ProjectConfig } from '../../../generated-types/ProjectConfig/ProjectConfig';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { PossiblyAsyncResult } from '../../../utils/Query';
import { NestedKeyOf } from '../../../utils/Types';

// TODO: SC-1895 This is copy-pasted from Projects/CompanyTable for speed.
// Fast follow-up: Refactor to share the code between the two components.
type ProjectConfigCompanyListRow = {
  id: string;
  category: string;
  companyName: string;
  contactName: string | null;
  address: string;
  city: string | null;
  state: string;
};

const buildTableDataModelConfig = ({
  projectConfigResult,
  isContractorsOnly,
}: {
  projectConfigResult: PossiblyAsyncResult<ProjectConfig | undefined>;
  isContractorsOnly: boolean;
}): InitialTableData<ProjectConfigCompanyListRow, { id: string }, ProjectConfig | undefined> => {
  const transformRows = (
    unfilteredProjectConfig: ProjectConfig | undefined,
  ): ProjectConfigCompanyListRow[] => {
    const unfilteredProjectConfigCompanies = unfilteredProjectConfig?.companies ?? [];
    const projectConfigCompanies = isContractorsOnly
      ? unfilteredProjectConfigCompanies.filter((pc) =>
          pc.company.category.toLowerCase().includes('contractor'),
        )
      : unfilteredProjectConfigCompanies.filter(
          (pc) => !pc.company.category.toLowerCase().includes('contractor'),
        );

    return projectConfigCompanies.map(
      (pc): ProjectConfigCompanyListRow => ({
        id: pc.company.id,
        category: pc.company.category,
        companyName: pc.company.name,
        contactName: pc.contact?.fullName() ?? null,
        address: pc.company.address.basicDisplay(),
        city: pc.company.address.city,
        state: pc.company.address.state,
      }),
    );
  };

  const columns: ColumnConfig<
    ProjectConfigCompanyListRow,
    NestedKeyOf<ProjectConfigCompanyListRow>
  >[] = [
    {
      id: 'category',
      label: 'Category',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'companyName',
      label: 'Company',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/companies/${row.id}` }),
    },
    {
      id: 'contactName',
      label: 'Contact',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'address',
      label: 'Address',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'city',
      label: 'City',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'state',
      label: 'State',
      type: 'string',
      isDisplayed: true,
    },
  ];

  return {
    rowData: projectConfigResult,
    transformRows,
    columns,
    initialSortPath: 'companyName',
  };
};

type CompanyTableProps = {
  projectConfigId: string;
  isContractorsOnly: boolean;
  onEditCompanies: () => void;
};

export const CompanyTable = ({
  projectConfigId,
  isContractorsOnly,
  onEditCompanies,
}: CompanyTableProps): JSX.Element => {
  const projectConfigResult = useSlabQuery('GET project config by ID', {
    pathParams: {
      id: projectConfigId,
    },
  });
  const { isLoading, isError, data: projectConfig } = projectConfigResult;

  const tableModel = useLocalTableData(
    buildTableDataModelConfig({
      projectConfigResult,
      isContractorsOnly,
    }),
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || projectConfig === undefined) {
    return <div>ERROR</div>;
  }

  return (
    <Box padding='1rem'>
      <Box height='400px'>
        <Box display='flex' justifyContent='flex-end' paddingBottom='1.5rem'>
          <ButtonPill
            text={isContractorsOnly ? 'edit contractors' : 'edit other companies'}
            variant='primary'
            size='small'
            onClick={onEditCompanies}
            icon='edit'
          />
        </Box>
        <DataTable tableModel={tableModel} />
      </Box>
    </Box>
  );
};
