// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { Currency, NewCurrency } from '../Currency/Currency';
import { MixMaterial, NewMixMaterial } from '../MixMaterial/MixMaterial';
import { NewPlant, Plant } from '../Plant/Plant';

export class Mix {
  readonly id: string;
  readonly alternateID: string;
  readonly name: string;
  readonly psi: number;
  readonly mixMaterials: MixMaterial[];
  readonly materialCostOverride: Currency | null;
  readonly plant: Plant;
  readonly externalID: string | null;
  readonly slump: string | null;
  readonly aggregateSize: string | null;
  readonly waterCementRatio: string | null;
  readonly flyAshPercentage: string | null;
  readonly materialCost: Currency;
  readonly incompatibleBatchUnits: boolean;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.alternateID = data.alternateID === undefined ? '' : data.alternateID;
    this.name = data.name === undefined ? '' : data.name;
    this.psi = data.psi === undefined ? 0 : data.psi;
    this.mixMaterials = (data.mixMaterials ?? []).map((mixMaterials: any) =>
      NewMixMaterial(mixMaterials),
    );
    this.materialCostOverride =
      (data.materialCostOverride ?? null) === null ? null : NewCurrency(data.materialCostOverride);
    this.plant = NewPlant(data.plant);
    this.externalID =
      // eslint-disable-next-line no-nested-ternary
      (data.externalID ?? null) === null
        ? null
        : data.externalID === undefined
          ? ''
          : data.externalID;
    this.slump = data.slump === undefined ? null : data.slump;
    this.aggregateSize = data.aggregateSize === undefined ? null : data.aggregateSize;
    this.waterCementRatio = data.waterCementRatio === undefined ? null : data.waterCementRatio;
    this.flyAshPercentage = data.flyAshPercentage === undefined ? null : data.flyAshPercentage;
    this.materialCost = NewCurrency(data.materialCost);
    this.incompatibleBatchUnits =
      data.incompatibleBatchUnits === undefined ? false : data.incompatibleBatchUnits;
  }

  static zero(): Mix {
    const zeroValues: ExcludeMethodsDeep<Mix> = {
      id: NIL_UUID,
      alternateID: '',
      name: '',
      psi: 0,
      mixMaterials: [],
      materialCostOverride: null,
      plant: Plant.zero(),
      externalID: null,
      slump: null,
      aggregateSize: null,
      waterCementRatio: null,
      flyAshPercentage: null,
      materialCost: Currency.zero(),
      incompatibleBatchUnits: false,
    };
    return new Mix(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewMix = (props: PartialDeep<Mix, { recurseIntoArrays: true }>): Mix => new Mix(props);

export const NewMixFromDomainObject = (
  props: PartialDeep<DomainObject<Mix>, { recurseIntoArrays: true }>,
): Mix => new Mix(props);
