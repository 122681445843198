import { Box, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useState } from 'react';
import { useCookies } from 'react-cookie';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { ApiLookupInput } from '../../components/LookupInput/ApiLookupInput';
import { binaryStringToBase64 } from '../../types/Base64';
import { SetFormikValue } from '../../utils/FormikHelpers';

const extractToken = (): string | undefined => {
  const input = document.querySelector('textarea[name=authId]');
  if (!(input instanceof HTMLTextAreaElement)) {
    return undefined;
  }
  const trimmedToken = input.value.replaceAll(/\W+/g, '').replace(/[%=]+$/, '');
  if (trimmedToken === '') {
    return undefined;
  }
  return trimmedToken;
};

const buildClaimsToken = (values: { tenantID: string | null; userID: string | null }): string =>
  binaryStringToBase64(JSON.stringify(values));

export const AuthOverride = (): JSX.Element => {
  const [, setCookie] = useCookies(['AuthOverride']);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [showRawTokenForm, setShowRawTokenForm] = useState(false);

  const persistClaimsToken = (claimsToken: string | undefined): void => {
    if (claimsToken === undefined) {
      return;
    }
    setCookie('AuthOverride', claimsToken, { sameSite: 'strict', path: '/' });
    // Navigate to initial dashboard so that we don't try to access admin APIs
    // as a non-admin, using a full page reload so that userinfo and everything
    // reflects the newly effective auth identity.
    window.location.href = '/';
  };

  if (showRawTokenForm) {
    return (
      <Box display='flex' flexDirection='column' gap='3rem'>
        <Typography variant='h4'>Impersonate user</Typography>
        <TextField
          multiline
          name='authId'
          label='Claims token'
          onChange={(): void => setToken(extractToken())}
        />
        <ButtonPill
          text='Set Claims Override'
          variant='primary'
          disabled={extractToken() === undefined}
          onClick={(): void => persistClaimsToken(token)}
        />
        <ButtonPill
          text='Use Menus'
          variant='secondary'
          onClick={() => {
            setShowRawTokenForm(false);
          }}
        />
      </Box>
    );
  }

  return (
    <Box display='flex' flexDirection='column' gap='3rem'>
      <Typography variant='h4'>Impersonate user</Typography>
      <Formik
        initialValues={{ tenantID: null, userID: null }}
        validate={({ userID, tenantID }) => {
          const errors: { tenantID?: string; userID?: string } = {};
          if (tenantID === null) {
            errors.tenantID = 'Required';
          }
          if (userID === null) {
            errors.userID = 'Required';
          }
          return errors;
        }}
        onSubmit={(values) => {
          const claimsToken = buildClaimsToken(values);
          setToken(claimsToken);
          persistClaimsToken(claimsToken);
        }}
      >
        {(formikBag) => (
          <>
            <ApiLookupInput
              formState={formikBag.values}
              label='Tenant'
              name='tenantID'
              route={{
                barrel: 'GET tenants as admin',
                args: () => ({}),
                transform: ({ items }) =>
                  items.map((t) => ({
                    value: t.id,
                    label: t.name,
                  })),
              }}
              onMatchChange={(val) => {
                if (val === null) {
                  SetFormikValue(formikBag, 'userID', null);
                }
              }}
            />
            <ApiLookupInput
              formState={formikBag.values}
              label='User'
              name='userID'
              disabled={formikBag.values.tenantID === null}
              route={{
                barrel: 'GET users by tenant ID as admin',
                args: () => ({
                  pathParams: {
                    tenantID: formikBag.values.tenantID ?? '',
                  },
                }),
                options: { enabled: formikBag.values.tenantID !== null },
                transform: ({ items }) =>
                  items.map((u) => ({
                    value: u.id,
                    label: u.fullName(),
                    sublabels: [u.emailAddress, u.roles.map((r) => r.name).join(', ')],
                  })),
              }}
            />
            <ButtonPill
              variant='primary'
              text='Impersonate'
              onClick={formikBag.submitForm}
              disabled={
                !formikBag.isValid ||
                !(formikBag.touched.tenantID ?? false) ||
                !(formikBag.touched.userID ?? false)
              }
            />
          </>
        )}
      </Formik>
      <ButtonPill
        text='Use Raw Token'
        variant='secondary'
        onClick={() => {
          setShowRawTokenForm(true);
        }}
      />
    </Box>
  );
};
