import { Box, Grid, Typography } from '@mui/material';

import { FormatLinkCell } from '../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../components/DataTable/DataTable';
import { ColumnConfig } from '../../components/DataTable/TableDataModel';
import { useApiTableDataWithoutAggregations } from '../../components/DataTable/useApiTableData';
import { Page } from '../../components/Page/Page';
import Enums from '../../generated-types/Enums';
import { Tenant } from '../../generated-types/Tenant/Tenant';
import { NestedKeyOf } from '../../utils/Types';
import { AuthOverride } from './AuthOverride';

const tenantColumns: ColumnConfig<Tenant, NestedKeyOf<Tenant>>[] = [
  {
    id: 'name',
    label: 'Name',
    type: 'string',
    isDisplayed: true,
    formatValueForWeb: (label, row) =>
      FormatLinkCell({ label, link: `/_slabstack_staff/${row.id}` }),
  },
  {
    id: 'id',
    label: 'ID',
    type: 'string',
    isDisplayed: true,
  },
];

export const TenantList = (): JSX.Element => {
  const tableModel = useApiTableDataWithoutAggregations({
    rowsRouteKey: 'GET tenants as admin',
    extractRows: (list) => list.items,
    rowsPathParams: {},
    initialSortBy: {
      direction: Enums.SortDirection.Ascending,
      name: 'name',
    },
    makeColumnConfigs: () => [...tenantColumns],
  });

  return (
    <Page title='Tenants'>
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Tenants</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <DataTable tableModel={tableModel} />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <AuthOverride />
        </Grid>
      </Grid>
    </Page>
  );
};
