// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';

export class Tenant {
  readonly id: string;
  readonly name: string;
  readonly requiresQuoteApprovals: boolean;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.requiresQuoteApprovals =
      data.requiresQuoteApprovals === undefined ? false : data.requiresQuoteApprovals;
  }

  static zero(): Tenant {
    const zeroValues: ExcludeMethodsDeep<Tenant> = {
      id: NIL_UUID,
      name: '',
      requiresQuoteApprovals: false,
    };
    return new Tenant(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************
  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewTenant = (props: PartialDeep<Tenant, { recurseIntoArrays: true }>): Tenant =>
  new Tenant(props);

export const NewTenantFromDomainObject = (
  props: PartialDeep<DomainObject<Tenant>, { recurseIntoArrays: true }>,
): Tenant => new Tenant(props);
