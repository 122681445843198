import * as Yup from 'yup';

import { EmailRecipient } from '../../generated-types/EmailRecipient/EmailRecipient';
import { SendMessageInput } from '../../generated-types/SendMessageInput/SendMessageInput';
import { UploadFileInput } from '../../generated-types/UploadFileInput/UploadFileInput';
import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethods } from '../../utils/Types';
import {
  YupBase64,
  YupEmail,
  YupOptionalString,
  YupPDFFileName,
  YupString,
} from '../../utils/YupHelpers';

export type EmailFormikType = DomainObject<Pick<SendMessageInput, 'body' | 'file' | 'subject'>> & {
  // EmailRecipient must be explicit because its sub-property `name` is optional,
  // and `DomainObject<string | undefined>` does not work with Yup.
  bcc: EmailRecipient[];
  cc: EmailRecipient[];
  from: EmailRecipient[];
  to: EmailRecipient[];
};

/**
 * EmailRecipientSchema mimics the yup shape of `EmailRecipient` with the caveat
 * that email is only required, rather than of email format.
 *
 * When using this schema in an array, it is recommended to be used in conjunction with `YupEmail`
 * to hoist the error from the array object level to the higher level input name.
 */
const EmailRecipientSchema: Yup.SchemaOf<EmailRecipient> = Yup.object().shape({
  // YupEmail is not used because it would generate errors at the array sub-object level.
  email: YupString('Email address'),
  name: YupOptionalString('Name'),
});

const EmailRecipientListSchema: Yup.SchemaOf<EmailRecipient[]> = Yup.array()
  .of(EmailRecipientSchema)
  .test({
    name: 'is-valid-email',
    message: 'All inputs must be valid email addresses',
    test: (v) => (v ?? []).every((i) => YupEmail('').isValidSync(i.email)),
  });

const FileSchema: Yup.SchemaOf<ExcludeMethods<UploadFileInput>> = Yup.object().shape({
  fileName: YupPDFFileName('File name'),
  file: YupBase64('base64 data of file'),
});

export const EmailSchema: Yup.SchemaOf<EmailFormikType> = Yup.object().shape({
  body: YupString('Body'),
  subject: YupString('Subject'),
  to: EmailRecipientListSchema.min(1).label('To'),
  from: EmailRecipientListSchema.label('From'),
  cc: EmailRecipientListSchema.label('CC'),
  bcc: EmailRecipientListSchema.label('BCC'),
  file: FileSchema,
});

export const FormikEmail = (input: Partial<SendMessageInput> | undefined): EmailFormikType => ({
  body: input?.body ?? '',
  subject: input?.subject ?? '',
  to:
    input?.to?.map((i) => ({
      email: i.email ?? '',
      name: i.name,
    })) ?? [],
  from:
    input?.from?.map((i) => ({
      email: i.email ?? '',
      name: i.name,
    })) ?? [],
  cc:
    input?.cc?.map((i) => ({
      email: i.email ?? '',
      name: i.name,
    })) ?? [],
  bcc:
    input?.bcc?.map((i) => ({
      email: i.email ?? '',
      name: i.name,
    })) ?? [],
  file: {
    file: input?.file?.file ?? '',
    fileName: input?.file?.fileName ?? 'quote.pdf',
  },
});
