import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { DisplayField } from '../../components/DisplayField/DisplayField';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Page } from '../../components/Page/Page';
import Enums from '../../generated-types/Enums';
import { Units } from '../../generated-types/generated-units';
import { Material, NewMaterial } from '../../generated-types/Material/Material';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { NullableCostString, NullableCurrencyString } from '../../utils/Currency';
import { NIL_UUID } from '../../utils/UUID';
import { SimilarMaterialTable } from './components/SimilarMaterialTable';
import { MaterialDrawer } from './MaterialDrawer';

export const MaterialDetailsPage = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();

  const [materialId, setMaterialId] = useState<string | null>(null);
  const [isCloning, setIsCloning] = useState(false);

  const { toastHook: materialToastHook, ...materialDrawerProps } = useDrawerManager({
    baseUrl: '/materials',
    resourceTypeName: 'material',
    drawerProps: {
      resourceId: materialId,
    },
  });

  const {
    isLoading,
    isError,
    data: material,
  } = useSlabQuery('GET material by ID', {
    pathParams: {
      id: params?.id ?? '',
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || material === undefined) {
    return <div>ERROR</div>;
  }

  const title = material.name;

  const displayMaterial = {
    ...material,
    measurementUnit:
      material.measurementUnit !== null ? Units[material.measurementUnit].label : null,
    plantName: material.plant.name,
    supplierName: material.supplierCompany?.name ?? null,
    inProductCostString: NullableCostString({ cost: material.inProductCost }),
    rawMaterialCostString: NullableCostString({ cost: material.rawMaterialCost }),
    haulingCostString: NullableCostString({ cost: material.haulingCost }),
    plantLoadingCostString: NullableCostString({ cost: material.plantLoadingCost }),
    flatChangeDisplay: NullableCurrencyString({ cur: material.costRule?.flatChange ?? null }),
    percentageChangeDisplay: `${String(
      parseFloat((parseFloat(material.costRule?.markupRatio ?? '0') * 100).toFixed(3)),
    )}%`,
  };

  return (
    <Page title={title}>
      {materialToastHook.toast}
      <MaterialDrawer
        {...materialDrawerProps}
        materialId={materialId}
        onSuccess={(m): void => {
          materialToastHook.showToast('success');
          materialDrawerProps.setIsOpen(false);
          if (isCloning) {
            setIsCloning(false);
            navigate(`/materials/${m.id}`);
          }
        }}
        onClose={(): void => setIsCloning(false)}
        ensureDefined={(curMaterial): Material => {
          if (isCloning && curMaterial !== undefined) {
            return NewMaterial({
              ...curMaterial,
              id: NIL_UUID,
              name: `${curMaterial.name} - Copy`,
              externalID: null,
            });
          }
          return curMaterial ?? Material.zero();
        }}
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='2.5rem'>
        <Typography variant='h1'>{title}</Typography>
        <Box display='flex' gap='1rem'>
          <ButtonPill
            text='clone'
            variant='secondary'
            onClick={(): void => {
              setMaterialId(material.id);
              setIsCloning(true);
              materialDrawerProps.setIsOpen(true);
            }}
            icon='add'
          />
          <ButtonPill
            text='edit material'
            variant='primary'
            onClick={(): void => {
              setMaterialId(material.id);
              materialDrawerProps.setIsOpen(true);
            }}
            icon='edit'
          />
        </Box>
      </Box>
      <Box
        display='flex'
        gap='1.25rem'
        sx={{
          // Stretch immediate DisplayFields to take full width + equal width
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          },
          // Stretch DisplayFields' value section to fill available height
          '& > div > div:last-child': {
            flex: 1,
          },
        }}
      >
        <DisplayField
          title='Material Details'
          onEditClick={(): void => {
            setMaterialId(material.id);
            materialDrawerProps.setIsOpen(true);
          }}
          value={displayMaterial}
          type='singleField'
          displayMatrix={[
            [
              { key: 'name', label: 'Material name' },
              { key: 'alternateID', label: 'Material ID' },
              {
                key: 'plantName',
                label: 'Plant',
                type: 'uri',
                uri: `/plants/${material.plant.id}`,
              },
            ],
            [
              { key: 'materialType', label: 'Material Type' },
              {
                key: 'supplierName',
                label: 'Supplier',
                type: 'uri',
                uri: `/companies/${material.supplierCompany?.id}`,
              },
              { key: 'measurementUnit', label: 'Unit' },
            ],
            [],
          ]}
        />
        <DisplayField
          title='Cost Details'
          onEditClick={(): void => {
            setMaterialId(material.id);
            materialDrawerProps.setIsOpen(true);
          }}
          value={displayMaterial}
          type='singleField'
          displayMatrix={[
            [
              { key: 'rawMaterialCostString', label: 'Raw Material Cost' },
              { key: 'haulingCostString', label: 'Hauling Cost' },
              { key: 'plantLoadingCostString', label: 'Plant Loading Cost' },
            ],
            displayMaterial.costRule === null
              ? [{ key: 'inProductCostString', label: 'In Product Cost' }]
              : [
                  displayMaterial.costRule !== null &&
                  displayMaterial.costRule.kind === Enums.CostRuleKind.Flat
                    ? { label: 'Flat change', key: 'flatChangeDisplay' }
                    : { label: 'Percentage change', key: 'percentageChangeDisplay' },
                  { key: 'inProductCostString', label: 'In Product Cost' },
                ],
          ]}
        />
      </Box>
      <Box pt='2.5rem'>
        <SimilarMaterialTable
          material={material}
          onEditPress={(row): void => {
            setMaterialId(row.id);
            materialDrawerProps.setIsOpen(true);
          }}
        />
      </Box>
    </Page>
  );
};
