// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';

export class DispatchCustomer {
  readonly id: string;
  readonly name: string;
  readonly code: string;
  readonly creditStatusCode: string | null;
  readonly creditStatusDescription: string | null;
  readonly externalId: string;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.code = data.code === undefined ? '' : data.code;
    this.creditStatusCode = data.creditStatusCode === undefined ? null : data.creditStatusCode;
    this.creditStatusDescription =
      data.creditStatusDescription === undefined ? null : data.creditStatusDescription;
    this.externalId = data.externalId === undefined ? '' : data.externalId;
  }

  static zero(): DispatchCustomer {
    const zeroValues: ExcludeMethodsDeep<DispatchCustomer> = {
      id: NIL_UUID,
      name: '',
      code: '',
      creditStatusCode: null,
      creditStatusDescription: null,
      externalId: '',
    };
    return new DispatchCustomer(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewDispatchCustomer = (
  props: PartialDeep<DispatchCustomer, { recurseIntoArrays: true }>,
): DispatchCustomer => new DispatchCustomer(props);

export const NewDispatchCustomerFromDomainObject = (
  props: PartialDeep<DomainObject<DispatchCustomer>, { recurseIntoArrays: true }>,
): DispatchCustomer => new DispatchCustomer(props);
