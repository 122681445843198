import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import { randomUUID } from '../../utils/UUID';

export type SelectorOption = {
  name: string;
  id: string | null;
  subLabel?: string;
};

type SelectorProps = {
  label: string;
  selectedId: string | null;
  onSelectedId: (arg0: string | null) => void;
  selectorOpts: SelectorOption[];
  /** @default '15rem' */
  selectMinWidth?: string;
  /** @default '25rem' */
  selectMaxWidth?: string;
};

const NULL_VALUE = '-_NULL-_';

/**
 * Construct a simple selector
 */
export const Selector = ({
  label,
  selectedId,
  onSelectedId,
  selectorOpts,
  selectMinWidth = '15rem',
  selectMaxWidth = '25rem',
}: SelectorProps): JSX.Element => (
  <FormControl sx={{ minWidth: selectMinWidth, maxWidth: selectMaxWidth }}>
    <InputLabel>{label}</InputLabel>
    <Select
      value={selectedId ?? NULL_VALUE}
      onChange={(e): void => {
        onSelectedId(e.target.value === NULL_VALUE ? null : e.target.value);
      }}
      label={label}
    >
      {selectorOpts.map((opt) => (
        <MenuItem key={randomUUID()} value={opt.id ?? NULL_VALUE}>
          <Box>
            <Typography variant='body2'>
              {opt.name}
              {opt.subLabel !== undefined && (
                <Typography className='subLabel' variant='body3'>
                  {' '}
                  {opt.subLabel}
                </Typography>
              )}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
