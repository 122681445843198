// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.
/* eslint-disable camelcase */ // webgen:keep
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { EmailRecipient, NewEmailRecipient } from '../EmailRecipient/EmailRecipient';
import { NewUploadFileInput, UploadFileInput } from '../UploadFileInput/UploadFileInput';

export class SendMessageInput {
  readonly body: string;
  readonly subject: string;
  readonly to: EmailRecipient[];
  readonly from: EmailRecipient[];
  readonly cc?: EmailRecipient[];
  readonly bcc?: EmailRecipient[];
  readonly reply_to?: EmailRecipient[];
  readonly file: UploadFileInput;

  constructor(data: { [key: string]: any } = {}) {
    this.body = data.body === undefined ? '' : data.body;
    this.subject = data.subject === undefined ? '' : data.subject;
    this.to = (data.to ?? []).map((to: any) => NewEmailRecipient(to));
    this.from = (data.from ?? []).map((from: any) => NewEmailRecipient(from));
    this.cc = (data.cc ?? []).map((cc: any) => NewEmailRecipient(cc));
    this.bcc = (data.bcc ?? []).map((bcc: any) => NewEmailRecipient(bcc));
    this.reply_to = (data.reply_to ?? []).map((reply_to: any) => NewEmailRecipient(reply_to));
    this.file = NewUploadFileInput(data.file);
  }

  static zero(): SendMessageInput {
    const zeroValues: ExcludeMethodsDeep<SendMessageInput> = {
      body: '',
      subject: '',
      to: [],
      from: [],
      file: UploadFileInput.zero(),
    };
    return new SendMessageInput(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewSendMessageInput = (
  props: PartialDeep<SendMessageInput, { recurseIntoArrays: true }>,
): SendMessageInput => new SendMessageInput(props);

export const NewSendMessageInputFromDomainObject = (
  props: PartialDeep<DomainObject<SendMessageInput>, { recurseIntoArrays: true }>,
): SendMessageInput => new SendMessageInput(props);
