import _ from 'lodash';
import LogRocket from 'logrocket';
import { PartialDeep } from 'type-fest';
import * as Yup from 'yup';

import {
  AdditionalProjectOrQuoteProductsFormikSchema,
  MainProjectOrQuoteProductsFormikSchema,
  ProjectOrQuoteProductFormikType,
  QuoteProductToProjectOrQuoteProductFormik,
} from '../../components/ProductSection/ProjectOrQuoteProductFormik';
import { NewCompany } from '../../generated-types/Company/Company';
import Enums from '../../generated-types/Enums';
import { NewQuote, Quote } from '../../generated-types/Quote/Quote';
import {
  NewQuoteProductFromDomainObject,
  QuoteProduct,
} from '../../generated-types/QuoteProduct/QuoteProduct';
import { DomainObject } from '../../utils/ApiClient';
import { QuotePriceEscalationToFormik } from '../../utils/FormikHelpers';
import { RemoveNullProperties } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import {
  TestForUndefined,
  YupNullableLocalDate,
  YupNullableReference,
  YupNullableString,
  YupNumber,
  YupQuotePriceEscalations,
  YupReference,
  YupSchemaNullableReferenceType,
  YupSchemaNumberType,
  YupSchemaReferenceType,
} from '../../utils/YupHelpers';

export type QuoteFormikType = DomainObject<
  Omit<
    Quote,
    | 'project'
    | 'company'
    | 'contact'
    | 'products'
    | 'user'
    | 'quoteConfig'
    | 'revenue'
    | 'estimatedVolume'
    | 'revisionNumber'
    | 'status'
    | 'policyViolations'
  >
> & {
  project: YupSchemaReferenceType;
  revisionNumber: YupSchemaNumberType;
  company: YupSchemaNullableReferenceType;
  contact: YupSchemaNullableReferenceType;
  mainProducts: Omit<ProjectOrQuoteProductFormikType, 'displayOnly'>[];
  additionalProducts: Omit<ProjectOrQuoteProductFormikType, 'displayOnly'>[];
  user: YupSchemaReferenceType;
  /** onSubmit will default to 'save', and if 'Save as new' button is pressed, it switches the flag */
  submitType: 'save' | 'saveAsNew';
  quoteConfig: YupSchemaNullableReferenceType;
  status: YupSchemaReferenceType;
};

const sharedQuoteSchemaFields = {
  status: YupReference('Status'),
  quoteNumber: Yup.string().default('').label('Quote number'),
  name: YupNullableString('Name'),
  revisionNumber: YupNumber({
    label: 'Revision number',
    integer: true,
    positive: true,
  }),
  notes: YupNullableString('Notes'),
  expirationDate: YupNullableLocalDate('Expiration date'),
  project: YupReference('Project'),
  company: YupNullableReference('Company'),
  contact: YupNullableReference('Contact'),
  user: YupReference('User'),
  creationDate: YupNullableLocalDate('Creation date'),
  revisionDate: YupNullableLocalDate('Revision date'),
  quoteConfig: YupNullableReference('Quote config'),
  priceEscalations: YupQuotePriceEscalations('Price escalations'),
};

type QuoteSchemaFormikType = Yup.SchemaOf<
  Omit<
    QuoteFormikType,
    'id' | 'externalID' | 'submitType' | 'mainProducts' | 'additionalProducts'
  > & {
    mainProducts: Omit<ProjectOrQuoteProductFormikType, 'displayOnly' | 'isMoneySwitchOn'>[];
    additionalProducts: Omit<ProjectOrQuoteProductFormikType, 'displayOnly' | 'isMoneySwitchOn'>[];
  }
>;

/** This schema will be used for yup validation */
export const QuoteSchemaFormik: QuoteSchemaFormikType = Yup.object()
  .shape({
    ...sharedQuoteSchemaFields,

    mainProducts: MainProjectOrQuoteProductsFormikSchema,
    additionalProducts: AdditionalProjectOrQuoteProductsFormikSchema,
  })
  .test(TestForUndefined('QuoteSchemaFormik'));

type QuoteSchemaWireType = Yup.SchemaOf<
  Omit<
    QuoteFormikType,
    'id' | 'externalID' | 'submitType' | 'template' | 'mainProducts' | 'additionalProducts'
  > & {
    products: Omit<ProjectOrQuoteProductFormikType, 'displayOnly' | 'isMoneySwitchOn'>[];
  }
>;

/** This schema will be used to cast with the contactenation of mainProducts + additionalProducts */
export const QuoteSchemaWire: QuoteSchemaWireType = Yup.object().shape({
  ...sharedQuoteSchemaFields,

  products: AdditionalProjectOrQuoteProductsFormikSchema,
});

type FormikQuoteOpts = Partial<Quote & Pick<QuoteFormikType, 'submitType'>> | undefined;

/** Returns the form data for a Quote. */
export const FormikQuote = (
  quoteData: FormikQuoteOpts,
  usesDispatchCustomer: boolean,
): QuoteFormikType => {
  const quoteWithProducts =
    quoteData !== undefined ? NewQuote({ products: quoteData.products ?? [] }) : Quote.zero();

  const mainFormikQuoteProducts = quoteWithProducts
    .filterProducts(Enums.QuoteProductKind.Primary)
    .map(QuoteProductToProjectOrQuoteProductFormik);

  const additionalFormikQuoteProducts = quoteWithProducts
    .filterProducts(Enums.QuoteProductKind.Additional)
    .map(QuoteProductToProjectOrQuoteProductFormik);

  const quotePriceEscalationsFormik =
    quoteData?.priceEscalations !== undefined
      ? quoteData.priceEscalations.map(QuotePriceEscalationToFormik)
      : [];

  return {
    id: quoteData?.id ?? NIL_UUID,
    externalID: quoteData?.externalID ?? null,
    quoteNumber: quoteData?.quoteNumber ?? '',
    name: quoteData?.name ?? null,
    revisionNumber: quoteData?.revisionNumber ?? 1,
    status: {
      id: quoteData?.status?.id ?? null,
      option:
        quoteData?.status === undefined
          ? null
          : {
              value: quoteData.status.id,
              label: quoteData.status.name,
            },
    },
    notes: quoteData?.notes ?? null,
    expirationDate: quoteData?.expirationDate?.toISO() ?? null,
    creationDate: quoteData?.creationDate?.toISO() ?? new Date().toISOString(),
    revisionDate: quoteData?.revisionDate?.toISO() ?? null,
    user: {
      id: quoteData?.user?.id ?? null,
      option:
        quoteData?.user === undefined
          ? null
          : {
              value: quoteData.user.id,
              label: quoteData.user.fullName(),
            },
    },
    project: {
      id: quoteData?.project?.id ?? null,
      option:
        quoteData?.project === undefined
          ? null
          : {
              value: quoteData.project.id,
              label: quoteData.project.name,
            },
    },
    company:
      quoteData?.company === undefined || quoteData.company === null
        ? {
            id: null,
            option: null,
          }
        : {
            id: quoteData.company.id,
            option: {
              value: quoteData.company.id,
              label: quoteData.company.name,
              sublabels: NewCompany(quoteData.company).lookupSublabels(usesDispatchCustomer),
            },
          },
    contact: {
      id: quoteData?.contact?.id ?? null,
      option:
        quoteData?.contact === undefined || quoteData.contact === null
          ? null
          : {
              value: quoteData.contact.id,
              label: quoteData.contact.fullName(),
            },
    },
    mainProducts: mainFormikQuoteProducts,
    additionalProducts: additionalFormikQuoteProducts,
    submitType: quoteData?.submitType ?? 'save',
    quoteConfig: {
      id: quoteData?.quoteConfig?.id ?? null,
      option:
        quoteData?.quoteConfig === undefined || quoteData.quoteConfig === null
          ? null
          : {
              value: quoteData.quoteConfig.id,
              label: quoteData.quoteConfig.name,
            },
    },
    priceEscalations: quotePriceEscalationsFormik,
  };
};

// We can safely assume `false` for `usesDispatchCustomer` as that only affects initial values.
export const EmptyFormikQuote = FormikQuote(undefined, false);

/* Converts form data to a QuoteProduct domain object. */
export const NewQuoteProductFromFormik = (
  values: Omit<ProjectOrQuoteProductFormikType, 'displayOnly'>,
  dirtyEdits?: PartialDeep<Omit<ProjectOrQuoteProductFormikType, 'displayOnly'>>,
): QuoteProduct => {
  const input: Omit<ProjectOrQuoteProductFormikType, 'displayOnly'> = _.merge(
    {},
    values,
    dirtyEdits,
  );

  let castData: Parameters<typeof NewQuoteProductFromDomainObject>[0];
  try {
    castData = RemoveNullProperties(
      QuoteSchemaFormik.cast({ additionalProducts: [input] }).additionalProducts?.[0] ?? {},
    );
  } catch (e) {
    try {
      QuoteSchemaFormik.validateSyncAt(
        'additionalProducts',
        { additionalProducts: [input] },
        { strict: true, abortEarly: false },
      );
      LogRocket.error('NewQuoteProductFromFormik parsing failed, but validation succeeded?!');
    } catch (error) {
      const details =
        error instanceof Yup.ValidationError
          ? error.inner.map((inner) => `${inner.path}: ${inner.type}`).join('\n')
          : '';
      LogRocket.error(`NewQuoteProductFromFormik parsing failed. ${error}\n${details}`);
    }
    castData = {};
  }
  return NewQuoteProductFromDomainObject(castData);
};
