import { Box } from '@mui/material';
import _ from 'lodash';

import { DisplayField } from '../../../components/DisplayField/DisplayField';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { Project } from '../../../generated-types/Project/Project';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { CurrencyString } from '../../../utils/Currency';

type ProjectDetailsColumnProps = {
  project: Project;
  onProjectDetailsClick: () => void;
  onAddressInformationClick: () => void;
  onDetailsClick: () => void;
  onNoteClick: () => void;
  onCustomFieldsClick: () => void;
};
export const ProjectDetailsColumn = ({
  project,
  onProjectDetailsClick,
  onAddressInformationClick,
  onDetailsClick,
  onNoteClick,
  onCustomFieldsClick,
}: ProjectDetailsColumnProps): JSX.Element => {
  const {
    isLoading,
    isError,
    data: customFieldDefinitions,
  } = useSlabQuery('GET custom field definitions', {});

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || customFieldDefinitions === undefined) {
    return <div>ERROR</div>;
  }

  const displayProject = {
    ...project,
    ...project.address,
    winnerName: project.winningCompanyName(),
    confidenceString: project.confidenceDisplay(),
    estStartDateString: project.estimatedStartDateDisplay(),
    estEndDateString: project.estimatedEndDateDisplay(),
    bidDateString: project.bidDateDisplay(),
    expirationDateString: project.expirationDateDisplay(),
    estimatedRevenueString: CurrencyString({ cur: project.revenue }),
    // Wrap the number in a String(parseFloat()) to remove trailing zeros
    estimatedMarginString: project.estimatedMarginDisplay(),
    pricePerYardDisplay: project.pricePerYardDisplay(),
    estimatedMarginPercent: project.estimatedMarginPercent(),
    plantName: project.plant.name,
    totalCostString: CurrencyString({ cur: project.totalCosts }),
    userName: project.user.fullName(),
    status: project.projectStatus.name,
    estimatedVolumeString: project.estimatedVolume.toLocaleString(),
    taxCodeString:
      project.taxCode === null ? null : `${project.taxCode.name} (${project.taxCode.code})`,
    segmentString: project.segment === null ? null : project.segment.name,
    plantDistanceMilesString:
      project.plantDistanceMiles === null ? null : `${project.plantDistanceMiles} mi`,
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='fit-content'
      whiteSpace='nowrap'
      gap='1.25rem'
    >
      <DisplayField
        title='Project Details'
        onEditClick={onProjectDetailsClick}
        value={displayProject}
        type='singleField'
        displayMatrix={[
          [
            { label: 'Owner', key: 'owner' },
            { label: 'Status', key: 'status' },
          ],
          [
            {
              label: 'Project Winner',
              key: 'winnerName',
              type: 'uri',
              uri: `/companies/${project.winningCompany?.company?.id}`,
            },
            { label: 'Confidence %', key: 'confidenceString' },
          ],
          [
            { label: 'Est. Start Date', key: 'estStartDateString' },
            { label: 'Est. End Date', key: 'estEndDateString' },
          ],
          [
            { label: 'Bid Date', key: 'bidDateString' },
            { label: 'Expiration Date', key: 'expirationDateString' },
          ],
          [
            { label: 'Salesperson', key: 'userName' },
            { label: 'Competitor', key: 'competitor' },
          ],
          [
            { label: 'Tax Code', key: 'taxCodeString' },
            { label: 'Segment', key: 'segmentString' },
          ],
        ]}
      />
      {project.customFields.length > 0 && (
        <DisplayField
          title='Additional Information'
          type='array'
          onEditClick={onCustomFieldsClick}
          values={_.chunk(
            (project.customFields ?? []).map((pcf) => {
              const value = ((): string | null => {
                if (pcf.definition.type !== 'options') {
                  return pcf.value;
                }
                return (
                  customFieldDefinitions.items
                    .find((cfd) => cfd.id === pcf.definition.id)
                    ?.options?.find((opt) => String(opt.value) === pcf.value)?.label ?? null
                );
              })();
              return {
                label: pcf.definition.name,
                value,
              };
            }),
            2,
          )}
        />
      )}
      <DisplayField
        title='Job Site Address'
        onEditClick={onAddressInformationClick}
        value={displayProject}
        type='singleField'
        displayMatrix={[
          [
            { label: 'Line 1', key: 'line1' },
            { label: 'Line 2', key: 'line2' },
          ],
          [
            { label: 'Zip/Postal', key: 'postalCode' },
            { label: 'City/Town', key: 'city' },
          ],
          [
            { label: 'State/County', key: 'state' },
            { label: 'Country', key: 'country' },
          ],
        ]}
      />
      <DisplayField
        title='Details'
        onEditClick={onDetailsClick}
        value={displayProject}
        type='singleField'
        displayMatrix={[
          [
            { label: 'Distance from plant', key: 'plantDistanceMilesString' },
            { label: 'Est. Revenue', key: 'estimatedRevenueString' },
          ],
          [
            { label: 'Est. CUYD', key: 'estimatedVolumeString' },
            { label: 'Est. Margin', key: 'estimatedMarginString' },
          ],
          [
            { label: 'Price per yard', key: 'pricePerYardDisplay' },
            { label: 'Est. Margin %', key: 'estimatedMarginPercent' },
          ],
          [
            {
              label: 'Plant',
              key: 'plantName',
              type: 'uri',
              uri: `/plants/${project.plant.id}`,
            },
            { label: 'Est. Costs', key: 'totalCostString' },
          ],
        ]}
      />
      <DisplayField
        title='Note'
        onEditClick={onNoteClick}
        value={displayProject}
        type='singleField'
        displayMatrix={[[{ key: 'notes', type: 'notes', maxWidth: '30rem' }]]}
      />
    </Box>
  );
};
