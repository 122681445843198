import { QuestionMark } from '@mui/icons-material';
import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material';
import { Add, Edit2 } from 'iconsax-react';

type VariantOpts = 'primary' | 'secondary';

type SizeOpts = 'normal' | 'small';

type ButtonCssProps = {
  border: string;
  backgroundColor: string;
  color: string;
  disabledColor: string;
  hoverBgColor: string;
};

type SizeCssProps = {
  fontSize: string;
  iconSize: number;
};

type ButtonPillProps = {
  text: string;
  variant: VariantOpts;
  size?: SizeOpts;
  onClick: () => void;
  icon?: 'edit' | 'add';
  /** @default true */
  capitalize?: boolean;
  /** @default false */
  disabled?: boolean;
  /** @default undefined */
  tip?: string;
};

type ButtonPillCssProps = ButtonCssProps & {
  boxShadow: string;
};

export const ButtonPill = ({
  text,
  variant,
  size = 'normal',
  onClick,
  icon,
  capitalize = true,
  disabled = false,
  tip,
}: ButtonPillProps): JSX.Element => {
  const theme = useTheme();

  // Need to have variant props declared inside component so it can use useTheme
  const variantProps = (isDisabled: boolean): Record<VariantOpts, ButtonPillCssProps> => ({
    primary: {
      border: 'none',
      backgroundColor: isDisabled ? theme.palette.SlabYellow[400] : theme.palette.SlabYellow[500],
      color: theme.palette.SlabBlue[700],
      disabledColor: theme.palette.SlabBlue[400],
      hoverBgColor: theme.palette.SlabYellow[500],
      boxShadow: '0px 4px 32px 0px #ffd64180',
    },
    secondary: {
      border: `1px solid ${theme.palette.SlabBlue[700]}`,
      backgroundColor: theme.palette.SlabBlue[0],
      color: theme.palette.SlabBlue[700],
      disabledColor: theme.palette.SlabBlue[400],
      hoverBgColor: 'inherit',
      boxShadow: '0px 4px 32px 0px #c9d0fa80',
    },
  });

  const sizeProps: Record<SizeOpts, SizeCssProps> = {
    normal: {
      fontSize: '1.125rem',
      iconSize: 24,
    },
    small: {
      fontSize: '0.875rem',
      iconSize: 20,
    },
  };

  const iconElement = ((): JSX.Element | null => {
    const iconStyle = {
      height: sizeProps[size].iconSize,
      width: sizeProps[size].iconSize,
    };
    if (icon === 'edit') {
      return <Edit2 style={iconStyle} variant='TwoTone' />;
    }
    if (icon === 'add') {
      return <Add style={iconStyle} />;
    }
    return null;
  })();

  const button = (
    <Button
      variant='pill'
      disabled={disabled}
      onClick={onClick}
      sx={{
        textTransform: 'none',
        backgroundColor: variantProps(disabled)[variant].backgroundColor,
        color: variantProps(disabled)[variant].color,
        border: variantProps(disabled)[variant].border,
        '&:hover': {
          backgroundColor: variantProps(disabled)[variant].backgroundColor,
          boxShadow: variantProps(disabled)[variant].boxShadow,
        },
      }}
      startIcon={iconElement}
    >
      <Typography fontSize={sizeProps[size].fontSize}>
        {capitalize ? text.toUpperCase() : text}
      </Typography>
    </Button>
  );

  return tip === undefined || tip === '' ? (
    button
  ) : (
    <Tooltip enterDelay={200} title={tip} placement='bottom' arrow>
      <Box sx={{ display: 'flex' }}>
        {button}
        <QuestionMark
          sx={{
            backgroundColor: theme.palette.SlabBlue[800],
            color: theme.palette.SlabBlue[0],
            borderRadius: '50%',
            width: '0.75em',
            height: '0.75em',
          }}
        />
      </Box>
    </Tooltip>
  );
};
