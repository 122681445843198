// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class CustomFieldOptions {
  readonly label: string;
  readonly value: unknown;

  constructor(data: { [key: string]: any } = {}) {
    this.label = data.label === undefined ? '' : data.label;
    this.value = data.value === undefined ? null : data.value;
  }

  static zero(): CustomFieldOptions {
    const zeroValues: ExcludeMethodsDeep<CustomFieldOptions> = {
      label: '',
      value: null,
    };
    return new CustomFieldOptions(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewCustomFieldOptions = (
  props: PartialDeep<CustomFieldOptions, { recurseIntoArrays: true }>,
): CustomFieldOptions => new CustomFieldOptions(props);

export const NewCustomFieldOptionsFromDomainObject = (
  props: PartialDeep<DomainObject<CustomFieldOptions>, { recurseIntoArrays: true }>,
): CustomFieldOptions => new CustomFieldOptions(props);
