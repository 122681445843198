// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';

export class ProjectConfigSummary {
  readonly id: string;
  readonly name: string;
  readonly plant: string;
  readonly estimatedVolumeOverride: string | null;
  readonly revenueOverride: string | null;
  readonly plantId: string;
  readonly marketId: string;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.plant = data.plant === undefined ? '' : data.plant;
    this.estimatedVolumeOverride =
      data.estimatedVolumeOverride === undefined ? null : data.estimatedVolumeOverride;
    this.revenueOverride = data.revenueOverride === undefined ? null : data.revenueOverride;
    this.plantId = data.plantId === undefined ? NIL_UUID : data.plantId;
    this.marketId = data.marketId === undefined ? NIL_UUID : data.marketId;
  }

  static zero(): ProjectConfigSummary {
    const zeroValues: ExcludeMethodsDeep<ProjectConfigSummary> = {
      id: NIL_UUID,
      name: '',
      plant: '',
      estimatedVolumeOverride: null,
      revenueOverride: null,
      plantId: NIL_UUID,
      marketId: NIL_UUID,
    };
    return new ProjectConfigSummary(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************
  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewProjectConfigSummary = (
  props: PartialDeep<ProjectConfigSummary, { recurseIntoArrays: true }>,
): ProjectConfigSummary => new ProjectConfigSummary(props);

export const NewProjectConfigSummaryFromDomainObject = (
  props: PartialDeep<DomainObject<ProjectConfigSummary>, { recurseIntoArrays: true }>,
): ProjectConfigSummary => new ProjectConfigSummary(props);
