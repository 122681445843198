// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import Enums from '../Enums';
import { NewScope, Scope } from '../Scope/Scope';

export class Role {
  readonly id: string;
  readonly name: Enums.RoleName;
  readonly scopes: Scope[];

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? Enums.RoleName.Salesperson : data.name;
    this.scopes = (data.scopes ?? []).map((scopes: any) => NewScope(scopes));
  }

  static zero(): Role {
    const zeroValues: ExcludeMethodsDeep<Role> = {
      id: NIL_UUID,
      name: Enums.RoleName.Salesperson,
      scopes: [],
    };
    return new Role(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewRole = (props: PartialDeep<Role, { recurseIntoArrays: true }>): Role =>
  new Role(props);

export const NewRoleFromDomainObject = (
  props: PartialDeep<DomainObject<Role>, { recurseIntoArrays: true }>,
): Role => new Role(props);
