import { Font, Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { Quote } from '../../../../generated-types/Quote/Quote';
import { randomUUID } from '../../../../utils/UUID';
import { PdfColumnConfig, PdfColumnFromConfig, PdfTable } from '../../../components/PdfTable';

type HeaderRow = {
  id: string;
  firstValue: string;
  secondValue: string;
  thirdValue: string;
  fourthValue: string;
  secondValueTextStyle?: Style;
};

const cellContainerViewStyle: Style = {
  display: 'flex',
  justifyContent: 'center',
};

const centerAlignViewStyle: Style = {
  textAlign: 'center',
};

const balthazarTextStyle: Style = {
  fontFamily: 'Balthazar',
  fontSize: '10px',
};

const sansTextStyle: Style = {
  fontFamily: 'OpenSans',
  fontSize: '10px',
};

const formatFirstValueForPdf = (value: string): JSX.Element => (
  <View style={centerAlignViewStyle}>
    <Text style={balthazarTextStyle}>{value}</Text>
  </View>
);

const formatSecondValueForPdf = (value: string, row: HeaderRow): JSX.Element => (
  <View style={centerAlignViewStyle}>
    <Text style={[sansTextStyle, row.secondValueTextStyle ?? {}]}>{value}</Text>
  </View>
);

const formatThirdValueForPdf = (value: string): JSX.Element => (
  <View style={centerAlignViewStyle}>
    <Text style={balthazarTextStyle}>{value}</Text>
  </View>
);

const formatFourthValueForPdf = (value: string): JSX.Element => (
  <View style={centerAlignViewStyle}>
    <Text style={sansTextStyle}>{value}</Text>
  </View>
);

type CityTransitMixPdfHeaderTableProps = {
  quote: Quote;
};

export const CityTransitMixPdfHeaderTable = ({
  quote,
}: CityTransitMixPdfHeaderTableProps): JSX.Element => {
  Font.registerHyphenationCallback((word) => [word]);
  const rows: HeaderRow[] = [
    {
      id: randomUUID(),
      firstValue: 'Attention',
      secondValue: quote.contact?.firstName ?? ' ',
      thirdValue: 'Quote code',
      fourthValue: quote.quoteNumber,
    },
    {
      id: randomUUID(),
      firstValue: 'Company address',
      secondValue: quote.company?.name ?? ' ',
      secondValueTextStyle: { fontStyle: 'bold' },
      thirdValue: 'Phone #',
      fourthValue: quote.contact?.phoneNumber ?? ' ',
    },
    {
      id: randomUUID(),
      firstValue: ' ',
      secondValue: quote.company?.address?.addressDisplay() ?? ' ',
      thirdValue: 'Cell',
      fourthValue: ' ',
    },
    {
      id: randomUUID(),
      firstValue: 'Project',
      secondValue: quote.project.name,
      secondValueTextStyle: { fontStyle: 'bold', textDecoration: 'underline' },
      thirdValue: 'Email',
      fourthValue: quote.contact?.emailAddress ?? ' ',
    },
    {
      id: randomUUID(),
      firstValue: 'Address',
      secondValue: quote.project.address.addressDisplay(),
      thirdValue: 'Tax Exempt Status',
      fourthValue:
        quote.project.taxCode === null
          ? ' '
          : `${quote.project.taxCode.name} (${quote.project.taxCode.code})`,
    },
    {
      id: randomUUID(),
      firstValue: 'Quote Expiration Date',
      secondValue: quote.expirationDateDisplay() ?? ' ',
      secondValueTextStyle: { fontStyle: 'bold' },
      thirdValue: 'Terms',
      // TODO: update when terms are tied to quotes
      fourthValue: quote.notes ?? ' ',
    },
  ];

  const headerCols: PdfColumnConfig<HeaderRow>[] = [
    {
      id: 'firstValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      widthOverride: '10%',
      headerViewStyle: cellContainerViewStyle,
      rowViewStyle: cellContainerViewStyle,
      formatValueForPdf: formatFirstValueForPdf,
    },
    {
      id: 'secondValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      widthOverride: '40%',
      headerViewStyle: cellContainerViewStyle,
      rowViewStyle: cellContainerViewStyle,
      formatValueForPdf: formatSecondValueForPdf,
    },
    {
      id: 'thirdValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      widthOverride: '20%',
      headerViewStyle: cellContainerViewStyle,
      rowViewStyle: cellContainerViewStyle,
      formatValueForPdf: formatThirdValueForPdf,
    },
    {
      id: 'fourthValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      widthOverride: '30%',
      headerViewStyle: cellContainerViewStyle,
      rowViewStyle: cellContainerViewStyle,
      formatValueForPdf: formatFourthValueForPdf,
    },
  ];

  return (
    <PdfTable
      columns={headerCols.map((col) => PdfColumnFromConfig(col))}
      rows={rows}
      cellPadding='tight'
      bordered
    />
  );
};
