import { Box, Typography } from '@mui/material';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import {
  FormatErrorCell,
  FormatLinkCell,
} from '../../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../../components/DataTable/DataTable';
import { ColumnConfig } from '../../../components/DataTable/TableDataModel';
import {
  InitialTableData,
  useLocalTableData,
} from '../../../components/DataTable/useLocalTableData';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { ProjectConfigProduct } from '../../../generated-types/ProjectConfigProduct/ProjectConfigProduct';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { PossiblyAsyncResult } from '../../../utils/Query';
import { NestedKeyOf } from '../../../utils/Types';

type ProjectConfigProductListRow = {
  order: number;
  id: string;
  alternateID: string;
  name: string;
  quantity: number;
  usage: string | null;
  unitVolume: number | null;
  incompatibleMixBatchUnits: string;
};

const buildTableDataModelConfig = ({
  productsResult,
}: {
  productsResult: PossiblyAsyncResult<ProjectConfigProduct[] | undefined>;
}): InitialTableData<
  ProjectConfigProductListRow,
  { id: string },
  ProjectConfigProduct[] | undefined
> => {
  const transformRows = (products: ProjectConfigProduct[] = []): ProjectConfigProductListRow[] =>
    products.map(
      (p, idx): ProjectConfigProductListRow => ({
        // 'order' uses index assuming that the products are already sorted by order.
        order: idx + 1,

        id: p.product.id,
        alternateID: p.product.alternateID,
        name: p.product.name,
        quantity: p.quantity,
        usage: p.usage,
        unitVolume: p.product.cuydVolume,
        incompatibleMixBatchUnits: p.product.incompatibleMixBatchUnits ? 'UOM issue' : '',
      }),
    );

  const columns: ColumnConfig<
    ProjectConfigProductListRow,
    NestedKeyOf<ProjectConfigProductListRow>
  >[] = [
    {
      id: 'order',
      label: 'Order',
      type: 'number',
      isDisplayed: false,
    },
    {
      id: 'alternateID',
      label: 'Product ID',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/products/${row.id}` }),
    },
    {
      id: 'quantity',
      label: 'Quantity',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: 'usage',
      label: 'Usage',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'unitVolume',
      label: 'Unit volume',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: 'incompatibleMixBatchUnits',
      label: 'Incompatible batch unit',
      tooltipText:
        '"UOM issue" products include a mix material whose batch unit is incompatible with its underlying material’s cost unit.',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (v) => FormatErrorCell(v),
    },
  ];

  return {
    rowData: productsResult,
    transformRows,
    columns,
    initialSortPath: 'order',
  };
};

type ProductTableProps = {
  projectConfigId: string;
  onEditProducts: () => void;
};

export const ProjectConfigProductTable = ({
  projectConfigId,
  onEditProducts,
}: ProductTableProps): JSX.Element => {
  const productsResult = useSlabQuery('GET project config products by project config ID', {
    pathParams: {
      id: projectConfigId,
    },
  });
  const { isLoading, isError, data: products } = productsResult;

  const tableModel = useLocalTableData(buildTableDataModelConfig({ productsResult }));

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || products === undefined) {
    return <div>ERROR</div>;
  }

  return (
    <Box padding='1rem'>
      <Box height='400px'>
        <Box
          display='flex'
          justifyContent='space-between'
          paddingBottom='1.5rem'
          alignItems='center'
        >
          <Typography variant='h4'>Products</Typography>
          <ButtonPill
            text='edit products'
            variant='primary'
            size='small'
            onClick={onEditProducts}
            icon='edit'
          />
        </Box>
        <DataTable tableModel={tableModel} />
      </Box>
    </Box>
  );
};
