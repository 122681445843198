import { Button, TableCell, Typography } from '@mui/material';
import { Add, DocumentUpload, Edit2 } from 'iconsax-react';
import React from 'react';
import { Link } from 'react-router-dom';

import { SlabTheme } from '../../../theme';
import { randomUUID } from '../../../utils/UUID';
import { Column } from '../TableDataModel';

export const FormatLinkCell = ({ label, link }: { label: string; link: string }): JSX.Element => (
  <Link to={link}>{label}</Link>
);

/**
 * FormatErrorCell accepts a string value and if it is non-empty, will be displayed
 * in red like an error value.
 *
 * If the value provided is empty, `[no error]` in light text will be displayed.
 */
export const FormatErrorCell = (value: string | null | undefined): JSX.Element => {
  if (value === undefined || value === null || value === '') {
    return <Typography color={SlabTheme.palette.SlabBlue[200]}>[no error]</Typography>;
  }
  return <Typography color={SlabTheme.palette.error.main}>{value}</Typography>;
};

export const FormatButtonCell = ({
  label,
  onClick,
  icon,
}: {
  label: string | null;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  icon?: 'add' | 'edit' | 'send';
}): JSX.Element => {
  const iconElement = ((): JSX.Element | null => {
    const hasLabel = label !== '' && label !== null;
    const iconStyle = { paddingRight: hasLabel ? '0.5rem' : '0' };
    switch (icon) {
      case 'add':
        return <Add style={iconStyle} />;
      case 'edit':
        return <Edit2 style={iconStyle} />;
      case 'send':
        return <DocumentUpload style={iconStyle} />;
      default:
        return null;
    }
  })();
  return (
    <Button sx={{ minWidth: 'auto' }} onClick={onClick}>
      <>
        {iconElement}
        {label}
      </>
    </Button>
  );
};

type TableRowProps<TRow extends { id: string }> = {
  renderedColumns: ReadonlySet<Column<TRow>>;
  row: TRow;
  labelId: string;
};

/**
 * SlabTableRowCells takes a row & the according columns to display and constructs
 * the appropriate looking TableCells to be displayed in a TableRow.
 */
export const SlabTableRowCells = <TRow extends { id: string }>({
  renderedColumns,
  row,
  labelId,
}: TableRowProps<TRow>): JSX.Element => (
  <>
    {[...renderedColumns].map((column, index) => (
      <React.Fragment key={randomUUID()}>
        {/* Spacing for SeparatorIcon in THead */}
        {index > 0 && <TableCell padding='none' />}
        <TableCell id={`${labelId}-${column.id}`} sx={{ padding: '0.5rem' }}>
          {column.toWeb(row)}
        </TableCell>
      </React.Fragment>
    ))}
  </>
);
