// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { Address, NewAddress } from '../Address/Address';
import { LatLong, NewLatLong } from '../LatLong/LatLong';

export class LocationResult {
  readonly location: LatLong;
  readonly formattedAddress: string;
  readonly address: Address;
  readonly placeID: string;

  constructor(data: { [key: string]: any } = {}) {
    this.location = NewLatLong(data.location);
    this.formattedAddress = data.formattedAddress === undefined ? '' : data.formattedAddress;
    this.address = NewAddress(data.address);
    this.placeID = data.placeID === undefined ? '' : data.placeID;
  }

  static zero(): LocationResult {
    const zeroValues: ExcludeMethodsDeep<LocationResult> = {
      location: LatLong.zero(),
      formattedAddress: '',
      address: Address.zero(),
      placeID: '',
    };
    return new LocationResult(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewLocationResult = (
  props: PartialDeep<LocationResult, { recurseIntoArrays: true }>,
): LocationResult => new LocationResult(props);

export const NewLocationResultFromDomainObject = (
  props: PartialDeep<DomainObject<LocationResult>, { recurseIntoArrays: true }>,
): LocationResult => new LocationResult(props);
