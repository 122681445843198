// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';

export class ProjectStatus {
  readonly id: string;
  readonly name: string;
  readonly priority: number;
  readonly isArchived: boolean;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.priority = data.priority === undefined ? 0 : data.priority;
    this.isArchived = data.isArchived === undefined ? false : data.isArchived;
  }

  static zero(): ProjectStatus {
    const zeroValues: ExcludeMethodsDeep<ProjectStatus> = {
      id: NIL_UUID,
      name: '',
      priority: 0,
      isArchived: false,
    };
    return new ProjectStatus(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewProjectStatus = (
  props: PartialDeep<ProjectStatus, { recurseIntoArrays: true }>,
): ProjectStatus => new ProjectStatus(props);

export const NewProjectStatusFromDomainObject = (
  props: PartialDeep<DomainObject<ProjectStatus>, { recurseIntoArrays: true }>,
): ProjectStatus => new ProjectStatus(props);
