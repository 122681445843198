import ReactPDF, { Text } from '@react-pdf/renderer';

import { Quote } from '../../../../generated-types/Quote/Quote';
import { randomUUID } from '../../../../utils/UUID';
import {
  PdfColumnConfig,
  PdfColumnFromConfig,
  PdfTable,
  Styles,
} from '../../../components/PdfTable';
import { PdfTextWithLine } from '../../components/PdfTextWithLine';

const textStyle: ReactPDF.TextProps['style'] = {
  fontSize: '11pt',
};

const styledText = (text: string, style: ReactPDF.TextProps['style']): JSX.Element => (
  <Text style={style}>{text}</Text>
);

const formatElementForPdf = (value: JSX.Element): JSX.Element => value;

type SignatureRow = {
  id: string;
  leftValue: JSX.Element;
  rightValue: JSX.Element;
};

type PdfSignatureProps = {
  quote: Quote;
};

export const PdfSignatures = (props: PdfSignatureProps): JSX.Element => {
  const signatureRows: SignatureRow[] = [
    {
      id: randomUUID(),
      leftValue: (
        <PdfTextWithLine
          text='Joseph P. Carrara & Sons'
          underline
          borderBottom='none'
          textStyle={textStyle}
        />
      ),
      rightValue: (
        <PdfTextWithLine text='Acceptance' underline borderBottom='none' textStyle={textStyle} />
      ),
    },
    {
      id: randomUUID(),
      leftValue: <PdfTextWithLine text='By:' textStyle={textStyle} />,
      rightValue: <PdfTextWithLine text='Company Name:' textStyle={textStyle} />,
    },
    {
      id: randomUUID(),
      leftValue: (
        <Text style={[Styles.tableCell, { paddingLeft: '0.5in' }]}>
          {props.quote.user.fullName()}
        </Text>
      ),
      rightValue: <PdfTextWithLine text='Acknowledged By:' textStyle={textStyle} />,
    },
    {
      id: randomUUID(),
      leftValue: styledText('', {}),
      rightValue: <PdfTextWithLine text='Title:' textStyle={textStyle} />,
    },
    {
      id: randomUUID(),
      leftValue: styledText('', {}),
      rightValue: <PdfTextWithLine text='Date of Acceptance:' textStyle={textStyle} />,
    },
  ];

  const signatureCols: PdfColumnConfig<SignatureRow>[] = [
    {
      id: 'leftValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: formatElementForPdf as (value: JSX.Element | string | null) => JSX.Element,
    },
    {
      id: 'rightValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: formatElementForPdf as (value: JSX.Element | string | null) => JSX.Element,
    },
  ];

  return (
    <PdfTable columns={signatureCols.map((col) => PdfColumnFromConfig(col))} rows={signatureRows} />
  );
};
