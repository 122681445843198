import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export type ServerError = {
  message: string | undefined;
  kind: string | undefined;
};

export type QueryError = AxiosError<ServerError>;

export type PossiblyAsyncResult<Data> = Pick<
  UseQueryResult<Data, QueryError>,
  | 'error'
  | 'failureCount'
  | 'isError'
  | 'isFetched'
  | 'isFetching'
  | 'isPending'
  | 'isLoading'
  | 'isLoadingError'
  | 'isPlaceholderData'
  | 'isRefetchError'
  | 'isRefetching'
  | 'isStale'
  | 'isSuccess'
  | 'status'
> & { data: Data };

export const MakeImmediateResult = <Data,>(data: Data): PossiblyAsyncResult<Data> => ({
  data,
  error: null,
  failureCount: 0,
  isError: false,
  isFetched: true,
  isFetching: false,
  isLoading: false,
  isPending: false,
  isLoadingError: false,
  isPlaceholderData: false,
  isRefetchError: false,
  isRefetching: false,
  isStale: false,
  isSuccess: true,
  status: 'success',
});

type MergedApiResults<T extends { [key: string]: PossiblyAsyncResult<any> }> = PossiblyAsyncResult<{
  [Key in keyof T]: T[Key] extends PossiblyAsyncResult<infer Data> ? Data : never;
}>;

export const MergeAsyncResults = <T extends { [key: string]: PossiblyAsyncResult<any> }>(
  results: T,
): MergedApiResults<T> => {
  type Status = PossiblyAsyncResult<unknown>['status'];
  const status = Object.values(results)
    .map((r) => r.status)
    .sort((a, b) => {
      const statusOrder: Status[] = ['error', 'pending', 'success'];
      return statusOrder.indexOf(a) - statusOrder.indexOf(b);
    })[0];
  const data = Object.fromEntries(
    Object.entries(results).map(([key, result]) => [key, result.data]),
  ) as MergedApiResults<T>['data'];

  const isPending = status === 'pending';
  const isFetching = Object.values(results).some((r) => r.isFetching);

  return {
    data,
    error:
      Object.values(results)
        .map((r) => r.error)
        .find((e) => e !== null) ?? null,
    failureCount: Object.values(results)
      .map((r) => r.failureCount)
      .reduce((sum, count) => sum + count, 0),
    status,
    isError: status === 'error',
    isSuccess: status === 'success',
    isPending,
    isLoading: isPending && isFetching,
    isLoadingError: Object.values(results).some((r) => r.isLoadingError),
    isFetching,
    isFetched: Object.values(results).every((r) => r.isFetched),
    isRefetching: Object.values(results).some((r) => r.isRefetching),
    isRefetchError: Object.values(results).some((r) => r.isRefetchError),
    isPlaceholderData: Object.values(results).some((r) => r.isPlaceholderData),
    isStale: Object.values(results).some((r) => r.isStale),
  };
};
