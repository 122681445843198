import ReactPDF, { Document, Page } from '@react-pdf/renderer';

import { Quote } from '../../../../../generated-types/Quote/Quote';
import { PdfSignatures } from '../../../default/components/PdfSignatures';

const pageStyle: ReactPDF.PageProps['style'] = {
  padding: '0.5in',
};

type CarewDefaultSigningSectionProps = {
  quote: Quote;
};

export const CarewDefaultSigningSection = ({
  quote,
}: CarewDefaultSigningSectionProps): JSX.Element => (
  <Document title={quote.quoteNumber}>
    <Page style={pageStyle}>
      <PdfSignatures quote={quote} tenantName='Carew Concrete' />
    </Page>
  </Document>
);
