// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { Cost, NewCost } from '../Cost/Cost';

export class ReadyMixConstants {
  readonly operatingCost: Cost;
  readonly sgaCost: Cost;
  readonly targetMargin: string;
  readonly minimumMargin: string;

  constructor(data: { [key: string]: any } = {}) {
    this.operatingCost = NewCost(data.operatingCost);
    this.sgaCost = NewCost(data.sgaCost);
    this.targetMargin = data.targetMargin === undefined ? '0' : data.targetMargin;
    this.minimumMargin = data.minimumMargin === undefined ? '0' : data.minimumMargin;
  }

  static zero(): ReadyMixConstants {
    const zeroValues: ExcludeMethodsDeep<ReadyMixConstants> = {
      operatingCost: Cost.zero(),
      sgaCost: Cost.zero(),
      targetMargin: '0',
      minimumMargin: '0',
    };
    return new ReadyMixConstants(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /** Display the target margin as a percentage */
  targetMarginDisplay(): string {
    return `${String(parseFloat((parseFloat(this.targetMargin) * 100).toFixed(3)))}%`;
  }

  /** Display the minimum margin as a percentage */
  minimumMarginDisplay(): string {
    return `${String(parseFloat((parseFloat(this.minimumMargin) * 100).toFixed(3)))}%`;
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewReadyMixConstants = (
  props: PartialDeep<ReadyMixConstants, { recurseIntoArrays: true }>,
): ReadyMixConstants => new ReadyMixConstants(props);

export const NewReadyMixConstantsFromDomainObject = (
  props: PartialDeep<DomainObject<ReadyMixConstants>, { recurseIntoArrays: true }>,
): ReadyMixConstants => new ReadyMixConstants(props);
