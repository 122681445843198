import { Box, Chip, Tooltip, Typography, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { DisplayField } from '../../components/DisplayField/DisplayField';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { MarkAsSentAndShowToast, NylasFlyout } from '../../components/Nylas/NylasFlyout';
import { Page } from '../../components/Page/Page';
import Enums from '../../generated-types/Enums';
import { NewQuote, Quote } from '../../generated-types/Quote/Quote';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { ServerErrorMessage, useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { NIL_UUID } from '../../utils/UUID';
import { AdditionalProductTable } from './components/AdditionalProductTable';
import { MainProductTable } from './components/MainProductTable';
import { PriceEscalationTable } from './components/PriceEscalationTable';
import { QuoteSection } from './components/QuoteDrawerSections';
import { QuotePdfModal } from './components/QuotePdfModal';
import { QuoteDrawer } from './QuoteDrawer';

/**
 * DispatchCustomer is the user-facing sub-section indicating whether
 * a company has a valid dispatch customer or not, and how that impacts
 * the quote export process.
 */
const DispatchCustomer = ({ quote }: { quote: Quote }): JSX.Element => {
  const theme = useTheme();

  // Connected to dispatch customer
  if (quote.company !== null && quote.company.dispatchCustomer !== null) {
    return (
      <Box sx={{ color: theme.palette.success.dark }}>
        {quote.company.dispatchCustomer.name} ({quote.company.dispatchCustomer.code})
      </Box>
    );
  }

  // Disconnected from dispatch customer
  return (
    <Tooltip
      enterDelay={200}
      title='A dispatch customer must be linked before a quote may be exported.'
      placement='bottom'
      arrow
    >
      <Box sx={{ color: theme.palette.error.dark }}>(no linked dispatch customer)</Box>
    </Tooltip>
  );
};

export const QuoteDetailsPage = (): JSX.Element => {
  const theme = useTheme();
  const params = useParams();
  const quoteId = params?.id ?? NIL_UUID;

  const navigate = useNavigate();

  const ctx = useContext(SlabContext);
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);

  const [initialSection, setInitialSection] = useState<QuoteSection | undefined>();
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [isSendQuoteOpen, setIsSendQuoteOpen] = useState(false);

  const { toastHook: quoteToastHook, ...quoteDrawerProps } = useDrawerManager({
    baseUrl: '/quotes',
    resourceTypeName: 'quote',
    drawerProps: {
      resourceId: quoteId,
    },
  });

  const quoteResult = useSlabQuery('GET quote by ID', {
    pathParams: {
      id: quoteId,
    },
  });
  const { isLoading: isLoadingQuote, isError: isErrorQuote, data: unfilledQuote } = quoteResult;

  const {
    isLoading: isLoadingQuoteProducts,
    isError: isErrorQuoteProducts,
    data: quoteProducts,
  } = useSlabQuery('GET quote products by quote ID', {
    pathParams: {
      id: quoteId,
    },
  });

  const markAsSent = useSlabMutation('POST mark quote as sent');

  const isLoading = isLoadingQuote || isLoadingQuoteProducts;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError = isErrorQuote || isErrorQuoteProducts;
  const isDataUndefined = unfilledQuote === undefined || quoteProducts === undefined;

  if (isError || isDataUndefined) {
    return <div>ERROR</div>;
  }

  const quote = NewQuote({
    ...unfilledQuote,
    products: quoteProducts,
  });

  const displayQuote = {
    ...quote,
    creationDateString: quote.creationDateDisplay(),
    revisionDateString: quote.revisionDateDisplay(),
    expirationDateString: quote.expirationDateDisplay(),
    bidDateString: quote.project.bidDateDisplay(),
    plantName: quote.project.plant.name,
    userFullName: quote.user.fullName(),
    projectName: quote.project.name,
    companyName: quote.company?.name ?? null,
    contactName: quote.contact?.fullName() ?? null,
    statusString: quote.status.name,
    dispatchCustomer: <DispatchCustomer quote={quote} />,
    ...quote.project.address,
  };

  const displayFields = {
    'Quote Information': (
      <DisplayField
        title='Quote Information'
        onEditClick={(): void => {
          setInitialSection('Quote Information*');
          quoteDrawerProps.setIsOpen(true);
        }}
        value={displayQuote}
        type='singleField'
        displayMatrix={[
          [
            { key: 'creationDateString', label: 'Creation date' },
            { key: 'revisionDateString', label: 'Revision date' },
          ],
          [
            { key: 'expirationDateString', label: 'Expiration date' },
            { key: 'statusString', label: 'Status' },
          ],
          [
            { key: 'userFullName', label: 'Salesperson' },
            {
              key: 'projectName',
              label: 'Project',
              type: 'uri',
              uri: `/projects/${quote.project.id}`,
            },
          ],
          [
            { key: 'bidDateString', label: 'Bid date' },
            {
              label: 'Plant',
              key: 'plantName',
              type: 'uri',
              uri: `/plants/${quote.project.plant.id}`,
            },
          ],
        ]}
      />
    ),
    'Buyer Information': (
      <DisplayField
        title='Buyer Information'
        onEditClick={(): void => {
          setInitialSection('Buyer Information');
          quoteDrawerProps.setIsOpen(true);
        }}
        value={displayQuote}
        type='singleField'
        displayMatrix={[
          [
            {
              key: 'companyName',
              label: 'Company',
              type: 'uri',
              uri: `/companies/${quote.company?.id}`,
            },
          ],
          [
            {
              key: 'contactName',
              label: 'Contact',
              type: 'uri',
              uri: `/contacts/${quote.contact?.id}`,
            },
          ],
          !usesDispatchCustomer
            ? []
            : [
                {
                  key: 'dispatchCustomer',
                  label: 'Dispatch customer',
                },
              ],
        ]}
      />
    ),
    'Job Site Address': (
      <DisplayField
        title='Job Site Address'
        value={displayQuote}
        type='singleField'
        displayMatrix={[
          [
            { key: 'line1', label: 'Line 1' },
            { key: 'line2', label: 'Line 2' },
          ],
          [
            { key: 'postalCode', label: 'Zip/Postal' },
            { key: 'city', label: 'City/Town' },
          ],
          [
            { key: 'state', label: 'State/County' },
            { key: 'country', label: 'Country' },
          ],
        ]}
      />
    ),
    Terms: (
      <DisplayField
        title='Terms'
        onEditClick={(): void => {
          setInitialSection('Terms');
          quoteDrawerProps.setIsOpen(true);
        }}
        value={displayQuote}
        type='singleField'
        displayMatrix={[[{ key: 'notes', type: 'notes', maxWidth: '100%' }]]}
      />
    ),
  };

  const QuoteDetailsContent = ((): JSX.Element => (
    <Box display='flex' flexDirection='column' gap='2rem'>
      <Box
        display='flex'
        paddingTop='2rem'
        gap='1.25rem'
        sx={{
          // Stretch immediate DisplayFields to take full width + equal width
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          },
          // Stretch DisplayFields' value section to fill available height
          '& > div > div:last-child': {
            flex: 1,
          },
        }}
      >
        {displayFields['Quote Information']}
        {displayFields['Buyer Information']}
        {displayFields['Job Site Address']}
      </Box>
      <Box>
        <MainProductTable
          quoteId={quote.id}
          onEditProducts={(): void => {
            setInitialSection('Products');
            quoteDrawerProps.setIsOpen(true);
          }}
        />
      </Box>
      <Box>
        <AdditionalProductTable
          quoteId={quote.id}
          onEditServices={(): void => {
            setInitialSection('Additional services');
            quoteDrawerProps.setIsOpen(true);
          }}
        />
      </Box>
      <Box>
        <PriceEscalationTable
          escalationsResult={{ ...quoteResult, data: quote.priceEscalations }}
          onEditEscalations={(): void => {
            setInitialSection('Price Escalations');
            quoteDrawerProps.setIsOpen(true);
          }}
        />
      </Box>
      <Box>{displayFields.Terms}</Box>
    </Box>
  ))();

  const title = quote.name !== null ? `${quote.name} (${quote.quoteNumber})` : quote.quoteNumber;
  const canSend = !ctx.userInfo.tenant.requiresQuoteApprovals || quote.status.isApprovedToSend;

  return (
    <Page title={title}>
      {quoteToastHook.toast}
      <QuotePdfModal quote={quote} isOpen={isPdfModalOpen} setIsOpen={setIsPdfModalOpen} />
      <QuoteDrawer
        {...quoteDrawerProps}
        quoteId={quoteDrawerProps.resourceId}
        initialSection={initialSection}
        onSaveAsNew={(newQuote): void => {
          quoteDrawerProps.setIsOpen(false);
          quoteToastHook.showToast('success');
          navigate(`/quotes/${newQuote.id}`);
        }}
        onClose={(): void => setInitialSection(undefined)}
      />
      <NylasFlyout
        isOpen={isSendQuoteOpen}
        setIsOpen={setIsSendQuoteOpen}
        quoteId={quote.id}
        key={quote.id}
        onClose={(): void => {
          setIsSendQuoteOpen(false);
        }}
        onSuccess={async (): Promise<void> => {
          await MarkAsSentAndShowToast({
            quoteId: quote.id,
            markAsSentMutation: markAsSent,
            toastHook: quoteToastHook,
          });
        }}
        onError={(err): void =>
          quoteToastHook.showToast('error', ServerErrorMessage(err) ?? 'Unable to send quote')
        }
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='2.5rem' gap='1.25rem'>
        <Box display='flex' alignItems='center' gap='1.25rem'>
          <Typography variant='h1'>{title}</Typography>
          <Chip label={`Revision ${quote.revisionNumber}`} />
          {quote.hasUOMIssues() && <Chip color='error' label='UOM issue' />}
        </Box>
        <Box display='flex' gap='1rem'>
          {canSend ? (
            <ButtonPill
              text='Send Quote'
              variant='secondary'
              onClick={(): void => setIsSendQuoteOpen(true)}
            />
          ) : (
            <Tooltip arrow placement='bottom' title='Manager approval required before sending'>
              <Box display='flex'>
                <ButtonPill text='send quote' variant='secondary' disabled onClick={() => {}} />
              </Box>
            </Tooltip>
          )}
          <ButtonPill
            text='Generate Quote'
            variant='secondary'
            onClick={(): void => setIsPdfModalOpen(true)}
          />
          <ButtonPill
            text='Edit Quote'
            variant='primary'
            onClick={(): void => {
              setInitialSection('Quote Information*');
              quoteDrawerProps.setIsOpen(true);
            }}
            icon='edit'
          />
        </Box>
      </Box>
      {quote.hasUOMIssues() && (
        <Typography color={theme.palette.error.main}>
          The products in this quote contain UOM issues
        </Typography>
      )}
      {QuoteDetailsContent}
    </Page>
  );
};
