import { AutocompleteRenderInputParams, Box, TextField, Typography } from '@mui/material';
import LogRocket from 'logrocket';
import React from 'react';

import Enums from '../../generated-types/Enums';
import { ListURLParams } from '../../utils/ApiClient';
import { NestedKeyOf } from '../../utils/Types';
import { randomUUID } from '../../utils/UUID';
import { InputTooltip } from '../InputTooltip/InputTooltip';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

export const DEFAULT_LOOKUP_LENGTH = 100;

export type LookupInputOption = {
  /** The value that formik will use when setting values */
  value: any;
  /** The display label in the lookup */
  label: string;
  /** Optional sublabels */
  sublabels?: string[];
  /** Optional grouping */
  group?: string;
  /** @default false */
  isDisabled?: boolean;
};

export type SharedLookupInputProps<
  FState extends Record<string, any>,
  FPath extends NestedKeyOf<FState>,
> = {
  formState: FState;
  name: FPath;
  label?: string;
  options: LookupInputOption[];

  /** @default false */
  grouped?: boolean;

  /**
   * onMatchChange is an optional callback that is invoked when the input
   * matches or does not match one of the component's supplied options.
   *
   * onMatchChange will be called with `null` if the input value does not
   * match any values in the passed in `options` parameter. If there is a match,
   * it will be called with the `option.value`.
   *
   * Currently, onMatchChange is invoked immediately prior to touching and
   * setting the value of the Formik field, so within the callback, the previous
   * value is still accessible in Formik's state while the new value is passed
   * to the callback.
   */
  onMatchChange?: (arg0: string | null) => void;

  /** @default empty */
  placeholder?: string;

  /** @default undefined */
  disabled?: boolean;
  tip?: string;
};

export type ApiLookupInputBaseProps<
  FState extends Record<string, any>,
  FPath extends NestedKeyOf<FState>,
> = SharedLookupInputProps<FState, FPath> & {
  onInputChange?: (arg0: string) => void;
  /** If true, this will filter locally rather than follow API-based filtering. */
  localFiltering?: boolean;
};

/**
 * `GetOptFromValue` returns the first Option that contains the value provided.
 * If there is no match, `null` is returned.
 * If there should be a match but none is provided, it alerts `LogRocket` and returns `null`.
 */
export const GetOptFromValue = (v: any, options: LookupInputOption[]): LookupInputOption | null => {
  // Short-circuit if there is guaranteed to be no matches
  if (v === '' || v === null || options.length === 0) {
    return null;
  }
  const valueOpt = options.find((opt) => opt.value === v);
  if (v !== null && valueOpt === undefined) {
    LogRocket.error('InputLookup selected value did not match available options:', v);
  }
  return valueOpt === undefined ? null : valueOpt;
};

type LookupInputInputProps = {
  params: AutocompleteRenderInputParams;
  name: string;
  stateValue: string;
  hasError: boolean;
  helperText: string | undefined;
  isLoading: boolean;

  placeholder?: string;
  label?: string;
  tip?: string;
};
export const LookupInputInput = ({
  params,
  name,
  stateValue,
  hasError,
  helperText,
  isLoading,
  placeholder,
  label,
  tip,
}: LookupInputInputProps): JSX.Element => {
  const labelWithTip =
    label === undefined ? null : (
      <Box display='flex' gap='0.25rem' alignItems='center'>
        {label}
        {tip !== undefined ? <InputTooltip tip={tip} /> : null}
      </Box>
    );
  return (
    <TextField
      {...params}
      name={name}
      value={stateValue}
      error={hasError}
      helperText={hasError ? helperText : ' '}
      placeholder={placeholder}
      label={labelWithTip}
      // Close outlined box + fix height if label is undefined
      sx={
        label === undefined
          ? {
              fieldset: {
                top: 0,
              },
              legend: {
                display: 'none',
              },
            }
          : {}
      }
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isLoading ? <LoadingSpinner color='inherit' size={20} wrapInBox={false} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );
};

/**
 * `LookupInputAutocompleteOption` is the component used when rendering an option inside
 * of a lookup.
 */
export const LookupInputAutocompleteOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: LookupInputOption,
): JSX.Element => (
  <Box
    // 'props' handles all hover + click events + default css
    {...props}
    // Providing an explicit `value` as the key should guarantee unique-ness.
    // If every `value` is not unique, the user may experience lingering options rendered at unexpected times.
    key={option.value}
    component='li'
    display='flex'
    flexDirection='column'
    alignItems='start !important'
    padding='0rem'
    whiteSpace='nowrap'
  >
    <Typography>{option.label}</Typography>
    {(option.sublabels ?? [])
      .filter((sl) => sl !== '')
      .map((sublabel) => (
        <Typography key={randomUUID()} variant='body3'>
          {sublabel}
        </Typography>
      ))}
  </Box>
);

type QueryParams = {
  queryParams: ListURLParams<{}>;
};

/**
 * Given the form state and inputText, construct appropriate query parameters.
 *
 * If a form is initially loaded (not dirty), search for a match where possible.
 *
 * If a form is dirty, search against the user input text.
 */
export const ConstructListQueryParams = (inputText: string): QueryParams => {
  const queryParams: QueryParams = {
    queryParams: {
      page: 0,
      perPage: DEFAULT_LOOKUP_LENGTH,
    },
  };

  if (inputText.trim() !== '') {
    queryParams.queryParams.filterBy = [
      {
        operation: Enums.FilterOperation.Lookup,
        value: inputText,
      },
    ];
  }

  return queryParams;
};

/**
 * `AlertDupeOptions` will alert LogRocket if there are any duplicate
 * `value`s inside of options.
 */
export const AlertDupeOptions = (options: LookupInputOption[]): void => {
  // If there are duplicate `value`s, alert LogRocket as this will affect user experience.
  const optVals = options.map((o) => o.value);
  const dupeVals = optVals.filter((value, index, self) => self.indexOf(value) !== index);
  if (dupeVals.length !== 0) {
    LogRocket.error(
      'Duplicate option values found. This may show users unintended lookup values.',
      dupeVals,
    );
  }
};
