import { Box } from '@mui/material';

import { NylasAccountManagement } from '../../components/Nylas/NylasAccountManagement';
import { Page } from '../../components/Page/Page';
import { useToast } from '../../components/Toast/useToast';
import { ServerErrorMessage } from '../../hooks/useSlabQuery';

export const NylasAccountManagementPage = (): JSX.Element => {
  const { showToast, toast } = useToast('credentials');

  return (
    <Page title='Nylas login'>
      {toast}
      <Box gap={2} display='flex' flexDirection='column'>
        <NylasAccountManagement
          onSuccess={(message: string): void => {
            showToast('success', message);
          }}
          onError={(error): void => {
            showToast('error', ServerErrorMessage(error) ?? 'Nylas returned an error');
          }}
        />
      </Box>
    </Page>
  );
};
