// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';

export class ForecastStatusAgg {
  readonly projectStatusId: string;
  readonly projectStatusName: string;
  readonly projectCount: string;
  readonly totalProjectCuyd: string;

  constructor(data: { [key: string]: any } = {}) {
    this.projectStatusId = data.projectStatusId === undefined ? NIL_UUID : data.projectStatusId;
    this.projectStatusName = data.projectStatusName === undefined ? '' : data.projectStatusName;
    this.projectCount = data.projectCount === undefined ? '0' : data.projectCount;
    this.totalProjectCuyd = data.totalProjectCuyd === undefined ? '0' : data.totalProjectCuyd;
  }

  static zero(): ForecastStatusAgg {
    const zeroValues: ExcludeMethodsDeep<ForecastStatusAgg> = {
      projectStatusId: NIL_UUID,
      projectStatusName: '',
      projectCount: '0',
      totalProjectCuyd: '0',
    };
    return new ForecastStatusAgg(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewForecastStatusAgg = (
  props: PartialDeep<ForecastStatusAgg, { recurseIntoArrays: true }>,
): ForecastStatusAgg => new ForecastStatusAgg(props);

export const NewForecastStatusAggFromDomainObject = (
  props: PartialDeep<DomainObject<ForecastStatusAgg>, { recurseIntoArrays: true }>,
): ForecastStatusAgg => new ForecastStatusAgg(props);
