// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

// webgen:import { "names": ["DistanceDurationPair"], "path": "../DistanceDurationPair/DistanceDurationPair" }

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { DistanceDurationPair } from '../DistanceDurationPair/DistanceDurationPair';
import {
  DistanceResultsByOrigin,
  NewDistanceResultsByOrigin,
} from '../DistanceResultsByOrigin/DistanceResultsByOrigin';

export class DistanceResult {
  readonly originAddresses: string[];
  readonly destinationAddresses: string[];
  readonly results: DistanceResultsByOrigin[];

  constructor(data: { [key: string]: any } = {}) {
    this.originAddresses = data.originAddresses ?? [];
    this.destinationAddresses = data.destinationAddresses ?? [];
    this.results = (data.results ?? []).map((results: any) => NewDistanceResultsByOrigin(results));
  }

  static zero(): DistanceResult {
    const zeroValues: ExcludeMethodsDeep<DistanceResult> = {
      originAddresses: [],
      destinationAddresses: [],
      results: [],
    };
    return new DistanceResult(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /** Get the results for given origin and destination matrix indices */
  resultByIndex(originIdx: number, destinationIdx: number): DistanceDurationPair | null {
    if (this.results !== undefined && this.results.length > originIdx) {
      const projectResults = this.results[originIdx].resultsForOrigin;
      if (projectResults.length > destinationIdx) {
        return projectResults[destinationIdx];
      }
    }
    return null;
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewDistanceResult = (
  props: PartialDeep<DistanceResult, { recurseIntoArrays: true }>,
): DistanceResult => new DistanceResult(props);

export const NewDistanceResultFromDomainObject = (
  props: PartialDeep<DomainObject<DistanceResult>, { recurseIntoArrays: true }>,
): DistanceResult => new DistanceResult(props);
