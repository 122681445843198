import * as Yup from 'yup';

import { QuoteConfig } from '../../generated-types/QuoteConfig/QuoteConfig';
import { DomainObject } from '../../utils/ApiClient';
import { NullableCurrencyString } from '../../utils/Currency';
import { NIL_UUID } from '../../utils/UUID';
import {
  TestForUndefined,
  YupReference,
  YupSchemaReferenceType,
  YupString,
} from '../../utils/YupHelpers';

export type QuoteConfigProductFormikType = {
  product: YupSchemaReferenceType;

  // Display-only formik fields
  displayOnly: {
    listPrice: string | null;
  } | null;
};

export type QuoteConfigFormikType = DomainObject<Omit<QuoteConfig, 'plant' | 'products'>> & {
  plant: YupSchemaReferenceType;
  products: QuoteConfigProductFormikType[];
};

export const QuoteConfigSchema: Yup.SchemaOf<
  Omit<QuoteConfigFormikType, 'id' | 'products'> & {
    products: Omit<QuoteConfigProductFormikType, 'displayOnly'>[];
  }
> = Yup.object()
  .shape({
    name: YupString('Name'),
    terms: YupString('Terms'),
    plant: YupReference('Plant'),
    products: Yup.array().of(
      Yup.object({
        product: YupReference('Product'),
      }),
    ),
  })
  .test(TestForUndefined('QuoteConfigSchema'));

export const FormikQuoteConfig = (
  quoteConfig: Partial<QuoteConfig> | undefined,
): QuoteConfigFormikType => ({
  id: quoteConfig?.id ?? NIL_UUID,
  name: quoteConfig?.name ?? '',
  terms: quoteConfig?.terms ?? '',
  plant: {
    id: quoteConfig?.plant?.id ?? null,
    option:
      quoteConfig?.plant === undefined
        ? null
        : {
            value: quoteConfig.plant.id,
            label: quoteConfig.plant.name,
          },
  },
  products: (quoteConfig?.products ?? []).map((qcp) => ({
    product: {
      id: qcp.product.id,
      option: {
        value: qcp.product.id,
        label: qcp.product.name,
        sublabels: [qcp.product.alternateID],
      },
    },
    displayOnly: {
      listPrice: NullableCurrencyString({ cur: qcp.product.listPrice }),
    },
  })),
});

export const EmptyFormikQuoteConfig = FormikQuoteConfig(undefined);
