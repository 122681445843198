// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import Enums from '../Enums';

export class CompanyCategoryAgg {
  readonly companyCategory: Enums.CompanyCategory;
  readonly count: number;

  constructor(data: { [key: string]: any } = {}) {
    this.companyCategory =
      data.companyCategory === undefined ? Enums.CompanyCategory.Contractor : data.companyCategory;
    this.count = data.count === undefined ? 0 : data.count;
  }

  static zero(): CompanyCategoryAgg {
    const zeroValues: ExcludeMethodsDeep<CompanyCategoryAgg> = {
      companyCategory: Enums.CompanyCategory.Contractor,
      count: 0,
    };
    return new CompanyCategoryAgg(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewCompanyCategoryAgg = (
  props: PartialDeep<CompanyCategoryAgg, { recurseIntoArrays: true }>,
): CompanyCategoryAgg => new CompanyCategoryAgg(props);

export const NewCompanyCategoryAggFromDomainObject = (
  props: PartialDeep<DomainObject<CompanyCategoryAgg>, { recurseIntoArrays: true }>,
): CompanyCategoryAgg => new CompanyCategoryAgg(props);
