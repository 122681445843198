import { DateTime } from 'luxon';
import { natsort } from 'natsort-esm';

import { Cost } from '../../../generated-types/Cost/Cost';
import { Currency } from '../../../generated-types/Currency/Currency';
import Enums from '../../../generated-types/Enums';
import { ListURLParams } from '../../../utils/ApiClient';

// Comparator from natsort-esm, which handles niceties like sorting numbers embedded in strings
// based on numeric rather than lexicographic order.
const compareStringsAsc = natsort({ tokenizeWholeNumbersOnly: true });

const compareNumbersAsc = (aVal: number, bVal: number): number => aVal - bVal;

export const BooleanSortValue = (value: boolean | null): number | null =>
  value === null ? null : Number(value);
export const DateTimeSortValue = (value: DateTime | null): number | null =>
  value?.toMillis() ?? null;
export const CurrencySortValue = (value: Currency | null): string | null =>
  value?.number?.replaceAll(',', '') ?? null;
export const CostSortValue = (value: Cost | null): string | null =>
  CurrencySortValue(value?.amount ?? null);
export const DineroSortValue = (value: Dinero.Dinero | null): number | null =>
  value?.toUnit() ?? null;

const sortComparator = <T extends { id: string }>(
  a: T,
  b: T,
  extractSortValue: (v: T) => string | number | null,
  order: Enums.SortDirection,
): number => {
  const aValue = extractSortValue(a);
  const bValue = extractSortValue(b);

  if (bValue === null || bValue === '') {
    return -1;
  }
  if (aValue === null || aValue === '') {
    return 1;
  }
  if (aValue === bValue) {
    return 0;
  }

  let ascResult: number;
  if (typeof aValue === 'number' && typeof bValue === 'number') {
    ascResult = compareNumbersAsc(aValue, bValue);
  } else {
    ascResult = compareStringsAsc(String(aValue), String(bValue));
  }
  return order === Enums.SortDirection.Ascending ? ascResult : -1 * ascResult;
};

export type SortConfig<TData extends { id: string }> = NonNullable<ListURLParams<TData>['sortBy']>;

type Comparator<TData extends {}> = (a: TData, b: TData) => number;

export const getComparator =
  <TData extends { id: string }>(
    direction: Enums.SortDirection,
    extractSortValue: (value: TData) => string | number | null,
  ): Comparator<TData> =>
  (a, b) =>
    sortComparator(a, b, extractSortValue, direction);
