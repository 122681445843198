// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

// webgen:import { "names": ["CalculateOtherCost", "CalculateSuggestedPrice"], "path": "../../utils/CostCalculations" }
// webgen:import { "wildcardName": "CurrencyHelpers", "path": "../../utils/Currency" }
// webgen:import { "defaultName": "Dinero", "path": "dinero.js" }

import Dinero from 'dinero.js';
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { CalculateOtherCost, CalculateSuggestedPrice } from '../../utils/CostCalculations';
import * as CurrencyHelpers from '../../utils/Currency';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { Currency, NewCurrency } from '../Currency/Currency';
import { DeliveryCost, NewDeliveryCost } from '../DeliveryCost/DeliveryCost';
import Enums from '../Enums';
import { NewProduct, Product } from '../Product/Product';

export class QuoteProduct {
  readonly product: Product;
  readonly kind: Enums.QuoteProductKind;
  readonly quantity: number | null;
  readonly price: Currency;
  readonly class: number | null;
  readonly externalName: string | null;
  readonly deliveryCosts: DeliveryCost;
  readonly usage: string | null;
  readonly otherCost: Currency;
  readonly materialCost: Currency;

  constructor(data: { [key: string]: any } = {}) {
    this.product = NewProduct(data.product);
    this.kind = data.kind === undefined ? Enums.QuoteProductKind.Additional : data.kind;
    this.quantity = data.quantity === undefined ? null : data.quantity;
    this.price = NewCurrency(data.price);
    this.class = data.class === undefined ? null : data.class;
    this.externalName = data.externalName === undefined ? null : data.externalName;
    this.deliveryCosts = NewDeliveryCost(data.deliveryCosts);
    this.usage = data.usage === undefined ? null : data.usage;
    this.otherCost = NewCurrency(data.otherCost);
    this.materialCost = NewCurrency(data.materialCost);
  }

  static zero(): QuoteProduct {
    const zeroValues: ExcludeMethodsDeep<QuoteProduct> = {
      product: Product.zero(),
      kind: Enums.QuoteProductKind.Additional,
      quantity: null,
      price: Currency.zero(),
      class: null,
      externalName: null,
      deliveryCosts: DeliveryCost.zero(),
      usage: null,
      otherCost: Currency.zero(),
      materialCost: Currency.zero(),
    };
    return new QuoteProduct(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /** Returns the price * quantity as a Currency */
  extendedPriceCurrency(): Currency {
    const price = CurrencyHelpers.CurrencyDinero(this.price).toUnit();
    const extendedPriceData: ExcludeMethodsDeep<Currency> = {
      number: (price * (this.quantity ?? 1)).toFixed(2),
      currency: 'USD',
    };
    return new Currency(extendedPriceData);
  }

  /** Returns the price * quantity as a string */
  extendedPriceDisplay(): string {
    return CurrencyHelpers.CurrencyString({ cur: this.extendedPriceCurrency() });
  }

  // TODO: costPerUnit should return a Currency instead of a Dinero
  /** Returns the material costs + locally-calculated other costs as a Dinero. */
  costPerUnit(): Dinero.Dinero {
    const materialCostDinero = CurrencyHelpers.CurrencyDinero(this.product.getMaterialCost());
    const otherCostDinero = CurrencyHelpers.CurrencyDinero(
      this.calculateOtherCost() ?? Currency.zero(),
    );
    return materialCostDinero.add(otherCostDinero);
  }

  costPerUnitDisplay(): string {
    return CurrencyHelpers.DineroString({ dinero: this.costPerUnit() });
  }

  /** Returns the margin as a number | null */
  margin(): number | null {
    const listPrice = CurrencyHelpers.CurrencyDinero(this.price).toUnit();
    if (Number.isNaN(listPrice) || listPrice <= 0) {
      return null;
    }
    const totalCost = this.costPerUnit().toUnit();
    const marginNumber = (1 - totalCost / listPrice) * 100;

    // TODO: Replace this with decimal precision in #492
    return parseFloat(marginNumber.toFixed(3));
  }

  /** Returns the percentage-based margin as a formatted percentage. */
  marginAsPercentage(): string | null {
    const thisMargin = this.margin();
    return thisMargin === null ? null : `${thisMargin.toLocaleString()}%`;
  }

  /** Returns the percentage-based margin as a formatted currency amount. */
  marginAsFormattedCurrency(): string | null {
    const listPrice = CurrencyHelpers.CurrencyDinero(this.price).toUnit();
    if (Number.isNaN(listPrice) || listPrice <= 0) {
      return null;
    }
    const totalCost = this.costPerUnit().toUnit();
    const marginNumber = listPrice - totalCost;

    return CurrencyHelpers.CurrencyString({
      cur: NewCurrency({
        number: String(marginNumber),
      }),
    });
  }

  /** Returns the margin over material as a number | null */
  marginOverMaterial(): number | null {
    const listPrice = CurrencyHelpers.CurrencyDinero(this.price).toUnit();
    if (Number.isNaN(listPrice) || listPrice <= 0) {
      return null;
    }
    const materialCost = CurrencyHelpers.CurrencyDinero(this.product.getMaterialCost()).toUnit();
    const marginNumber = (1 - materialCost / listPrice) * 100;

    // TODO: Replace this with decimal precision in #492
    return parseFloat(marginNumber.toFixed(3));
  }

  /** Returns the percentage-based margin over material as a formatted percentage. */
  marginOverMaterialAsPercentage(): string | null {
    const thisMargin = this.marginOverMaterial();
    return thisMargin === null ? null : `${thisMargin.toLocaleString()}%`;
  }

  /** Returns the percentage-based margin over material as a formatted currency amount. */
  marginOverMaterialAsFormattedCurrency(): string | null {
    const listPrice = CurrencyHelpers.CurrencyDinero(this.price).toUnit();
    if (Number.isNaN(listPrice) || listPrice <= 0) {
      return null;
    }
    const materialCost = CurrencyHelpers.CurrencyDinero(this.product.getMaterialCost()).toUnit();
    const marginOverMaterial = listPrice - materialCost;

    return CurrencyHelpers.CurrencyString({
      cur: NewCurrency({
        number: String(marginOverMaterial),
      }),
    });
  }

  /** If product.cuydVolume or quoteProduct.quantity are null, return null. */
  estimatedYards(): number | null {
    return this.product.cuydVolume === null || this.quantity === null
      ? null
      : this.quantity * this.product.cuydVolume;
  }

  /** display-friendly thousands-separated `estimatedYards()` */
  estimatedYardsDisplay(): string | null {
    const estYards = this.estimatedYards();
    return estYards === null
      ? null
      : CurrencyHelpers.numericFormatter(String(estYards), { thousandSeparator: true });
  }

  /** (price * quantity) / estimatedYards. If estimatedYards is 0 or null, return null */
  pricePerYard(): number | null {
    const estYards = this.estimatedYards();
    return estYards === null || estYards === 0
      ? null
      : parseFloat(this.extendedPriceCurrency().number) / estYards;
  }

  /** display-friendly thousands-separated `pricePerYard()` */
  pricePerYardDisplay(): string | null {
    const ppy = this.pricePerYard();
    return ppy === null
      ? null
      : `$${CurrencyHelpers.numericFormatter(ppy.toFixed(2), { thousandSeparator: true })}`;
  }

  calculateOtherCost(): Currency | null {
    return CalculateOtherCost({
      product: this.product,
      deliveryCosts: this.deliveryCosts,
      otherCostOverride: this.product.otherCostOverride,
    });
  }

  calculateSuggestedPrice(): Currency | null {
    return CalculateSuggestedPrice({
      product: this.product,
      deliveryCosts: this.deliveryCosts,
      otherCostOverride: this.product.otherCostOverride,
    });
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewQuoteProduct = (
  props: PartialDeep<QuoteProduct, { recurseIntoArrays: true }>,
): QuoteProduct => new QuoteProduct(props);

export const NewQuoteProductFromDomainObject = (
  props: PartialDeep<DomainObject<QuoteProduct>, { recurseIntoArrays: true }>,
): QuoteProduct => new QuoteProduct(props);
