// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import Enums from '../Enums';

export class CompanySummary {
  readonly id: string;
  readonly name: string;
  readonly category: Enums.CompanyCategory;
  readonly streetAddress: string | null;
  readonly city: string | null;
  readonly state: string;
  readonly creditStatusCode: string | null;
  readonly creditStatusDescription: string | null;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.category = data.category === undefined ? Enums.CompanyCategory.Contractor : data.category;
    this.streetAddress = data.streetAddress === undefined ? null : data.streetAddress;
    this.city = data.city === undefined ? null : data.city;
    this.state = data.state === undefined ? '' : data.state;
    this.creditStatusCode = data.creditStatusCode === undefined ? null : data.creditStatusCode;
    this.creditStatusDescription =
      data.creditStatusDescription === undefined ? null : data.creditStatusDescription;
  }

  static zero(): CompanySummary {
    const zeroValues: ExcludeMethodsDeep<CompanySummary> = {
      id: NIL_UUID,
      name: '',
      category: Enums.CompanyCategory.Contractor,
      streetAddress: null,
      city: null,
      state: '',
      creditStatusCode: null,
      creditStatusDescription: null,
    };
    return new CompanySummary(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewCompanySummary = (
  props: PartialDeep<CompanySummary, { recurseIntoArrays: true }>,
): CompanySummary => new CompanySummary(props);

export const NewCompanySummaryFromDomainObject = (
  props: PartialDeep<DomainObject<CompanySummary>, { recurseIntoArrays: true }>,
): CompanySummary => new CompanySummary(props);
