// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import Enums from '../Enums';

export class ProductSummary {
  readonly id: string;
  readonly actualMargin: string | null;
  readonly alternateId: string;
  readonly category: Enums.ProductCategory;
  readonly costPerUnit: string;
  readonly name: string;
  readonly plantName: string;
  readonly pricePerUnit: string | null;
  readonly suggestedPrice: string;
  readonly targetMargin: string;
  readonly incompatibleMixBatchUnits: string | null;
  readonly marketId: string;
  readonly plantId: string;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.actualMargin = data.actualMargin === undefined ? null : data.actualMargin;
    this.alternateId = data.alternateId === undefined ? '' : data.alternateId;
    this.category = data.category === undefined ? Enums.ProductCategory.Mix : data.category;
    this.costPerUnit = data.costPerUnit === undefined ? '' : data.costPerUnit;
    this.name = data.name === undefined ? '' : data.name;
    this.plantName = data.plantName === undefined ? '' : data.plantName;
    this.pricePerUnit = data.pricePerUnit === undefined ? null : data.pricePerUnit;
    this.suggestedPrice = data.suggestedPrice === undefined ? '' : data.suggestedPrice;
    this.targetMargin = data.targetMargin === undefined ? '' : data.targetMargin;
    this.incompatibleMixBatchUnits =
      data.incompatibleMixBatchUnits === undefined ? null : data.incompatibleMixBatchUnits;
    this.marketId = data.marketId === undefined ? NIL_UUID : data.marketId;
    this.plantId = data.plantId === undefined ? NIL_UUID : data.plantId;
  }

  static zero(): ProductSummary {
    const zeroValues: ExcludeMethodsDeep<ProductSummary> = {
      id: NIL_UUID,
      actualMargin: null,
      alternateId: '',
      category: Enums.ProductCategory.Mix,
      costPerUnit: '',
      name: '',
      plantName: '',
      pricePerUnit: null,
      suggestedPrice: '',
      targetMargin: '',
      incompatibleMixBatchUnits: null,
      marketId: NIL_UUID,
      plantId: NIL_UUID,
    };
    return new ProductSummary(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewProductSummary = (
  props: PartialDeep<ProductSummary, { recurseIntoArrays: true }>,
): ProductSummary => new ProductSummary(props);

export const NewProductSummaryFromDomainObject = (
  props: PartialDeep<DomainObject<ProductSummary>, { recurseIntoArrays: true }>,
): ProductSummary => new ProductSummary(props);
