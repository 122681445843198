// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

// webgen:import { "defaultName": "Dinero", "path": "dinero.js" }
// webgen:import { "names": ["CalculateOtherCost", "CalculateSuggestedPrice"], "path": "../../utils/CostCalculations" }
// webgen:import { "wildcardName": "CurrencyHelpers", "path": "../../utils/Currency" }

import Dinero from 'dinero.js';
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { CalculateOtherCost, CalculateSuggestedPrice } from '../../utils/CostCalculations';
import * as CurrencyHelpers from '../../utils/Currency';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { Currency, NewCurrency } from '../Currency/Currency';
import { DeliveryCost, NewDeliveryCost } from '../DeliveryCost/DeliveryCost';
import { NewProduct, Product } from '../Product/Product';

export class ProjectProduct {
  readonly product: Product;
  readonly quantity: number;
  readonly usage: string | null;
  readonly price: Currency;
  readonly deliveryCosts: DeliveryCost;
  readonly otherCost: Currency;
  readonly materialCost: Currency;

  constructor(data: { [key: string]: any } = {}) {
    this.product = NewProduct(data.product);
    this.quantity = data.quantity === undefined ? 0 : data.quantity;
    this.usage = data.usage === undefined ? null : data.usage;
    this.price = NewCurrency(data.price);
    this.deliveryCosts = NewDeliveryCost(data.deliveryCosts);
    this.otherCost = NewCurrency(data.otherCost);
    this.materialCost = NewCurrency(data.materialCost);
  }

  static zero(): ProjectProduct {
    const zeroValues: ExcludeMethodsDeep<ProjectProduct> = {
      product: Product.zero(),
      quantity: 0,
      usage: null,
      price: Currency.zero(),
      deliveryCosts: DeliveryCost.zero(),
      otherCost: Currency.zero(),
      materialCost: Currency.zero(),
    };
    return new ProjectProduct(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  unitMargin(): number {
    const unitCost = this.costPerUnit().toUnit();
    const unitPrice = CurrencyHelpers.CurrencyDinero(this.price).toUnit();
    return (1 - unitCost / unitPrice) * 100;
  }

  /** Returns the percentage-based margin as a formatted percentage. */
  marginAsPercentage(): string | null {
    const thisMargin = this.unitMargin();
    return thisMargin === null ? null : `${thisMargin.toLocaleString()}%`;
  }

  calculateOtherCost(): Currency | null {
    return CalculateOtherCost({
      product: this.product,
      deliveryCosts: this.deliveryCosts,
      otherCostOverride: this.product.otherCostOverride,
    });
  }

  calculateSuggestedPrice(): Currency | null {
    return CalculateSuggestedPrice({
      product: this.product,
      deliveryCosts: this.deliveryCosts,
      otherCostOverride: this.product.otherCostOverride,
    });
  }

  /** Returns the material costs + other costs as a Dinero. This uses the server-calculated other costs. */
  costPerUnit(): Dinero.Dinero {
    const materialCostDinero = CurrencyHelpers.CurrencyDinero(this.materialCost);
    const otherCostDinero = CurrencyHelpers.CurrencyDinero(this.otherCost);
    return materialCostDinero.add(otherCostDinero);
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewProjectProduct = (
  props: PartialDeep<ProjectProduct, { recurseIntoArrays: true }>,
): ProjectProduct => new ProjectProduct(props);

export const NewProjectProductFromDomainObject = (
  props: PartialDeep<DomainObject<ProjectProduct>, { recurseIntoArrays: true }>,
): ProjectProduct => new ProjectProduct(props);
