// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class EmailRecipient {
  readonly email: string;
  readonly name?: string;

  constructor(data: { [key: string]: any } = {}) {
    this.email = data.email === undefined ? '' : data.email;
    this.name = data.name;
  }

  static zero(): EmailRecipient {
    const zeroValues: ExcludeMethodsDeep<EmailRecipient> = {
      email: '',
    };
    return new EmailRecipient(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewEmailRecipient = (
  props: PartialDeep<EmailRecipient, { recurseIntoArrays: true }>,
): EmailRecipient => new EmailRecipient(props);

export const NewEmailRecipientFromDomainObject = (
  props: PartialDeep<DomainObject<EmailRecipient>, { recurseIntoArrays: true }>,
): EmailRecipient => new EmailRecipient(props);
