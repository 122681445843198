import { Style } from '@react-pdf/types';

/**
 * Condenses multiple react-pdf styles into a single style object.
 */
export const StyleFrom = (...styles: (Style | Style[] | undefined)[]): Style => {
  const styleArray = styles.flat().filter((s) => s !== undefined);
  const newStyle: Style = styleArray.reduce((acc: Style, curStyle) => {
    if (curStyle === undefined) {
      return acc;
    }
    if (Array.isArray(curStyle)) {
      return {
        ...acc,
        ...StyleFrom(curStyle),
      };
    }
    return {
      ...acc,
      ...curStyle,
    };
  }, {});

  return newStyle;
};
