import { Box, InputAdornment, TextField } from '@mui/material';
import { Field, FieldAttributes, useFormikContext } from 'formik';
import { Duration, DurationLikeObject } from 'luxon';
import { NumericFormat } from 'react-number-format';

import { SetFormikValue } from '../../utils/FormikHelpers';
import { InputTooltip } from '../InputTooltip/InputTooltip';

type SingleUnitDurationInputProps = {
  name: string;
  /** The duration unit (e.g. 'minutes', 'hours'). Will also be shown as an endAdornment. */
  unit: keyof DurationLikeObject;
  label?: string;

  /** Default empty */
  placeholder?: string;
  disabled?: boolean;
  /** Optional onChange chain function */
  onInputChange?: (newValue: string) => Promise<void>;
  /** Optional locale for type formatting */
  locale?: string;
  tip?: string;
};

export const SingleUnitDurationInput = ({
  name,
  label,
  disabled,
  onInputChange,
  placeholder,
  locale,
  tip,
  unit,
}: SingleUnitDurationInputProps): JSX.Element => {
  /** If value is a Duration, convert it to the correct form-value string. If it's a form input, return unchanged. */
  const handleValue = (value: Duration | string | null | undefined): string => {
    if (value === null || value === undefined) {
      return '';
    }
    if (typeof value === 'string') {
      return String(Duration.fromISO(value).as(unit));
    }
    return value.as(unit).toLocaleString(locale);
  };

  const labelWithTip =
    label === undefined ? null : (
      <Box display='flex' gap='0.25rem' alignItems='center'>
        {label}
        {tip !== undefined ? <InputTooltip tip={tip} /> : null}
      </Box>
    );

  return (
    <Field id={name} name={name}>
      {({ field: { value, onBlur }, meta }: FieldAttributes<any>): JSX.Element => {
        const formikBag = useFormikContext<any>();
        const hasError: boolean = meta.touched === true && meta.error !== undefined;
        const formikError = meta.error;

        const handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
          const val = event.currentTarget.value;
          if (val === '') {
            await SetFormikValue(formikBag, name, null);
            return;
          }
          const duration = Duration.fromObject({
            [unit]: parseInt(val.replaceAll(',', ''), 10),
          });
          SetFormikValue(formikBag, name, duration.toISO());
        };

        return (
          <NumericFormat
            id={name}
            decimalScale={0}
            thousandSeparator
            customInput={TextField}
            InputProps={{
              endAdornment: <InputAdornment position='end'>{unit}</InputAdornment>,
            }}
            name={name}
            label={labelWithTip}
            value={handleValue(value)}
            min={0}
            onBlur={async (e): Promise<void> => {
              // If you enter a negative then click away, NumberFormat automatically clears it.
              // Because of that, we check and if it is only a `-`, set the formikBag state to match the
              // soon-to-be empty string. Otherwise an `Invalid` error is displayed, while no value exists.
              const curV = e.currentTarget.value;
              if (curV === '-') {
                await SetFormikValue(formikBag, name, null);
              }
              await onBlur(e);
            }}
            onChange={async (e): Promise<void> => {
              await handleChange(e);
              const newDuration =
                e.target.value === ''
                  ? ''
                  : Duration.fromObject({
                      [unit]: parseInt(e.target.value.replaceAll(',', ''), /* radix= */ 10),
                    }).toISO();
              await onInputChange?.(newDuration);
            }}
            fullWidth
            error={hasError}
            helperText={hasError ? formikError : ' '}
            placeholder={placeholder}
            disabled={disabled}
            // Close outlined box + fix height if label is undefined
            sx={
              label === undefined
                ? {
                    fieldset: {
                      top: 0,
                    },
                    legend: {
                      display: 'none',
                    },
                  }
                : {}
            }
          />
        );
      }}
    </Field>
  );
};
