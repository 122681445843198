import { QuestionMark } from '@mui/icons-material';
import { Tooltip, TooltipProps, useTheme } from '@mui/material';

type InputTooltipProps = Omit<TooltipProps, 'title' | 'children'> & {
  tip: string;
};

/**
 * Slabstack custom tooltip that utilizes MUI. Its primary purpose is to
 * wrap our Input-like components that have error message padding such that
 * the tip is displayed closer to the Input TextField.
 */
export const InputTooltip = ({ tip, ...props }: InputTooltipProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Tooltip enterDelay={200} title={tip} placement='top' arrow {...props}>
      <QuestionMark
        sx={{
          backgroundColor: theme.palette.SlabBlue[800],
          color: theme.palette.SlabBlue[0],
          borderRadius: '50%',
          width: '0.75em',
          height: '0.75em',
        }}
      />
    </Tooltip>
  );
};
