import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Activities } from '../../components/Activities/Activities';
import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { FormatLinkCell } from '../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../components/DataTable/DataTable';
import { ColumnConfig } from '../../components/DataTable/TableDataModel';
import { InitialTableData, useLocalTableData } from '../../components/DataTable/useLocalTableData';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Page } from '../../components/Page/Page';
import { Contact } from '../../generated-types/Contact/Contact';
import { ContactListRow } from '../../generated-types/row-types';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { List } from '../../utils/List';
import { PossiblyAsyncResult } from '../../utils/Query';
import { NestedKeyOf } from '../../utils/Types';
import { ContactDrawer } from './ContactDrawer';

const buildTableDataModelConfig = (
  contactsResult: PossiblyAsyncResult<List<Contact> | undefined>,
): InitialTableData<ContactListRow, { id: string }, List<Contact> | undefined> => {
  const transformRows = (contactList: List<Contact> = List.zero()): ContactListRow[] =>
    contactList.items.map(
      (c): ContactListRow => ({
        id: c.id,
        name: c.fullName(),
        companyName: c.company.name,
        jobTitle: c.jobTitle,
        email: c.emailAddress,
        phone: c.phoneNumber,
      }),
    );

  const columns: ColumnConfig<ContactListRow, NestedKeyOf<ContactListRow>>[] = [
    {
      id: 'name',
      label: 'Name',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/contacts/${row.id}` }),
    },
    {
      id: 'companyName',
      label: 'Company',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'jobTitle',
      label: 'Job title',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'email',
      label: 'Email',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'phone',
      label: 'Phone',
      type: 'string',
      isDisplayed: true,
    },
  ];

  return {
    rowData: contactsResult,
    transformRows,
    columns,
    initialSortPath: 'companyName',
  };
};

export const ContactList = (): JSX.Element => {
  const navigate = useNavigate();

  const [contactId, setContactId] = useState<string | null>(null);
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false);
  const [activityId, setActivityId] = useState<string | null>(null);

  const { toastHook: contactToastHook, ...contactDrawerProps } = useDrawerManager({
    baseUrl: '/contacts',
    resourceTypeName: 'contact',
    drawerProps: {
      resourceId: contactId,
    },
  });

  const contactsResult = useSlabQuery('GET contacts', {});
  const { isLoading, isError, data: contactList } = contactsResult;

  const tableModel = useLocalTableData(buildTableDataModelConfig(contactsResult));

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || contactList === undefined) {
    return <div>ERROR</div>;
  }

  const currentDate = DateTime.now();

  return (
    <Page title='Contacts'>
      {contactToastHook.toast}
      <ContactDrawer
        {...contactDrawerProps}
        onSave={(contact): void => {
          if (contactId === null) {
            navigate(`/contacts/${contact.id}`);
          } else {
            contactDrawerProps.setIsOpen(false);
            contactToastHook.showToast('success');
          }
        }}
      />
      <Activities
        currentDate={currentDate}
        isDrawerOpen={isActivityDrawerOpen}
        setIsDrawerOpen={setIsActivityDrawerOpen}
        activityId={activityId}
        setActivityId={setActivityId}
        drawerOnly
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Contacts</Typography>
        <Box display='flex' gap='1rem'>
          <ButtonPill
            text='add activity'
            variant='secondary'
            onClick={(): void => {
              setActivityId(null);
              setIsActivityDrawerOpen(true);
            }}
            icon='add'
          />
          <ButtonPill
            text='create contact'
            variant='primary'
            onClick={(): void => {
              setContactId(null);
              contactDrawerProps.setIsOpen(true);
            }}
            icon='add'
          />
        </Box>
      </Box>

      <Box height='400px'>
        <DataTable
          tableModel={tableModel}
          onEditPress={(row): void => {
            setContactId(row.id);
            contactDrawerProps.setIsOpen(true);
          }}
          includePrint={{ title: 'Contacts' }}
        />
      </Box>
    </Page>
  );
};
