import { Company } from '../../generated-types/Company/Company';
import { Contact } from '../../generated-types/Contact/Contact';
import { Project } from '../../generated-types/Project/Project';
import { YupSchemaReferenceType } from '../../utils/YupHelpers';

/**
 * hasCompany returns true if the company is one of the project's companies.
 */
export const hasCompany = (project: Project | undefined, company: Company): boolean => {
  const projCompanyIDs = (project?.companies ?? []).map((pc) => pc.company.id);
  return projCompanyIDs.includes(company.id);
};

const selectedObjectsFromRefs = <T extends { id: string }>(
  objs: T[],
  refs: YupSchemaReferenceType[],
): T[] => {
  const ids = refs.map(({ id }) => id);
  return objs.filter((c) => ids.includes(c.id));
};

/**
 * companiesForProject filters selectedCompanyRefs down to only those
 * companies that belong to selectedProject.
 */
export const companiesForProject = (
  selectedProject: Project | undefined,
  selectedCompanyRefs: YupSchemaReferenceType[],
  allCompanies: Company[],
): YupSchemaReferenceType[] => {
  const projectCompanyIDs = (selectedProject?.companies ?? []).map((c) => c.company.id);

  const companies = selectedObjectsFromRefs(allCompanies, selectedCompanyRefs);
  const projectCompanies = companies.filter((c) => projectCompanyIDs.includes(c.id));
  return projectCompanies.map(({ id, name }) => ({
    id,
    option: {
      value: id,
      label: name,
    },
  }));
};

/**
 * contactsForCompanies filters selectedContactRefs down to only those that
 * belong to at least one of the companies in selectedCompanyIDs.
 */
export const contactsForCompanies = (
  selectedCompanyIDs: string[],
  selectedContactRefs: YupSchemaReferenceType[],
  allContacts: Contact[],
): YupSchemaReferenceType[] => {
  const contacts = selectedObjectsFromRefs(allContacts, selectedContactRefs);
  const companyContacts = contacts.filter((c) => selectedCompanyIDs.includes(c.company.id));
  return companyContacts.map((contact) => ({
    id: contact.id,
    option: {
      value: contact.id,
      label: contact.fullName(),
    },
  }));
};
