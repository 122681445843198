import * as Yup from 'yup';

import Enums from '../../generated-types/Enums';
import { Material, NewMaterial } from '../../generated-types/Material/Material';
import { DomainObject } from '../../utils/ApiClient';
import { NullableCostString } from '../../utils/Currency';
import { FormikCost, FormikCostRule } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import {
  nonNegativeCost,
  TestForUndefined,
  YupCost,
  YupEnum,
  YupNullableCost,
  YupNullableCostRule,
  YupNullableEnum,
  YupNullableReference,
  YupNullableString,
  YupReference,
  YupSchemaCostType,
  YupSchemaNullableCostType,
  YupSchemaNullableReferenceType,
  YupSchemaReferenceType,
  YupString,
} from '../../utils/YupHelpers';

export type MaterialFormikType = DomainObject<
  Omit<
    Material,
    | 'plant'
    | 'supplierCompany'
    | 'inProductCost'
    | 'inProductCostOverride'
    | 'rawMaterialCost'
    | 'haulingCost'
    | 'plantLoadingCost'
  >
> & {
  plant: YupSchemaReferenceType;
  supplierCompany: YupSchemaNullableReferenceType;
  inProductCostOverride: YupSchemaNullableCostType;
  rawMaterialCost: YupSchemaCostType;
  haulingCost: YupSchemaNullableCostType;
  plantLoadingCost: YupSchemaNullableCostType;

  // costUnit helps to test that unit is selected when at least one cost
  // has a value set for the amount.number
  costUnit: Enums.Unit | null;

  displayOnly: {
    actualCost: string | null;
  };
};

const sharedMaterialSchemaFields = {
  name: YupString('Name'),
  materialType: YupEnum(Enums.MaterialType, 'Material type'),
  measurementUnit: YupNullableEnum(Enums.Unit, 'Unit'),
  plant: YupReference('Plant'),
  supplierCompany: YupNullableReference('Supplier'),
  alternateID: YupNullableString('Alternate ID'),
  inProductCostOverride: YupNullableCost('In-product cost').test(nonNegativeCost),
  rawMaterialCost: YupCost('Raw material cost'),
  haulingCost: YupNullableCost('Hauling cost'),
  plantLoadingCost: YupNullableCost('Plant loading cost'),
  costRule: YupNullableCostRule('Cost adjustment'),
  displayOnly: Yup.object().shape({
    actualCost: YupNullableString('actual cost'),
  }),
};

export const MaterialSchemaFormik: Yup.SchemaOf<Omit<MaterialFormikType, 'id' | 'externalID'>> =
  Yup.object()
    .shape({
      ...sharedMaterialSchemaFields,

      costUnit: YupEnum(Enums.Unit, 'Cost unit'),
    })
    .test(TestForUndefined('MaterialSchemaFormik'));

export const MaterialSchemaWire: Yup.SchemaOf<
  Omit<MaterialFormikType, 'id' | 'externalID' | 'costUnit'>
> = Yup.object().shape({
  ...sharedMaterialSchemaFields,
});

/**
 * getMaterialCostUnit will return the unit if a material has a raw material cost.
 * If it returns null, the unit was cleared, or a new material is being created.
 */
const getMaterialCostUnit = (materialData: Partial<Material> | undefined): Enums.Unit | null =>
  materialData?.rawMaterialCost?.unit ?? null;

export const FormikMaterial = (
  materialData: Partial<Material> | undefined,
  usesDispatchCustomer: boolean,
): MaterialFormikType => {
  const emptyCostWithSharedUnit: YupSchemaNullableCostType = {
    amount: {
      currency: 'USD',
      number: '',
    },
    unit: getMaterialCostUnit(materialData),
  };
  const material = NewMaterial(materialData ?? {});

  return {
    id: materialData?.id ?? NIL_UUID,
    alternateID: materialData?.alternateID ?? null,
    externalID: materialData?.externalID ?? null,
    name: materialData?.name ?? '',
    materialType: materialData?.materialType ?? '',
    measurementUnit: materialData?.measurementUnit ?? null,
    supplierCompany: {
      id: materialData?.supplierCompany?.id ?? null,
      option:
        materialData?.supplierCompany === undefined || materialData?.supplierCompany === null
          ? null
          : {
              value: materialData.supplierCompany.id,
              label: materialData.supplierCompany.name,
              sublabels: materialData.supplierCompany.lookupSublabels(usesDispatchCustomer),
            },
    },
    plant: {
      id: materialData?.plant?.id ?? null,
      option:
        materialData?.plant === undefined
          ? null
          : {
              value: materialData.plant.id,
              label: materialData.plant.name,
            },
    },

    // When creating a new material, typecast the unit to be `null`, so that it further
    // implies a Unit of Purchase (costUnit) must be selected first (better UX than
    // showing `ac` by default).
    rawMaterialCost:
      materialData?.rawMaterialCost === undefined
        ? {
            amount: { number: '0.0', currency: 'USD' },
            unit: null as unknown as Enums.Unit,
          }
        : FormikCost(materialData.rawMaterialCost),

    // For other costs, if there are existing costs use them, otherwise use the emptyCostWithSharedUnit.
    // That helper matches up cost units if there are any existing costs with a unit.
    inProductCostOverride:
      materialData?.inProductCostOverride !== undefined &&
      materialData.inProductCostOverride !== null
        ? FormikCost(materialData.inProductCostOverride)
        : emptyCostWithSharedUnit,
    haulingCost:
      materialData?.haulingCost !== undefined && materialData.haulingCost !== null
        ? FormikCost(materialData.haulingCost)
        : emptyCostWithSharedUnit,
    plantLoadingCost:
      materialData?.plantLoadingCost !== undefined && materialData.plantLoadingCost !== null
        ? FormikCost(materialData.plantLoadingCost)
        : emptyCostWithSharedUnit,

    costUnit: getMaterialCostUnit(materialData) ?? null,

    costRule: FormikCostRule(materialData?.costRule),

    displayOnly: {
      actualCost: NullableCostString({ cost: material.calculateInProductCost() }),
    },
  };
};

export const EmptyFormikMaterial = FormikMaterial(undefined, false);
