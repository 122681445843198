// Do not commit/store any secret keys in plaintext here.
// Default all env variables to dev to force them to not be `undefined`

import axios from 'axios';

import Enums from '../generated-types/Enums';

// Extend auth0 User type to include custom claims
declare module '@auth0/auth0-react' {
  interface Tenant {
    tenantID: string;
    tenantName: string;
    userID: string;
    userEmail: string;
    roleNames: Enums.RoleName[];
  }

  interface User {
    admin: boolean;
    tenants: Tenant[];
  }

  // We pick each item because the User class has a `[key: string]: any` index signature,
  // which prevents us from enforcing compiler type safety.
  interface Auth0User
    extends Pick<
      User,
      | 'admin'
      | 'tenants'
      | 'name'
      | 'given_name'
      | 'family_name'
      | 'middle_name'
      | 'nickname'
      | 'preferred_username'
      | 'profile'
      | 'picture'
      | 'website'
      | 'email'
      | 'email_verified'
      | 'gender'
      | 'birthdate'
      | 'zoneinfo'
      | 'locale'
      | 'phone_number'
      | 'phone_number_verified'
      | 'address'
      | 'updated_at'
      | 'sub'
    > {
    activeTenant: Tenant;
  }
}

type ApiConfigVars = {
  offline: boolean;
  offlineTenantID: string;
  offlineUserID: string;
  maintenanceNoticeBody: string;
  maintenanceNoticeTitle: string;
  maintenanceNoticeSeverity: string;
  noDBModeEnabled: boolean;
  readOnlyModeEnabled: boolean;
};

type SlabConfigType = ApiConfigVars & {
  build: string;
  auth0: {
    audience: string;
    domain: string;
    clientID: string;
  };
  googleMaps: {
    id: string;
  };
  logRocket: {
    appID: string;
  };
  intercom: {
    apiBase?: string;
    appID?: string;
  };
};

// NOTE: All variables from the Vite environment are strings. To check boolean
// feature flags, make sure to check if they are {var} === 'true'.
const SlabConfigVars = await (async (): Promise<SlabConfigType> =>
  axios.get<ApiConfigVars>('/config').then((d) => ({
    ...d.data,
    build: import.meta.env.VITE_BUILD,
    auth0: {
      audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      domain: import.meta.env.VITE_AUTH0_DOMAIN,
      clientID: import.meta.env.VITE_AUTH0_CLIENTID,
    },
    googleMaps: {
      id: import.meta.env.VITE_MAP_ID,
    },
    logRocket: {
      appID: import.meta.env.VITE_LOGROCKET_APP_ID,
    },
    intercom: {
      apiBase: import.meta.env.VITE_INTERCOM_API_BASE,
      appID: import.meta.env.VITE_INTERCOM_APP_ID,
    },
  })))();

/**
 * @description SlabConfig is the exported object that contains the static config properties for the application.
 */
export const SlabConfig = SlabConfigVars;
