import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';

import { randomUUID } from '../../utils/UUID';

type SingleTab = {
  label: string;
  element: React.ReactNode;
};

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel = ({ children, value, index }: TabPanelProps): JSX.Element => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && children}
  </div>
);

type TabBarProps = {
  tabs: SingleTab[];

  /** @default false */
  hideBarIfSingleTab?: boolean;
};

export const TabBar = ({ tabs, hideBarIfSingleTab = false }: TabBarProps): JSX.Element => {
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {!(tabs.length === 1 && hideBarIfSingleTab) && (
        <Box sx={{ borderBottom: 1, borderColor: theme.palette.SlabBlue.Stroke }}>
          <Tabs value={value} onChange={handleChange} aria-label='tab bar' variant='scrollable'>
            {tabs.map((t, idx) => (
              <Tab
                key={randomUUID()}
                label={t.label}
                id={`simple-tab-${idx}`}
                sx={{ fontSize: '1.125rem' }}
              />
            ))}
          </Tabs>
        </Box>
      )}
      {tabs.map((t, idx) => (
        <TabPanel key={randomUUID()} value={value} index={idx}>
          {t.element}
        </TabPanel>
      ))}
    </Box>
  );
};
