// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';

export class TaxCode {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly description: string | null;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.code = data.code === undefined ? '' : data.code;
    this.name = data.name === undefined ? '' : data.name;
    this.description = data.description === undefined ? null : data.description;
  }

  static zero(): TaxCode {
    const zeroValues: ExcludeMethodsDeep<TaxCode> = {
      id: NIL_UUID,
      code: '',
      name: '',
      description: null,
    };
    return new TaxCode(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewTaxCode = (props: PartialDeep<TaxCode, { recurseIntoArrays: true }>): TaxCode =>
  new TaxCode(props);

export const NewTaxCodeFromDomainObject = (
  props: PartialDeep<DomainObject<TaxCode>, { recurseIntoArrays: true }>,
): TaxCode => new TaxCode(props);
