import LogRocket from 'logrocket';

export const Intercom = (...args: Parameters<Window['Intercom']>): void => {
  try {
    window.Intercom(...args);
  } catch (e) {
    LogRocket.captureException(e as Error, {
      tags: { thirdParty: 'Intercom' },
      extra: { args: JSON.stringify(args) },
    });
  }
};
