import { useEffect } from 'react';

/**
 * Only initiate a useEffect hook after a certain delay of time has
 * passed since the dependencies have not had any changes occur.
 * */
export const useDebouncedEffect = (
  effect: () => void,
  deps: unknown[] | undefined,
  delay: number | undefined,
): void => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return (): void => clearTimeout(handler);
  }, [...(deps ?? []), delay]);
};
