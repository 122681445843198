import { Box, styled, Switch, SwitchProps, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import { SetFormikValue } from '../../utils/FormikHelpers';
import { Input, InputProps } from '../Input/Input';

export const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: theme.palette.SlabBlue[0],
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.SlabChart.Indigo[200],
      },
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.SlabChart.Indigo[200],
  },
}));

type InputSwitchProps = {
  switchFormikName: string;

  leftLabel: string;
  rightLabel: string;

  leftValue: string;
  rightValue: string;

  leftInputProps: Omit<InputProps, 'disabled'>;
  rightInputProps: Omit<InputProps, 'disabled'>;
};

export const InputSwitch = ({
  switchFormikName,

  leftLabel,
  rightLabel,

  leftValue,
  rightValue,

  leftInputProps,
  rightInputProps,
}: InputSwitchProps): JSX.Element => {
  const formikBag = useFormikContext<any>();

  const switchFormikValue = formikBag.getFieldMeta(switchFormikName);
  const isSwitchChecked = switchFormikValue.value === rightValue;

  return (
    <Box display='flex' flexDirection='column' gap='0.5rem'>
      <Box display='flex' alignItems='center'>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={`${switchFormikName}.switch`}>
          <Typography fontWeight={isSwitchChecked ? 'normal' : 'bold'}>{leftLabel}</Typography>
        </label>
        <StyledSwitch
          id={`${switchFormikName}.switch`}
          checked={isSwitchChecked}
          onClick={(): void => {
            if (isSwitchChecked) {
              SetFormikValue(formikBag, switchFormikName, leftValue);
            } else {
              SetFormikValue(formikBag, switchFormikName, rightValue);
            }
          }}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={`${switchFormikName}.switch`}>
          <Typography fontWeight={isSwitchChecked ? 'bold' : 'normal'}>{rightLabel}</Typography>
        </label>
      </Box>
      <Box display='flex' flexDirection='row' gap='1rem'>
        <Input {...leftInputProps} disabled={isSwitchChecked} />
        <Input {...rightInputProps} disabled={!isSwitchChecked} />
      </Box>
    </Box>
  );
};
