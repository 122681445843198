import { useEffect, useRef, useState } from 'react';

export const MapDirections = ({
  projectLocation,
  plantLocation,
  map,
}: {
  projectLocation: google.maps.LatLngLiteral | google.maps.LatLng | undefined;
  plantLocation: google.maps.LatLngLiteral | google.maps.LatLng | undefined;
  map?: google.maps.Map | null;
}): JSX.Element => {
  const directionsRef = useRef<HTMLDivElement | null>(null);
  const [directionsRenderer, setDirectionsRenderer] = useState<google.maps.DirectionsRenderer>();
  const DirectionsCallback = (result: google.maps.DirectionsResult | null): void => {
    if (directionsRenderer !== undefined && result !== null) {
      directionsRenderer.setDirections(result);
      const bounds = result.routes.map((i) => i.bounds).reduce((prev, curr) => prev.union(curr));
      if (bounds !== undefined) {
        map?.panToBounds(bounds);
      }
    }
  };

  useEffect(() => {
    if (directionsRenderer === undefined) {
      const newDirectionsRenderer = new google.maps.DirectionsRenderer({
        hideRouteList: false,
        draggable: true,
        preserveViewport: false,
        suppressInfoWindows: false,
        suppressMarkers: false,
      });
      newDirectionsRenderer.setPanel(directionsRef.current);
      setDirectionsRenderer(newDirectionsRenderer);
    }
  }, [directionsRef]);

  if (map !== undefined && map !== null && directionsRenderer !== undefined) {
    directionsRenderer.setMap(map);
  }

  useEffect(() => {
    if (projectLocation !== undefined && plantLocation !== undefined) {
      const directions = new google.maps.DirectionsService();
      directions.route(
        {
          destination: projectLocation,
          origin: plantLocation,
          travelMode: google.maps.TravelMode.DRIVING,
          optimizeWaypoints: true,
          provideRouteAlternatives: false,
          unitSystem: google.maps.UnitSystem.IMPERIAL,
          drivingOptions: {
            departureTime: new Date(Date.now()),
            trafficModel: google.maps.TrafficModel.BEST_GUESS,
          },
          avoidFerries: true,
        },
        DirectionsCallback,
      );
    }
  }, [projectLocation, plantLocation, directionsRenderer, map]);

  return <div ref={directionsRef} id='directions' />;
};
