import { DateTime } from 'luxon';
import * as Yup from 'yup';

import { Activity } from '../../generated-types/Activity/Activity';
import { ActivityCategory, ActivityStatus } from '../../generated-types/generated-enums';
import { DomainObject } from '../../utils/ApiClient';
import { ValidDateOrDefault } from '../../utils/DateHelpers';
import { NIL_UUID } from '../../utils/UUID';
import {
  TestForUndefined,
  YupEnum,
  YupNullableReference,
  YupNullableString,
  YupReference,
  YupSchemaNullableReferenceType,
  YupSchemaReferenceType,
  YupString,
  YupUTCDate,
} from '../../utils/YupHelpers';

export type ActivityFormikType = DomainObject<
  Omit<Activity, 'project' | 'companies' | 'contacts' | 'assignees' | 'creator'>
> & {
  project: YupSchemaNullableReferenceType;
  companies: YupSchemaReferenceType[];
  contacts: YupSchemaReferenceType[];
  assignees: YupSchemaReferenceType[];
  // creator is omitted; it's not currently displayed or editable.
};

export const ActivitySchema: Yup.SchemaOf<Omit<ActivityFormikType, 'id'>> = Yup.object()
  .shape({
    name: YupString('Name'),
    description: YupNullableString('Description'),
    category: YupEnum(ActivityCategory, 'Category'),
    status: YupEnum(ActivityStatus, 'Status'),
    startTime: YupUTCDate('Start time'),
    endTime: YupUTCDate('End time'),
    project: YupNullableReference('Project'),
    companies: Yup.array().of(YupReference('Company')),
    contacts: Yup.array().of(YupReference('Contact')),
    assignees: Yup.array().of(YupReference('Assignee')),
  })
  .test(TestForUndefined('ActivitySchema'));

export const FormikActivity = (
  defaults: { currentDate: DateTime },
  activityData: Partial<Activity> | undefined,
): ActivityFormikType => {
  const nextHour = defaults.currentDate.startOf('hour').plus({ hours: 1 });
  return {
    id: activityData?.id ?? NIL_UUID,
    name: activityData?.name ?? '',
    description: activityData?.description ?? null,
    startTime: ValidDateOrDefault(activityData?.startTime, nextHour).toISO() ?? '',
    endTime:
      ValidDateOrDefault(activityData?.endTime, nextHour.plus({ minutes: 30 })).toISO() ?? '',
    category: activityData?.category ?? ActivityCategory.Task,
    status: activityData?.status ?? ActivityStatus.Planned,
    project: {
      id: activityData?.project?.id ?? null,
      option:
        activityData?.project === undefined || activityData?.project === null
          ? null
          : {
              value: activityData.project.id,
              label: activityData.project.name,
            },
    },
    companies: (activityData?.companies ?? []).map(({ id, name }) => ({
      id,
      option: {
        value: id,
        label: name,
      },
    })),
    contacts: (activityData?.contacts ?? []).map((c) => ({
      id: c.id,
      option: {
        value: c.id,
        label: c.fullName(),
      },
    })),
    assignees: (activityData?.assignees ?? []).map((u) => ({
      id: u.id,
      option: {
        value: u.id,
        label: u.fullName(),
      },
    })),
  };
};
