import { View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { Quote } from '../../../../generated-types/Quote/Quote';
import { randomUUID } from '../../../../utils/UUID';
import { PdfHeader } from '../../../components/PdfHeader';
import { PdfColumnConfig, PdfColumnFromConfig, PdfTable } from '../../../components/PdfTable';

const infoStyle: Style = {
  textAlign: 'left',
  paddingRight: '8pt',
};

type InfoRow = {
  id: string;
  value: string | null;
  viewStyle: Style;
};

type HeaderRow = {
  id: string;
  leftValue: JSX.Element;
  rightValue: JSX.Element;
};

const renderHeaderValueToPdf = (value: JSX.Element): JSX.Element => value;

type PdfHeaderTableProps = {
  quote: Quote;
};

export const PdfHeaderTable = ({ quote }: PdfHeaderTableProps): JSX.Element => {
  const logoImg = (
    <View style={{ alignItems: 'center' }}>
      <PdfHeader
        image={{
          src: '../carrara-logo.jpg',
          imgStyle: {
            width: '2.85in',
            height: '1in',
          },
        }}
      />
    </View>
  );

  const projectLocation = [
    `${quote.project.address.basicDisplay()}`,
    quote.project.address.city,
    quote.project.address.state,
  ]
    .filter((s) => s !== '' && s !== null)
    .join(', ');

  const infoRows: InfoRow[] = [
    {
      id: randomUUID(),
      value: quote.company !== null ? `CUSTOMER: ${quote.company.name}` : '',
      viewStyle: infoStyle,
    },
    {
      id: randomUUID(),
      value: `PROJECT: ${quote.project.name}`,
      viewStyle: infoStyle,
    },
    {
      id: randomUUID(),
      value: `PROJECT LOCATION: ${projectLocation}`,
      viewStyle: infoStyle,
    },
    {
      id: randomUUID(),
      value: quote.creationDate === null ? null : `DATE: ${quote.creationDateDisplay()}`,
      viewStyle: infoStyle,
    },
    {
      id: randomUUID(),
      value: quote.revisionDate === null ? null : `REVISED: ${quote.revisionDateDisplay()}`,
      viewStyle: infoStyle,
    },
  ];

  const infoCols: PdfColumnConfig<InfoRow>[] = [
    {
      id: 'value',
      label: '',
      type: 'string',
      isDisplayed: false,
    },
  ];

  const infoTable = (
    <PdfTable columns={infoCols.map((col) => PdfColumnFromConfig(col))} rows={infoRows} />
  );

  const tableRows: HeaderRow[] = [
    {
      id: randomUUID(),
      leftValue: logoImg,
      rightValue: infoTable,
    },
  ];

  const tableCols: PdfColumnConfig<HeaderRow>[] = [
    {
      id: 'leftValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: renderHeaderValueToPdf as (
        value: JSX.Element | string | null,
      ) => JSX.Element,
    },
    {
      id: 'rightValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: renderHeaderValueToPdf as (
        value: JSX.Element | string | null,
      ) => JSX.Element,
    },
  ];

  return (
    <PdfTable
      columns={tableCols.map((col) => PdfColumnFromConfig(col))}
      rows={tableRows}
      cellPadding='tight'
    />
  );
};
