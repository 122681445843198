// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { NewPlant, Plant } from '../Plant/Plant';
import {
  NewQuoteConfigProduct,
  QuoteConfigProduct,
} from '../QuoteConfigProduct/QuoteConfigProduct';

export class QuoteConfig {
  readonly id: string;
  readonly name: string;
  readonly terms: string;
  readonly plant: Plant;
  readonly products: QuoteConfigProduct[];

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.terms = data.terms === undefined ? '' : data.terms;
    this.plant = NewPlant(data.plant);
    this.products = (data.products ?? []).map((products: any) => NewQuoteConfigProduct(products));
  }

  static zero(): QuoteConfig {
    const zeroValues: ExcludeMethodsDeep<QuoteConfig> = {
      id: NIL_UUID,
      name: '',
      terms: '',
      plant: Plant.zero(),
      products: [],
    };
    return new QuoteConfig(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewQuoteConfig = (
  props: PartialDeep<QuoteConfig, { recurseIntoArrays: true }>,
): QuoteConfig => new QuoteConfig(props);

export const NewQuoteConfigFromDomainObject = (
  props: PartialDeep<DomainObject<QuoteConfig>, { recurseIntoArrays: true }>,
): QuoteConfig => new QuoteConfig(props);
