// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import Enums from '../Enums';

export class MaterialTypeAgg {
  readonly materialType: Enums.MaterialType;
  readonly count: number;

  constructor(data: { [key: string]: any } = {}) {
    this.materialType =
      data.materialType === undefined ? Enums.MaterialType.Other : data.materialType;
    this.count = data.count === undefined ? 0 : data.count;
  }

  static zero(): MaterialTypeAgg {
    const zeroValues: ExcludeMethodsDeep<MaterialTypeAgg> = {
      materialType: Enums.MaterialType.Other,
      count: 0,
    };
    return new MaterialTypeAgg(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewMaterialTypeAgg = (
  props: PartialDeep<MaterialTypeAgg, { recurseIntoArrays: true }>,
): MaterialTypeAgg => new MaterialTypeAgg(props);

export const NewMaterialTypeAggFromDomainObject = (
  props: PartialDeep<DomainObject<MaterialTypeAgg>, { recurseIntoArrays: true }>,
): MaterialTypeAgg => new MaterialTypeAgg(props);
