import { Box, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { SlabDrawer } from '../../components/SlabDrawer/SlabDrawer';
import Enums from '../../generated-types/Enums';
import { NewProjectConfig, ProjectConfig } from '../../generated-types/ProjectConfig/ProjectConfig';
import { SharedDrawerOverrideProps } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { DefaultEnsureDefined } from '../../utils/DomainHelpers';
import { FormErrorNotification } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import {
  ProjectConfigDrawerSections,
  ProjectConfigSection,
} from './components/ProjectConfigDrawerSections';
import {
  FormikProjectConfig,
  ProjectConfigFormikType,
  ProjectConfigSchema,
  ProjectConfigSchemaWire,
} from './ProjectConfigFormik';

const formikProjectConfigToWire = (values: ProjectConfigFormikType): ProjectConfig =>
  new ProjectConfig(
    _.merge(ProjectConfig.zero(), {
      ...values,
      companies: values.contractors.concat(values.otherCompanies),
    }),
  );

type ProjectConfigDrawerProps = Omit<
  SharedDrawerOverrideProps<ProjectConfig>,
  'resourceId' | 'onSave'
> & {
  projectConfigId: string | null;
  onSuccess: (projectConfig: ProjectConfig) => void;

  /** @default 'Details*' */
  initialSection?: ProjectConfigSection;
};

const ProjectConfigDrawerPage = ({
  projectConfigId,
  setIsOpen,
  initialSection = 'Details*',
  onSuccess,
  onError,
  onClose,
  ensureDefined = DefaultEnsureDefined,
}: ProjectConfigDrawerProps): JSX.Element | null => {
  const ctx = React.useContext(SlabContext);
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);

  const isProjectConfigIdNull = projectConfigId === null;

  const createNew = useSlabMutation('POST project config', {
    onSuccess,
    onError,
  });
  const updateExisting = useSlabMutation('PUT project config by ID', {
    onSuccess,
    onError,
  });

  const {
    isLoading: isLoadingProjectConfig,
    isError: isErrorProjectConfig,
    data: maybeProjectConfig,
  } = useSlabQuery(
    'GET project config by ID',
    {
      pathParams: {
        id: projectConfigId ?? '',
      },
    },
    { enabled: !isProjectConfigIdNull },
  );

  const {
    isLoading: isLoadingProjectConfigProducts,
    isError: isErrorProjectConfigProducts,
    data: maybeProjectConfigProducts,
  } = useSlabQuery(
    'GET project config products by project config ID',
    {
      pathParams: {
        id: projectConfigId ?? '',
      },
    },
    { enabled: !isProjectConfigIdNull },
  );

  const isLoading = isLoadingProjectConfig || isLoadingProjectConfigProducts;
  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError = isErrorProjectConfig || isErrorProjectConfigProducts;
  if (isError) {
    return <div>ERROR</div>;
  }

  const projectConfig = ensureDefined(
    maybeProjectConfig === undefined || maybeProjectConfigProducts === undefined
      ? undefined
      : NewProjectConfig({
          ...maybeProjectConfig,
          products: maybeProjectConfigProducts,
        }),
  );

  const formikProjectConfig = FormikProjectConfig(projectConfig, usesDispatchCustomer);

  const isNew = formikProjectConfig.id === NIL_UUID;

  return (
    <Formik
      validationSchema={ProjectConfigSchema}
      initialValues={formikProjectConfig}
      enableReinitialize
      onSubmit={async (values): Promise<void> => {
        // TODO #2219: Need a NewProjectConfigFromForm wrapper for this case
        const wireProjectConfig = formikProjectConfigToWire(values);
        if (formikProjectConfig.id === NIL_UUID) {
          createNew.mutate({
            args: {
              body: wireProjectConfig,
            },
            schema: ProjectConfigSchemaWire,
          });
        } else {
          updateExisting.mutate({
            args: {
              pathParams: {
                id: formikProjectConfig.id,
              },
              body: wireProjectConfig,
            },
            schema: ProjectConfigSchemaWire,
          });
        }
      }}
    >
      {(formikBag): JSX.Element => (
        <Box padding='5rem 3.5rem 5rem 3.5rem'>
          <FormErrorNotification />
          <Typography variant='h1' fontSize='2rem'>
            {isNew ? 'Create ' : 'Edit '}a project config
          </Typography>
          <ProjectConfigDrawerSections initialSection={initialSection} />
          <Grid pt='1rem' container spacing={2} sx={{ '& button': { width: '100%' } }}>
            <Grid item xs={4}>
              <ButtonPill
                text='cancel'
                variant='secondary'
                onClick={(): void => {
                  formikBag.resetForm({ values: formikProjectConfig });
                  setIsOpen(false);
                  onClose?.();
                }}
                disabled={createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='reset'
                variant='secondary'
                onClick={(): void => formikBag.resetForm({ values: formikProjectConfig })}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='save'
                variant='primary'
                onClick={formikBag.submitForm}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
};

export const ProjectConfigDrawer = (props: ProjectConfigDrawerProps): JSX.Element => (
  <SlabDrawer isOpen={props.isOpen}>
    <ProjectConfigDrawerPage {...props} />
  </SlabDrawer>
);
