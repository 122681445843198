import { Box, Divider, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import _ from 'lodash';
import * as React from 'react';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { Input } from '../../components/Input/Input';
import { InputDropdown } from '../../components/InputDropdown/InputDropdown';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { ApiLookupInput } from '../../components/LookupInput/ApiLookupInput';
import {
  DEFAULT_LOOKUP_LENGTH,
  LookupInputOption,
} from '../../components/LookupInput/LookupInputSharedComponents';
import { AddressMap } from '../../components/Map/AddressMap';
import { SlabDrawer } from '../../components/SlabDrawer/SlabDrawer';
import { Company } from '../../generated-types/Company/Company';
import { DispatchCustomerSummary } from '../../generated-types/DispatchCustomerSummary/DispatchCustomerSummary';
import Enums from '../../generated-types/Enums';
import { SharedDrawerOverrideProps } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { DefaultEnsureDefined, enumLookups, lookups } from '../../utils/DomainHelpers';
import { FormErrorNotification, SetFormikValue } from '../../utils/FormikHelpers';
import { List } from '../../utils/List';
import { NIL_UUID } from '../../utils/UUID';
import { CompanyFormikType, CompanySchema, FormikCompany } from './CompanyFormik';

type OnDispatchCustomerChangeOpts = {
  dispatchCustomerId: string | null;
  dispatchCustomerSummaryList: List<DispatchCustomerSummary> | undefined;
};

/**
 * Given the formikBag and the values from the dispatch customer onMatchChange
 * handler, calculate the correct displayOnly value and return it.
 */
export const OnDispatchCustomerChange = ({
  dispatchCustomerId,
  dispatchCustomerSummaryList,
}: OnDispatchCustomerChangeOpts): CompanyFormikType['displayOnly'] => {
  const dispatchCustomer = dispatchCustomerSummaryList?.items.find(
    (dc) => dc.id === dispatchCustomerId,
  );
  const displayOnly =
    dispatchCustomer === undefined
      ? { creditStatusCode: null, creditStatusDescription: null }
      : {
          creditStatusCode: dispatchCustomer.creditStatusCode,
          creditStatusDescription: dispatchCustomer.creditStatusDescription,
        };
  return displayOnly;
};

type CompanyDrawerProps = SharedDrawerOverrideProps<Company>;

const companyCategoryOpts = enumLookups(Enums.CompanyCategory);

const CompanyDrawerPage = ({
  resourceId,
  setIsOpen,
  onSave,
  onError,
  onClose,
  ensureDefined = DefaultEnsureDefined,
}: CompanyDrawerProps): JSX.Element | null => {
  const ctx = React.useContext(SlabContext);
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);

  const isNew = resourceId === null;

  const createNew = useSlabMutation('POST company', {
    onSuccess: (data) => onSave(data),
    onError,
  });
  const updateExisting = useSlabMutation('PUT company by ID', {
    onSuccess: (data) => onSave(data),
    onError,
  });

  const {
    isLoading,
    isError,
    data: maybeCompany,
  } = useSlabQuery(
    'GET company by ID',
    {
      pathParams: {
        id: resourceId ?? '',
      },
    },
    { enabled: !isNew },
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <div>ERROR</div>;
  }

  const company = ensureDefined(maybeCompany);
  const formikCompany = FormikCompany(company);

  return (
    <Formik
      validationSchema={CompanySchema}
      initialValues={formikCompany}
      onSubmit={async (values): Promise<void> => {
        const wireCompany = new Company(
          _.merge(Company.zero(), {
            ...values,
            dispatchCustomer: {
              ...values.dispatchCustomer,
              // The option does not contain an external ID, and the server cannot accept
              // the zero value of a URN yet, so set it to null.
              externalId: null,
            },
          }),
        );
        if (formikCompany.id === NIL_UUID) {
          createNew.mutate({
            args: {
              body: wireCompany,
            },
            schema: CompanySchema,
          });
        } else {
          updateExisting.mutate({
            args: {
              pathParams: {
                id: formikCompany.id,
              },
              body: wireCompany,
            },
            schema: CompanySchema,
          });
        }
      }}
    >
      {(formikBag): JSX.Element => (
        <Box padding='5rem 3.5rem 5rem 3.5rem'>
          <FormErrorNotification />
          <Typography variant='h1' fontSize='2rem'>
            {isNew ? 'Create ' : 'Edit '}a company
          </Typography>
          <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
            <Typography variant='h2'>Company details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Input name='name' label='Company name*' />
              </Grid>
              <Grid item xs={6}>
                <Input name='alternateID' label='ID' />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputDropdown
                  label='Company category'
                  name='category'
                  options={companyCategoryOpts}
                />
              </Grid>
            </Grid>
            {!usesDispatchCustomer ? null : (
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} paddingBottom='1rem'>
                    <ApiLookupInput
                      formState={formikBag.values}
                      label='Dispatch customer'
                      name='dispatchCustomer'
                      route={{
                        barrel: 'GET dispatch customer summaries',
                        args: (inputText) => ({
                          queryParams: {
                            page: 0,
                            perPage: DEFAULT_LOOKUP_LENGTH,
                            filterBy: [
                              {
                                operation: Enums.FilterOperation.Lookup,
                                value: inputText,
                              },
                              {
                                operation: Enums.FilterOperation.EqualsOrNull,
                                name: 'companyId',
                                value: maybeCompany?.id ?? NIL_UUID,
                              },
                            ],
                          },
                        }),
                        transform: (dispatchCustomerList): LookupInputOption[] =>
                          lookups({
                            ...dispatchCustomerList,
                            label: (dc) => dc.name,
                            sublabels: (dc) => [dc.code],
                          }),
                      }}
                      onMatchChange={(dispatchCustomerId, dispatchCustomerSummaryList) => {
                        const displayOnly = OnDispatchCustomerChange({
                          dispatchCustomerId,
                          dispatchCustomerSummaryList,
                        });
                        SetFormikValue(formikBag, 'displayOnly', displayOnly);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Input
                      label='Credit status code'
                      name='displayOnly.creditStatusCode'
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label='Credit status description'
                      name='displayOnly.creditStatusDescription'
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <Divider />
          <AddressMap name='address' hideMap />
          <Grid container spacing={2} sx={{ '& button': { width: '100%' } }}>
            <Grid item xs={4}>
              <ButtonPill
                text='cancel'
                variant='secondary'
                onClick={(): void => {
                  formikBag.resetForm({ values: formikCompany });
                  setIsOpen(false);
                  onClose?.();
                }}
                disabled={createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='reset'
                variant='secondary'
                onClick={(): void => formikBag.resetForm({ values: formikCompany })}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='save'
                variant='primary'
                onClick={formikBag.submitForm}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
};

export const CompanyDrawer = (props: CompanyDrawerProps): JSX.Element => (
  <SlabDrawer isOpen={props.isOpen}>
    <CompanyDrawerPage {...props} />
  </SlabDrawer>
);
