// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class AutocompleteMatchedSubstring {
  readonly length: number;
  readonly offset: number;

  constructor(data: { [key: string]: any } = {}) {
    this.length = data.length === undefined ? 0 : data.length;
    this.offset = data.offset === undefined ? 0 : data.offset;
  }

  static zero(): AutocompleteMatchedSubstring {
    const zeroValues: ExcludeMethodsDeep<AutocompleteMatchedSubstring> = {
      length: 0,
      offset: 0,
    };
    return new AutocompleteMatchedSubstring(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewAutocompleteMatchedSubstring = (
  props: PartialDeep<AutocompleteMatchedSubstring, { recurseIntoArrays: true }>,
): AutocompleteMatchedSubstring => new AutocompleteMatchedSubstring(props);

export const NewAutocompleteMatchedSubstringFromDomainObject = (
  props: PartialDeep<DomainObject<AutocompleteMatchedSubstring>, { recurseIntoArrays: true }>,
): AutocompleteMatchedSubstring => new AutocompleteMatchedSubstring(props);
