import { Text, View } from '@react-pdf/renderer';

import { PdfSpacer } from '../../components/PdfSpacer';
import { PdfTextWithLine } from '../../components/PdfTextWithLine';

export const PdfSignatures = (): JSX.Element => (
  <View style={{ fontSize: '10pt' }}>
    <View style={{ backgroundColor: '#d8d8d8', textAlign: 'center' }}>
      <Text>Signature</Text>
    </View>
    <PdfSpacer height='0.25in' />
    <View style={{ width: '60%', alignSelf: 'center' }}>
      <PdfTextWithLine text='X' />
    </View>
    <PdfSpacer height='0.1in' />
    <View style={{ width: '60%', alignSelf: 'center' }}>
      <PdfTextWithLine text='Date' />
    </View>
    <PdfSpacer height='0.25in' />
    <View style={{ textAlign: 'center' }}>
      <Text>Thank you for your business!</Text>
    </View>
  </View>
);
