// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { Company, NewCompany } from '../Company/Company';
import { Contact, NewContact } from '../Contact/Contact';

export class ProjectCompany {
  readonly company: Company;
  readonly contact: Contact | null;

  constructor(data: { [key: string]: any } = {}) {
    this.company = NewCompany(data.company);
    this.contact =
      // eslint-disable-next-line no-nested-ternary
      (data.contact ?? null) === null ? null : NewContact(data.contact);
  }

  static zero(): ProjectCompany {
    const zeroValues: ExcludeMethodsDeep<ProjectCompany> = {
      company: Company.zero(),
      contact: null,
    };
    return new ProjectCompany(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewProjectCompany = (
  props: PartialDeep<ProjectCompany, { recurseIntoArrays: true }>,
): ProjectCompany => new ProjectCompany(props);

export const NewProjectCompanyFromDomainObject = (
  props: PartialDeep<DomainObject<ProjectCompany>, { recurseIntoArrays: true }>,
): ProjectCompany => new ProjectCompany(props);
