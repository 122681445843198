import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { Formik, FormikContextType, FormikProps } from 'formik';
import _ from 'lodash';
import * as React from 'react';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { CostRuleInput } from '../../components/CostRuleInput/CostRuleInput';
import { Input } from '../../components/Input/Input';
import { InputDropdown } from '../../components/InputDropdown/InputDropdown';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { ApiLookupInput } from '../../components/LookupInput/ApiLookupInput';
import { LocalLookupInput } from '../../components/LookupInput/LocalLookupInput';
import {
  ConstructListQueryParams,
  DEFAULT_LOOKUP_LENGTH,
  LookupInputOption,
} from '../../components/LookupInput/LookupInputSharedComponents';
import { SlabDrawer } from '../../components/SlabDrawer/SlabDrawer';
import { Cost, NewCost } from '../../generated-types/Cost/Cost';
import Enums from '../../generated-types/Enums';
import { Unit } from '../../generated-types/generated-enums';
import { Material, NewMaterialFromDomainObject } from '../../generated-types/Material/Material';
import { SharedDrawerOverrideProps } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { SlabContext } from '../../SlabContext';
import { NullableCostString } from '../../utils/Currency';
import { DefaultEnsureDefined, enumLookups, lookups } from '../../utils/DomainHelpers';
import {
  ClearFormikFields,
  FormErrorNotification,
  FormikCostRule,
  SetFormikValue,
} from '../../utils/FormikHelpers';
import { UnitFromString, UnitOptions } from '../../utils/UnitHelpers';
import { NIL_UUID } from '../../utils/UUID';
import { YupSchemaNullableCostType } from '../../utils/YupHelpers';
import {
  EmptyFormikMaterial,
  FormikMaterial,
  MaterialFormikType,
  MaterialSchemaFormik,
  MaterialSchemaWire,
} from './MaterialFormik';

const formikNullableCostTransform = (cost: YupSchemaNullableCostType): Cost =>
  NewCost({
    amount: {
      number: cost?.amount?.number?.replaceAll(',', ''),
      currency: cost?.amount?.currency,
    },
    unit: cost?.unit ?? undefined,
  });

const newMaterialFromFormik = (formikBag: FormikContextType<MaterialFormikType>): Material =>
  NewMaterialFromDomainObject({
    rawMaterialCost: formikNullableCostTransform(formikBag.values.rawMaterialCost),
    haulingCost: formikNullableCostTransform(formikBag.values.haulingCost),
    plantLoadingCost: formikNullableCostTransform(formikBag.values.plantLoadingCost),
    costRule: {
      kind: formikBag.values.costRule?.kind,
      flatChange: {
        number: formikBag.values.costRule?.flatChange?.number?.replaceAll(',', ''),
        currency: formikBag.values.costRule?.flatChange?.currency,
      },
      markupRatio:
        formikBag.values.costRule?.markupRatio === undefined ||
        formikBag.values.costRule?.markupRatio === null ||
        formikBag.values.costRule?.markupRatio === ''
          ? null
          : (parseFloat(formikBag.values.costRule?.markupRatio ?? '') / 100).toFixed(3),
    },
  });

type MaterialDrawerProps = Omit<SharedDrawerOverrideProps<Material>, 'resourceId' | 'onSave'> & {
  materialId: string | null;
  onSuccess: (material: Material) => void;
};

const materialTypeOpts = enumLookups(Enums.MaterialType);

const MaterialDrawerPage = ({
  materialId,
  setIsOpen,
  onSuccess,
  onError,
  onClose,
  ensureDefined = DefaultEnsureDefined,
}: MaterialDrawerProps): JSX.Element | null => {
  const ctx = React.useContext(SlabContext);
  const usesDispatchCustomer = ctx.userInfo.hasFlags([
    Enums.FeatureFlagName.FeatureFlagDispatchCustomer,
  ]);

  const isMaterialIdNull = materialId === null;

  const createNew = useSlabMutation('POST material', {
    onSuccess,
    onError,
  });
  const updateExisting = useSlabMutation('PUT material by ID', {
    onSuccess,
    onError,
  });

  const {
    isLoading: isLoadingMaterial,
    isError: isErrorMaterial,
    data: maybeMaterial,
  } = useSlabQuery(
    'GET material by ID',
    {
      pathParams: {
        id: materialId ?? '',
      },
    },
    { enabled: !isMaterialIdNull },
  );

  const isLoading = isLoadingMaterial;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError = isErrorMaterial;

  if (isError) {
    return <div>ERROR</div>;
  }

  const material = ensureDefined(maybeMaterial);
  const formikMaterial = FormikMaterial(material, usesDispatchCustomer);

  const isNew = formikMaterial.id === NIL_UUID;

  return (
    <Formik
      validationSchema={MaterialSchemaFormik}
      initialValues={formikMaterial}
      onSubmit={async (values): Promise<void> => {
        // TODO #2219 Need a NewMaterialFromForm wrapper for this case
        const wireMaterial = new Material(
          _.merge(Material.zero(), {
            ...values,
            costRule: {
              ...values.costRule,
              markupRatio:
                values.costRule !== null &&
                values.costRule.markupRatio !== null &&
                values.costRule.markupRatio !== ''
                  ? (parseFloat(values.costRule.markupRatio) / 100).toFixed(3)
                  : null,
            },
          }),
        );
        if (formikMaterial.id === NIL_UUID) {
          createNew.mutate({
            args: {
              body: wireMaterial,
            },
            schema: MaterialSchemaWire,
          });
        } else {
          updateExisting.mutate({
            args: {
              pathParams: {
                id: formikMaterial.id,
              },
              body: wireMaterial,
            },
            schema: MaterialSchemaWire,
          });
        }
      }}
    >
      {(formikBag): JSX.Element => {
        // If we do not debounce, when a user inputs values too quickly it can cause an endless render loop.
        const debouncedUpdate = React.useCallback(
          _.debounce((fbag: FormikProps<MaterialFormikType>) => {
            if ((fbag.values.costUnit ?? '') === '') {
              SetFormikValue(fbag, 'displayOnly.actualCost', 'Select a Unit of Purchase.');
            } else {
              const tempMaterial = newMaterialFromFormik(fbag);
              SetFormikValue(
                fbag,
                'displayOnly.actualCost',
                NullableCostString({ cost: tempMaterial.calculateInProductCost() }),
              );
            }
          }, 100),
          [],
        );

        // When cost values change, update the formik-displayed calculated cost.
        React.useEffect(() => {
          debouncedUpdate(formikBag);
        }, [
          formikBag.values.haulingCost,
          formikBag.values.rawMaterialCost,
          formikBag.values.plantLoadingCost,
          formikBag.values.costRule,
        ]);

        return (
          <Box padding='5rem 3.5rem 5rem 3.5rem'>
            <FormErrorNotification />
            <Typography variant='h1' fontSize='2rem'>
              {isNew ? 'Create ' : 'Edit '}a material
            </Typography>
            <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
              <Typography variant='h2'>Material details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Input name='name' label='Material name*' />
                </Grid>
                <Grid item xs={6}>
                  <Input name='alternateID' label='Material ID' />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ApiLookupInput
                    formState={formikBag.values}
                    label='Plant*'
                    name='plant'
                    route={{
                      barrel: 'GET plants',
                      args: ConstructListQueryParams,
                      transform: (plantList): LookupInputOption[] =>
                        lookups({
                          ...plantList,
                          label: (p) => p.name,
                        }),
                    }}
                    onMatchChange={(plantId, plantList): void => {
                      if (plantId === null) {
                        ClearFormikFields(formikBag, EmptyFormikMaterial, 'costRule');
                      } else {
                        const matchingPlant = (plantList?.items ?? []).find(
                          (p) => p.id === plantId,
                        );
                        SetFormikValue(
                          formikBag,
                          'costRule',
                          FormikCostRule(matchingPlant?.materialCostRule),
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputDropdown
                    label='Material type*'
                    name='materialType'
                    options={materialTypeOpts}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ApiLookupInput
                    formState={formikBag.values}
                    label='Supplier'
                    name='supplierCompany'
                    route={{
                      barrel: 'GET companies',
                      args: (inputText) => ({
                        queryParams: {
                          page: 0,
                          perPage: DEFAULT_LOOKUP_LENGTH,
                          filterBy: [
                            {
                              operation: Enums.FilterOperation.Equals,
                              name: 'category',
                              value: Enums.CompanyCategory.Supplier,
                            },
                            {
                              operation: Enums.FilterOperation.Lookup,
                              value: inputText,
                            },
                          ],
                        },
                      }),
                      transform: (companyList): LookupInputOption[] =>
                        lookups({
                          ...companyList,
                          label: (c) => c.name,
                          sublabels: (c) => c.lookupSublabels(usesDispatchCustomer),
                        }),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocalLookupInput
                    formState={formikBag.values}
                    name='measurementUnit'
                    label='Batch unit'
                    options={UnitOptions}
                    grouped
                    tip='Unit of Measurement when used in Mix Designs'
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
              <Typography variant='h2'>Cost details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <LocalLookupInput
                    formState={formikBag.values}
                    name='costUnit'
                    label='Unit of Purchase*'
                    tip='Unit in which this material is purchased'
                    options={UnitOptions}
                    onMatchChange={(match): void => {
                      const matchUnit = match as Enums.Unit | null;
                      SetFormikValue(formikBag, 'inProductCostOverride.unit', matchUnit);
                      // A user _can_ set this to null, and the yup rules will be responsible for messaging.
                      SetFormikValue(
                        formikBag,
                        'rawMaterialCost.unit',
                        matchUnit as unknown as Unit,
                      );
                      SetFormikValue(formikBag, 'haulingCost.unit', matchUnit);
                      SetFormikValue(formikBag, 'plantLoadingCost.unit', matchUnit);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Input
                    name='rawMaterialCost'
                    type='cost'
                    disabled={(formikBag.values.costUnit ?? '') === ''}
                    // Conditionally require unit depending on empty input
                    onInputChange={async (): Promise<void> => {
                      formikBag.setFieldTouched('costUnit', true);
                    }}
                    label='Raw material cost*'
                    tip='Raw material cost of purchasing this material'
                    startAdornment='$'
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={UnitFromString(formikBag.values.rawMaterialCost.unit)?.label ?? ''}
                    fullWidth
                    disabled
                    label='Unit'
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Input
                    name='haulingCost'
                    type='cost'
                    disabled={(formikBag.values.costUnit ?? '') === ''}
                    // Conditionally require unit depending on empty input
                    onInputChange={async (): Promise<void> => {
                      formikBag.setFieldTouched('costUnit', true);
                    }}
                    label='Hauling Cost'
                    tip='Hauling cost of purchasing this material'
                    startAdornment='$'
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={UnitFromString(formikBag.values.haulingCost?.unit)?.label ?? ''}
                    fullWidth
                    disabled
                    label='Unit'
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Input
                    name='plantLoadingCost'
                    type='cost'
                    disabled={(formikBag.values.costUnit ?? '') === ''}
                    // Conditionally require unit depending on empty input
                    onInputChange={async (): Promise<void> => {
                      formikBag.setFieldTouched('costUnit', true);
                    }}
                    label='Plant Loading Cost'
                    tip='Plant loading cost of purchasing this material'
                    startAdornment='$'
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={UnitFromString(formikBag.values.plantLoadingCost?.unit)?.label ?? ''}
                    fullWidth
                    disabled
                    label='Unit'
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} wrap='nowrap'>
                <Grid item xs={12}>
                  <CostRuleInput name='costRule' />
                </Grid>
              </Grid>
              <Box paddingBottom='1.5rem'>
                <Typography>
                  Actual total cost: {formikBag.values.displayOnly.actualCost}
                </Typography>
              </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Input
                  name='inProductCostOverride'
                  type='cost'
                  disabled={(formikBag.values.measurementUnit ?? '') === ''}
                  // Conditionally require unit depending on empty input
                  onInputChange={async (): Promise<void> => {
                    formikBag.setFieldTouched('costUnit', true);
                  }}
                  label='In Product Cost Override'
                  tip='Total material cost to be used in product cost calculations. If left empty, the calculated actual cost will be used.'
                  startAdornment='$'
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={UnitFromString(formikBag.values.inProductCostOverride?.unit)?.label ?? ''}
                  fullWidth
                  disabled
                  label='Unit'
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ '& button': { width: '100%' } }}>
              <Grid item xs={4}>
                <ButtonPill
                  text='cancel'
                  variant='secondary'
                  onClick={(): void => {
                    formikBag.resetForm({ values: formikMaterial });
                    setIsOpen(false);
                    onClose?.();
                  }}
                  disabled={createNew.isPending || updateExisting.isPending}
                />
              </Grid>
              <Grid item xs={4}>
                <ButtonPill
                  text='reset'
                  variant='secondary'
                  onClick={(): void => formikBag.resetForm({ values: formikMaterial })}
                  disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
                />
              </Grid>
              <Grid item xs={4}>
                <ButtonPill
                  text='save'
                  variant='primary'
                  onClick={formikBag.submitForm}
                  disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
                />
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </Formik>
  );
};

export const MaterialDrawer = (props: MaterialDrawerProps): JSX.Element => (
  <SlabDrawer isOpen={props.isOpen}>
    <MaterialDrawerPage {...props} />
  </SlabDrawer>
);
