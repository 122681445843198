import { MutableRefObject, useReducer, useRef } from 'react';

/**
 * A version of `useReducer` that doesn't dispatch action results to React's rendering system until after the
 * current synchronous processing microtask has completed, and which exposes access to both the React-visible
 * state and a synchronously updated Ref that reflects the state that the React-visible state will be updated
 * to at the next asynchronous opportunity.
 *
 * If multiple actions are dispatched in the same synchronous execution path, or otherwise dispatched before
 * the runtime's async scheduler executes this hook's callback, then all of the intervening dispatches will be
 * coalesced into a single React-visible state change.
 *
 * The dispatchAction callback returned from this hook returns Promise<void> instead of void; that Promise
 * resolves when React has processed an updated state that reflects the outcome of the dispatched action.
 *
 * In general, code with `useAsyncReducer` is expected to use:
 *
 * - the returned array's first value (`reactState`) for read-oriented purposes, to ensure that all components
 *   in a single React rendering pass see the same currently-active value for this reducer's state
 *
 * - the returned array's second value (`stateRef`) on write paths that compute a new state or action for this
 *   reducer from its existing state, to ensure that we preserve all changes that were previously triggered by
 *   other actions
 */
export const useAsyncReducer = <State, Action, InitialState>(
  reducer: React.Reducer<State, Action>,
  initialState: InitialState,
  initializer: (input: InitialState) => State,
): [State, MutableRefObject<State>, (a: Action) => Promise<void>] => {
  const stateRef = useRef<State>(initializer(initialState));
  const dispatchPromiseRef = useRef<Promise<void> | undefined>();

  const [reactState, dispatchActionToReact] = useReducer(
    () => stateRef.current,
    stateRef.current,
    (initial) => initial,
  );

  const dispatch = (action: Action): Promise<void> => {
    stateRef.current = reducer(stateRef.current, action);
    // Schedule a callback on the next microtask to update React's state, unless one is already scheduled.
    if (dispatchPromiseRef.current === undefined) {
      dispatchPromiseRef.current = Promise.resolve().then(() => {
        dispatchPromiseRef.current = undefined;
        dispatchActionToReact();
      });
    }
    return dispatchPromiseRef.current;
  };

  return [reactState, stateRef, dispatch];
};
