// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { Currency, NewCurrency } from '../Currency/Currency';
import Enums from '../Enums';

export class Cost {
  readonly amount: Currency;
  readonly unit: Enums.Unit;

  constructor(data: { [key: string]: any } = {}) {
    this.amount = NewCurrency(data.amount);
    this.unit = data.unit === undefined ? Enums.Unit.Ac : data.unit;
  }

  static zero(): Cost {
    const zeroValues: ExcludeMethodsDeep<Cost> = {
      amount: Currency.zero(),
      unit: Enums.Unit.Ac,
    };
    return new Cost(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /**
   * Returns the sum of this Cost with the provided values.
   *
   * For now, all of the Cost objects being added must have the same unit (e.g. they could all be
   * Enums.Unit.CuYd), except that zero-amount costs in `values` can use a mismatched unit.
   */
  add(...values: Cost[]): Cost | null {
    const units = [
      this.unit,
      ...values.filter((v) => Number(v.amount.number) !== 0).map((v) => v.unit),
    ];
    if (new Set(units).size !== 1) {
      // TODO handle mismatched units
      return null;
    }
    const amount = this.amount.add(...values.map((v) => v.amount));
    if (amount === null) {
      return null;
    }
    const costData: ExcludeMethodsDeep<Cost> = { amount, unit: units[0] };
    return new Cost(costData);
  }

  /**
   * Returns a new Cost whose amount consists of this Cost's amount multiplied by
   * the specified factor.
   */
  multiply(factor: number): Cost | null {
    const amount = this.amount.multiply(factor);
    if (amount === null) {
      return null;
    }
    const costData: ExcludeMethodsDeep<Cost> = { amount, unit: this.unit };
    return new Cost(costData);
  }

  /**
   * Returns a new Cost whose amount consists of this Cost's amount divided by
   * the specified denominator.
   */
  divide(denominator: number): Cost | null {
    const amount = this.amount.divide(denominator);
    if (amount === null) {
      return null;
    }
    const costData: ExcludeMethodsDeep<Cost> = { amount, unit: this.unit };
    return new Cost(costData);
  }

  /**
   * Returns this cost with all commas stripped out of the `amount.number`.
   * Useful to use inside of calculations to make sure that `Number` parsing does not fail accidentally.
   */
  stripCommas(): Cost {
    return new Cost({
      ...this,
      amount: {
        ...this.amount,
        number: this.amount.number.replaceAll(',', ''),
      },
    });
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewCost = (props: PartialDeep<Cost, { recurseIntoArrays: true }>): Cost =>
  new Cost(props);

export const NewCostFromDomainObject = (
  props: PartialDeep<DomainObject<Cost>, { recurseIntoArrays: true }>,
): Cost => new Cost(props);
