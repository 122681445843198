// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import Enums from '../Enums';
import { FeatureFlag, NewFeatureFlag } from '../FeatureFlag/FeatureFlag';
import { NewTenant, Tenant } from '../Tenant/Tenant';
import { NewUser, User } from '../User/User';

export class UserInfo {
  readonly readableDomainTypes: Enums.DomainType[];
  readonly featureFlags: FeatureFlag[];
  readonly tenant: Tenant;
  readonly user: User;

  constructor(data: { [key: string]: any } = {}) {
    this.readableDomainTypes = (data.readableDomainTypes ?? []).map((readableDomainTypes: any) =>
      readableDomainTypes === undefined ? Enums.DomainType.Activities : readableDomainTypes,
    );
    this.featureFlags = (data.featureFlags ?? []).map((featureFlags: any) =>
      NewFeatureFlag(featureFlags),
    );
    this.tenant = NewTenant(data.tenant);
    this.user = NewUser(data.user);
  }

  static zero(): UserInfo {
    const zeroValues: ExcludeMethodsDeep<UserInfo> = {
      readableDomainTypes: [],
      featureFlags: [],
      tenant: Tenant.zero(),
      user: User.zero(),
    };
    return new UserInfo(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /**
   * hasFlags will return true if the user's flags satisfy the requirements.
   *
   * If no flags are provided, it will return false. It assumes a developer mistake.
   *
   * If operator is `and`, all flags must be present.
   *
   * If operator is `or`, or if operator is not provided, any flag must be present.
   */
  hasFlags(flagNames: Enums.FeatureFlagName[], operator: 'and' | 'or' = 'or'): boolean {
    if (flagNames.length === 0) {
      return false;
    }

    if (operator === 'and') {
      return flagNames.every((flagName) =>
        this.featureFlags.some((f) => f.name === flagName && f.isEnabled),
      );
    }
    return this.featureFlags.some((flag) => flagNames.includes(flag.name) && flag.isEnabled);
  }

  /**
   * hasRoles returns true if the user's roles satisfy the requirements.
   *
   * - If no roles are provided, hasRoles assumes a developer mistake and returns false.
   * - If operator is 'and', returns true if the current user has all of the specified roles.
   * - If operator is 'or' (this is the default), returns true if the current user has one or more
   *   of the specified roles.
   */
  hasRoles(roleNames: Enums.RoleName[], operator: 'and' | 'or' = 'or'): boolean {
    if (roleNames.length === 0) {
      return false;
    }

    if (operator === 'and') {
      return roleNames.every((roleName) => this.user.roles.some((role) => role.name === roleName));
    }
    return this.user.roles.some((role) => roleNames.includes(role.name));
  }

  /**
   * userIfRoles returns the current User if that user has all of the specified roles
   * (according to the behavior of `hasRoles`), otherwise returning undefined.
   */
  userIfRoles(roleNames: Enums.RoleName[]): User | undefined {
    if (!this.hasRoles(roleNames, 'and')) {
      return undefined;
    }
    return this.user;
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewUserInfo = (props: PartialDeep<UserInfo, { recurseIntoArrays: true }>): UserInfo =>
  new UserInfo(props);

export const NewUserInfoFromDomainObject = (
  props: PartialDeep<DomainObject<UserInfo>, { recurseIntoArrays: true }>,
): UserInfo => new UserInfo(props);
