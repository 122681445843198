import { Box, Button, Divider, Grid, TextField, Typography, useTheme } from '@mui/material';
import { FieldArray, FormikContextType, useFormikContext } from 'formik';
import { Add, Trash } from 'iconsax-react';
import LogRocket from 'logrocket';
import { Duration } from 'luxon';
import { memo } from 'react';
import { PartialDeep, SetNonNullable } from 'type-fest';

import { Cost } from '../../generated-types/Cost/Cost';
import { Currency, NewCurrency } from '../../generated-types/Currency/Currency';
import { DeliveryCost, NewDeliveryCost } from '../../generated-types/DeliveryCost/DeliveryCost';
import Enums from '../../generated-types/Enums';
import { Plant } from '../../generated-types/Plant/Plant';
import { Product } from '../../generated-types/Product/Product';
import { ProductSummary } from '../../generated-types/ProductSummary/ProductSummary';
import { Project } from '../../generated-types/Project/Project';
import { ProjectProduct } from '../../generated-types/ProjectProduct/ProjectProduct';
import { NewQuoteProduct, QuoteProduct } from '../../generated-types/QuoteProduct/QuoteProduct';
import { NewDeliveryCostOrNullFromFormik, PlantFormikType } from '../../pages/Plants/PlantFormik';
import { ProjectFormikType } from '../../pages/Projects/ProjectFormik';
import { NewQuoteProductFromFormik, QuoteFormikType } from '../../pages/Quotes/QuoteFormik';
import { ListURLParams, QueryRouteBarrelTypes } from '../../utils/ApiClient';
import { NullableCostString, NullableCurrencyString } from '../../utils/Currency';
import { ZERO_DURATION_STRING } from '../../utils/DateHelpers';
import { lookups } from '../../utils/DomainHelpers';
import { FormikDeliveryCosts, SetFormikValue } from '../../utils/FormikHelpers';
import { UnitFromString } from '../../utils/UnitHelpers';
import { YupSchemaDeliveryCostsType } from '../../utils/YupHelpers';
import { Input } from '../Input/Input';
import { StyledSwitch } from '../InputSwitch/InputSwitch';
import { ApiLookupInput } from '../LookupInput/ApiLookupInput';
import {
  ConstructListQueryParams,
  LookupInputOption,
} from '../LookupInput/LookupInputSharedComponents';
import { SingleUnitDurationInput } from '../SingleUnitDurationInput/SingleUnitDurationInput';
import {
  FormikProductReference,
  ProjectOrQuoteProductFormikType,
} from './ProjectOrQuoteProductFormik';

/**
 * SharedFormikType is a combination of possible formik states based on where
 * the ProductSection might be used. When used in the Project drawer, it will
 * rely on the ProjectFormikType. When used in the Quote drawer, it will rely
 * on the QuoteFormikType. This type is used to ensure that the ProductSection
 * can be used in both contexts.
 */
type SharedFormikType = Pick<
  ProjectFormikType,
  'plantDistanceMiles' | 'plantDriveTime' | 'products'
> &
  Pick<QuoteFormikType, 'mainProducts' | 'additionalProducts'>;

/**
 * Given a record that maps indexes to values, and an index to remove,
 * return an adjusted record such that the new index keys are adjusted
 * to consider the index that is being removed.
 */
export const RemoveAndAdjustIndexedRecord = <T,>(
  record: Record<number, T>,
  indexToRemove: number,
): Record<number, T> => {
  const updatedRecord = Object.entries(record).reduce(
    (acc, [idxKey, idxValue]) => {
      const currentKey = parseInt(idxKey, 10);
      if (currentKey < indexToRemove) {
        acc[currentKey] = idxValue;
      }
      if (currentKey > indexToRemove) {
        acc[currentKey - 1] = idxValue;
      }
      return acc;
    },
    {} as Record<number, T>,
  );
  return updatedRecord;
};

/**
 * Given a record that maps indexes to values, and an index to add,
 * return an adjusted record such that the new index keys are adjusted
 * to consider the index that is being added.
 */
export const AddAndAdjustIndexedRecord = <T,>(
  record: Record<number, T>,
  indexToAdd: number,
  toAdd?: T,
): Record<number, T> => {
  if (indexToAdd >= Object.keys(record).length) {
    return {
      ...record,
      [indexToAdd]: toAdd ?? (null as T),
    };
  }

  const updatedRecord = Object.entries(record).reduce(
    (acc, [idxKey, idxValue]) => {
      const currentKey = parseInt(idxKey, 10);
      if (currentKey < indexToAdd) {
        acc[currentKey] = idxValue;
      }
      if (currentKey === indexToAdd) {
        acc[currentKey] = toAdd ?? (null as T);
      }
      if (currentKey >= indexToAdd) {
        acc[currentKey + 1] = idxValue;
      }
      return acc;
    },
    {} as Record<number, T>,
  );
  return updatedRecord;
};

const Memo = memo(
  // eslint-disable-next-line react/prop-types
  ({ children }: { children: JSX.Element; value: any }): JSX.Element => children,
  (prev, next) => prev.value === next.value,
);

export type ProductSectionProps = {
  sectionType: Enums.QuoteProductKind;
  plant: Plant;
  /** When using this prop at the Project Product level, provide `null`. */
  project: Project | null;
  curProductsRef: React.MutableRefObject<Record<number, Product | null>>;
  /** When using this prop at the Project Product level, provide an empty record. */
  projectProductsByProductId: Record<string, ProjectProduct>;
};

type buildInitialDeliveryCostsOptions = {
  deliveryCosts: DeliveryCost | undefined | null;
  drivingTime: Duration | null;
  drivingDistance: string | null;
};

/**
 * When a new product is selected, conditionally pick the correct delivery cost to use.
 *
 * If there is a project product with an ID matching the selected ID, AND it has a valid
 * delivery cost, then use that.
 *
 * Otherwise, fall back to the current plant delivery costs.
 */
const newProductDeliveryCost = ({
  projectProduct,
  plantProduct,
}: {
  projectProduct: ProjectProduct | undefined;
  plantProduct: Product;
}): DeliveryCost | null => {
  if (projectProduct !== undefined && projectProduct.deliveryCosts.perMinCost.number !== '0.00') {
    return projectProduct.deliveryCosts;
  }

  return plantProduct.plant.deliveryCosts;
};

type cascadedDeliveryCostReturn =
  | {
      formikDeliveryCost: QuoteFormikType['mainProducts'][number]['deliveryCosts'];
      shouldUpdate: false;
    }
  | {
      deliveryCost: DeliveryCost | null;
      shouldUpdate: true;
    };

/**
 * Cascade down to use the appropriate delivery costs for calculations.
 *
 * 1. The snapshot QuoteProduct delivery costs are valid
 * 2. The above is not valid, but there is a matching ProjectProduct with
 *    valid delivery costs
 * 3. There is either no ProjectProduct, or it has invalid snapshot delivery
 *    costs, so use the plant delivery costs.
 */
export const CascadedDeliveryCost = ({
  formikProduct,
  projectProduct,
  plantProduct,
}: {
  formikProduct: QuoteFormikType['mainProducts'][number];
  projectProduct: ProjectProduct | undefined;
  plantProduct: Product;
}): cascadedDeliveryCostReturn => {
  if (
    formikProduct.deliveryCosts.perMinCost !== null &&
    formikProduct.deliveryCosts.perMinCost.number !== '0.00'
  ) {
    return {
      formikDeliveryCost: formikProduct.deliveryCosts,
      shouldUpdate: false,
    };
  }

  if (projectProduct !== undefined && projectProduct.deliveryCosts.perMinCost.number !== '0.00') {
    return {
      deliveryCost: projectProduct.deliveryCosts,
      shouldUpdate: true,
    };
  }

  return {
    deliveryCost: plantProduct.plant.deliveryCosts,
    shouldUpdate: true,
  };
};

/** Add driving time and distance to the specified delivery costs. */
export const buildInitialDeliveryCosts = ({
  deliveryCosts,
  drivingTime,
  drivingDistance,
}: buildInitialDeliveryCostsOptions): DeliveryCost => {
  const newDC = NewDeliveryCost({
    ...deliveryCosts,
    driveDistanceToJob: drivingDistance ?? '0',
    driveDistanceToPlant: drivingDistance ?? '0',
    driveToJobTime: drivingTime ?? Duration.fromObject({}),
    driveToPlantTime: drivingTime ?? Duration.fromObject({}),
  });

  // It does not automatically calculate totalDeliveryCosts properly.
  const dc = NewDeliveryCost({
    ...newDC,
    totalDeliveryCost: newDC.calculateTotalDeliveryCost() ?? undefined,
  });
  return dc;
};

/** Convert a domain-model DeliveryCost object to its Formik representation. */
const deliveryCostToFormik = (
  deliveryCosts: DeliveryCost,
): SetNonNullable<YupSchemaDeliveryCostsType> =>
  FormikDeliveryCosts({
    ...deliveryCosts,
    totalDeliveryCost: deliveryCosts.calculateTotalDeliveryCost() ?? Cost.zero(),
  });

/** Recalculate the value for ProjectOrQuoteProductFormikType.deliveryCosts.displayOnly.totalDeliveryCost */
export const RecalculateTotalDeliveryCost = (deliveryCosts: DeliveryCost | null): string =>
  NullableCostString({ cost: deliveryCosts?.calculateTotalDeliveryCost() ?? null });

/** Recalculate the values for ProjectOrQuoteProductFormikType.displayOnly */
export const RecalculateDisplayOnly = (
  quoteProduct: QuoteProduct,
): ProjectOrQuoteProductFormikType['displayOnly'] => {
  const suggestedPrice = NullableCurrencyString({ cur: quoteProduct.calculateSuggestedPrice() });
  const listPrice = NullableCurrencyString({ cur: quoteProduct.product.listPrice });
  const unitCost = quoteProduct.costPerUnitDisplay();
  const targetMargin = quoteProduct.product.targetMarginDisplay();
  const actualMarginPercentage = quoteProduct.marginAsPercentage();
  const actualMarginCurrency = quoteProduct.marginAsFormattedCurrency();
  const marginOverMaterialsCurrency = quoteProduct.marginOverMaterialAsFormattedCurrency();
  const marginOverMaterialsPercentage = quoteProduct.marginOverMaterialAsPercentage();

  return {
    suggestedPrice,
    listPrice,
    unitCost,
    targetMargin,
    actualMarginPercentage,
    actualMarginCurrency,
    marginOverMaterialsCurrency,
    marginOverMaterialsPercentage,
  };
};

type setRecalculatedCostsReturn = {
  totalDeliveryCost: string;
  displayOnly: ReturnType<typeof RecalculateDisplayOnly>;
};

/**
 * Recalculate the displayOnly values on ProjectOrQuoteProductFormikType and its deliveryCosts,
 * and set the new values in the formikBag.
 */
const setRecalculatedCosts = ({
  formikBag,
  deliveryCostInputOverrides = {},
  quoteProductOverrides = {},
  fieldPathPrefix,
  formikProduct,
  plantProduct,
}: {
  formikBag: FormikContextType<SharedFormikType>;
  deliveryCostInputOverrides?: PartialDeep<PlantFormikType['deliveryCosts']>;
  quoteProductOverrides?: PartialDeep<QuoteProduct>;
  fieldPathPrefix: `products.${number}` | `mainProducts.${number}` | `additionalProducts.${number}`;
  formikProduct: Omit<ProjectOrQuoteProductFormikType, 'displayOnly'>;
  plantProduct: Product | undefined;
}): setRecalculatedCostsReturn => {
  const deliveryCosts = NewDeliveryCostOrNullFromFormik(
    formikProduct.deliveryCosts,
    deliveryCostInputOverrides,
  );
  const quoteProductInitial = NewQuoteProductFromFormik(formikProduct, {
    product: plantProduct,
  });
  const quoteProduct = NewQuoteProduct({
    ...quoteProductInitial,
    ...quoteProductOverrides,
    deliveryCosts: {
      ...deliveryCosts,
      totalDeliveryCost: deliveryCosts?.calculateTotalDeliveryCost() ?? Cost.zero(),
    },
  });

  const totalDeliveryCost = RecalculateTotalDeliveryCost(quoteProduct.deliveryCosts);
  const displayOnly = RecalculateDisplayOnly(quoteProduct);

  SetFormikValue(formikBag, `${fieldPathPrefix}.deliveryCosts.displayOnly`, { totalDeliveryCost });
  // TODO: add support for setting non-leaf properties via FormikHelpers.SetFormikValue
  formikBag.setFieldValue(`${fieldPathPrefix}.displayOnly`, displayOnly);

  return { totalDeliveryCost, displayOnly };
};

/** Determine the default value of QuoteProduct.price for a Product that is newly added to the Quote. */
export const CalculateInitialPrice = ({
  plantProduct,
  projectProduct,
  deliveryCosts,
}: {
  plantProduct: Product | undefined;
  projectProduct: ProjectProduct | undefined;
  deliveryCosts: DeliveryCost | undefined;
}): Currency => {
  // If no current product, clear the price
  if (plantProduct === undefined) {
    return NewCurrency({
      number: '',
      currency: 'USD',
    });
  }

  // Prefer project product unit price if exists
  if (projectProduct?.price !== undefined) {
    return projectProduct.price;
  }

  // If there is a hard-coded list price on the product, use that
  if (plantProduct.listPrice !== null) {
    return plantProduct.listPrice;
  }

  // Else pre-fill with suggested price
  const suggestedPrice = NewQuoteProduct({
    product: plantProduct,
    deliveryCosts:
      deliveryCosts ?? projectProduct?.deliveryCosts ?? plantProduct.plant.deliveryCosts,
  }).calculateSuggestedPrice();
  if (suggestedPrice === null) {
    return NewCurrency({ number: '', currency: 'USD' });
  }
  return suggestedPrice;
};

export const NewEmptySharedFormikProduct = ({
  sectionType,
}: Pick<ProductSectionProps, 'sectionType'>): ProjectOrQuoteProductFormikType => ({
  product: {
    id: null,
    option: null,
    incompatibleMixBatchUnits: false,
    allowIncompatibleMixBatchUnits: false,
  },
  kind:
    sectionType === Enums.QuoteProductKind.Primary
      ? Enums.QuoteProductKind.Primary
      : Enums.QuoteProductKind.Additional,
  quantity: sectionType === Enums.QuoteProductKind.Primary ? 1 : null,
  externalName: null,
  class: null,
  price: {
    number: '',
    currency: 'USD',
  },
  isMoneySwitchOn: false,
  displayOnly: null,
  deliveryCosts: deliveryCostToFormik(
    buildInitialDeliveryCosts({
      deliveryCosts: DeliveryCost.zero(),
      drivingDistance: null,
      drivingTime: null,
    }),
  ),
  usage: null,
});

export const ProductSection = ({
  sectionType,
  plant,
  project,
  curProductsRef,
  projectProductsByProductId,
}: ProductSectionProps): JSX.Element => {
  const theme = useTheme();

  const formikBag = useFormikContext<SharedFormikType>();

  const formikSectionType = ((): 'products' | 'mainProducts' | 'additionalProducts' => {
    // If project is null, we are at the Project level. So use 'products'.
    if (project === null) {
      return 'products';
    }

    switch (sectionType) {
      case Enums.QuoteProductKind.Primary:
        return 'mainProducts';
      case Enums.QuoteProductKind.Additional:
        return 'additionalProducts';
      default:
        LogRocket.error(`Type passed to ProductSection with no match: ${sectionType}`);
        return 'mainProducts';
    }
  })();

  const formikProducts =
    formikBag.getFieldMeta<Omit<ProjectOrQuoteProductFormikType, 'displayOnly'>[]>(
      formikSectionType,
    ).value;

  return (
    <FieldArray
      name={formikSectionType}
      // Sees 2x performance benefit at no behavior compromise.
      validateOnChange={false}
      render={(arrayHelpers): JSX.Element => (
        <>
          {formikProducts.map((formikProduct, index) => {
            const absIdx =
              formikSectionType === 'additionalProducts'
                ? index + formikBag.values.mainProducts.length
                : index;
            const isCurProductSelected = ((): boolean => {
              const currentProductId = formikBag.getFieldMeta<string | null>(
                `${formikSectionType}.${index}.product.id`,
              );
              return currentProductId.value !== null;
            })();

            const plantProduct = curProductsRef.current[absIdx];

            /** Display Only Inputs. */
            const moneyDisplaySwitch = (
              <Box display='flex' alignItems='center'>
                <Typography fontWeight='bold'>$</Typography>
                <StyledSwitch
                  checked={!formikProduct.isMoneySwitchOn}
                  onChange={() =>
                    SetFormikValue(
                      formikBag,
                      `${formikSectionType}.${index}.isMoneySwitchOn`,
                      !formikProduct.isMoneySwitchOn,
                    )
                  }
                />
                <Typography fontWeight='normal'>%</Typography>
              </Box>
            );
            const marginOverMaterialsDisplay = (
              <Input
                name={
                  formikProduct.isMoneySwitchOn
                    ? `${formikSectionType}.${index}.displayOnly.marginOverMaterialsCurrency`
                    : `${formikSectionType}.${index}.displayOnly.marginOverMaterialsPercentage`
                }
                label='MoM'
                tip='Margin over materials'
                disabled
              />
            );
            const oneWayTripTimeDisplay = (
              <SingleUnitDurationInput
                name={`${formikSectionType}.${index}.deliveryCosts.driveToJobTime`}
                label='One-way trip time'
                unit='minutes'
                disabled
              />
            );
            const actualMarginDisplay = (
              <Input
                name={
                  formikProduct.isMoneySwitchOn
                    ? `${formikSectionType}.${index}.displayOnly.actualMarginCurrency`
                    : `${formikSectionType}.${index}.displayOnly.actualMarginPercentage`
                }
                label='Actual margin'
                disabled
              />
            );
            const unitCostDisplay = (
              <Input
                name={`${formikSectionType}.${index}.displayOnly.unitCost`}
                label='Unit cost'
                disabled
              />
            );
            const listPriceDisplay = (
              <Input
                name={`${formikSectionType}.${index}.displayOnly.listPrice`}
                label='List price'
                disabled
              />
            );
            const suggestedPriceDisplay = (
              <Input
                name={`${formikSectionType}.${index}.displayOnly.suggestedPrice`}
                label='Suggested price'
                disabled
              />
            );
            const targetMarginDisplay = (
              <Input
                name={`${formikSectionType}.${index}.displayOnly.targetMargin`}
                label='Target margin'
                disabled
              />
            );
            const deliveryCostDisplay = (
              <TextField
                label='Delivery cost'
                value={formikProduct.deliveryCosts?.displayOnly?.totalDeliveryCost ?? ''}
                fullWidth
                disabled
              />
            );

            /** Basic Inputs. */
            const externalNameInput = (
              <Input
                name={`${formikSectionType}.${index}.externalName`}
                label='External name'
                disabled={!isCurProductSelected}
              />
            );
            const classInput = (
              <Input
                name={`${formikSectionType}.${index}.class`}
                label='Class'
                type='number'
                disabled={!isCurProductSelected}
              />
            );
            const usageInput = (
              <Input
                name={`${formikSectionType}.${index}.usage`}
                type='textarea'
                label='Usage'
                disabled={!isCurProductSelected}
              />
            );
            const quantityInput = (
              <Input
                name={`${formikSectionType}.${index}.quantity`}
                label='Quantity*'
                type='number'
                disabled={!isCurProductSelected}
              />
            );

            /** Complex Inputs. */
            const productInput = (
              <ApiLookupInput
                formState={formikBag.values}
                name={`${formikSectionType}.${index}.product`}
                label='Product*'
                route={{
                  barrel: 'GET products',
                  args: (inputText): QueryRouteBarrelTypes['GET products']['args'] => {
                    const args = ConstructListQueryParams(inputText);
                    const filterBy: ListURLParams<ProductSummary>['filterBy'] = [
                      ...(args.queryParams?.filterBy ?? []),
                      {
                        name: 'plantId',
                        operation: Enums.FilterOperation.Equals,
                        value: plant.id,
                      },
                    ];
                    return {
                      ...args,
                      queryParams: {
                        ...args.queryParams,
                        filterBy,
                      },
                    };
                  },
                  // If you change this transform, you probably also want to make corresponding changes
                  // to the product's option data generated by QuoteProductToProjectOrQuoteProductFormik().
                  transform: (pl): LookupInputOption[] =>
                    lookups({
                      ...pl,
                      label: (p): string => {
                        if (p.incompatibleMixBatchUnits) {
                          return `[UOM issue] ${p.alternateID}`;
                        }
                        return p.alternateID;
                      },
                      sublabels: (p) => [p.name],
                    }),
                }}
                onMatchChange={(productId, pl): void => {
                  if (productId === null) {
                    SetFormikValue(formikBag, `${formikSectionType}.${index}`, {
                      ...NewEmptySharedFormikProduct({ sectionType }),
                      isMoneySwitchOn: formikProduct.isMoneySwitchOn,
                    });
                    // Update matching product state
                    const copyCurProducts = { ...curProductsRef.current };
                    copyCurProducts[absIdx] = null;
                    curProductsRef.current = copyCurProducts;
                    return;
                  }

                  const newPlantProduct =
                    (pl?.items ?? []).find((p) => p.id === productId) ?? Product.zero();
                  const newProjectProduct =
                    productId === null ? undefined : projectProductsByProductId[productId];

                  const product = FormikProductReference(
                    newProjectProduct?.product ?? newPlantProduct,
                  );
                  SetFormikValue(formikBag, `${formikSectionType}.${index}.product`, product);

                  const newDeliveryCost = newProductDeliveryCost({
                    plantProduct: newPlantProduct,
                    projectProduct: newProjectProduct,
                  });

                  const initialDeliveryCosts = ((): DeliveryCost => {
                    // If no project, we are in the project product drawer.
                    // So use the dynamic plantDriveTime in the current form.
                    if (project === null) {
                      return buildInitialDeliveryCosts({
                        deliveryCosts: newDeliveryCost,
                        drivingTime: Duration.fromISO(
                          formikBag.values.plantDriveTime ?? ZERO_DURATION_STRING,
                        ),
                        drivingDistance: formikBag.values.plantDistanceMiles,
                      });
                    }
                    // Otherwise we are in the quote products drawer, so use the project's plantDriveTime.
                    return buildInitialDeliveryCosts(
                      productId === null
                        ? {
                            deliveryCosts: undefined,
                            drivingTime: project.plantDriveTime,
                            drivingDistance: project.plantDistanceMiles,
                          }
                        : {
                            deliveryCosts: newDeliveryCost,
                            drivingTime: project.plantDriveTime,
                            drivingDistance: project.plantDistanceMiles,
                          },
                    );
                  })();
                  const initialFormikDeliveryCosts = deliveryCostToFormik(initialDeliveryCosts);
                  SetFormikValue(
                    formikBag,
                    `${formikSectionType}.${index}.deliveryCosts`,
                    initialFormikDeliveryCosts,
                  );
                  const price = CalculateInitialPrice({
                    plantProduct: newPlantProduct,
                    projectProduct: newProjectProduct,
                    deliveryCosts: initialDeliveryCosts,
                  });
                  SetFormikValue(formikBag, `${formikSectionType}.${index}.price`, price);
                  SetFormikValue(
                    formikBag,
                    `${formikSectionType}.${index}.quantity`,
                    sectionType === Enums.QuoteProductKind.Additional ? null : 1,
                  );
                  SetFormikValue(
                    formikBag,
                    `${formikSectionType}.${index}.externalName`,
                    newPlantProduct.externalName(),
                  );
                  SetFormikValue(
                    formikBag,
                    `${formikSectionType}.${index}.usage`,
                    sectionType === Enums.QuoteProductKind.Primary
                      ? newProjectProduct?.usage ?? null
                      : null,
                  );
                  setRecalculatedCosts({
                    formikBag,
                    formikProduct,
                    fieldPathPrefix: `${formikSectionType}.${index}`,
                    plantProduct: newPlantProduct,
                    deliveryCostInputOverrides: initialFormikDeliveryCosts,
                    quoteProductOverrides: {
                      price,
                      product: newPlantProduct,
                      deliveryCosts: initialDeliveryCosts,
                    },
                  });
                  // Update matching product state
                  const copyCurProducts = { ...curProductsRef.current };
                  copyCurProducts[absIdx] = newPlantProduct;
                  curProductsRef.current = copyCurProducts;
                }}
              />
            );
            const unitPriceInput = (
              <Input
                name={`${formikSectionType}.${index}.price`}
                label='Unit price*'
                type='currency'
                startAdornment='$'
                disabled={!isCurProductSelected}
                onInputChange={async (unitPriceNumber): Promise<void> => {
                  setRecalculatedCosts({
                    formikBag,
                    formikProduct,
                    fieldPathPrefix: `${formikSectionType}.${index}`,
                    plantProduct: plantProduct ?? undefined,
                    quoteProductOverrides: {
                      price: NewCurrency({ number: unitPriceNumber, currency: 'USD' }),
                    },
                  });
                }}
              />
            );
            // eslint-disable-next-line react/no-unstable-nested-components
            const deliveryCostOverrideInput = ((): JSX.Element | null => {
              if (plantProduct?.category !== Enums.ProductCategory.Mix) {
                return null;
              }
              const endAdornmentUnit =
                UnitFromString(formikProduct.deliveryCosts.deliveryCostOverride?.unit)?.label ?? '';
              return (
                <Input
                  name={`${formikSectionType}.${index}.deliveryCosts.deliveryCostOverride`}
                  label='Delivery cost override'
                  type='cost'
                  startAdornment='$'
                  endAdornment={`/${endAdornmentUnit}`}
                  onInputChange={async (deliveryCostOverride): Promise<void> => {
                    const cascadedDeliveryCost = CascadedDeliveryCost({
                      formikProduct,
                      projectProduct: projectProductsByProductId[plantProduct.id],
                      plantProduct,
                    });

                    const formikDeliveryCost =
                      cascadedDeliveryCost.shouldUpdate === false
                        ? cascadedDeliveryCost.formikDeliveryCost
                        : FormikDeliveryCosts(cascadedDeliveryCost.deliveryCost ?? undefined);
                    const { totalDeliveryCost } = setRecalculatedCosts({
                      formikBag,
                      formikProduct,
                      fieldPathPrefix: `${formikSectionType}.${index}`,
                      plantProduct,
                      deliveryCostInputOverrides: {
                        ...formikDeliveryCost,
                        deliveryCostOverride: {
                          amount: { number: deliveryCostOverride },
                        },
                      },
                    });

                    // We must update this in the form state so that the next delivery cost
                    // sent to the API will be a snapshot.
                    if (cascadedDeliveryCost.shouldUpdate) {
                      const initialDeliveryCosts =
                        project === null
                          ? buildInitialDeliveryCosts({
                              deliveryCosts: cascadedDeliveryCost.deliveryCost,
                              drivingTime: Duration.fromISO(
                                formikBag.values.plantDriveTime ?? ZERO_DURATION_STRING,
                              ),
                              drivingDistance: formikBag.values.plantDistanceMiles,
                            })
                          : buildInitialDeliveryCosts({
                              deliveryCosts: cascadedDeliveryCost.deliveryCost,
                              drivingTime: project.plantDriveTime,
                              drivingDistance: project.plantDistanceMiles,
                            });
                      SetFormikValue(formikBag, `${formikSectionType}.${index}.deliveryCosts`, {
                        ...FormikDeliveryCosts(initialDeliveryCosts),
                        deliveryCostOverride: {
                          amount: {
                            number: deliveryCostOverride,
                            currency:
                              formikProduct.deliveryCosts.deliveryCostOverride?.amount?.currency ??
                              'USD',
                          },
                          unit:
                            formikProduct.deliveryCosts.deliveryCostOverride?.unit ??
                            FormikDeliveryCosts(undefined).deliveryCostOverride.unit,
                        },
                        displayOnly: {
                          totalDeliveryCost,
                        },
                      });
                    }
                  }}
                />
              );
            })();
            const pourTimeInput = plantProduct?.category === Enums.ProductCategory.Mix && (
              <SingleUnitDurationInput
                name={`${formikSectionType}.${index}.deliveryCosts.pourTime`}
                label='Pour time'
                unit='minutes'
                onInputChange={async (pourTime): Promise<void> => {
                  setRecalculatedCosts({
                    formikBag,
                    formikProduct,
                    fieldPathPrefix: `${formikSectionType}.${index}`,
                    plantProduct,
                    deliveryCostInputOverrides: { pourTime },
                  });
                }}
              />
            );
            const waitTimeInput = plantProduct?.category === Enums.ProductCategory.Mix && (
              <SingleUnitDurationInput
                name={`${formikSectionType}.${index}.deliveryCosts.waitTime`}
                label='Wait time'
                unit='minutes'
                onInputChange={async (waitTime): Promise<void> => {
                  setRecalculatedCosts({
                    formikBag,
                    formikProduct,
                    fieldPathPrefix: `${formikSectionType}.${index}`,
                    plantProduct,
                    deliveryCostInputOverrides: { waitTime },
                  });
                }}
              />
            );
            const loadSizeInput = plantProduct?.category === Enums.ProductCategory.Mix && (
              <Input
                name={`${formikSectionType}.${index}.deliveryCosts.loadSize`}
                label='Load size'
                type='number'
                endAdornment={
                  UnitFromString(formikProduct.deliveryCosts?.loadSizeUnit)?.label ?? ''
                }
                onInputChange={async (loadSize): Promise<void> => {
                  setRecalculatedCosts({
                    formikBag,
                    formikProduct,
                    fieldPathPrefix: `${formikSectionType}.${index}`,
                    plantProduct,
                    deliveryCostInputOverrides: { loadSize },
                  });
                }}
              />
            );
            const incompatibleBatchUnitSwitch = formikProduct.product.incompatibleMixBatchUnits && (
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <StyledSwitch
                    id={`${formikSectionType}.${index}.product.allowIncompatibleMixBatchUnits`}
                    checked={
                      formikBag.values[formikSectionType][index].product
                        .allowIncompatibleMixBatchUnits
                    }
                    onChange={(_, checked) =>
                      formikBag.setFieldValue(
                        `${formikSectionType}.${index}.product.allowIncompatibleMixBatchUnits`,
                        checked,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={11}>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label
                    htmlFor={`${formikSectionType}.${index}.product.allowIncompatibleMixBatchUnits`}
                  >
                    <Typography
                      color={
                        formikProduct.product.allowIncompatibleMixBatchUnits ? undefined : 'error'
                      }
                    >
                      Accept UOM issue? Costs cannot be calculated for this product because some of
                      its mix’s materials use a batch unit that is incompatible with the material’s
                      cost unit.
                    </Typography>
                  </label>
                </Grid>
              </Grid>
            );
            const deliveryCostRow = (
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  {deliveryCostOverrideInput}
                </Grid>
                {formikProduct.deliveryCosts.deliveryCostOverride !== null &&
                formikProduct.deliveryCosts.deliveryCostOverride?.amount?.number !== '' ? (
                  <Grid item xs={8}>
                    <Typography color='textSecondary'>
                      Detailed cost inputs have been hidden because the calculated delivery cost is
                      overridden here.
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={2}>
                      {loadSizeInput}
                    </Grid>
                    <Grid item xs={2}>
                      {pourTimeInput}
                    </Grid>
                    <Grid item xs={2}>
                      {waitTimeInput}
                    </Grid>
                    <Grid item xs={2}>
                      {oneWayTripTimeDisplay}
                    </Grid>
                    <Grid item xs={2}>
                      {deliveryCostDisplay}
                    </Grid>
                  </>
                )}
              </Grid>
            );

            // UI for Project Product drawer
            if (formikSectionType === 'products') {
              return (
                <Memo
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${formikSectionType}-grid-${index}`}
                  value={{ formikProduct, plantProduct }}
                >
                  <>
                    {moneyDisplaySwitch}
                    <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
                      <Grid container spacing={2}>
                        <Grid item xs={11}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              {productInput}
                            </Grid>
                            <Grid item xs={6}>
                              {usageInput}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={3}>
                              {quantityInput}
                            </Grid>
                            <Grid item xs={3}>
                              {unitPriceInput}
                            </Grid>
                            <Grid item xs={3}>
                              {actualMarginDisplay}
                            </Grid>
                            <Grid item xs={3}>
                              {marginOverMaterialsDisplay}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={3}>
                              {targetMarginDisplay}
                            </Grid>
                            <Grid item xs={3}>
                              {suggestedPriceDisplay}
                            </Grid>
                            <Grid item xs={3}>
                              {listPriceDisplay}
                            </Grid>
                            <Grid item xs={3}>
                              {unitCostDisplay}
                            </Grid>
                          </Grid>
                          {plantProduct?.category === Enums.ProductCategory.Mix && deliveryCostRow}
                          {incompatibleBatchUnitSwitch}
                        </Grid>
                        <Grid
                          item
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                          xs={1}
                        >
                          <Button
                            onClick={(): void => {
                              arrayHelpers.remove(index);
                              // Update matching product state
                              const copyCurProducts = RemoveAndAdjustIndexedRecord(
                                curProductsRef.current,
                                absIdx,
                              );
                              curProductsRef.current = copyCurProducts;
                            }}
                          >
                            <Trash variant='TwoTone' color={theme.palette.SlabChart.Indigo[300]} />
                          </Button>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Box>
                  </>
                </Memo>
              );
            }

            // UI for Quote Product main products Drawer
            if (formikSectionType === 'mainProducts') {
              return (
                <Memo
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${formikSectionType}-grid-${index}`}
                  value={{ formikProduct, plantProduct }}
                >
                  <>
                    {moneyDisplaySwitch}
                    <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
                      <Grid container spacing={2}>
                        <Grid item xs={11}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              {productInput}
                            </Grid>
                            <Grid item xs={4}>
                              {externalNameInput}
                            </Grid>
                            <Grid item xs={1}>
                              {classInput}
                            </Grid>
                            <Grid item xs={3}>
                              {usageInput}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={3}>
                              {quantityInput}
                            </Grid>
                            <Grid item xs={3}>
                              {unitPriceInput}
                            </Grid>
                            <Grid item xs={3}>
                              {actualMarginDisplay}
                            </Grid>
                            <Grid item xs={3}>
                              {marginOverMaterialsDisplay}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={3}>
                              {targetMarginDisplay}
                            </Grid>
                            <Grid item xs={3}>
                              {suggestedPriceDisplay}
                            </Grid>
                            <Grid item xs={3}>
                              {listPriceDisplay}
                            </Grid>
                            <Grid item xs={3}>
                              {unitCostDisplay}
                            </Grid>
                          </Grid>
                          {plantProduct?.category === Enums.ProductCategory.Mix && deliveryCostRow}
                          {incompatibleBatchUnitSwitch}
                        </Grid>
                        <Grid
                          item
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                          xs={1}
                        >
                          <Button
                            onClick={(): void => {
                              arrayHelpers.remove(index);
                              // Update matching product state
                              const copyCurProducts = RemoveAndAdjustIndexedRecord(
                                curProductsRef.current,
                                absIdx,
                              );
                              curProductsRef.current = copyCurProducts;
                            }}
                          >
                            <Trash variant='TwoTone' color={theme.palette.SlabChart.Indigo[300]} />
                          </Button>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Box>
                  </>
                </Memo>
              );
            }

            // UI for Quote Product additional services Drawer
            return (
              <Memo
                // eslint-disable-next-line react/no-array-index-key
                key={`${formikSectionType}-grid-${index}`}
                value={{ formikProduct, plantProduct }}
              >
                <>
                  {moneyDisplaySwitch}
                  <Box paddingY='1.25rem' display='flex' flexDirection='column' gap='1rem'>
                    <Grid container spacing={2}>
                      <Grid item xs={11}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            {productInput}
                          </Grid>
                          <Grid item xs={6}>
                            {externalNameInput}
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            {unitPriceInput}
                          </Grid>
                          <Grid item xs={4}>
                            {actualMarginDisplay}
                          </Grid>
                          <Grid item xs={4}>
                            {marginOverMaterialsDisplay}
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            {targetMarginDisplay}
                          </Grid>
                          <Grid item xs={3}>
                            {suggestedPriceDisplay}
                          </Grid>
                          <Grid item xs={3}>
                            {listPriceDisplay}
                          </Grid>
                          <Grid item xs={3}>
                            {unitCostDisplay}
                          </Grid>
                        </Grid>
                        {plantProduct?.category === Enums.ProductCategory.Mix && deliveryCostRow}
                        {incompatibleBatchUnitSwitch}
                      </Grid>
                      <Grid item display='flex' justifyContent='center' alignItems='center' xs={1}>
                        <Button
                          onClick={(): void => {
                            arrayHelpers.remove(index);
                            // Update matching product state
                            const copyCurProducts = RemoveAndAdjustIndexedRecord(
                              curProductsRef.current,
                              absIdx,
                            );
                            curProductsRef.current = copyCurProducts;
                          }}
                        >
                          <Trash variant='TwoTone' color={theme.palette.SlabChart.Indigo[300]} />
                        </Button>
                      </Grid>
                    </Grid>
                    <Divider />
                  </Box>
                </>
              </Memo>
            );
          })}
          <Button
            onClick={(): void => {
              arrayHelpers.push(NewEmptySharedFormikProduct({ sectionType }));
              // Update matching product state
              const { mainLength, additionalLength } = ((): {
                mainLength: number;
                additionalLength: number;
              } => {
                if (project === null) {
                  return {
                    mainLength: formikBag.values.products.length,
                    additionalLength: 0,
                  };
                }
                return {
                  mainLength: formikBag.values.mainProducts.length,
                  additionalLength: formikBag.values.additionalProducts.length,
                };
              })();
              const idxToAdd =
                formikSectionType === 'additionalProducts'
                  ? mainLength + additionalLength
                  : mainLength;

              const copyCurQuoteProducts = AddAndAdjustIndexedRecord(
                curProductsRef.current,
                idxToAdd,
              );
              curProductsRef.current = copyCurQuoteProducts;
            }}
            sx={{
              textTransform: 'none',
              color: theme.palette.SlabChart.Indigo[300],
            }}
          >
            <Box display='flex' gap='0.5rem'>
              <Add color={theme.palette.SlabChart.Indigo[300]} />
              Add {formikProducts.length === 0 ? 'product' : 'another'}
            </Box>
          </Button>
        </>
      )}
    />
  );
};
