import { InputOption } from '../components/InputDropdown/InputDropdown';
import { LookupInputOption } from '../components/LookupInput/LookupInputSharedComponents';
import { randomUUID } from './UUID';

/**
 * Ref represents nested references to other object types via a primary key ID.
 */
export type Ref = {
  id: string;
};

/**
 * NullableRef represents nested references to other object types via a primary key ID.
 * It is considered valid if it has the same type as Ref.
 */
export type NullableRef = {
  id: string | null;
} | null;

/**
 * isValidReference returns true if the argument is a Ref with an id that is not
 * null or ''.
 */
export const isValidReference = (v: Ref | NullableRef | null | undefined): boolean =>
  (v?.id ?? '') !== '';

/**
 * byID turns an array of Ref-typed values into a Map from those Refs' ids to the Refs.
 */
export const byID = <T extends Ref>(items: T[] | undefined): Map<string | undefined, T> =>
  new Map((items ?? []).map((item: T): [string, T] => [item.id, item]));

/**
 * A utility function that will generate a sort function based on the type of object passed in.
 * It is useful in conjunction with the `lookups` `compare` option.
 *
 * @example
 * // a is a Company with property `name` which is a 'string'
 * PropertyComparator((a) => a.name),
 */
export const PropertyComparator =
  <T,>(transform: (a: T) => string): ((itemA: T, itemB: T) => number) =>
  (a, b) =>
    new Intl.Collator(undefined, {
      caseFirst: 'upper',
      numeric: true,
    }).compare(transform(a), transform(b));

type LookupsOpts<T extends Ref> = {
  /** Items from which to generate lookups. */
  items: T[] | null | undefined;

  /** A function that determines the primary label for the item. */
  label: (item: T) => string;

  /**
   * The count of total items that could be displayed. Based on the number of items
   * rendered, an additional option may be added displaying how many more are available.
   */
  count: number;

  /** An optional function that determines secondary labels for the item. */
  sublabels?: (item: T) => string[] | undefined;

  /**
   * An optional comparator function to sort items.
   *
   * If omitted, it will sort by label.
   *
   * If it is null, it will return the passed items in-order.

   * When comparing strings, it is suggested to use PropertyComparator.
   *
   * @example
   * // a is a Company with property `name` which is a 'string'
   * compare: PropertyComparator((a) => a.name),
   */
  compare?: ((itemA: T, itemB: T) => number) | null;
};

/**
 * lookups turns an array of Ref-typed values, and a function for extracting the
 * label from the Ref into an array of InputOptions or LookupInputOptions
 */
export const lookups = <T extends Ref>(
  opts: LookupsOpts<T>,
): InputOption[] | LookupInputOption[] => {
  const sortedItems = ((): T[] => {
    const items = [...(opts.items ?? [])];
    if (opts.compare === undefined) {
      return items.sort(PropertyComparator(opts.label));
    }
    if (opts.compare === null) {
      return items;
    }
    return items.sort(opts.compare);
  })();

  const options: LookupInputOption[] = sortedItems.map((i: T) => ({
    value: i.id,
    label: opts.label(i),
    sublabels: opts.sublabels?.(i),
  }));

  if (opts.count > options.length) {
    const countNotRendered = opts.count - options.length;
    options.push({
      value: randomUUID(),
      label: `${countNotRendered} more option${countNotRendered === 1 ? '' : 's'}`,
      isDisabled: true,
    });
  }
  return options;
};

export const enumLookups = <T extends string>(obj: { [s: string]: T }): InputOption[] => {
  const enumAsIDObj = Object.values(obj).map((v) => ({
    id: v,
  }));

  return lookups({
    items: enumAsIDObj,
    label: (v) => v.id,
    count: Object.values(obj).length,
  });
};

/**
 * IncludeReference adds a given domain object to the end of an array, unless a
 * reference having that object's ID is already present. Returns a new array.
 */
export const IncludeReference = <T extends { id: string }>(
  refs: T[] | null | undefined,
  obj: T,
): T[] => {
  const elts = refs ?? [];
  const found = elts.find((val) => val.id === obj.id);
  return found !== undefined ? elts : [...elts, obj];
};

/** Reduces initialization boilerplate code */
export type EnsureDefinedFunction<T> = (obj: T | undefined) => Partial<T>;

/** Reduces initialization boilerplate code */
export const DefaultEnsureDefined = <T,>(obj: T | undefined): Partial<T> =>
  obj === undefined ? {} : obj;

/** Given a number | null, convert it to a locale string and add a trailing % sign. */
export const formatAsPercent = (pct: number | null): string =>
  pct === null ? '' : `${pct.toLocaleString()}%`;

// Given an array of elements, this returns an numerically indexed record of values to the elements.
// It takes an optional `getEl` parameter which allows selecting a subobject from an object in the array of elements.
export const ArrayToIndexedRecord = <T, R>(arr: T[], getEl?: (arg: T) => R): Record<number, R> => {
  const getElement = getEl === undefined ? (arg: T): T => arg : getEl;
  return arr.reduce(
    (acc, cur, idx) => ({
      ...acc,
      [`${idx}`]: getElement(cur),
    }),
    {} as Record<number, R>,
  );
};
