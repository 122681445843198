import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { Input } from '../../../components/Input/Input';
import { ApiLookupInput } from '../../../components/LookupInput/ApiLookupInput';
import {
  ConstructListQueryParams,
  LookupInputOption,
} from '../../../components/LookupInput/LookupInputSharedComponents';
import { SlabStep, SlabStepper } from '../../../components/SlabStepper/SlabStepper';
import { Plant } from '../../../generated-types/Plant/Plant';
import { lookups } from '../../../utils/DomainHelpers';
import { ClearFormikFields } from '../../../utils/FormikHelpers';
import { EmptyFormikQuoteConfig, QuoteConfigFormikType } from '../QuoteConfigFormik';
import { DynamicQuoteConfigProductLookup } from './DynamicQuoteConfigProductLookup';

const QuoteConfigDetailsSection = (): JSX.Element => {
  const formikBag = useFormikContext<QuoteConfigFormikType>();

  return (
    <Box display='flex' flexDirection='column' gap='1rem'>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input name='name' label='Name*' />
        </Grid>
        <Grid item xs={6}>
          <ApiLookupInput
            formState={formikBag.values}
            name='plant'
            label='Plant*'
            route={{
              barrel: 'GET plants',
              args: ConstructListQueryParams,
              transform: (plantList): LookupInputOption[] =>
                lookups({
                  ...plantList,
                  label: (p: Plant) => p.name,
                }),
            }}
            onMatchChange={(plantId): void => {
              const prevPlantId = formikBag.values.plant.id;
              if (plantId === null || (plantId !== null && plantId !== prevPlantId)) {
                ClearFormikFields(formikBag, EmptyFormikQuoteConfig, 'products');
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input name='terms' label='Terms*' type='textarea' />
        </Grid>
      </Grid>
    </Box>
  );
};

export type QuoteConfigSection = 'Details*' | 'Default additional products';

const steps: SlabStep<QuoteConfigFormikType>[] = [
  {
    label: 'Details*',
    content: QuoteConfigDetailsSection,
    maybeErrorFieldNames: ['name', 'plant', 'terms'],
  },
  {
    label: 'Default additional products',
    content: DynamicQuoteConfigProductLookup,
    maybeErrorFieldNames: ['products'],
  },
];

type QuoteConfigDrawerSectionsProps = {
  initialSection: QuoteConfigSection;
};

export const QuoteConfigDrawerSections = ({
  initialSection,
}: QuoteConfigDrawerSectionsProps): JSX.Element => {
  const formikBag = useFormikContext<QuoteConfigFormikType>();

  const initialStep = steps.map((s) => s.label).indexOf(initialSection);

  const stepStateHook = useState(initialStep);
  const [, setActiveStep] = stepStateHook;

  // If the initialSection changes, we use this hook to update the state
  useEffect(() => {
    const newInitialStep = steps.map((s) => s.label).indexOf(initialSection);
    setActiveStep(newInitialStep);
  }, [initialSection]);

  return (
    <SlabStepper
      steps={steps}
      activeStepHook={stepStateHook}
      isStepDisabled={(step: SlabStep<QuoteConfigFormikType>): boolean =>
        step.label !== 'Details*' && formikBag.values.plant.id === null
      }
    />
  );
};
