import 'devextreme/dist/css/dx.light.css';

import ArrayStore from 'devextreme/data/array_store';
import { Appointment, OptionChangedEvent } from 'devextreme/ui/scheduler';
import Scheduler from 'devextreme-react/scheduler';
import { DateTime } from 'luxon';

import { Activity } from '../../generated-types/Activity/Activity';

const ActivitySchedulerViews = ['agenda', 'day', 'month', 'week'] as const;

export type ActivitySchedulerView = (typeof ActivitySchedulerViews)[number];

export type ActivitySchedulerProps = {
  date: DateTime;
  view: ActivitySchedulerView;
  activities: Activity[];
  onSelect?: (act: Activity | null) => void;
  onViewChange?: (view: ActivitySchedulerView) => void;
  onDateChange?: (date: DateTime) => void;
};

export const findActivity = (activities: Activity[], apptData: Appointment): Activity | null =>
  activities.find(
    (act) =>
      act.name === apptData.text &&
      act.startTime.toISO() === apptData.startDate &&
      act.endTime.toISO() === apptData.endDate,
  ) ?? null;

export const ActivityScheduler = ({
  date,
  view,
  activities,
  onSelect,
  onViewChange,
  onDateChange,
}: ActivitySchedulerProps): JSX.Element => {
  const appointments = new ArrayStore({
    key: 'id',
    data: activities.map(
      (act: Activity): Appointment => ({
        id: act.id,
        text: act.name,
        description: act.description ?? undefined,
        startDate: act.startTime.toISO() ?? '',
        endDate: act.endTime.toISO() ?? '',
      }),
    ),
  });

  return (
    <Scheduler
      dataSource={appointments}
      height='100%'
      allDayPanelMode='hidden'
      views={[...ActivitySchedulerViews]}
      currentView={view}
      currentDate={date.toJSDate()}
      useDropDownViewSwitcher
      onAppointmentClick={(e: any): void => {
        onSelect?.(findActivity(activities, e.appointmentData));
        e.cancel = true;
      }}
      onOptionChanged={(e: OptionChangedEvent): void => {
        if (e.name === 'currentView') {
          onViewChange?.(ActivitySchedulerViews.includes(e.value) ? e.value : 'agenda');
        } else if (e.name === 'currentDate') {
          onDateChange?.(DateTime.fromJSDate(new Date(e.value)));
        }
      }}
      editing={{
        allowAdding: false,
        allowDeleting: false,
        allowResizing: false,
        allowDragging: false,
        allowUpdating: false,
        allowTimeZoneEditing: false,
      }}
    />
  );
};
