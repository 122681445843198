import ReactPDF, { Document, Font, Page, StyleSheet, View } from '@react-pdf/renderer';
import { DateTime } from 'luxon';

import defaultHyphenation from './components/defaultHyphenation';
import { PdfColumn, PdfTable } from './components/PdfTable';
import { PdfTextBlock } from './components/PdfTextBlock';
import { RegisterFonts } from './Fonts';
import { PdfSpacer } from './templates/components/PdfSpacer';

RegisterFonts();

const styles = StyleSheet.create({
  page: {
    padding: '0.25in',
  },
});

type DataTableListPdfProps<TRow extends { id: string }> = {
  rows: TRow[];
  columns: PdfColumn<TRow>[];
  title: string;
  pageStyle?: ReactPDF.PageProps;
  /** @default false */
  disableHyphenation?: boolean;
};

export const DataTableListPdf = <TRow extends { id: string }>({
  rows,
  columns,
  title,
  pageStyle,
  disableHyphenation = false,
}: DataTableListPdfProps<TRow>): JSX.Element => {
  if (disableHyphenation) {
    Font.registerHyphenationCallback((word) => [word]);
  } else {
    Font.registerHyphenationCallback(defaultHyphenation());
  }
  const ps = [pageStyle?.style ?? {}].flat();

  return (
    <Document title={title}>
      <Page style={[styles.page, ...ps]}>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <PdfTextBlock content={title} textStyle={{ fontSize: '18pt' }} />
          <PdfTextBlock content={DateTime.now().toISODate()} textStyle={{ fontSize: '18pt' }} />
        </View>
        <PdfSpacer height='0.25in' />
        <PdfTable columns={columns} rows={rows} displayHeaders striped bordered />
      </Page>
    </Document>
  );
};
