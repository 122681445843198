import { useState } from 'react';

import { Toast, ToastProps } from './Toast';

export type ToastSeverity = 'success' | 'error';

type ToastSubProps = Pick<ToastProps, 'body' | 'header'> & {
  severity: ToastSeverity;
};

export type ToastHook = {
  showToast: (type: ToastSeverity, message?: string) => void;
  toast: JSX.Element;
};

/**
 * useToast is a hook that returns an object with two properties:
 * - showToast: a function that displays the toast, with an optional message
 * - toast: the toast component to render
 *
 * @param resourceTypeName an optional string used to populate default success and
 *        error messages
 */
export const useToast = (resourceTypeName?: string): ToastHook => {
  const [toastProps, setToastProps] = useState<ToastSubProps | null>(null);
  const [showToast, setShowToast] = useState(false);

  const defaultSuccess =
    resourceTypeName !== undefined
      ? `Saved the ${resourceTypeName} successfully`
      : 'The requested operation was successful';
  const defaultFailure =
    resourceTypeName !== undefined
      ? `Failed to save the ${resourceTypeName}`
      : 'The requested operation failed';

  return {
    showToast(type: ToastSeverity, message?: string): void {
      switch (type) {
        case 'success':
          setShowToast(true);
          setToastProps({
            header: 'Success',
            severity: 'success',
            body: message ?? defaultSuccess,
          });
          break;
        case 'error':
        default:
          setShowToast(true);
          setToastProps({
            header: 'Error',
            severity: 'error',
            body: message ?? defaultFailure,
          });
          break;
      }
    },
    toast: (
      <Toast
        header={toastProps?.header ?? ''}
        severity={toastProps?.severity}
        body={toastProps?.body ?? ''}
        setShow={setShowToast}
        show={showToast}
      />
    ),
  };
};
