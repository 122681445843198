import { Query, QueryKey } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import { useState } from 'react';

import { useDebouncedEffect } from '../../hooks/useDebouncedEffect';
import { useSlabQuery, UseSlabQueryType } from '../../hooks/useSlabQuery';
import { QueryRouteBarrelTypes } from '../../utils/ApiClient';
import { QueryError } from '../../utils/Query';
import { NestedKeyOf } from '../../utils/Types';
import { YupSchemaNullableReferenceType } from '../../utils/YupHelpers';
import { ApiLookupInputBase, ErrorOption, LoadingOption } from './ApiLookupInputBase';
import {
  AlertDupeOptions,
  ApiLookupInputBaseProps,
  LookupInputOption,
} from './LookupInputSharedComponents';

type InputLookupProps<
  QKey extends keyof QueryRouteBarrelTypes,
  QBarrel extends QueryRouteBarrelTypes[QKey],
  QOptions extends UseSlabQueryType<QKey, QBarrel, QueryError>['options'],
> = {
  route: {
    barrel: QKey;
    args: (arg0: string) => QBarrel['args'];
    options?: QOptions;
    transform: (d: QBarrel['returns']) => LookupInputOption[];
  };
  onMatchChange?: (arg0: string | null, d: QBarrel['returns'] | undefined) => void;
};

/**
 * @example
 * <ApiLookupInput
 *   formState={formikBag.values}
 *   name='company.id'
 *   route={{
 *     barrel: 'GET companies',
 *     args: () => ({}),
 *     transform: (companyList): LookupInputOption[] => {
 *       return lookups({
 *         items: companyList.companies,
 *         label: (c) => c.name,
 *         count: companyList.count,
 *       });
 *     },
 *   }}
 *   label='Company name*'
 * />
 */
export const ApiLookupInput = <
  FState extends Record<string, any>,
  FPath extends NestedKeyOf<FState>,
  QKey extends keyof QueryRouteBarrelTypes,
  QBarrel extends QueryRouteBarrelTypes[QKey],
  QOptions extends UseSlabQueryType<QKey, QBarrel, QueryError>['options'],
>({
  route,
  onMatchChange = undefined,
  ...props
}: InputLookupProps<QKey, QBarrel, QOptions> &
  Omit<
    ApiLookupInputBaseProps<FState, FPath>,
    'options' | 'onMatchChange' | 'onInputChange' | 'localFiltering'
  >): JSX.Element => {
  const { getFieldMeta } = useFormikContext();

  const formOption = (getFieldMeta(props.name).value as YupSchemaNullableReferenceType)?.option;

  const [lookupText, setLookupText] = useState('');
  const [debouncedLookupText, setDebouncedLookupText] = useState('');

  useDebouncedEffect(
    () => {
      // Only set debounced "search" values when a formOption does not exist.
      // By doing this, if a user selects a value, then clears a value, an excessive "debounced"
      // API request with the previously selected value does not occur.
      if (formOption === undefined || formOption === null) {
        setDebouncedLookupText(lookupText);
      }
    },
    [lookupText, formOption],
    200,
  );

  const routeArgs = route.args(debouncedLookupText);

  const routeEnabled = route.options?.enabled;
  const formOptionExists = formOption !== undefined && formOption !== null;
  // If `option` is defined, do NOT make an API request.
  const enabled =
    typeof routeEnabled === 'function'
      ? (query: Query<QBarrel['returns'], QueryError, QBarrel['returns'], QueryKey>): boolean =>
          routeEnabled(query) && !formOptionExists
      : (routeEnabled ?? true) && !formOptionExists;

  const { isLoading, isError, data } = useSlabQuery(route.barrel, routeArgs, {
    // refetchInterval must be explicit for type compilation when spreading.
    refetchInterval: route.options?.refetchInterval,
    ...route.options,
    enabled,
  });

  const options = ((): LookupInputOption[] => {
    if (formOption !== undefined && formOption !== null) {
      return [formOption];
    }
    if (isLoading) {
      return [LoadingOption];
    }
    if (isError) {
      return [ErrorOption];
    }
    // If there is no data, it is assumed that transform will not append options.
    if (data === undefined) {
      return [];
    }
    const opts = route.transform(data);
    AlertDupeOptions(opts);

    return opts;
  })();

  const baseOnMatchChange =
    onMatchChange === undefined
      ? undefined
      : (arg0: string | null): void => onMatchChange?.(arg0, data);

  const hasQueryParams = Object.keys(routeArgs).find((k) => k === 'queryParams') !== undefined;
  return (
    <ApiLookupInputBase
      {...props}
      options={options}
      onMatchChange={baseOnMatchChange}
      onInputChange={(input): void => setLookupText(input)}
      localFiltering={!hasQueryParams}
    />
  );
};
