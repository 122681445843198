// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';

export class ForecastPlantAgg {
  readonly plantId: string;
  readonly plantName: string;
  readonly totalProjectCuyd: string;

  constructor(data: { [key: string]: any } = {}) {
    this.plantId = data.plantId === undefined ? NIL_UUID : data.plantId;
    this.plantName = data.plantName === undefined ? '' : data.plantName;
    this.totalProjectCuyd = data.totalProjectCuyd === undefined ? '0' : data.totalProjectCuyd;
  }

  static zero(): ForecastPlantAgg {
    const zeroValues: ExcludeMethodsDeep<ForecastPlantAgg> = {
      plantId: NIL_UUID,
      plantName: '',
      totalProjectCuyd: '0',
    };
    return new ForecastPlantAgg(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewForecastPlantAgg = (
  props: PartialDeep<ForecastPlantAgg, { recurseIntoArrays: true }>,
): ForecastPlantAgg => new ForecastPlantAgg(props);

export const NewForecastPlantAggFromDomainObject = (
  props: PartialDeep<DomainObject<ForecastPlantAgg>, { recurseIntoArrays: true }>,
): ForecastPlantAgg => new ForecastPlantAgg(props);
