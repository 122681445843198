// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NewProduct, Product } from '../Product/Product';

export class ProjectConfigProduct {
  readonly product: Product;
  readonly quantity: number;
  readonly usage: string | null;

  constructor(data: { [key: string]: any } = {}) {
    this.product = NewProduct(data.product);
    this.quantity = data.quantity === undefined ? 0 : data.quantity;
    this.usage = data.usage === undefined ? null : data.usage;
  }

  static zero(): ProjectConfigProduct {
    const zeroValues: ExcludeMethodsDeep<ProjectConfigProduct> = {
      product: Product.zero(),
      quantity: 0,
      usage: null,
    };
    return new ProjectConfigProduct(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************
  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewProjectConfigProduct = (
  props: PartialDeep<ProjectConfigProduct, { recurseIntoArrays: true }>,
): ProjectConfigProduct => new ProjectConfigProduct(props);

export const NewProjectConfigProductFromDomainObject = (
  props: PartialDeep<DomainObject<ProjectConfigProduct>, { recurseIntoArrays: true }>,
): ProjectConfigProduct => new ProjectConfigProduct(props);
