import { Text, View } from '@react-pdf/renderer';

import { Quote } from '../../../../generated-types/Quote/Quote';
import { randomUUID } from '../../../../utils/UUID';
import { PdfHeader } from '../../../components/PdfHeader';
import { PdfImageProps } from '../../../components/PdfImage';
import {
  PdfColumnConfig,
  PdfColumnFromConfig,
  PdfTable,
  Styles,
} from '../../../components/PdfTable';

const formatLeftValueForPdf = (value: string): JSX.Element => (
  <View style={{ textAlign: 'left', paddingRight: '8pt' }}>
    <Text style={[Styles.tableCell, { padding: '0' }]}>{value}</Text>
  </View>
);

const formatRightValueForPdf = (value: string): JSX.Element => (
  <View style={{ textAlign: 'right', paddingLeft: '8pt' }}>
    <Text style={[Styles.tableCell, { padding: '0' }]}>{value}</Text>
  </View>
);

const formatElementForPdf = (value: JSX.Element): JSX.Element => value;

type InfoRow = {
  id: string;
  leftValue: string | null;
  rightValue: string | null;
};

type HeaderRow = {
  id: string;
  leftValue: JSX.Element;
  rightValue: JSX.Element;
};

type PdfHeaderTableProps = {
  quote: Quote;
  logo: PdfImageProps;
  infoTableProps?: {
    leftValue?: Pick<PdfColumnConfig<HeaderRow>, 'widthOverride'>;
    rightValue?: Pick<PdfColumnConfig<HeaderRow>, 'widthOverride'>;
  };
};

export const PdfHeaderTable = ({
  quote,
  logo,
  infoTableProps,
}: PdfHeaderTableProps): JSX.Element => {
  const logoImg = (
    <View style={{ alignItems: 'center' }}>
      <PdfHeader image={logo} />
    </View>
  );

  const quoteCompanyAddressEmpty = (quote.company?.address?.basicDisplay() ?? '') === '';
  const infoRows: InfoRow[] = [
    {
      id: randomUUID(),
      leftValue: quote.company !== null ? `Customer: ${quote.company.name}` : '',
      rightValue: `Project: ${quote.project.name}`,
    },
    {
      id: randomUUID(),
      leftValue: quoteCompanyAddressEmpty
        ? ''
        : `Address: ${quote.company?.address?.basicDisplay()}`,
      rightValue: `Location: ${quote.project.address.basicDisplay()}`,
    },
    {
      id: randomUUID(),
      leftValue: ((): string | null => {
        const secondaryAddress =
          quote.company === null ? null : quote.company.address.basicStateDisplay();
        return quoteCompanyAddressEmpty && secondaryAddress !== null
          ? `Address: ${secondaryAddress}`
          : secondaryAddress;
      })(),
      rightValue: quote.project.address.basicStateDisplay(),
    },
    {
      id: randomUUID(),
      leftValue: [quote.contact?.fullName(), quote.contact?.phoneNumber]
        .filter((s) => s !== undefined)
        .join(' '),
      rightValue: null,
    },
    {
      id: randomUUID(),
      leftValue: null,
      rightValue: quote.creationDate === null ? null : quote.creationDateDisplay(),
    },
    {
      id: randomUUID(),
      leftValue: null,
      rightValue: quote.revisionDate === null ? null : `Revised ${quote.revisionDateDisplay()}`,
    },
  ];

  const infoCols: PdfColumnConfig<InfoRow>[] = [
    {
      id: 'leftValue',
      label: '',
      type: 'string',
      isDisplayed: false,
      formatValueForPdf: formatLeftValueForPdf,
    },
    {
      id: 'rightValue',
      label: '',
      type: 'string',
      isDisplayed: false,
      formatValueForPdf: formatRightValueForPdf,
    },
  ];

  const infoTable = (
    <PdfTable
      columns={infoCols.map((col) => PdfColumnFromConfig(col))}
      rows={infoRows}
      cellPadding='tight'
    />
  );

  const headerRows: HeaderRow[] = [
    {
      id: randomUUID(),
      leftValue: logoImg,
      rightValue: infoTable,
    },
  ];

  const headerCols: PdfColumnConfig<HeaderRow>[] = [
    {
      id: 'leftValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: formatElementForPdf as (value: JSX.Element | string | null) => JSX.Element,
      ...infoTableProps?.leftValue,
    },
    {
      id: 'rightValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: formatElementForPdf as (value: JSX.Element | string | null) => JSX.Element,
      ...infoTableProps?.rightValue,
    },
  ];

  return (
    <PdfTable
      columns={headerCols.map((col) => PdfColumnFromConfig(col))}
      rows={headerRows}
      cellPadding='tight'
    />
  );
};
