import { Quote } from '../../../generated-types/Quote/Quote';
import { PdfTextBlock, PdfTextBlockProps } from '../../components/PdfTextBlock';

type PdfNotesProps = {
  quote: Quote;
  /** @default 12 */
  fontSize?: number;
  textBlockProps?: Omit<PdfTextBlockProps, 'content'>;
};

export const PdfNotes = ({ quote, fontSize = 12, textBlockProps }: PdfNotesProps): JSX.Element => (
  <PdfTextBlock
    content={quote.notes ?? ' '}
    textStyle={{
      fontSize,
    }}
    {...textBlockProps}
  />
);
