import ReactPDF, { Document, Page, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { DateTime } from 'luxon';

import Enums from '../../../../../generated-types/Enums';
import { Quote } from '../../../../../generated-types/Quote/Quote';
import { PdfTextBlock } from '../../../../components/PdfTextBlock';
import { PdfPriceEscalationTable } from '../../../components/PdfPriceEscalationTable';
import { PdfProductsTable } from '../../../components/PdfProductsTable';
import { PdfSpacer } from '../../../components/PdfSpacer';
import { CityTransitMixPdfHeaderTable } from '../../components/PdfHeaderTable';

const pageStyle: ReactPDF.PageProps['style'] = {
  padding: '0.5in',
};

const headerViewStyle: Style = {
  textAlign: 'center',
  fontFamily: 'InknutAntiqua',
  fontStyle: 'bold',
};
const headerTextStyle: Style = {
  fontFamily: 'InknutAntiqua',
  fontSize: '10px',
};

const rowViewStyle: Style = {
  textAlign: 'center',
};
const rowTextStyle: Style = {
  fontFamily: 'OpenSans',
  fontSize: '10px',
};

type CityTransitMixDefaultInitialSectionProps = {
  quote: Quote;
};

export const CityTransitMixDefaultInitialSection = ({
  quote,
}: CityTransitMixDefaultInitialSectionProps): JSX.Element => (
  <Document title={quote.quoteNumber}>
    <Page style={pageStyle}>
      <PdfTextBlock
        content='City Transit Mix, Inc.'
        textStyle={{
          fontSize: '18px',
          fontFamily: 'DancingScript',
          fontStyle: 'bold',
          color: '#2f5496',
        }}
      />
      <PdfSpacer height='0.25in' />
      <PdfTextBlock
        content={DateTime.now().toFormat('LLL dd, yyyy')}
        textStyle={{
          fontFamily: 'DancingScript',
          fontSize: '12px',
        }}
      />
      <PdfSpacer height='0.1in' />
      <PdfTextBlock
        content={quote.project.plant.address.basicDisplay()}
        textStyle={{
          fontFamily: 'DancingScript',
          fontSize: '12px',
        }}
      />
      <PdfTextBlock
        content={quote.project.plant.address.basicStateDisplay()}
        textStyle={{
          fontFamily: 'DancingScript',
          fontSize: '12px',
        }}
      />
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <PdfTextBlock
          content='Phone# 718-523-1488'
          textStyle={{
            fontSize: '11px',
            fontFamily: 'OpenSans',
            fontStyle: 'bold',
            color: '#305397',
          }}
        />
        <a href='mailto:info@citytransitmix.com' aria-label='info@citytransitmix.com'>
          <PdfTextBlock
            content='info@citytransitmix.com'
            textStyle={{
              fontSize: '11px',
              fontFamily: 'OpenSans',
              fontStyle: 'bold',
              color: '#0563c0',
              textDecoration: 'underline',
            }}
          />
        </a>
      </View>
      <PdfSpacer height='0.1in' />
      <CityTransitMixPdfHeaderTable quote={quote} />
      <PdfSpacer height='0.25in' />
      <PdfTextBlock
        content='Ready Mixed Concrete Pricing'
        textStyle={{
          fontFamily: 'InknutAntiqua',
          fontSize: '12px',
        }}
      />
      <PdfSpacer height='0.1in' />
      <PdfProductsTable
        products={quote.filterProducts(Enums.QuoteProductKind.Primary)}
        columnConfigs={[
          {
            id: 'name',
            label: 'Mix Design Strength',
            type: 'string',
            headerTextStyle,
            headerViewStyle,
            rowTextStyle,
            rowViewStyle,
            width: '50%',
          },
          {
            id: 'measurementUnit',
            label: 'Unit',
            type: 'string',
            headerTextStyle,
            headerViewStyle,
            rowTextStyle,
            rowViewStyle,
            width: '25%',
          },
          {
            id: 'price',
            label: 'Price',
            type: 'Currency',
            headerTextStyle,
            headerViewStyle,
            rowTextStyle,
            rowViewStyle,
            width: '35%',
          },
        ]}
        displayHeaders
        bordered
      />
      <PdfSpacer height='0.5in' />
      <PdfTextBlock
        content='Extra Products - Additional Charges'
        textStyle={{
          fontFamily: 'InknutAntiqua',
          fontSize: '12px',
        }}
      />
      <PdfSpacer height='0.1in' />
      <PdfProductsTable
        products={quote.filterProducts(Enums.QuoteProductKind.Additional)}
        columnConfigs={[
          {
            id: 'name',
            label: 'Description',
            type: 'string',
            headerTextStyle,
            headerViewStyle,
            rowTextStyle,
            rowViewStyle,
            width: '50%',
          },
          {
            id: 'price',
            label: 'Price',
            type: 'Currency',
            headerTextStyle,
            headerViewStyle,
            rowTextStyle,
            rowViewStyle,
            width: '25%',
          },
          {
            id: 'measurementUnit',
            label: 'Unit Price',
            type: 'string',
            headerTextStyle,
            headerViewStyle,
            rowTextStyle,
            rowViewStyle,
            width: '25%',
          },
        ]}
        displayHeaders
        bordered
      />
      {quote.priceEscalations.length === 0 ? null : (
        <View>
          <PdfSpacer height='0.25in' />
          <PdfTextBlock
            content='Price Escalations'
            textStyle={{
              fontFamily: 'InknutAntiqua',
              fontSize: '12px',
            }}
          />
          <PdfSpacer height='0.1in' />
          <PdfPriceEscalationTable
            quotePriceEscalations={quote.priceEscalations}
            columnConfigs={[
              {
                id: 'escalationDate',
                label: 'Escalation Date',
                headerTextStyle,
                headerViewStyle,
                rowViewStyle,
                rowTextStyle,
              },
              {
                id: 'amount',
                label: 'Amount',
                headerTextStyle,
                headerViewStyle,
                rowViewStyle,
                rowTextStyle,
              },
            ]}
            displayHeaders
            bordered
          />
        </View>
      )}
    </Page>
  </Document>
);
