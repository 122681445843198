import { Box, Typography } from '@mui/material';

import { FormatLinkCell } from '../../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../../components/DataTable/DataTable';
import { ColumnConfig } from '../../../components/DataTable/TableDataModel';
import {
  ApiTableDataWithoutAggregationsParams,
  useApiTableDataWithoutAggregations,
} from '../../../components/DataTable/useApiTableData';
import Enums from '../../../generated-types/Enums';
import { Product } from '../../../generated-types/Product/Product';
import { ProductSummary } from '../../../generated-types/ProductSummary/ProductSummary';
import { QueryRouteBarrelTypes } from '../../../utils/ApiClient';
import { NestedKeyOf } from '../../../utils/Types';

const buildTableDataModelConfig = ({
  product,
}: {
  product: Product;
}): ApiTableDataWithoutAggregationsParams<
  ProductSummary,
  'GET product summaries',
  QueryRouteBarrelTypes['GET product summaries']
> => {
  const columns: ColumnConfig<ProductSummary, NestedKeyOf<ProductSummary>>[] = [
    {
      id: 'alternateId',
      label: 'Product ID',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'name',
      label: 'Product',
      type: 'string',
      isDisplayed: true,
      formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/products/${row.id}` }),
    },
    {
      id: 'category',
      label: 'Product type',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'pricePerUnit',
      label: 'Price',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'plantName',
      label: 'Plant',
      type: 'string',
      isDisplayed: true,
    },
    {
      id: 'costPerUnit',
      label: 'Cost',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'actualMargin',
      label: 'Actual margin',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'targetMargin',
      label: 'Target margin',
      type: 'string',
      isDisplayed: false,
    },
    {
      id: 'suggestedPrice',
      label: 'Suggested price',
      type: 'string',
      isDisplayed: false,
    },
  ];

  return {
    rowsRouteKey: 'GET product summaries',
    extractRows: (list) => list.items,
    initialSortBy: {
      name: 'plantName',
      direction: Enums.SortDirection.Ascending,
    },
    initialFilterBy: [
      { operation: Enums.FilterOperation.NotEquals, name: 'id', value: product.id },
      { operation: Enums.FilterOperation.Equals, name: 'name', value: product.name },
    ],
    makeColumnConfigs: () => columns,
  };
};

type CompanyTableProps = {
  product: Product;
  onEditPress: (value: ProductSummary) => void;
};

export const SimilarProductTable = ({ product, onEditPress }: CompanyTableProps): JSX.Element => {
  const tableModel = useApiTableDataWithoutAggregations(buildTableDataModelConfig({ product }));

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' textAlign='center' paddingBottom='1.25rem'>
        <Box alignSelf='center'>
          <Typography variant='h4'>Similar Products</Typography>
        </Box>
      </Box>

      <Box height='400px'>
        <DataTable tableModel={tableModel} onEditPress={onEditPress} />
      </Box>
    </Box>
  );
};
