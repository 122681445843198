// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import Enums from '../Enums';

export class ProjectForecastSummary {
  readonly id: string;
  readonly year: string;
  readonly unit: Enums.Unit;
  readonly januaryValue: string;
  readonly februaryValue: string;
  readonly marchValue: string;
  readonly aprilValue: string;
  readonly mayValue: string;
  readonly juneValue: string;
  readonly julyValue: string;
  readonly augustValue: string;
  readonly septemberValue: string;
  readonly octoberValue: string;
  readonly novemberValue: string;
  readonly decemberValue: string;
  readonly projectId: string;
  readonly projectName: string;
  readonly projectStatusId: string;
  readonly projectStatusName: string;
  readonly plantName: string;
  readonly plantId: string;
  readonly marketId: string;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? '' : data.id;
    this.year = data.year === undefined ? '' : data.year;
    this.unit = data.unit === undefined ? Enums.Unit.Ac : data.unit;
    this.januaryValue = data.januaryValue === undefined ? '0' : data.januaryValue;
    this.februaryValue = data.februaryValue === undefined ? '0' : data.februaryValue;
    this.marchValue = data.marchValue === undefined ? '0' : data.marchValue;
    this.aprilValue = data.aprilValue === undefined ? '0' : data.aprilValue;
    this.mayValue = data.mayValue === undefined ? '0' : data.mayValue;
    this.juneValue = data.juneValue === undefined ? '0' : data.juneValue;
    this.julyValue = data.julyValue === undefined ? '0' : data.julyValue;
    this.augustValue = data.augustValue === undefined ? '0' : data.augustValue;
    this.septemberValue = data.septemberValue === undefined ? '0' : data.septemberValue;
    this.octoberValue = data.octoberValue === undefined ? '0' : data.octoberValue;
    this.novemberValue = data.novemberValue === undefined ? '0' : data.novemberValue;
    this.decemberValue = data.decemberValue === undefined ? '0' : data.decemberValue;
    this.projectId = data.projectId === undefined ? NIL_UUID : data.projectId;
    this.projectName = data.projectName === undefined ? '' : data.projectName;
    this.projectStatusId = data.projectStatusId === undefined ? NIL_UUID : data.projectStatusId;
    this.projectStatusName = data.projectStatusName === undefined ? '' : data.projectStatusName;
    this.plantName = data.plantName === undefined ? '' : data.plantName;
    this.plantId = data.plantId === undefined ? NIL_UUID : data.plantId;
    this.marketId = data.marketId === undefined ? NIL_UUID : data.marketId;
  }

  static zero(): ProjectForecastSummary {
    const zeroValues: ExcludeMethodsDeep<ProjectForecastSummary> = {
      id: '',
      year: '',
      unit: Enums.Unit.Ac,
      januaryValue: '0',
      februaryValue: '0',
      marchValue: '0',
      aprilValue: '0',
      mayValue: '0',
      juneValue: '0',
      julyValue: '0',
      augustValue: '0',
      septemberValue: '0',
      octoberValue: '0',
      novemberValue: '0',
      decemberValue: '0',
      projectId: NIL_UUID,
      projectName: '',
      projectStatusId: NIL_UUID,
      projectStatusName: '',
      plantName: '',
      plantId: NIL_UUID,
      marketId: NIL_UUID,
    };
    return new ProjectForecastSummary(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewProjectForecastSummary = (
  props: PartialDeep<ProjectForecastSummary, { recurseIntoArrays: true }>,
): ProjectForecastSummary => new ProjectForecastSummary(props);

export const NewProjectForecastSummaryFromDomainObject = (
  props: PartialDeep<DomainObject<ProjectForecastSummary>, { recurseIntoArrays: true }>,
): ProjectForecastSummary => new ProjectForecastSummary(props);
