import ReactPDF from '@react-pdf/renderer';
import { Buffer } from 'buffer';
import LogRocket from 'logrocket';
import { PDFDocument, PDFPage } from 'pdf-lib';

import { NewPdfPart, PdfPart } from '../generated-types/PdfPart/PdfPart';
import { PdfTemplate } from '../generated-types/PdfTemplate/PdfTemplate';
import { Quote } from '../generated-types/Quote/Quote';
import { Base64, uint8ArrayToBase64 } from '../types/Base64';
import { TemplateByName } from './TemplateByName';

/**
 * partToPdfBytes renders a PdfPart to an ArrayBuffer of binary data containing
 * a PDF document, whose content consists of the part's template rendered with
 * data from the specified quote.
 */
const partToPdfBytes = async (pdfTemplate: PdfPart, quote: Quote): Promise<ArrayBuffer> => {
  // Render the dynamic content from the template, if available.
  if (pdfTemplate.template != null) {
    const blob = await ReactPDF.pdf(TemplateByName({ name: pdfTemplate.template, quote })).toBlob();
    return blob.arrayBuffer();
  }
  // Otherwise, use the staticContent.
  if (pdfTemplate.staticContent.length > 0) {
    return Buffer.from(pdfTemplate.staticContent, 'base64');
  }

  LogRocket.error('pdfTemplate part has neither template nor staticContent');
  return Buffer.from([]);
};

/**
 * Given a quote and a template of type 'code', this will generate a PDF and
 * return it as a base64-encoded string. If no pdfParts are provided, it will
 * render a single part from the pdfTemplate's template value.
 */
export const buildPdf = async (quote: Quote, pdfTemplate: PdfTemplate): Promise<Base64> => {
  const pdfDoc = await PDFDocument.create();

  const parts = [...pdfTemplate.parts];
  if (parts.length === 0) {
    parts.push(NewPdfPart(pdfTemplate));
  }

  const pageArrays: PDFPage[][] = await Promise.all(
    parts.map(async (part): Promise<PDFPage[]> => {
      const pdfBytes = await partToPdfBytes(part, quote);
      const tempPdfDoc = await PDFDocument.load(pdfBytes);
      // Split the tempPdfDoc into individual pages.
      return pdfDoc.copyPages(tempPdfDoc, tempPdfDoc.getPageIndices());
    }),
  );
  pageArrays.flat().forEach((page) => pdfDoc.addPage(page));
  return uint8ArrayToBase64(await pdfDoc.save());
};
