// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { Base64 } from '../../types/Base64';
import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class GeneratedPDF {
  readonly pdf: Base64;

  constructor(data: { [key: string]: any } = {}) {
    this.pdf = data.pdf === undefined ? '' : data.pdf;
  }

  static zero(): GeneratedPDF {
    const zeroValues: ExcludeMethodsDeep<GeneratedPDF> = {
      pdf: '',
    };
    return new GeneratedPDF(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewGeneratedPDF = (
  props: PartialDeep<GeneratedPDF, { recurseIntoArrays: true }>,
): GeneratedPDF => new GeneratedPDF(props);

export const NewGeneratedPDFFromDomainObject = (
  props: PartialDeep<DomainObject<GeneratedPDF>, { recurseIntoArrays: true }>,
): GeneratedPDF => new GeneratedPDF(props);
