import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { Field, FieldAttributes } from 'formik';

import { randomUUID } from '../../utils/UUID';
import { InputTooltip } from '../InputTooltip/InputTooltip';

export type InputOption = {
  /** The display label in the dropdown */
  label: string;
  /** The value that formik will use when setting values */
  value: any;
};

type InputDropdownProps = {
  label?: string;
  name: string;
  options: InputOption[];
  disabled?: boolean;

  /** Optional function to do something after select */
  onSelect?: (arg0: any) => Promise<void>;
  /** Adding a tip will wrap the input component in a hoverable tooltip */
  tip?: string;
};

/**
 * A Slabstack formik-friendly dropdown.
 *
 * Must be used inside `<Formik><Form>{component}</Form></Formik>`.
 *
 * @example
 * <Formik {your_values_here} >
 * {(formikBag): JSX.Element => (
 *   <Form>
 *     <InputDropdown
 *       label='Company name'
 *       name='company.id'
 *       options={companyOpts}
 *     />
 *   </Form>
 * </Formik>
 */
export const InputDropdown = ({
  label,
  name,
  options,
  disabled,
  onSelect,
  tip,
}: InputDropdownProps): JSX.Element => (
  <Field id={`${name}-field`} name={name}>
    {({ field: { value }, form: { handleChange }, meta }: FieldAttributes<any>): JSX.Element => {
      const hasError: boolean = meta.touched === true && meta.error !== undefined;
      const labelWithTip =
        label === undefined ? null : (
          <Box display='flex' gap='0.25rem' alignItems='center'>
            {label}
            {tip !== undefined ? <InputTooltip tip={tip} /> : null}
          </Box>
        );

      return (
        <FormControl fullWidth error={hasError}>
          {label !== undefined && (
            <InputLabel id={`${name}-label`} htmlFor={name}>
              {labelWithTip}
            </InputLabel>
          )}
          <Select
            labelId={label === undefined ? undefined : `${name}-label`}
            id={name}
            value={value ?? ''}
            name={name}
            label={label}
            disabled={disabled}
            onChange={async (e): Promise<void> => {
              await handleChange(e);

              await onSelect?.(e.target.value);
            }}
            // Close outlined box + fix height if label is undefined
            sx={
              label === undefined
                ? {
                    fieldset: {
                      top: 0,
                    },
                    legend: {
                      display: 'none',
                    },
                  }
                : {}
            }
          >
            {options.map(
              (opt): JSX.Element => (
                <MenuItem key={randomUUID()} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ),
            )}
          </Select>
          <FormHelperText id={`${name}-helper-text`}>{hasError ? meta.error : ' '}</FormHelperText>
        </FormControl>
      );
    }}
  </Field>
);
