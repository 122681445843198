import { Button, Step, StepContent, StepLabel, Stepper, Typography, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';

const sectionHasErrors = <FormikType,>(fieldNames: (keyof FormikType & string)[]): boolean => {
  const formikCtx = useFormikContext<FormikType>();
  const errorKeys = Object.keys(formikCtx.errors);
  const touchedKeys = Object.keys(formikCtx.touched);
  // fieldNames
  const foundErrorKeys = fieldNames.filter(
    (fn) => errorKeys.includes(fn) && touchedKeys.includes(fn),
  );

  return foundErrorKeys.length > 0;
};

export type SlabStep<FormikType> = {
  label: string;
  content: (...input: any[]) => JSX.Element;
  maybeErrorFieldNames: (keyof FormikType & string)[];
};

type SlabStepperProps<FormikType> = {
  steps: SlabStep<FormikType>[];
  activeStepHook: [number, React.Dispatch<React.SetStateAction<number>>];
  isStepDisabled?: (step: SlabStep<FormikType>) => boolean;
};

export const SlabStepper = <FormikType,>({
  steps,
  activeStepHook,
  isStepDisabled = (): boolean => false,
}: SlabStepperProps<FormikType>): JSX.Element => {
  const theme = useTheme();

  const [activeStep, setActiveStep] = activeStepHook;

  return (
    <Stepper nonLinear activeStep={activeStep} orientation='vertical'>
      {steps.map((step, idx) => (
        <Step key={step.label}>
          <StepLabel>
            <Button
              onClick={(): void => setActiveStep(idx)}
              disabled={isStepDisabled(step)}
              sx={{
                color: sectionHasErrors(step.maybeErrorFieldNames)
                  ? theme.palette.error.main
                  : 'inherit',
                // Avoid capitalization.
                textTransform: 'none',
              }}
            >
              <Typography variant='h2'>{step.label}</Typography>
            </Button>
          </StepLabel>
          <StepContent>{step.content()}</StepContent>
        </Step>
      ))}
    </Stepper>
  );
};
