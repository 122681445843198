import { Wrapper } from '@googlemaps/react-wrapper';
import { Box, Typography } from '@mui/material';

import { SlabConfig } from '../../utils/SlabConfig';
import { Map, MAP_ZOOM_FAR, MAP_ZOOM_MID, MapRenderer, Marker } from './Map';

export const PlantMap = ({
  location,
}: {
  location: google.maps.LatLngLiteral | undefined;
}): JSX.Element => (
  <Box
    sx={{
      '> div': {
        height: '65vh',
        margin: '1rem 0 1rem 0',
      },
    }}
  >
    {SlabConfig.googleMaps.id !== undefined ? (
      <Wrapper apiKey={SlabConfig.googleMaps.id} render={MapRenderer} libraries={['places']}>
        <Map center={location} zoom={location === undefined ? MAP_ZOOM_FAR : MAP_ZOOM_MID}>
          <Marker key={`${location?.lat ?? 0},${location?.lng ?? 0}`} location={location} />
        </Map>
      </Wrapper>
    ) : (
      <Typography color='red'>Map cannot load because the API key was not found</Typography>
    )}
  </Box>
);
