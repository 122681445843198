// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { DateTime } from 'luxon';
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import * as DateHelpers from '../../utils/DateHelpers';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { Currency, NewCurrency } from '../Currency/Currency';
import Enums from '../Enums';

export class QuotePriceEscalation {
  readonly escalationDate: DateTime;
  readonly kind: Enums.EscalationKind;
  readonly changeRatio: string | null;
  readonly flatChange: Currency | null;

  constructor(data: { [key: string]: any } = {}) {
    this.escalationDate = DateHelpers.ParseServerDate(data.escalationDate);
    this.kind = data.kind === undefined ? Enums.EscalationKind.Percentage : data.kind;
    this.changeRatio = data.changeRatio === undefined ? null : data.changeRatio;
    this.flatChange = (data.flatChange ?? null) === null ? null : NewCurrency(data.flatChange);
  }

  static zero(): QuotePriceEscalation {
    const zeroValues: ExcludeMethodsDeep<QuotePriceEscalation> = {
      escalationDate: DateHelpers.ParseServerDate(DateHelpers.ZERO_DATE_STRING),
      kind: Enums.EscalationKind.Percentage,
      changeRatio: null,
      flatChange: null,
    };
    return new QuotePriceEscalation(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewQuotePriceEscalation = (
  props: PartialDeep<QuotePriceEscalation, { recurseIntoArrays: true }>,
): QuotePriceEscalation => new QuotePriceEscalation(props);

export const NewQuotePriceEscalationFromDomainObject = (
  props: PartialDeep<DomainObject<QuotePriceEscalation>, { recurseIntoArrays: true }>,
): QuotePriceEscalation => new QuotePriceEscalation(props);
