import { Auth0Provider } from '@auth0/auth0-react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import Dinero from 'dinero.js';
import LogRocket from 'logrocket';
import { Settings } from 'luxon';
import React from 'react';

import { SlabQueryClient } from './QueryClient';
import { SlabRouter } from './Router';
import { SlabAuthProvider } from './SlabAuthProvider';
import { SlabTheme } from './theme';
import { SlabConfig } from './utils/SlabConfig';

declare global {
  interface Window {
    Intercom: any;
  }
}

// Set default Luxon timezone to system time. The backend works exclusively in
// UTC; Yup helpers and generated domain constructors convert back and forth
// between UTC and system time as needed.
Settings.defaultZone = 'system';

// Set default Dinero settings
Dinero.globalLocale = 'en-US';
Dinero.defaultCurrency = 'USD';
Dinero.defaultPrecision = 2;

if (SlabConfig.logRocket.appID !== undefined) {
  LogRocket.init(SlabConfig.logRocket.appID, {
    release: SlabConfig.build,
  });
}

export const App = (): JSX.Element => {
  if (SlabConfig.auth0.domain === undefined || SlabConfig.auth0.clientID === undefined) {
    // TODO: #288 Replace with error page
    return <div>An unknown Slabstack error occurred</div>;
  }

  return (
    <Auth0Provider
      domain={SlabConfig.auth0.domain}
      clientId={SlabConfig.auth0.clientID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <QueryClientProvider client={SlabQueryClient}>
        <ThemeProvider theme={SlabTheme}>
          <CssBaseline />
          <SlabAuthProvider>
            <SlabRouter />
          </SlabAuthProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </Auth0Provider>
  );
};
