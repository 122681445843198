import { Auth0ContextInterface, Auth0User, useAuth0, User } from '@auth0/auth0-react';
import LogRocket from 'logrocket';
import { useCookies } from 'react-cookie';

import Enums from '../generated-types/Enums';
import { SlabConfig } from '../utils/SlabConfig';

/**
 * UpdateActiveAuth0User is a helper function that takes an Auth0 user,
 * a list of tenants expected to contain at least one tenant, and a selected
 * user ID from a cookie. It returns an updated version of the Auth0 user with
 * the active tenant set to the tenant corresponding to the selected user ID.
 */
export const UpdateActiveAuth0User = (
  user: User,
  selectedUserID: string | undefined,
): Auth0User | undefined => {
  const { tenants } = user;

  // If we have a cookie, find the corresponding tenant in the list of tenants
  // in Auth0 metadata.
  const selectedTenant = tenants.find((t) => t.userID === selectedUserID);

  // If we can't find a selected tenant for the cookie, default to the first
  // tenant. If we don't have a first tenant, return undefined.
  if (selectedTenant === undefined) {
    if (tenants.length > 0) {
      return {
        ...user,
        activeTenant: tenants[0],
      };
    }
    return undefined;
  }

  // If we did find a selected tenant for the cookie, return it with an updated
  // version of what we got from Auth0, to maintain compatibility with existing
  // Auth0 defaults.
  return {
    ...user,
    activeTenant: selectedTenant,
  };
};

/**
 * Auth0Context is a type that extends the Auth0ContextInterface from the Auth0 SDK
 * but replaces the User type with a defined Slabstack-friendly Auth0User type.
 */
type Auth0Context = Omit<
  Auth0ContextInterface<User>,
  // Unused properties
  | 'getAccessTokenWithPopup'
  | 'getIdTokenClaims'
  | 'handleRedirectCallback'
  | 'loginWithRedirect'
  // Overridden properties
  | 'user'
> & {
  user: Auth0User;
};

/**
 * useSlabAuth is a custom hook that wraps the useAuth0 hook from the Auth0 SDK.
 * It provides the same functionality as useAuth0, but with the addition of
 * translating an Auth0 user to a Slabstack-friendly Auth0User.
 * It also supports selecting a tenant based on a cookie.
 *
 * For most purposes, you'll want to use SlabContext instead of this hook.
 */
export const useSlabAuth = (): Auth0Context => {
  const config = SlabConfig;

  if (config.offline === true) {
    return {
      // With how the backend is setup for offline access, we don't need to construct one.
      getAccessTokenSilently: async (): Promise<any> => 'offline',
      // To keep TypeScript happy, mock out the login and logout methods that we use
      loginWithPopup: async (): Promise<void> => {},
      logout: async (): Promise<void> => {},
      user: {
        admin: true,
        activeTenant: {
          tenantID: config.offlineTenantID,
          tenantName: 'Offline tenant',
          userID: config.offlineUserID,
          userEmail: 'offline@slabstackoffline.com',
          roleNames: [Enums.RoleName.SlabstackAdministrator],
        },
        tenants: [
          {
            tenantID: config.offlineTenantID,
            tenantName: 'Offline tenant',
            userID: config.offlineUserID,
            userEmail: 'offline@slabstackoffline.com',
            roleNames: [Enums.RoleName.SlabstackAdministrator],
          },
        ],
      },
      isLoading: false,
      isAuthenticated: true,
    };
  }

  const defaultAuth0 = useAuth0();
  const [{ User: selectedUser }] = useCookies(['User']);

  // If a user is undefined, return undefined. This is ok hook behavior.
  if (defaultAuth0.user === undefined) {
    return {
      ...defaultAuth0,
      user: undefined as unknown as Auth0User,
    };
  }

  // If a user has no tenants, log an error and return undefined as this implies
  // the user's Auth0 metadata is not set up correctly.
  if (defaultAuth0.user.tenants === undefined) {
    LogRocket.error('User has no tenants in Auth0 metadata');
    return {
      ...defaultAuth0,
      user: undefined as unknown as Auth0User,
    };
  }

  // If there is a cookie, replace the default user/tenant choice with the user
  // described in the cookie. (If the user in the cookie is not in the tenants
  // list from the claims, we'll keep the default.)
  const auth0User = UpdateActiveAuth0User(defaultAuth0.user, selectedUser);

  if (auth0User === undefined) {
    LogRocket.error('User translated from Auth0 metadata/cookie data is undefined');
    return {
      ...defaultAuth0,
      user: undefined as unknown as Auth0User,
    };
  }

  // The parameterized type of the Auth0ContextInterface has changed from User to Auth0User.
  const modifiedAuth0 = {
    ...defaultAuth0,
    user: auth0User,
  };

  return modifiedAuth0;
};
