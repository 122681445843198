import { Style } from '@react-pdf/types';

import Enums from '../../../../generated-types/Enums';
import { Quote } from '../../../../generated-types/Quote/Quote';
import { PdfProductsTable } from '../../components/PdfProductsTable';

const tableHeaderViewStyle: Style = {
  textAlign: 'center',
  backgroundColor: '#808080',
  color: 'white',
};

const tableRowViewStyle: Style = {
  textAlign: 'center',
};

const cellTextStyle: Style = {
  fontFamily: 'Helvetica',
  fontSize: '9pt',
};

export const customDauphinaisUnitLabels = (label: string): string => {
  switch (label) {
    case 'CUYD':
      return 'cy';
    case '/CUYD':
      return '/y';
    default:
      return label;
  }
};

export const PdfMainProductTable = ({ quote }: { quote: Quote }): JSX.Element => (
  <PdfProductsTable
    products={quote.filterProducts(Enums.QuoteProductKind.Primary)}
    displayHeaders
    columnConfigs={[
      {
        id: 'alternateID',
        label: 'Product Code',
        type: 'string',
        headerViewStyle: tableHeaderViewStyle,
        rowViewStyle: tableRowViewStyle,
        headerTextStyle: cellTextStyle,
        rowTextStyle: cellTextStyle,
        width: '20%',
      },
      {
        id: 'name',
        label: 'Description',
        type: 'string',
        headerViewStyle: tableHeaderViewStyle,
        rowViewStyle: tableRowViewStyle,
        headerTextStyle: cellTextStyle,
        rowTextStyle: cellTextStyle,
        width: '50%',
      },
      {
        id: 'measurementUnit',
        label: 'UOM',
        type: 'string',
        headerViewStyle: tableHeaderViewStyle,
        rowViewStyle: tableRowViewStyle,
        headerTextStyle: cellTextStyle,
        rowTextStyle: cellTextStyle,
        width: '10%',
        formatValueToString: customDauphinaisUnitLabels,
      },
      {
        id: 'price',
        label: 'Price',
        type: 'Currency',
        headerViewStyle: tableHeaderViewStyle,
        rowViewStyle: tableRowViewStyle,
        headerTextStyle: cellTextStyle,
        rowTextStyle: cellTextStyle,
        width: '20%',
      },
    ]}
    bordered
  />
);
