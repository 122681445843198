// Code generated by domaingen. DO NOT EDIT.

/* eslint-disable dot-notation */

import Enums from './Enums';

export type UnitEnumValue = (typeof Enums.Unit)[keyof typeof Enums.Unit];

export type CategoryDefinition = {
  label: string;
};

export type UnitDefinition = {
  value: UnitEnumValue;
  label: string;
  description: string;
  category: CategoryDefinition;
};

export const CategoryKeys = [
  'Dry Volume',
  'Wet Volume',
  'Length',
  'Area',
  'Weight',
  'Other',
] as const;

export const Categories: Record<(typeof CategoryKeys)[number], CategoryDefinition> = {
  'Dry Volume': {
    label: 'Dry Volume',
  },
  'Wet Volume': {
    label: 'Wet Volume',
  },
  Length: {
    label: 'Length',
  },
  Area: {
    label: 'Area',
  },
  Weight: {
    label: 'Weight',
  },
  Other: {
    label: 'Other',
  },
};

export const Units: Record<UnitEnumValue, UnitDefinition> = {
  cu_yd: {
    value: 'cu_yd' as Enums.Unit,
    label: 'CUYD',
    description: 'cubic yards',
    category: Categories['Dry Volume'],
  },

  ea: {
    value: 'ea' as Enums.Unit,
    label: 'ea',
    description: 'each',
    category: Categories['Other'],
  },

  lb: {
    value: 'lb' as Enums.Unit,
    label: 'lb',
    description: 'pounds',
    category: Categories['Weight'],
  },

  tn: {
    value: 'tn' as Enums.Unit,
    label: 'tn',
    description: 'short tons',
    category: Categories['Weight'],
  },

  fl_oz: {
    value: 'fl_oz' as Enums.Unit,
    label: 'fl oz',
    description: 'fluid ounces',
    category: Categories['Wet Volume'],
  },

  fl_oz_per_cwt: {
    value: 'fl_oz_per_cwt' as Enums.Unit,
    label: 'fl oz/cwt',
    description: 'fl oz per hundredweight',
    category: Categories['Wet Volume'],
  },

  gal: {
    value: 'gal' as Enums.Unit,
    label: 'gal',
    description: 'gallons',
    category: Categories['Wet Volume'],
  },

  per_hr: {
    value: 'per_hr' as Enums.Unit,
    label: '/hr',
    description: 'per hour',
    category: Categories['Other'],
  },

  in: {
    value: 'in' as Enums.Unit,
    label: 'in',
    description: 'inches',
    category: Categories['Length'],
  },

  ft: {
    value: 'ft' as Enums.Unit,
    label: 'ft',
    description: 'feet',
    category: Categories['Length'],
  },

  yd: {
    value: 'yd' as Enums.Unit,
    label: 'yd',
    description: 'yards',
    category: Categories['Length'],
  },

  lf: {
    value: 'lf' as Enums.Unit,
    label: 'LF',
    description: 'linear feet',
    category: Categories['Length'],
  },

  mm: {
    value: 'mm' as Enums.Unit,
    label: 'mm',
    description: 'millimeters',
    category: Categories['Length'],
  },

  cm: {
    value: 'cm' as Enums.Unit,
    label: 'cm',
    description: 'centimeters',
    category: Categories['Length'],
  },

  m: {
    value: 'm' as Enums.Unit,
    label: 'm',
    description: 'meters',
    category: Categories['Length'],
  },

  km: {
    value: 'km' as Enums.Unit,
    label: 'km',
    description: 'kilometers',
    category: Categories['Length'],
  },

  mi: {
    value: 'mi' as Enums.Unit,
    label: 'mi',
    description: 'miles',
    category: Categories['Length'],
  },

  sq_in: {
    value: 'sq_in' as Enums.Unit,
    label: 'sq in',
    description: 'square inches',
    category: Categories['Area'],
  },

  sq_ft: {
    value: 'sq_ft' as Enums.Unit,
    label: 'sq ft',
    description: 'square feet',
    category: Categories['Area'],
  },

  sq_yd: {
    value: 'sq_yd' as Enums.Unit,
    label: 'sq yd',
    description: 'square yards',
    category: Categories['Area'],
  },

  sq_mi: {
    value: 'sq_mi' as Enums.Unit,
    label: 'sq mi',
    description: 'square miles',
    category: Categories['Area'],
  },

  ac: {
    value: 'ac' as Enums.Unit,
    label: 'ac',
    description: 'acres',
    category: Categories['Area'],
  },

  sq_mm: {
    value: 'sq_mm' as Enums.Unit,
    label: 'sq mm',
    description: 'square millimeter',
    category: Categories['Area'],
  },

  sq_cm: {
    value: 'sq_cm' as Enums.Unit,
    label: 'sq cm',
    description: 'square centimeter',
    category: Categories['Area'],
  },

  sq_m: {
    value: 'sq_m' as Enums.Unit,
    label: 'sq m',
    description: 'square meters',
    category: Categories['Area'],
  },

  sq_km: {
    value: 'sq_km' as Enums.Unit,
    label: 'sq km',
    description: 'square kilometers',
    category: Categories['Area'],
  },

  cu_in: {
    value: 'cu_in' as Enums.Unit,
    label: 'cu in',
    description: 'cubic inches',
    category: Categories['Dry Volume'],
  },

  cu_ft: {
    value: 'cu_ft' as Enums.Unit,
    label: 'cu ft',
    description: 'cubic feet',
    category: Categories['Dry Volume'],
  },

  cu_mm: {
    value: 'cu_mm' as Enums.Unit,
    label: 'cu mm',
    description: 'cubic millimeters',
    category: Categories['Dry Volume'],
  },

  cu_cm: {
    value: 'cu_cm' as Enums.Unit,
    label: 'cu cm',
    description: 'cubic centimeters',
    category: Categories['Dry Volume'],
  },

  cu_m: {
    value: 'cu_m' as Enums.Unit,
    label: 'cu m',
    description: 'cubic meters',
    category: Categories['Dry Volume'],
  },

  pt: {
    value: 'pt' as Enums.Unit,
    label: 'pt',
    description: 'pints',
    category: Categories['Wet Volume'],
  },

  qt: {
    value: 'qt' as Enums.Unit,
    label: 'qt',
    description: 'quarts',
    category: Categories['Wet Volume'],
  },

  L: {
    value: 'L' as Enums.Unit,
    label: 'L',
    description: 'liters',
    category: Categories['Wet Volume'],
  },

  mL: {
    value: 'mL' as Enums.Unit,
    label: 'mL',
    description: 'milliliters',
    category: Categories['Wet Volume'],
  },

  oz: {
    value: 'oz' as Enums.Unit,
    label: 'oz',
    description: 'ounces',
    category: Categories['Weight'],
  },

  g: {
    value: 'g' as Enums.Unit,
    label: 'g',
    description: 'grams',
    category: Categories['Weight'],
  },

  kg: {
    value: 'kg' as Enums.Unit,
    label: 'kg',
    description: 'kilograms',
    category: Categories['Weight'],
  },

  t: {
    value: 't' as Enums.Unit,
    label: 'tonne',
    description: 'metric tons',
    category: Categories['Weight'],
  },

  per_L: {
    value: 'per_L' as Enums.Unit,
    label: '/L',
    description: 'per liter',
    category: Categories['Wet Volume'],
  },

  per_qty: {
    value: 'per_qty' as Enums.Unit,
    label: '/qty',
    description: 'per quantity',
    category: Categories['Other'],
  },

  per_cu_yd: {
    value: 'per_cu_yd' as Enums.Unit,
    label: '/CUYD',
    description: 'per CUYD',
    category: Categories['Dry Volume'],
  },

  per_m: {
    value: 'per_m' as Enums.Unit,
    label: '/m',
    description: 'per meter',
    category: Categories['Length'],
  },

  bag: {
    value: 'bag' as Enums.Unit,
    label: 'bag',
    description: 'bag',
    category: Categories['Other'],
  },

  dosage: {
    value: 'dosage' as Enums.Unit,
    label: 'dosage',
    description: 'dosage',
    category: Categories['Other'],
  },

  percent: {
    value: 'percent' as Enums.Unit,
    label: '%',
    description: 'percent',
    category: Categories['Other'],
  },

  per_tn: {
    value: 'per_tn' as Enums.Unit,
    label: '/tn',
    description: 'per short ton',
    category: Categories['Weight'],
  },

  per_load: {
    value: 'per_load' as Enums.Unit,
    label: '/load',
    description: 'per load',
    category: Categories['Other'],
  },

  per_cu_m: {
    value: 'per_cu_m' as Enums.Unit,
    label: '/cu_m',
    description: 'per cubic meter',
    category: Categories['Dry Volume'],
  },

  block_equivalent_unit: {
    value: 'block_equivalent_unit' as Enums.Unit,
    label: 'BlckEqUn',
    description: 'Block Equivalent Units',
    category: Categories['Other'],
  },
};
