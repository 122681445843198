// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { Currency, NewCurrency } from '../Currency/Currency';
import { NewPlant, Plant } from '../Plant/Plant';
import {
  NewProjectConfigCompany,
  ProjectConfigCompany,
} from '../ProjectConfigCompany/ProjectConfigCompany';
import {
  NewProjectConfigProduct,
  ProjectConfigProduct,
} from '../ProjectConfigProduct/ProjectConfigProduct';

export class ProjectConfig {
  readonly id: string;
  readonly name: string;
  readonly plant: Plant;
  readonly estimatedVolumeOverride: number | null;
  readonly revenueOverride: Currency | null;
  readonly companies: ProjectConfigCompany[];
  readonly products: ProjectConfigProduct[];

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.plant = NewPlant(data.plant);
    this.estimatedVolumeOverride =
      data.estimatedVolumeOverride === undefined ? null : data.estimatedVolumeOverride;
    this.revenueOverride =
      (data.revenueOverride ?? null) === null ? null : NewCurrency(data.revenueOverride);
    this.companies = (data.companies ?? []).map((companies: any) =>
      NewProjectConfigCompany(companies),
    );
    this.products = (data.products ?? []).map((products: any) => NewProjectConfigProduct(products));
  }

  static zero(): ProjectConfig {
    const zeroValues: ExcludeMethodsDeep<ProjectConfig> = {
      id: NIL_UUID,
      name: '',
      plant: Plant.zero(),
      estimatedVolumeOverride: null,
      revenueOverride: null,
      companies: [],
      products: [],
    };
    return new ProjectConfig(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************
  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewProjectConfig = (
  props: PartialDeep<ProjectConfig, { recurseIntoArrays: true }>,
): ProjectConfig => new ProjectConfig(props);

export const NewProjectConfigFromDomainObject = (
  props: PartialDeep<DomainObject<ProjectConfig>, { recurseIntoArrays: true }>,
): ProjectConfig => new ProjectConfig(props);
