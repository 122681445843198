import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { ColumnConfig, ColumnFromConfig } from '../../../components/DataTable/TableDataModel';
import { Currency } from '../../../generated-types/Currency/Currency';
import { Units } from '../../../generated-types/generated-units';
import { QuoteProduct } from '../../../generated-types/QuoteProduct/QuoteProduct';
import { NestedKeyOf, NestedKeyOfValue } from '../../../utils/Types';
import {
  PdfColumnConfig,
  PdfColumnFromConfig,
  PdfTable,
  PdfTableProps,
  Styles,
} from '../../components/PdfTable';

export type ProductRow = {
  id: string;
  index: number;
  class: string | null;
  name: string | null;
  price: Currency;
  measurementUnit: string | null;
  pricePerYard: string | null;
  alternateID: string | null;
  psi: number | null;
  slump: string | null;
  waterCementRatio: string | null;
  aggregateSize: string | null;
  flyAshPercentage: string | null;
  usage: string | null;
};

type ProductColumn = ColumnConfig<ProductRow, NestedKeyOf<ProductRow>> & {
  width?: Style['width'];
  headerViewStyle?: Style;
  headerTextStyle?: Style;
  rowViewStyle?: Style;
  rowTextStyle?: Style;
};

const makePdfRenderer =
  (
    { rowViewStyle = {}, rowTextStyle = {} }: ProductColumn,
    toText: (value: ProductRow) => string,
  ) =>
  (value: NestedKeyOfValue<ProductRow, NestedKeyOf<ProductRow>>, row: ProductRow): JSX.Element => (
    <View style={rowViewStyle}>
      <Text style={[Styles.tableCell, rowTextStyle]}>{toText(row)}</Text>
    </View>
  );

const makeHeadingPdfRenderer =
  ({ headerViewStyle = {}, headerTextStyle = {} }: ProductColumn) =>
  (label: string): JSX.Element => (
    <View style={headerViewStyle}>
      <Text style={[Styles.tableCellHeader, headerTextStyle]}>{label}</Text>
    </View>
  );

type PdfProductsTableProps = {
  products: QuoteProduct[];
  columnConfigs: ProductColumn[];
} & Omit<PdfTableProps<ProductRow>, 'columns' | 'rows'>;

export const PdfProductsTable = ({
  products,
  columnConfigs,
  ...tableProps
}: PdfProductsTableProps): JSX.Element => {
  const rows: ProductRow[] = products.map((p, index) => ({
    id: p.product.id,
    index: index + 1,
    class: p.class === null ? null : `Class ${p.class}`,
    name: p.externalName,
    price: p.price,
    measurementUnit: Units[p.product.measurementUnit].label ?? null,
    pricePerYard: p.pricePerYardDisplay(),
    alternateID: p.product.alternateID,
    psi: p.product.mix?.psi ?? null,
    slump: p.product.mix?.slump ?? null,
    waterCementRatio: p.product.mix?.waterCementRatio ?? null,
    aggregateSize: p.product.mix?.aggregateSize ?? null,
    flyAshPercentage: p.product.mix?.flyAshPercentage ?? null,
    usage: p.usage,
  }));

  const columns: PdfColumnConfig<ProductRow>[] = Object.values(columnConfigs).map(
    (config): PdfColumnConfig<ProductRow> => ({
      ...config,
      widthOverride: config.width,
      formatValueForPdf:
        (config.formatValueForPdf as any) ??
        makePdfRenderer(config, ColumnFromConfig(config).toText),
      formatColumnHeaderForPdf: makeHeadingPdfRenderer(config),
    }),
  );

  return (
    <PdfTable
      columns={columns.map((col) => PdfColumnFromConfig(col))}
      rows={rows}
      {...tableProps}
    />
  );
};
