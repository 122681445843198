import { Alert, AlertColor, AlertTitle, Box } from '@mui/material';

import { SlabConfig } from '../../utils/SlabConfig';

/**
 * Based on config variables set at our infractructure level,
 * conditionally display a maintenace alert box.
 */
export const MaintenanceNotice = (): JSX.Element | null => {
  const { maintenanceNoticeBody, maintenanceNoticeTitle, maintenanceNoticeSeverity } = SlabConfig;

  if (maintenanceNoticeBody === '') {
    return null;
  }

  return (
    <Box paddingBottom='1em'>
      <Alert
        severity={
          maintenanceNoticeSeverity !== '' ? (maintenanceNoticeSeverity as AlertColor) : 'info'
        }
      >
        {maintenanceNoticeTitle !== '' && (
          <AlertTitle sx={{ fontWeight: 'bold' }}>{maintenanceNoticeTitle}</AlertTitle>
        )}
        {maintenanceNoticeBody}
      </Alert>
    </Box>
  );
};
