import Enums from '../../generated-types/Enums';
import { InputSwitch } from '../InputSwitch/InputSwitch';

type CostRuleInputProps = {
  /** The formik name of the high-level material cost rule. */
  name: string;
};

export const CostRuleInput = ({ name }: CostRuleInputProps): JSX.Element => (
  <InputSwitch
    switchFormikName={`${name}.kind`}
    leftLabel='Percentage Change'
    rightLabel='Flat Amount'
    leftValue={Enums.CostRuleKind.Ratio}
    rightValue={Enums.CostRuleKind.Flat}
    leftInputProps={{
      name: `${name}.markupRatio`,
      type: 'number',
      label: 'Percentage Change',
      precision: 1,
      endAdornment: '%',
    }}
    rightInputProps={{
      name: `${name}.flatChange`,
      type: 'currency',
      label: 'Flat Amount',
      startAdornment: '$',
    }}
  />
);
