import { View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { Quote } from '../../../../generated-types/Quote/Quote';
import { PdfTextBlock } from '../../../components/PdfTextBlock';
import { QuotePdf } from '../../../QuotePdf';
import { PdfNotes } from '../../components/PdfNotes';
import { PdfPriceEscalationTable } from '../../components/PdfPriceEscalationTable';
import { PdfSpacer } from '../../components/PdfSpacer';
import { PdfAdditionalProductTable } from '../components/PdfAdditionalProductTable';
import { PdfHeaderTable } from '../components/PdfHeaderTable';
import { PdfMainProductTable } from '../components/PdfMainProductTable';
import { PdfSignatures } from '../components/PdfSignatures';

const colViewStyle: Style = {
  textAlign: 'center',
  backgroundColor: '#808080',
  color: 'white',
};

const rowViewStyle: Style = {
  textAlign: 'center',
};

const cellTextStyle: Style = {
  fontFamily: 'Helvetica',
  fontSize: '9pt',
};

type DauphinaisDefaultTemplateProps = {
  quote: Quote;
};

export const DauphinaisDefaultTemplate = ({
  quote,
}: DauphinaisDefaultTemplateProps): JSX.Element => (
  <QuotePdf
    quote={quote}
    disableHyphenation
    pageStyle={{
      style: {
        padding: '0.5in',
      },
    }}
  >
    <PdfHeaderTable quote={quote} />
    <PdfSpacer height='0.25in' />
    <PdfMainProductTable quote={quote} />
    <PdfSpacer height='0.25in' />
    <PdfTextBlock
      content='Service and Miscellaneous Fee Charges'
      textStyle={{
        fontFamily: 'Helvetica-Bold',
        fontSize: '10pt',
      }}
    />
    <PdfSpacer height='0.1in' />
    <PdfAdditionalProductTable quote={quote} />
    {quote.priceEscalations.length === 0 ? null : (
      <View>
        <PdfSpacer height='0.25in' />
        <PdfTextBlock
          content='Price Escalations'
          textStyle={{
            fontFamily: 'Helvetica-Bold',
            fontSize: '10pt',
          }}
        />
        <PdfSpacer height='0.1in' />
        <PdfPriceEscalationTable
          quotePriceEscalations={quote.priceEscalations}
          columnConfigs={[
            {
              id: 'escalationDate',
              label: 'Escalation Date',
              headerViewStyle: colViewStyle,
              rowViewStyle,
              headerTextStyle: cellTextStyle,
              rowTextStyle: cellTextStyle,
            },
            {
              id: 'amount',
              label: 'Amount',
              headerViewStyle: colViewStyle,
              rowViewStyle,
              headerTextStyle: cellTextStyle,
              rowTextStyle: cellTextStyle,
            },
          ]}
          displayHeaders
          bordered
        />
      </View>
    )}
    {quote.notes !== null && (
      <View>
        <PdfSpacer height='0.25in' />
        <PdfNotes
          quote={quote}
          textBlockProps={{
            textStyle: {
              fontFamily: 'Calibri',
              lineHeight: '1.4pt',
              fontSize: '11pt',
            },
          }}
        />
      </View>
    )}
    {/* Signing area */}
    <PdfSpacer height='0.25in' />
    <PdfSignatures />
  </QuotePdf>
);
