import { Box, Button, Typography, useTheme } from '@mui/material';

import { FilterByOpts } from '../../../utils/ApiClient';
import { MakeImmediateResult, PossiblyAsyncResult } from '../../../utils/Query';
import { randomUUID } from '../../../utils/UUID';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';

export type FilterLabel<TRow extends { id: string }, TAgg extends { id: string }> = {
  /** The label to display on each tab */
  label: string;
  additionalLabels?: (aggregations: { [id: string]: TAgg }) => string[];
  /** The value to filter against; if null then no filter will be applied */
  filter: FilterByOpts<TRow> | null;
  resultCount?: (aggregations: { [id: string]: TAgg }) => number;
};

export type FilterTabsProps<TRow extends { id: string }, TAgg extends { id: string }> = {
  filters: FilterLabel<TRow, TAgg>[];
  aggregations?: PossiblyAsyncResult<{ [id: string]: TAgg }>;
  handleChangeTab: (filter: FilterLabel<TRow, TAgg>, index: number) => void;
  activeFilter: FilterByOpts<TRow> | null;
};

export const FilterTabs = <TRow extends { id: string }, TAgg extends { id: string }>({
  filters,
  aggregations = MakeImmediateResult({}),
  handleChangeTab,
  activeFilter,
}: FilterTabsProps<TRow, TAgg>): JSX.Element => {
  const theme = useTheme();

  const renderAdditionalLabels = ({
    filter,
    color,
  }: {
    filter: FilterLabel<TRow, TAgg>;
    color: string;
  }): JSX.Element | JSX.Element[] | null => {
    if (filter.additionalLabels === undefined || aggregations.isError) {
      return null;
    }
    if (aggregations.isLoading || aggregations.data === undefined) {
      return (
        <Box sx={{ lineHeight: 1, marginTop: '4px', marginBottom: '2px' }}>
          <LoadingSpinner size={20} sx={{ color }} color='inherit' wrapInBox={false} />
        </Box>
      );
    }
    return filter.additionalLabels(aggregations.data).map((subLabel) => (
      <Typography variant='body2' color={color} key={randomUUID()} noWrap>
        {subLabel}
      </Typography>
    ));
  };

  const renderResultCount = ({
    filter,
    color,
    backgroundColor,
  }: {
    filter: FilterLabel<TRow, TAgg>;
    color: string;
    backgroundColor: string;
  }): JSX.Element | JSX.Element[] | null => {
    if (filter.resultCount === undefined || aggregations.isError) {
      return null;
    }
    return (
      <Box paddingX='0.875rem' borderRadius='0.5rem' height='fit-content' sx={{ backgroundColor }}>
        {aggregations.isLoading || aggregations.data === undefined ? (
          <LoadingSpinner
            size={14}
            sx={{
              margin: 'auto',
              verticalAlign: 'middle',
              color,
            }}
            wrapInBox={false}
          />
        ) : (
          <Typography variant='body3' fontSize='0.875rem' color={color}>
            {filter.resultCount(aggregations.data)}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    // NOTE: We do not use MUI `Tabs` here because:
    //   1. It must have `Tab`s nested underneath, which do not have enough customization
    //   2. If used without `Tab`s, throws prop-injection warnings
    <Box
      display='flex'
      sx={{
        borderBottom: `1px solid ${theme.palette.SlabBlue.Stroke}`,
        // -webkit-scrollbar properties for Safari
        '::-webkit-scrollbar': {
          background: theme.palette.background.default,
          height: '10px',
        },
        '::-webkit-scrollbar-thumb': {
          border: `2px solid ${theme.palette.background.default}`,
          borderRadius: '5px',
          background: theme.palette.SlabBlue[200],
          padding: '2px',
        },
        // overflow-x, not overflow, so that we don't get a vertical scrollbar :-D
        overflowX: 'auto',
        // standard scrollbar properties for everyone else
        scrollbarColor: `${theme.palette.SlabBlue[200]} ${theme.palette.background.default}`,
        scrollbarWidth: 'thin',
      }}
    >
      {filters.map((f, idx) => {
        const isActive =
          f.filter === null || activeFilter === null
            ? f.filter === activeFilter
            : JSON.stringify(f.filter) === JSON.stringify(activeFilter);
        const additionalLabelColor = isActive
          ? theme.palette.SlabBlue[400]
          : theme.palette.SlabBlue[300];
        const resultCountColor = isActive ? theme.palette.SlabBlue[0] : theme.palette.SlabBlue[400];
        const resultCountBackground = isActive
          ? theme.palette.SlabChart.Indigo[200]
          : theme.palette.SlabBlue.Stroke;
        return (
          <Button
            key={randomUUID()}
            disableRipple
            id={`project-filter-tab-${idx}`}
            onClick={(): void => handleChangeTab(f, idx)}
            sx={{
              minWidth: 'auto',
              borderRadius: 0,
              borderBottom: isActive ? `1px solid ${theme.palette.SlabBlue[500]}` : 'none',
              textTransform: 'none',
            }}
          >
            <Box paddingX='1rem' display='flex' gap='0.625rem'>
              <Box display='flex' flexDirection='column' textAlign='left'>
                <Typography
                  variant='body1'
                  noWrap
                  color={isActive ? theme.palette.SlabBlue[500] : theme.palette.SlabBlue[400]}
                >
                  {f.label}
                </Typography>
                {renderAdditionalLabels({ filter: f, color: additionalLabelColor })}
              </Box>
              {renderResultCount({
                filter: f,
                color: resultCountColor,
                backgroundColor: resultCountBackground,
              })}
            </Box>
          </Button>
        );
      })}
    </Box>
  );
};
