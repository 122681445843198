import { Box, CircularProgress, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';

import { TabBar } from '../../components/TabBar/TabBar';
import { ProjectStatusList } from '../../generated-types/ProjectStatus/ProjectStatusAdminList';
import { useSlabQuery } from '../../hooks/useSlabQuery';

export const TenantAdminPage = (): JSX.Element => {
  const { tenantID } = useParams<'tenantID'>();
  if (tenantID === undefined) {
    return <div>ERROR</div>;
  }

  const { data } = useSlabQuery('GET tenants as admin', {});
  const tenantName =
    data === undefined ? (
      <CircularProgress size={20} />
    ) : (
      data.items.find((t) => t.id === tenantID)?.name ?? ''
    );

  const tabs = [{ label: 'Project Statuses', element: <ProjectStatusList tenantID={tenantID} /> }];

  return (
    <Box>
      <Typography variant='h5'>
        <Link to='/_slabstack_staff'>Admin Portal</Link>: {tenantName}
      </Typography>
      <TabBar tabs={tabs} />
    </Box>
  );
};
