// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { Distance, NewDistance } from '../Distance/Distance';
import { NewTripDuration, TripDuration } from '../TripDuration/TripDuration';

export class DistanceDurationPair {
  readonly origin: string;
  readonly destination: string;
  readonly distance: Distance;
  readonly duration: TripDuration;

  constructor(data: { [key: string]: any } = {}) {
    this.origin = data.origin === undefined ? '' : data.origin;
    this.destination = data.destination === undefined ? '' : data.destination;
    this.distance = NewDistance(data.distance);
    this.duration = NewTripDuration(data.duration);
  }

  static zero(): DistanceDurationPair {
    const zeroValues: ExcludeMethodsDeep<DistanceDurationPair> = {
      origin: '',
      destination: '',
      distance: Distance.zero(),
      duration: TripDuration.zero(),
    };
    return new DistanceDurationPair(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewDistanceDurationPair = (
  props: PartialDeep<DistanceDurationPair, { recurseIntoArrays: true }>,
): DistanceDurationPair => new DistanceDurationPair(props);

export const NewDistanceDurationPairFromDomainObject = (
  props: PartialDeep<DomainObject<DistanceDurationPair>, { recurseIntoArrays: true }>,
): DistanceDurationPair => new DistanceDurationPair(props);
