import ReactPDF, { Text, View } from '@react-pdf/renderer';
import { DateTime } from 'luxon';

import { Quote } from '../../../../generated-types/Quote/Quote';
import { FormatDisplayDateTime } from '../../../../utils/DateHelpers';
import { randomUUID } from '../../../../utils/UUID';
import { PdfHeader } from '../../../components/PdfHeader';
import {
  PdfColumnConfig,
  PdfColumnFromConfig,
  PdfTable,
  Styles,
} from '../../../components/PdfTable';
import { PdfTextBlock } from '../../../components/PdfTextBlock';
import { PdfSpacer } from '../../components/PdfSpacer';

const normalHelvetica: ReactPDF.TextProps['style'] = {
  fontFamily: 'Helvetica',
  fontSize: '10pt',
};

const boldHelvetica: ReactPDF.TextProps['style'] = {
  fontFamily: 'Helvetica-Bold',
  fontSize: '10pt',
};

type TopInfoTableRow = {
  id: string;
  textStyle: ReactPDF.TextProps['style'];
  first: string | null;
  second: string | null;
  third: string | null;
  fourth: string | null;
  fifth: string | null;
};

type BottomInfoTableRow = {
  id: string;
  first: string | null;
  second: string | null;
  third: string | null;
  fourth: string | null;
};

const formatTopInfoFirstValueForPdf = (value: string, row: TopInfoTableRow): JSX.Element => (
  <View style={Styles.tableCell}>
    <Text style={row.textStyle}>{value}</Text>
  </View>
);
const formatTopInfoSecondValueForPdf = (value: string, row: TopInfoTableRow): JSX.Element => (
  <View style={Styles.tableCell}>
    <Text style={row.textStyle}>{value}</Text>
  </View>
);
const formatTopInfoThirdValueForPdf = (value: string, row: TopInfoTableRow): JSX.Element => (
  <View style={Styles.tableCell}>
    <Text style={row.textStyle}>{value}</Text>
  </View>
);
const formatTopInfoFourthValueForPdf = (value: string, row: TopInfoTableRow): JSX.Element => (
  <View style={[Styles.tableCell, { textAlign: 'right' }]}>
    <Text style={row.textStyle}>{value}</Text>
  </View>
);
const formatTopInfoFifthValueForPdf = (value: string, row: TopInfoTableRow): JSX.Element => (
  <View style={[Styles.tableCell, { textAlign: 'right' }]}>
    <Text style={row.textStyle}>{value}</Text>
  </View>
);

const formatBottomInfoFirstValueForPdf = (value: string): JSX.Element => (
  <View style={Styles.tableCell}>
    <Text style={boldHelvetica}>{value}</Text>
  </View>
);
const formatBottomInfoSecondValueForPdf = (value: string): JSX.Element => (
  <View style={Styles.tableCell}>
    <Text style={normalHelvetica}>{value}</Text>
  </View>
);
const formatBottomInfoThirdValueForPdf = (value: string): JSX.Element => (
  <View style={Styles.tableCell}>
    <Text style={boldHelvetica}>{value}</Text>
  </View>
);
const formatBottomInfoFourthValueForPdf = (value: string): JSX.Element => (
  <View style={Styles.tableCell}>
    <Text style={normalHelvetica}>{value}</Text>
  </View>
);

type PdfHeaderTableProps = {
  quote: Quote;
};

export const PdfHeaderTable = ({ quote }: PdfHeaderTableProps): JSX.Element => {
  const logoImg = (
    <View style={{ alignItems: 'center' }}>
      <PdfHeader
        image={{
          src: '../dauphinais-logo.png',
          imgStyle: {
            width: '2.72in',
            height: '1in',
          },
        }}
      />
    </View>
  );

  const quotationLine = (
    <View style={{ backgroundColor: '#ADD8E6' }}>
      <PdfTextBlock content='QUOTATION' textStyle={{ fontSize: '12pt' }} />
    </View>
  );

  const topInfoTable = ((): JSX.Element => {
    const rows: TopInfoTableRow[] = [
      {
        id: randomUUID(),
        textStyle: boldHelvetica,
        first: 'Quote Code',
        second: 'Quoted By',
        third: 'Email',
        fourth: 'Quote Date',
        fifth: 'Expiration Date',
      },
      {
        id: randomUUID(),
        textStyle: normalHelvetica,
        first: quote.name,
        second: quote.user.fullName(),
        third: quote.user.emailAddress,
        fourth:
          quote.creationDate === null
            ? null
            : FormatDisplayDateTime(quote.creationDate, DateTime.DATE_SHORT),
        fifth:
          quote.expirationDate === null
            ? null
            : FormatDisplayDateTime(quote.expirationDate, DateTime.DATE_SHORT),
      },
    ];

    const columns: PdfColumnConfig<TopInfoTableRow>[] = [
      {
        id: 'first',
        label: '',
        type: 'string',
        isDisplayed: false,
        formatValueForPdf: formatTopInfoFirstValueForPdf,
        widthOverride: '17%',
      },
      {
        id: 'second',
        label: '',
        type: 'string',
        isDisplayed: false,
        formatValueForPdf: formatTopInfoSecondValueForPdf,
        widthOverride: '18%',
      },
      {
        id: 'third',
        label: '',
        type: 'string',
        isDisplayed: false,
        formatValueForPdf: formatTopInfoThirdValueForPdf,
        widthOverride: '30%',
      },
      {
        id: 'fourth',
        label: '',
        type: 'string',
        isDisplayed: false,
        formatValueForPdf: formatTopInfoFourthValueForPdf,
        widthOverride: '17%',
      },
      {
        id: 'fifth',
        label: '',
        type: 'string',
        isDisplayed: false,
        formatValueForPdf: formatTopInfoFifthValueForPdf,
        widthOverride: '17%',
      },
    ];

    return <PdfTable columns={columns.map((col) => PdfColumnFromConfig(col))} rows={rows} />;
  })();

  const botInfoTable = ((): JSX.Element => {
    const rows: BottomInfoTableRow[] = [
      {
        id: randomUUID(),
        first: 'Customer:',
        second: quote.company?.name ?? null,
        third: 'Project Name:',
        fourth: quote.project.name,
      },
      {
        id: randomUUID(),
        first: 'Attn:',
        second: quote.contact?.fullName() ?? null,
        third: 'Project Address:',
        // VERIFY/FIXME: Do we want `addressDisplay()`, or `basicDisplay()`?

        fourth: quote.project.address.addressDisplay(),
      },
      {
        id: randomUUID(),
        first: 'Phone #:',
        second: quote.contact?.phoneNumber ?? null,
        third: null,
        fourth: null,
      },
      {
        id: randomUUID(),
        first: 'Email:',
        second: quote.contact?.emailAddress ?? null,
        third: null,
        fourth: null,
      },
      {
        id: randomUUID(),
        first: 'Address:',
        second: quote.company?.address?.addressDisplay() ?? null,
        third: null,
        fourth: null,
      },
    ];

    const columns: PdfColumnConfig<BottomInfoTableRow>[] = [
      {
        id: 'first',
        label: '',
        type: 'string',
        isDisplayed: false,
        formatValueForPdf: formatBottomInfoFirstValueForPdf,
        widthOverride: '16%',
      },
      {
        id: 'second',
        label: '',
        type: 'string',
        isDisplayed: false,
        formatValueForPdf: formatBottomInfoSecondValueForPdf,
        widthOverride: '31%',
      },
      {
        id: 'third',
        label: '',
        type: 'string',
        isDisplayed: false,
        formatValueForPdf: formatBottomInfoThirdValueForPdf,
        widthOverride: '20%',
      },
      {
        id: 'fourth',
        label: '',
        type: 'string',
        isDisplayed: false,
        formatValueForPdf: formatBottomInfoFourthValueForPdf,
        widthOverride: '33%',
      },
    ];

    return <PdfTable columns={columns.map((col) => PdfColumnFromConfig(col))} rows={rows} />;
  })();

  return (
    <View
      style={{
        // Default all fonts below to use Helvetica.
        fontFamily: 'Helvetica',
      }}
    >
      {logoImg}
      <PdfSpacer height='0.1in' />
      {quotationLine}
      {topInfoTable}
      <View style={{ borderBottom: '1pt solid black' }}> </View>
      {botInfoTable}
    </View>
  );
};
