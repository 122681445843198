import Enums from '../generated-types/Enums';
import { NewUserFromDomainObject, User } from '../generated-types/User/User';
import { List } from './List';
import { ExcludeMethodsDeep } from './Types';

/** Filter a user list so that it only includes users with the specified role. */
export const ByRole = (userList: List<User>, role: Enums.RoleName): List<User> => {
  const filteredUsers = userList.items.filter((u) => u.roles.some((r) => r.name === role));
  const listData: ExcludeMethodsDeep<List<User>> = {
    items: filteredUsers,
    count: filteredUsers.length,
  };
  return new List(NewUserFromDomainObject, listData);
};
