/**
 * Base64 is a string that contains base64-encoded binary content.
 *
 * Generally, code paths that produce base64 strings will cast their encoded
 * base64 strings to this type. Then, using the Base64 type in places where we
 * expect to consume base64 strings enables the TS compiler to prevent us from
 * accidentally processing a non-base64 string as base64.
 */
export type Base64 = '' | (string & { _slab_is_base_64: undefined });

/**
 * binaryStringToBase64 converts a string containing binary data to a Base64
 * encoded string for the same bytes.
 */
export const binaryStringToBase64 = (binaryData: string): Base64 => btoa(binaryData) as Base64;

/**
 * uint8ArrayToBase64 returns a base64-encoded copy of the provided binary data.
 */
export const uint8ArrayToBase64 = (array: Uint8Array): Base64 =>
  // TODO: migrate to Uint8Array#toBase64() when available
  // (see https://github.com/tc39/proposal-arraybuffer-base64/issues/51)
  binaryStringToBase64([...array].map((byte) => String.fromCharCode(byte)).join(''));

/**
 * base64ToBinaryString converts a string containing Base64-encoded data to a
 * decoded string representing the same bytes.
 */
export const base64ToBinaryString = (encodedData: Base64): string => atob(encodedData);

/**
 * base64ToUint8Array returns a Uint8Array containing the decoded bytes
 * represented by the provided Base64 string.
 */
export const base64ToUint8Array = (encoded: Base64): Uint8Array =>
  // TODO: migrate to Uint8Array.fromBase64() when available
  // (see https://github.com/tc39/proposal-arraybuffer-base64/issues/51)
  Uint8Array.from(base64ToBinaryString(encoded), (char) => char.charCodeAt(0));

/**
 * stringAsBase64 validates whether the possiblyEncoded string can be
 * interpreted as base64-encoded data. If so, it returns the provided string
 * with its type narrowed to Base64; otherwise returns undefined.
 *
 * Note that this is not necessarily a foolproof way to verify whether the
 * string _should_ be interpreted as base64-encoded data; many textual strings
 * that are limited to whitespace and the base64 alphabet (e.g. 'hello world')
 * will pass this test.
 */
export const stringAsBase64 = (possiblyEncoded: string): Base64 | undefined => {
  try {
    base64ToBinaryString(possiblyEncoded as Base64);
    return possiblyEncoded as Base64;
  } catch {
    return undefined;
  }
};
