// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

// webgen:import { "names": ["Currency"], "path": "../Currency/Currency" }

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { Company, NewCompany } from '../Company/Company';
import { Cost, NewCost } from '../Cost/Cost';
import { CostRule, NewCostRule } from '../CostRule/CostRule';
import { Currency } from '../Currency/Currency';
import Enums from '../Enums';
import { NewPlant, Plant } from '../Plant/Plant';

export class Material {
  readonly id: string;
  readonly alternateID: string | null;
  readonly name: string;
  readonly supplierCompany: Company | null;
  readonly rawMaterialCost: Cost;
  readonly haulingCost: Cost | null;
  readonly plantLoadingCost: Cost | null;
  readonly materialType: Enums.MaterialType;
  readonly measurementUnit: Enums.Unit | null;
  readonly plant: Plant;
  readonly externalID: string | null;
  readonly costRule: CostRule | null;
  readonly inProductCostOverride: Cost | null;
  readonly inProductCost: Cost;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.alternateID = data.alternateID === undefined ? null : data.alternateID;
    this.name = data.name === undefined ? '' : data.name;
    this.supplierCompany =
      // eslint-disable-next-line no-nested-ternary
      (data.supplierCompany ?? null) === null ? null : NewCompany(data.supplierCompany);
    this.rawMaterialCost = NewCost(data.rawMaterialCost);
    this.haulingCost = (data.haulingCost ?? null) === null ? null : NewCost(data.haulingCost);
    this.plantLoadingCost =
      (data.plantLoadingCost ?? null) === null ? null : NewCost(data.plantLoadingCost);
    this.materialType =
      data.materialType === undefined ? Enums.MaterialType.Other : data.materialType;
    this.measurementUnit = data.measurementUnit === undefined ? null : data.measurementUnit;
    this.plant = NewPlant(data.plant);
    this.externalID =
      // eslint-disable-next-line no-nested-ternary
      (data.externalID ?? null) === null
        ? null
        : data.externalID === undefined
          ? ''
          : data.externalID;
    this.costRule =
      // eslint-disable-next-line no-nested-ternary
      (data.costRule ?? null) === null ? null : NewCostRule(data.costRule);
    this.inProductCostOverride =
      (data.inProductCostOverride ?? null) === null ? null : NewCost(data.inProductCostOverride);
    this.inProductCost = NewCost(data.inProductCost);
  }

  static zero(): Material {
    const zeroValues: ExcludeMethodsDeep<Material> = {
      id: NIL_UUID,
      alternateID: null,
      name: '',
      supplierCompany: null,
      rawMaterialCost: Cost.zero(),
      haulingCost: null,
      plantLoadingCost: null,
      materialType: Enums.MaterialType.Other,
      measurementUnit: null,
      plant: Plant.zero(),
      externalID: null,
      costRule: null,
      inProductCostOverride: null,
      inProductCost: Cost.zero(),
    };
    return new Material(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /** Calculates the actual in product cost based on current class values. */
  calculateInProductCost(): Cost | null {
    const initialCostDataWithUnit: ExcludeMethodsDeep<Cost> = {
      ...Cost.zero(),
      unit:
        this.rawMaterialCost?.unit ??
        this.haulingCost?.unit ??
        this.plantLoadingCost?.unit ??
        Cost.zero().unit,
    };
    const initialCostWithUnit = new Cost(initialCostDataWithUnit);

    const baseCostsSum = initialCostWithUnit.add(
      this.rawMaterialCost ?? Cost.zero(),
      this.haulingCost ?? Cost.zero(),
      this.plantLoadingCost ?? Cost.zero(),
    );

    if (this.costRule === null) {
      return baseCostsSum;
    }

    switch (this.costRule.kind) {
      case Enums.CostRuleKind.Flat:
        // eslint-disable-next-line no-case-declarations
        const flatToAdd: ExcludeMethodsDeep<Cost> = {
          amount: this.costRule.flatChange ?? Currency.zero(),
          unit: initialCostWithUnit.unit,
        };

        return baseCostsSum !== null ? baseCostsSum.add(new Cost(flatToAdd)) : new Cost(flatToAdd);

      case Enums.CostRuleKind.Ratio:
        if (baseCostsSum === null) {
          return null;
        }

        if (this.costRule.markupRatio === null || this.costRule.markupRatio === '') {
          return baseCostsSum;
        }

        return baseCostsSum.multiply(1 + parseFloat(this.costRule.markupRatio));

      default:
        return baseCostsSum;
    }
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewMaterial = (props: PartialDeep<Material, { recurseIntoArrays: true }>): Material =>
  new Material(props);

export const NewMaterialFromDomainObject = (
  props: PartialDeep<DomainObject<Material>, { recurseIntoArrays: true }>,
): Material => new Material(props);
