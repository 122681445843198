import { Drawer, PaperProps, SlideProps, SxProps, Theme, useTheme } from '@mui/material';
import React from 'react';

type SlabDrawerProps = {
  isOpen: boolean;
  /** @default undefined */
  slideProps?: Partial<SlideProps>;
  /**
   * @default
   * { sx: { width: '60rem', maxWidth: '100%' } }
   */
  paperProps?: Partial<PaperProps>;
  /**
   * @default
   * { zIndex: theme.zIndex.drawer + 2 }
   */
  sx?: SxProps<Theme>;
  children: React.ReactNode;
};

/**
 * The SlabDrawer is a wrapper around our app's "drawer content".
 * It renders `null` when the drawer is not open to avoid firing
 * requests when the content is not rendered and displayed to an end-user,
 * while still maintaining open/close animation.
 *
 * @example
 * <SlabDrawer isOpen={props.isOpen}>
 *   <ContactDrawerContent {...props} />
 * </SlabDrawer>
 */
export const SlabDrawer = ({
  isOpen,
  slideProps = undefined,
  paperProps = { sx: { width: '60rem', maxWidth: '100%' } },
  sx,
  children,
}: SlabDrawerProps): JSX.Element => {
  const theme = useTheme();

  const sxVal = sx !== undefined ? sx : { zIndex: theme.zIndex.drawer + 2 };

  // We render the drawer with null content inside rather than just
  // returning a `null` JSX Element so that the drawer maintains its
  // native ability to open and close with animation.
  return (
    <Drawer anchor='right' open={isOpen} SlideProps={slideProps} PaperProps={paperProps} sx={sxVal}>
      {!isOpen ? null : children}
    </Drawer>
  );
};
