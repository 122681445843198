import _ from 'lodash';
import { DateTime } from 'luxon';

import { Forecast } from '../../generated-types/Forecast/Forecast';
import { MonthlyForecastCells } from '../../generated-types/row-types';
import { DEFAULT_FORECAST_YEAR_FROM, DEFAULT_FORECAST_YEAR_TO } from '../../utils/DateHelpers';

export const filteredMappedForecasts = (
  forecasts: Forecast[],
  year: number,
): MonthlyForecastCells =>
  forecasts
    .filter((f) => f.intervalStart.year === year)
    .reduce(
      (acc, f) => ({
        ...acc,
        [f.intervalStart.month]: f.value === '' ? 0 : parseFloat(f.value),
      }),
      {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
    );

type YearOption = {
  year: number;
  hasValues: boolean;
};

/** Given a set of years with forecast data, return an array of possible year options for filtering. */
export const possibleYears = ({
  yearsWithForecasts,
  now = DateTime.now(),
}: {
  yearsWithForecasts: ReadonlySet<number>;
  now?: DateTime;
}): YearOption[] => {
  const years = [...yearsWithForecasts].sort((a, b) => a - b);
  const min = Math.min(now.year + DEFAULT_FORECAST_YEAR_FROM, _.first(years) ?? Infinity);
  const max = Math.max(now.year + DEFAULT_FORECAST_YEAR_TO + 1, _.last(years) ?? -Infinity);
  return _.range(min, max).map((year) => ({ year, hasValues: yearsWithForecasts.has(year) }));
};
