import { Alert } from '@mui/material';
import IframeResizer from 'iframe-resizer-react';

import Enums from '../../generated-types/Enums';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { TabBar } from '../TabBar/TabBar';

type MetabaseDashboardProps = {
  kind: Enums.DashboardKind;
};

// Consider the dashboard response to be stale when its age is slightly less
// than the default expiration time configured on the backend.
const DASHBOARD_EXPIRATION_SECONDS = (60 * 60 - 5) * 1000;

export const MetabaseDashboard = (props: MetabaseDashboardProps): JSX.Element => {
  const { isLoading, isError, data } = useSlabQuery(
    'GET dashboards',
    { pathParams: { kind: props.kind } },
    {
      staleTime: DASHBOARD_EXPIRATION_SECONDS,
    },
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || data === undefined) {
    return <div>ERROR</div>;
  }

  if (data.length < 1) {
    return (
      <Alert severity='error'>
        You do not have the necessary permissions to view the dashboard on this page. Please contact
        a Slabstack administrator for support.
      </Alert>
    );
  }

  return (
    <TabBar
      hideBarIfSingleTab
      tabs={data.map((dash) => ({
        label: dash.name,
        element: (
          <IframeResizer
            title={dash.name}
            src={dash.url}
            style={{ width: '100%', height: '100%' }}
          />
        ),
      }))}
    />
  );
};
