// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class Address {
  readonly line1: string | null;
  readonly line2: string | null;
  readonly city: string | null;
  readonly state: string;
  readonly country: string | null;
  readonly postalCode: string | null;
  readonly latitude: string | null;
  readonly longitude: string | null;

  constructor(data: { [key: string]: any } = {}) {
    this.line1 = data.line1 === undefined ? null : data.line1;
    this.line2 = data.line2 === undefined ? null : data.line2;
    this.city = data.city === undefined ? null : data.city;
    this.state = data.state === undefined ? 'AK' : data.state;
    this.country = data.country === undefined ? null : data.country;
    this.postalCode = data.postalCode === undefined ? null : data.postalCode;
    this.latitude = data.latitude === undefined ? null : data.latitude;
    this.longitude = data.longitude === undefined ? null : data.longitude;
  }

  static zero(): Address {
    const zeroValues: ExcludeMethodsDeep<Address> = {
      line1: null,
      line2: null,
      city: null,
      state: 'AK',
      country: null,
      postalCode: null,
      latitude: null,
      longitude: null,
    };
    return new Address(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /** Returns the fields of the address in a normative format for addresses.
   * The block of `line1 line2?` will be followed by a `;`, `city` will be followed by a `,`
   * if `city`` is not null, otherwise there is no `,`. In all other cases, if
   * a property is `null`, then it is just excluded from the string.
   *
   * @example
   * const add = NewAddress({
   *   line1: 'line1',
   *   line2: 'line2',
   *   city: 'city',
   *   state: 'state',
   *   postalCode: 'zip',
   *   country: 'country',
   * });
   * // Logs: "line1 line2; city, state zip country"
   * console.log(add.addressDisplay());

   * @example
   * const add = NewAddress({
   *   line1: 'line1',
   *   state: 'state',
   *   country: 'country',
   * });
   * // Logs: "line1; state country"
   * console.log(add.addressDisplay());
   *
   * @example
   * const add = NewAddress({
   *   line2: 'line2',
   *   state: 'state',
   *   country: 'country',
   * });
   * // Logs: "line2; state country"
   * console.log(add.addressDisplay());
   *
   * @example
   * const add = NewAddress({
   *   state: 'state',
   * });
   * // Logs: "state"
   * console.log(add.addressDisplay());
   */
  addressDisplay(): string {
    return ''
      .concat(this.line1 ?? '')
      .concat(this.line2 !== null && this.line2 !== undefined ? ` ${this.line2}` : '')
      .concat(
        (this.line1 !== null && this.line1 !== undefined) ||
          (this.line2 !== null && this.line2 !== undefined)
          ? ';'
          : '',
      )
      .concat(this.city !== null && this.city !== undefined ? ` ${this.city},` : '')
      .concat(` ${this.state}`)
      .concat(
        this.postalCode !== null && this.postalCode !== undefined ? ` ${this.postalCode}` : '',
      )
      .concat(this.country !== null && this.country !== undefined ? ` ${this.country}` : '')
      .replace(/;$/, '')
      .trimStart();
  }

  /** Returns the address formatted as `line1, line2` */
  basicDisplay(): string {
    return [this.line1, this.line2].filter((s) => s !== null).join(', ');
  }

  /** Returns the address formatted as `city, state postalCode` */
  basicStateDisplay(): string {
    const addressData: Partial<ExcludeMethodsDeep<Address>> = {
      city: this.city,
      state: this.state,
      postalCode: this.postalCode,
    };
    return new Address(addressData).addressDisplay();
  }

  toLatLngPairString(): string | undefined {
    return this !== undefined &&
      this.latitude !== null &&
      this.latitude !== undefined &&
      this.longitude !== null &&
      this.longitude !== undefined
      ? `${this.latitude},${this.longitude}`
      : undefined;
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewAddress = (props: PartialDeep<Address, { recurseIntoArrays: true }>): Address =>
  new Address(props);

export const NewAddressFromDomainObject = (
  props: PartialDeep<DomainObject<Address>, { recurseIntoArrays: true }>,
): Address => new Address(props);
