import { Auth0ContextInterface, User } from '@auth0/auth0-react';
import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import LogRocket from 'logrocket';

import { QueryRouteBarrelTypes, QueryRouteBarrelVals } from '../utils/ApiClient';
import { QueryError, ServerError } from '../utils/Query';
import { SlabConfig } from '../utils/SlabConfig';
import { useSlabAuth } from './useSlabAuth';

/**
 * See https://www.geeksforgeeks.org/maximum-length-of-a-url-in-different-browsers/
 *
 * As of 10/11/2023, Edge has the shortest allowable length of a URL, which is 2083 characters.
 */
const MAX_URL_LENGTH = 2083;

type Params = Record<string, object | string | number | boolean>;

type UrlFromParamsOpts = {
  queryParams: Params;
  pathParams: Params;
  urlSplits: string[];
};

/**
 * Given a record of objects/arrays/strings, flatten it
 * into a URL-processable string[][] object.
 */
export const flattenRecord = (params: Params): [string, string][] => {
  const result: [string, string][] = [];
  Object.entries(params).forEach(([key, val]) => {
    if (typeof val === 'object' && Array.isArray(val) && val.length > 0) {
      // Handle arrays of primitives
      if (typeof _.first(val) !== 'object') {
        val.forEach((subObj) => {
          result.push([`${key}`, subObj]);
        });
      } else {
        // Handle arrays of objects
        val.forEach((subObj, idx) => {
          const fr = flattenRecord(subObj as Params);
          Object.entries(fr).forEach(([, [subKey, subVal]]) => {
            result.push([`${key}.${idx}.${subKey}`, subVal]);
          });
        });
      }
    } else if (typeof val === 'object' && !Array.isArray(val)) {
      // Handle objects
      const fr = flattenRecord(val as Params);
      Object.entries(fr).forEach(([, [subKey, subVal]]) => {
        result.push([`${key}.${subKey}`, subVal]);
      });
    } else {
      // Handle primitives
      result.push([key, String(params[key])]);
    }
  });
  return result;
};

export const UrlFromParams = ({
  queryParams,
  pathParams,
  urlSplits,
}: UrlFromParamsOpts): string => {
  // Convert query params to URL parameters
  const flattenedQueryParams = flattenRecord(queryParams);
  const queryParamsString = String(new URLSearchParams(flattenedQueryParams));
  const queryParamsURLString = queryParamsString === '' ? '' : `?${queryParamsString}`;

  // Use the path parameters to construct the base URL
  const stringifiedPathParams = Object.fromEntries(
    Object.entries(pathParams).map(([k, val]) => [k, String(val)]),
  );
  const baseUrl = urlSplits.map((u) => stringifiedPathParams[u] ?? u).join('');

  // Combine the base URL with path parameters to the query parameters
  const fullUrl = `${baseUrl}${queryParamsURLString}`;
  if (fullUrl.length >= MAX_URL_LENGTH) {
    LogRocket.error(
      'Generated a URL surpassing the maximum URL length for the minimum-allowed browser.',
      `Max Length allowed: ${MAX_URL_LENGTH}`,
      `Length encountered: ${fullUrl.length}`,
      fullUrl,
    );
  }
  return fullUrl;
};

export type ErrorResponse = {
  response?: {
    data: ServerError;
  };
};

/**
 * @returns the friendly error to show the user from an error response, or undefined
 * if there was no message.
 */
export const ServerErrorMessage = (err?: ErrorResponse): string | undefined =>
  err?.response?.data?.message;

export type UseSlabQueryType<
  TKey extends keyof QueryRouteBarrelTypes,
  TBarrel extends QueryRouteBarrelTypes[TKey],
  TError extends QueryError,
> = {
  routeBarrelKey: TKey;
  args: TBarrel['args'];
  options?:
    | Omit<UseQueryOptions<TBarrel['returns'], TError, TBarrel['returns'], QueryKey>, 'queryKey'>
    | undefined;
};

// exported for testing
export const MakeQueryArgs = <
  TKey extends keyof QueryRouteBarrelTypes,
  TBarrel extends QueryRouteBarrelTypes[TKey],
  TError extends QueryError,
>(
  getAccessTokenSilently: Auth0ContextInterface<User>['getAccessTokenSilently'],
  routeBarrelKey: UseSlabQueryType<TKey, TBarrel, TError>['routeBarrelKey'],
  args: UseSlabQueryType<TKey, TBarrel, TError>['args'],
  options?: UseSlabQueryType<TKey, TBarrel, TError>['options'],
): Parameters<typeof useQuery<TBarrel['returns'], TError>>[0] => {
  const barrel = QueryRouteBarrelVals[routeBarrelKey];
  const fullUrl = UrlFromParams({
    queryParams: ((args as any)?.queryParams as Params) ?? {},
    pathParams: ((args as any)?.pathParams as Params) ?? {},
    urlSplits: barrel.urlSplits,
  });

  const getObject = async (): Promise<TBarrel['returns']> => {
    // Check the user has a valid access token
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: SlabConfig.auth0.audience,
      },
    });

    // Extract the raw ID_Token into the authoerization bearer header
    const headers = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const { data } = await axios.get(fullUrl, headers);
    return barrel.new(data);
  };

  return {
    ...options,
    queryKey: [fullUrl, routeBarrelKey],
    queryFn: getObject,
  };
};

export const useSlabQuery = <
  TKey extends keyof QueryRouteBarrelTypes,
  TBarrel extends QueryRouteBarrelTypes[TKey],
  TError extends QueryError,
>(
  routeBarrelKey: UseSlabQueryType<TKey, TBarrel, TError>['routeBarrelKey'],
  args: UseSlabQueryType<TKey, TBarrel, TError>['args'],
  options?: UseSlabQueryType<TKey, TBarrel, TError>['options'],
): UseQueryResult<TBarrel['returns'], TError> => {
  const { getAccessTokenSilently } = useSlabAuth();
  return useQuery<TBarrel['returns'], TError>(
    MakeQueryArgs(getAccessTokenSilently, routeBarrelKey, args, options),
  );
};

export const useSlabQueryFetcher = <
  TKey extends keyof QueryRouteBarrelTypes,
  TBarrel extends QueryRouteBarrelTypes[TKey],
  TError extends QueryError,
>(): ((
  routeBarrelKey: UseSlabQueryType<TKey, TBarrel, TError>['routeBarrelKey'],
  args: UseSlabQueryType<TKey, TBarrel, TError>['args'],
  options?: Omit<UseSlabQueryType<TKey, TBarrel, TError>['options'], 'refetchInterval'>,
) => Promise<TBarrel['returns']>) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useSlabAuth();
  return (routeBarrelKey, args, options) =>
    queryClient.fetchQuery(MakeQueryArgs(getAccessTokenSilently, routeBarrelKey, args, options));
};
