import { Box, Chip, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { DisplayField } from '../../components/DisplayField/DisplayField';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Page } from '../../components/Page/Page';
import { useToast } from '../../components/Toast/useToast';
import { Units } from '../../generated-types/generated-units';
import { NewProduct, Product } from '../../generated-types/Product/Product';
import { ServerErrorMessage, useSlabQuery } from '../../hooks/useSlabQuery';
import { CurrencyString, NullableCurrencyString } from '../../utils/Currency';
import { NIL_UUID } from '../../utils/UUID';
import { SimilarProductTable } from './components/SimilarProductTable';
import { ProductDrawer } from './ProductDrawer';

export const ProductDetailsPage = (): JSX.Element => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();

  const [productId, setProductId] = useState<string | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const { showToast, toast } = useToast('product');

  const {
    isLoading,
    isError,
    data: product,
  } = useSlabQuery('GET product by ID', {
    pathParams: {
      id: params?.id ?? '',
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || product === undefined) {
    return <div>ERROR</div>;
  }

  const title = product.name;

  const displayProduct = {
    ...product,
    measurementUnit: Units[product.measurementUnit].label,
    mixDesignID: product.mix?.alternateID ?? null,
    mixDesignName: product.mix?.name ?? null,
    mixDesignPsi: product.mix?.psi ?? null,
    materialID: product.resaleMaterial?.alternateID ?? null,
    materialName: product.resaleMaterial?.name ?? null,
    materialType: product.resaleMaterial?.materialType ?? null,
    listPriceString: NullableCurrencyString({ cur: product.listPrice, omitNull: true }),
    suggestedPriceString: NullableCurrencyString({ cur: product.calculateSuggestedPrice() }),
    materialCostString: CurrencyString({ cur: product.getMaterialCost() }),
    otherCostString: CurrencyString({ cur: product.otherCost }),
    marginString: product.marginDisplay(),
    marginOverMaterials: product.marginOverMaterialDisplay(),
    minimumMarginString: product.minimumMarginDisplay(),
    targetMarginString: product.targetMarginDisplay(),
    plantName: product.plant.name,
    cuydVolumeString: product.cuydVolume?.toLocaleString() ?? null,
    incompatibleMixBatchUnitsString: product.incompatibleMixBatchUnits ? 'UOM issue' : '[no error]',
  };

  const displayFields = {
    'Product Details': (
      <DisplayField
        title='Product Details'
        onEditClick={(): void => {
          setProductId(product.id);
          setIsDrawerOpen(true);
        }}
        value={displayProduct}
        type='singleField'
        displayMatrix={[
          [
            { key: 'alternateID', label: 'Product ID' },
            { key: 'name', label: 'Name' },
            {
              key: 'plantName',
              label: 'Plant',
              type: 'uri',
              uri: `/plants/${product.plant.id}`,
            },
          ],
          [
            { key: 'category', label: 'Category' },
            { key: 'measurementUnit', label: 'Unit of measure' },
            { key: 'cuydVolumeString', label: 'CUYD per unit' },
          ],
        ]}
      />
    ),
    'Mix Design': (
      <DisplayField
        title='Mix Design'
        onEditClick={(): void => {
          setProductId(product.id);
          setIsDrawerOpen(true);
        }}
        value={displayProduct}
        type='singleField'
        displayMatrix={[
          [
            { key: 'mixDesignID', label: 'ID' },
            { key: 'mixDesignName', label: 'Name' },
          ],
          [
            { key: 'mixDesignPsi', label: 'PSI' },
            {
              key: 'incompatibleMixBatchUnitsString',
              label: 'Incompatible batch unit',
              color: displayProduct.incompatibleMixBatchUnits ? 'error' : undefined,
            },
          ],
        ]}
      />
    ),
    'Material Details': (
      <DisplayField
        title='Material'
        onEditClick={(): void => {
          setProductId(product.id);
          setIsDrawerOpen(true);
        }}
        value={displayProduct}
        type='singleField'
        displayMatrix={[
          [
            { key: 'materialID', label: 'ID' },
            { key: 'materialName', label: 'Name' },
            { key: 'materialType', label: 'Material type' },
          ],
        ]}
      />
    ),
    'Cost Details': (
      <DisplayField
        title='Costs'
        onEditClick={(): void => {
          setProductId(product.id);
          setIsDrawerOpen(true);
        }}
        value={displayProduct}
        type='singleField'
        displayMatrix={[
          [
            { key: 'materialCostString', label: 'Cost of raw materials' },
            { key: 'otherCostString', label: 'Other costs' },
          ],
        ]}
      />
    ),
    'Suggested Pricing': (
      <DisplayField
        title='Suggested Pricing'
        onEditClick={(): void => {
          setProductId(product.id);
          setIsDrawerOpen(true);
        }}
        value={displayProduct}
        type='singleField'
        displayMatrix={[
          [
            { key: 'minimumMarginString', label: 'Minimum margin' },
            { key: 'targetMarginString', label: 'Target margin' },
          ],
          [{ key: 'suggestedPriceString', label: 'Suggested price' }],
        ]}
      />
    ),
    'List Pricing': (
      <DisplayField
        title='List Pricing'
        onEditClick={(): void => {
          setProductId(product.id);
          setIsDrawerOpen(true);
        }}
        value={displayProduct}
        type='singleField'
        displayMatrix={[
          [
            { key: 'listPriceString', label: 'List price' },
            { key: 'marginOverMaterials', label: 'Margin over materials' },
          ],
          [{ key: 'marginString', label: 'Total margin' }],
        ]}
      />
    ),
  };

  return (
    <Page title={title}>
      {toast}
      <ProductDrawer
        productId={productId}
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        onClose={(): void => {
          // Always reset the product ID onClose to re-render the drawer.
          setProductId(null);
          setIsCloning(false);
        }}
        onSuccess={(p): void => {
          showToast('success');
          setIsDrawerOpen(false);
          if (isCloning) {
            setIsCloning(false);
            navigate(`/products/${p.id}`);
          }
        }}
        onError={(err): void => showToast('error', ServerErrorMessage(err))}
        ensureDefined={(curProduct): Product => {
          if (isCloning && curProduct !== undefined) {
            return NewProduct({
              ...curProduct,
              id: NIL_UUID,
              name: `${curProduct.name} - Copy`,
              externalID: null,
            });
          }
          return curProduct ?? Product.zero();
        }}
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='2.5rem' gap='1.25rem'>
        <Box display='flex' alignItems='center' gap='1.25rem'>
          <Typography variant='h1'>{title}</Typography>
          {product.incompatibleMixBatchUnits && <Chip color='error' label='UOM issue' />}
        </Box>
        <Box display='flex' gap='1rem'>
          <ButtonPill
            text='clone product'
            variant='secondary'
            onClick={(): void => {
              setProductId(product.id);
              setIsCloning(true);
              setIsDrawerOpen(true);
            }}
            icon='add'
          />
          <ButtonPill
            text='edit product'
            variant='primary'
            onClick={(): void => {
              setProductId(product.id);
              setIsDrawerOpen(true);
            }}
            icon='edit'
          />
        </Box>
      </Box>
      {product.incompatibleMixBatchUnits && (
        <Box paddingBottom='1rem'>
          <Typography color={theme.palette.error.main}>
            Fix UOM issue in{' '}
            <Link to={`/mixdesigns/${product.mix?.id}`}>this product’s mix design</Link> to use it
            in projects or quotes
          </Typography>
        </Box>
      )}
      <Box
        display='flex'
        gap='1.25rem'
        paddingBottom='1.25rem'
        sx={{
          // Stretch immediate DisplayFields to take full width + equal width
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          },
          // Stretch DisplayFields' value section to fill available height
          '& > div > div:last-child': {
            flex: 1,
          },
        }}
      >
        {displayFields['Product Details']}
        {product.mix !== null ? displayFields['Mix Design'] : null}
        {product.resaleMaterial !== null ? displayFields['Material Details'] : null}
      </Box>
      <Box
        display='flex'
        gap='1.25rem'
        sx={{
          // Stretch immediate DisplayFields to take full width + equal width
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          },
          // Stretch DisplayFields' value section to fill available height
          '& > div > div:last-child': {
            flex: 1,
          },
        }}
      >
        {displayFields['Cost Details']}
        {displayFields['Suggested Pricing']}
        {displayFields['List Pricing']}
      </Box>
      <Box pt='2.5rem'>
        <SimilarProductTable
          product={product}
          onEditPress={(row): void => {
            setProductId(row.id);
            setIsDrawerOpen(true);
          }}
        />
      </Box>
    </Page>
  );
};
