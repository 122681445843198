import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { Field, FieldProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import {
  EmailRecipient,
  NewEmailRecipient,
} from '../../generated-types/EmailRecipient/EmailRecipient';
import { randomUUID } from '../../utils/UUID';
import { InputTooltip } from '../InputTooltip/InputTooltip';

interface MultiEmailInputProps {
  name: string;
  label: string;
  tip?: string;
  placeholder?: string;
  limit?: number;
}
/**
 * EmailRecipientWithIDType is a type to handle the case where a user enters the same
 * email address twice and tries to delete one of the values, each value must have a
 * unique ID to be used as a key on the Chip element.
 */
type EmailRecipientWithIDType = EmailRecipient & { id: string };
/**
 * @description EmailRecipientWithID takes an EmailRecipient object and adds a random
 * UUID in the id field, converting the object to be of type EmailRecipientWithIDType
 * @param recpt The EmailRecipient to be given an ID
 * @returns An EmailRecipientWithIDType that is the original EmailRecipient with a random
 * UUID stored in the id field
 */
const EmailRecipientWithID = (recpt: EmailRecipient): EmailRecipientWithIDType => ({
  ...recpt,
  id: randomUUID(),
});

/**
 * @description SplitInputIntoRecipients splits a string of zero or more email addresses
 * separated by commas or semicolons into a list of EmailRecipientWithIDType objects. If
 * the input is a string with no semicolons or commas, the entire string will be set to the
 * email field of the single object in the returned list
 * @param {string} textInput a string that represents zero or more email addresses separated
 * by commaas or semicolons
 * @returns A list of {EmailRecipientWithIDType} objects that have the email field set
 * to one of the emails in the input
 */
export const SplitInputIntoRecipients = (textInput: string): EmailRecipientWithIDType[] =>
  textInput
    .split(/\s*,\s*|\s*;\s*/)
    .map((i) =>
      i !== undefined && i !== ''
        ? EmailRecipientWithID(NewEmailRecipient({ email: i }))
        : undefined,
    )
    .filter((i) => i !== undefined) as EmailRecipientWithIDType[];

/**
 * @description A MultiEmailInput element is an Input-like element that allows for the user
 * to input multiple email addresses in the same input field. The email addresses that have
 * been "confirmed" as values are displayed as Chip elements in the StartAdornment of the
 * TextField. The freeform text input is confirmed as a value by typing a comma or
 * a semicolon, by pressing enter, or when the MultiEmailInput field loses focus. The values
 * that are confirmed are validated by Yup to be (structurally) valid email addresses. If
 * a confirmed value is not a structurally valid email address, the Chip representing that
 * value will be colored as an error.
 *
 * @param name The name of the Formik field
 * @param label The string to use as the field's label
 * @param tip A tooltip for the input field
 * @param placeholder A placeholder value
 * @returns An Input-like element that allows for inputting multiple email addresses
 */
export const MultiEmailInput = ({
  name,
  label,
  tip = `You can confirm the current value using a comma or semicolon, by pressing
   return/enter, or by moving to another input field`,
  placeholder,
  limit = 4,
}: MultiEmailInputProps): JSX.Element => {
  const labelWithTip =
    label === undefined ? null : (
      <Box display='flex' gap='0.25rem' alignItems='center'>
        {label}
        {tip !== undefined ? <InputTooltip tip={tip} /> : null}
      </Box>
    );

  return (
    <Field id={name} name={name}>
      {({
        field: { value, onBlur },
        form: { setFieldValue, setFieldTouched },
        meta,
      }: FieldProps<EmailRecipient[]>): JSX.Element => {
        const [emails, setEmails] = useState<EmailRecipientWithIDType[]>(
          value?.map((v) => EmailRecipientWithID(v)) ?? [],
        );
        const [textValue, setTextValue] = useState<string>('');
        const hasError = meta.touched && meta.error !== undefined;

        // This validates the field, showing helper text, after any of the freeform inputs are
        // turned into a value inside of a Chip or a chip is removed.
        useEffect(() => {
          if (emails.length > 0) {
            setFieldTouched(name, true);
          }
          setFieldValue(name, emails?.map((v) => NewEmailRecipient(v)) ?? []);
        }, [emails]);

        return (
          <Autocomplete
            id={name}
            autoSelect
            freeSolo
            multiple
            fullWidth
            value={emails}
            limitTags={limit}
            options={emails}
            inputValue={textValue}
            onBlur={onBlur}
            open={false}
            isOptionEqualToValue={(o, v): boolean => o.id === v.id}
            getOptionLabel={(option): string =>
              typeof option === 'string' ? option : option.email
            }
            getLimitTagsText={(more): string => `+ ${more} more`}
            onInputChange={(e, textInput): void => {
              if (textInput?.includes(';') || textInput?.includes(',')) {
                setEmails([...emails, ...SplitInputIntoRecipients(textInput)]);
                setTextValue('');
              } else {
                setTextValue(textInput);
              }
            }}
            onChange={(e, r): void => {
              // r is a list, an element of r is either a string or an EmailRecipientWithIDType.
              // The strings in r need to be converted to the correct value.
              setEmails(
                r.map((v) =>
                  typeof v === 'string' ? EmailRecipientWithID(NewEmailRecipient({ email: v })) : v,
                ),
              );
              setTextValue('');
            }}
            renderTags={(v): JSX.Element[] =>
              v.map(
                (tag): JSX.Element => (
                  <Chip
                    key={tag.id}
                    id={tag.id}
                    label={tag.email}
                    color={Yup.string().email().isValidSync(tag.email) ? 'primary' : 'error'}
                    onKeyDown={(e): void => {
                      if (e.key === 'Backspace') {
                        // If the event propagates, multiple chips will likely be removed unless the user
                        // releases the backspace key very quickly after pressing it
                        e.stopPropagation();
                      }
                    }}
                    onDelete={(): void => {
                      setEmails(emails.filter((e) => e.id !== tag.id));
                    }}
                    sx={{
                      margin: '.25rem',
                    }}
                  />
                ),
              )
            }
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                id={name}
                name={name}
                error={hasError}
                placeholder={placeholder}
                helperText={hasError ? meta.error : ' '}
                type='email'
                label={labelWithTip}
              />
            )}
          />
        );
      }}
    </Field>
  );
};
