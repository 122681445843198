// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { ForecastPlantAgg, NewForecastPlantAgg } from '../ForecastPlantAgg/ForecastPlantAgg';
import { ForecastStatusAgg, NewForecastStatusAgg } from '../ForecastStatusAgg/ForecastStatusAgg';
import {
  MonthlyForecastAgg,
  NewMonthlyForecastAgg,
} from '../MonthlyForecastAgg/MonthlyForecastAgg';
import { NewYearlyForecastAgg, YearlyForecastAgg } from '../YearlyForecastAgg/YearlyForecastAgg';

export class ForecastAgg {
  readonly byMonth: MonthlyForecastAgg[];
  readonly byYear: YearlyForecastAgg[];
  readonly byStatus: ForecastStatusAgg[];
  readonly byPlant: ForecastPlantAgg[];

  constructor(data: { [key: string]: any } = {}) {
    this.byMonth = (data.byMonth ?? []).map((byMonth: any) => NewMonthlyForecastAgg(byMonth));
    this.byYear = (data.byYear ?? []).map((byYear: any) => NewYearlyForecastAgg(byYear));
    this.byStatus = (data.byStatus ?? []).map((byStatus: any) => NewForecastStatusAgg(byStatus));
    this.byPlant = (data.byPlant ?? []).map((byPlant: any) => NewForecastPlantAgg(byPlant));
  }

  static zero(): ForecastAgg {
    const zeroValues: ExcludeMethodsDeep<ForecastAgg> = {
      byMonth: [],
      byYear: [],
      byStatus: [],
      byPlant: [],
    };
    return new ForecastAgg(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewForecastAgg = (
  props: PartialDeep<ForecastAgg, { recurseIntoArrays: true }>,
): ForecastAgg => new ForecastAgg(props);

export const NewForecastAggFromDomainObject = (
  props: PartialDeep<DomainObject<ForecastAgg>, { recurseIntoArrays: true }>,
): ForecastAgg => new ForecastAgg(props);
