// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class RevokeTokenOutput {
  readonly success?: boolean;

  constructor(data: { [key: string]: any } = {}) {
    this.success = data.success;
  }

  static zero(): RevokeTokenOutput {
    const zeroValues: ExcludeMethodsDeep<RevokeTokenOutput> = {};
    return new RevokeTokenOutput(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewRevokeTokenOutput = (
  props: PartialDeep<RevokeTokenOutput, { recurseIntoArrays: true }>,
): RevokeTokenOutput => new RevokeTokenOutput(props);

export const NewRevokeTokenOutputFromDomainObject = (
  props: PartialDeep<DomainObject<RevokeTokenOutput>, { recurseIntoArrays: true }>,
): RevokeTokenOutput => new RevokeTokenOutput(props);
