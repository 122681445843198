import { Box, Grid, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Page } from '../../components/Page/Page';
import { TaskList } from '../../components/TaskList/TaskList';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { UserActivities } from './components/UserActivities';

export const ActivitiesList = (): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activityId, setActivityId] = useState<string | null>(null);

  const { isLoading, isError, data: activityList } = useSlabQuery('GET activities', {});

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || activityList === undefined) {
    return <div>ERROR</div>;
  }

  const currentDate = DateTime.now();

  return (
    <Page title='Activities'>
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Activities</Typography>
        <ButtonPill
          text='add activity'
          variant='primary'
          onClick={(): void => {
            setActivityId(null);
            setIsDrawerOpen(true);
          }}
          icon='add'
        />
      </Box>

      <Grid container height='800px'>
        <Grid item xs={9}>
          <UserActivities
            currentDate={currentDate}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            activityId={activityId}
            setActivityId={setActivityId}
          />
        </Grid>
        <Grid item xs={3}>
          <Box paddingX='1.5rem'>
            <TaskList activities={activityList.items} currentDate={currentDate} />
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};
