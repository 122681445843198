import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { Column, ColumnConfig, ColumnFromConfig } from '../../components/DataTable/TableDataModel';
import { NestedKeyOf } from '../../utils/Types';
import { randomUUID } from '../../utils/UUID';

const borderedStyle = StyleSheet.create({
  borderedRow: {
    borderLeft: '1px solid black',
    borderBottom: '1px solid black',
  },
  borderTop: {
    borderTop: '1px solid black',
  },
  borderedCell: {
    borderRight: '1px solid black',
  },
});

const stripedStyle = StyleSheet.create({
  striped: {
    backgroundColor: '#f2f2f2',
  },
});

const tightPaddingStyle = StyleSheet.create({
  tight: {
    padding: 0,
  },
});

export const Styles = StyleSheet.create({
  table: {
    display: 'table' as any,
    width: 'auto',
    border: 0,
    tableLayout: 'auto',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    // borderStyle: BORDER_STYLE,
    // borderColor: BORDER_COLOR,
  },
  tableCol: {
    // borderStyle: BORDER_STYLE,
    // borderColor: BORDER_COLOR,
  },
  tableCellHeader: {
    padding: 5,
    fontSize: '12pt',
    fontWeight: 500,
  },
  tableCell: {
    width: 'auto',
    padding: 5,
    fontSize: '10pt',
  },
});

export type PdfColumnConfig<TRow extends { id: string }> = ColumnConfig<TRow, NestedKeyOf<TRow>> & {
  formatColumnHeaderForPdf?: (label: string) => string | JSX.Element | null;
  headerViewStyle?: Style;
  rowViewStyle?: Style;
  widthOverride?: Style['width'];
};

export type PdfColumn<TRow extends { id: string }> = Column<TRow> & {
  readonly columnHeaderToPdf: (label: string) => string | JSX.Element | null;
  readonly headerViewStyle?: Style;
  readonly rowViewStyle?: Style;
  readonly widthOverride?: Style['width'];
};

export const DEFAULT_COLUMN_HEADER_FORMATTER = (label: string): JSX.Element => (
  <Text style={Styles.tableCellHeader}>{label}</Text>
);

export const PdfColumnFromConfig = <TRow extends { id: string }>(
  config: PdfColumnConfig<TRow>,
): PdfColumn<TRow> => {
  const columnHeaderToPdf = config.formatColumnHeaderForPdf ?? DEFAULT_COLUMN_HEADER_FORMATTER;
  const webColumn = ColumnFromConfig(config);
  return {
    ...webColumn,
    columnHeaderToPdf,
    widthOverride: config.widthOverride,
    headerViewStyle: config.headerViewStyle,
    rowViewStyle: config.rowViewStyle,
  };
};

export type PdfTableProps<TRow extends { id: string }> = {
  columns: PdfColumn<TRow>[];
  rows: TRow[];
  /** @default false */
  bordered?: boolean;
  /** @default false */
  striped?: boolean;
  /** @default undefined */
  cellPadding?: 'tight';
  /** @default false */
  displayHeaders?: boolean;
};

export const PdfTable = <TRow extends { id: string }>({
  columns,
  rows,
  bordered = false,
  striped = false,
  cellPadding,
  displayHeaders = false,
}: PdfTableProps<TRow>): JSX.Element => {
  // If no rows & columns are not displayed, return empty value to avoid taking up space
  if (rows.length === 0 && !displayHeaders) {
    return (
      <View>
        <Text> </Text>
      </View>
    );
  }
  const bs = bordered ? borderedStyle.borderedRow : {};
  const ss = striped ? stripedStyle.striped : {};
  const cp = cellPadding === 'tight' ? tightPaddingStyle.tight : {};
  return (
    <View style={[Styles.table]}>
      {displayHeaders && (
        <View style={[Styles.tableRow, bs, bordered ? borderedStyle.borderTop : {}]}>
          {columns.map((col) => {
            const pdfCell = col.columnHeaderToPdf(col.label);
            const width = col.widthOverride ?? `${100 / columns.length}%`;
            return (
              <View
                key={randomUUID()}
                style={[
                  Styles.tableColHeader,
                  { width },
                  bordered === true ? borderedStyle.borderedCell : {},
                  col.headerViewStyle ?? {},
                ]}
              >
                {pdfCell}
              </View>
            );
          })}
        </View>
      )}
      {rows.map((row, i) => (
        <View
          key={randomUUID()}
          wrap={false}
          style={[
            Styles.tableRow,
            bs,
            i % 2 === 0 ? ss : {},
            i === 0 && bordered && displayHeaders === false ? borderedStyle.borderTop : {},
          ]}
        >
          {columns.map((col) => {
            const v = col.toPdf(row);
            const width = col.widthOverride ?? `${100 / columns.length}%`;
            return (
              <View
                key={randomUUID()}
                style={[
                  Styles.tableCol,
                  { height: '100%', width },
                  bordered === true ? borderedStyle.borderedCell : {},
                  col.rowViewStyle ?? {},
                ]}
              >
                {v === null || typeof v === 'string' ? (
                  <Text style={[Styles.tableCell, cp]}>{v ?? ' '}</Text>
                ) : (
                  v
                )}
              </View>
            );
          })}
        </View>
      ))}
    </View>
  );
};
