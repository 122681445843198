// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { Currency, NewCurrency } from '../Currency/Currency';

export class ProjectStatusAgg {
  readonly id: string;
  readonly name: string;
  readonly count: number;
  readonly totalRevenue: Currency;
  readonly totalCuyd: number;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.count = data.count === undefined ? 0 : data.count;
    this.totalRevenue = NewCurrency(data.totalRevenue);
    this.totalCuyd = data.totalCuyd === undefined ? 0 : data.totalCuyd;
  }

  static zero(): ProjectStatusAgg {
    const zeroValues: ExcludeMethodsDeep<ProjectStatusAgg> = {
      id: NIL_UUID,
      name: '',
      count: 0,
      totalRevenue: Currency.zero(),
      totalCuyd: 0,
    };
    return new ProjectStatusAgg(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewProjectStatusAgg = (
  props: PartialDeep<ProjectStatusAgg, { recurseIntoArrays: true }>,
): ProjectStatusAgg => new ProjectStatusAgg(props);

export const NewProjectStatusAggFromDomainObject = (
  props: PartialDeep<DomainObject<ProjectStatusAgg>, { recurseIntoArrays: true }>,
): ProjectStatusAgg => new ProjectStatusAgg(props);
