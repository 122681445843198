import { Box, Chip, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { ProjectMap } from '../../components/Map/ProjectMap';
import { MarkAsSentAndShowToast } from '../../components/Nylas/NylasFlyout';
import { Page } from '../../components/Page/Page';
import { TabBar } from '../../components/TabBar/TabBar';
import { Company } from '../../generated-types/Company/Company';
import { Contact } from '../../generated-types/Contact/Contact';
import Enums from '../../generated-types/Enums';
import { Project } from '../../generated-types/Project/Project';
import { ProjectProduct } from '../../generated-types/ProjectProduct/ProjectProduct';
import { Quote } from '../../generated-types/Quote/Quote';
import { NewQuoteProduct } from '../../generated-types/QuoteProduct/QuoteProduct';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { ServerErrorMessage, useSlabQuery } from '../../hooks/useSlabQuery';
import { NIL_UUID } from '../../utils/UUID';
import { QuoteDrawer } from '../Quotes/QuoteDrawer';
import { CompanyTable } from './components/CompanyTable';
import { ForecastTable } from './components/ForecastTable';
import { ProductTable } from './components/ProductTable';
import { ProjectActivities } from './components/ProjectActivities';
import { ProjectDetailsColumn } from './components/ProjectDetailsColumn';
import { ProjectSection } from './components/ProjectDrawerSections';
import { ProjectQuoteTable } from './components/ProjectQuoteTable';
import { ProjectDrawer } from './ProjectDrawer';

/**
 * Create a quote context from a project and its products.
 */
export const QuoteContextFromProject = (
  project: Project,
  projectProducts: ProjectProduct[],
): Partial<Quote> => {
  const projectQuoteProducts = projectProducts.map((p) =>
    NewQuoteProduct({
      product: p.product,
      kind: Enums.QuoteProductKind.Primary,
      externalName: p.product.externalName(),
      quantity: p.quantity,
      price: p.price,
      deliveryCosts: p.deliveryCosts,
      usage: p.usage,
    }),
  );

  const { quoteCompany, quoteContact } = ((): {
    quoteCompany: Company | null;
    quoteContact: Contact | null;
  } => {
    if (project.winningCompany !== null) {
      return {
        quoteCompany: project.winningCompany.company,
        quoteContact: project.winningCompany.contact,
      };
    }
    const contractorProjectCompanies = project.companies.filter(
      (pc) => pc.company.category === Enums.CompanyCategory.Contractor,
    );
    if (contractorProjectCompanies.length === 1) {
      return {
        quoteCompany: contractorProjectCompanies[0].company,
        quoteContact: contractorProjectCompanies[0].contact,
      };
    }
    return {
      quoteCompany: null,
      quoteContact: null,
    };
  })();

  return {
    project,
    name: project.name,
    // Default to 30 days from now.
    expirationDate: DateTime.now().plus({ days: 30 }),
    user: project.user,
    company: quoteCompany,
    contact: quoteContact,
    products: projectQuoteProducts,
  };
};

export const ProjectDetailsPage = (): JSX.Element => {
  const theme = useTheme();
  const params = useParams();
  const projectId = params?.id ?? NIL_UUID;

  const navigate = useNavigate();

  const [drawerQuoteId, setDrawerQuoteId] = useState<string | null>(null);
  const [initialSection, setInitialSection] = useState<ProjectSection | undefined>();
  const [selectedYear, setSelectedYear] = useState(DateTime.now().year);

  const { toastHook: projectToastHook, ...projectDrawerProps } = useDrawerManager({
    baseUrl: '/projects',
    resourceTypeName: 'project',
    drawerProps: {
      resourceId: projectId,
    },
  });

  const { toastHook: quoteToastHook, ...quoteDrawerProps } = useDrawerManager({
    baseUrl: '/quotes',
    resourceTypeName: 'quote',
    drawerProps: {
      resourceId: drawerQuoteId,
    },
  });

  const {
    isLoading: isLoadingProject,
    isError: isErrorProject,
    data: project,
  } = useSlabQuery('GET project by ID', {
    pathParams: {
      id: projectId,
    },
  });

  const {
    isLoading: isLoadingProjectProducts,
    isError: isErrorProjectProducts,
    data: projectProducts,
  } = useSlabQuery('GET project products by project ID', {
    pathParams: {
      id: projectId,
    },
  });

  const markAsSent = useSlabMutation('POST mark quote as sent');

  const isLoading = isLoadingProject || isLoadingProjectProducts;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError = isErrorProject || isErrorProjectProducts;

  const isDataUndefined = project === undefined || projectProducts === undefined;

  if (isError || isDataUndefined) {
    return <div>ERROR</div>;
  }

  const title = project.name;

  const projectLocation =
    project.address.latitude !== null && project.address.longitude !== null
      ? {
          lat: parseFloat(project.address.latitude),
          lng: parseFloat(project.address.longitude),
        }
      : undefined;
  const plantLocation =
    project.plant.address.latitude !== null && project.plant.address.longitude !== null
      ? {
          lat: parseFloat(project.plant.address.latitude),
          lng: parseFloat(project.plant.address.longitude),
        }
      : undefined;

  const hasUOMIssue = projectProducts.some((pp) => pp.product.incompatibleMixBatchUnits);

  return (
    <Page title={title}>
      {projectToastHook.toast}
      <ProjectDrawer
        {...projectDrawerProps}
        projectId={projectDrawerProps.resourceId}
        initialSection={initialSection}
        initialForecastYear={selectedYear}
        onSuccess={projectDrawerProps.onSave}
        onClose={(): void => setInitialSection(undefined)}
      />
      <QuoteDrawer
        {...quoteDrawerProps}
        quoteId={quoteDrawerProps.resourceId}
        onSave={(quote): void => {
          quoteDrawerProps.setIsOpen(false);
          navigate(`/quotes/${quote.id}`);
        }}
        onClose={(): void => {
          setDrawerQuoteId(null);
        }}
        ensureDefined={(quote): Partial<Quote> => {
          if (quote !== undefined) {
            return quote;
          }
          return QuoteContextFromProject(project, projectProducts);
        }}
        onSaveAsNew={drawerQuoteId === null ? undefined : quoteDrawerProps.onSaveAsNew}
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='2.5rem'>
        <Box display='flex' alignItems='center' gap='1.25rem'>
          <Typography variant='h1'>{title}</Typography>
          {hasUOMIssue && <Chip color='error' label='UOM issue' />}
        </Box>
        <Box display='flex' gap='1rem'>
          <ButtonPill
            text='edit project'
            variant='primary'
            onClick={(): void => {
              setInitialSection('Project Information*');
              projectDrawerProps.setIsOpen(true);
            }}
            icon='edit'
          />
        </Box>
      </Box>
      {hasUOMIssue && (
        <Box paddingBottom='1rem'>
          <Typography color={theme.palette.error.main}>
            The products in this project contain UOM issues
          </Typography>
        </Box>
      )}
      <Box display='flex'>
        <ProjectDetailsColumn
          project={project}
          onProjectDetailsClick={(): void => {
            setInitialSection('Project Information*');
            projectDrawerProps.setIsOpen(true);
          }}
          onAddressInformationClick={(): void => {
            setInitialSection('Job Site Address*');
            projectDrawerProps.setIsOpen(true);
          }}
          onDetailsClick={(): void => {
            setInitialSection('Details');
            projectDrawerProps.setIsOpen(true);
          }}
          onNoteClick={(): void => {
            setInitialSection('Note');
            projectDrawerProps.setIsOpen(true);
          }}
          onCustomFieldsClick={(): void => {
            setInitialSection('Additional Information');
            projectDrawerProps.setIsOpen(true);
          }}
        />
        <Box paddingLeft='2.5rem' flexGrow={1} sx={{ overflowX: 'clip', overflowY: 'visible' }}>
          <TabBar
            tabs={[
              {
                label: 'Quotes',
                element: (
                  <ProjectQuoteTable
                    onEditPress={(row): void => {
                      setDrawerQuoteId(row.id);
                      quoteDrawerProps.setIsOpen(true);
                    }}
                    projectId={projectId}
                    onSendQuote={async (quoteId): Promise<void> => {
                      await MarkAsSentAndShowToast({
                        quoteId: quoteId ?? '',
                        markAsSentMutation: markAsSent,
                        toastHook: quoteToastHook,
                      });
                    }}
                    onSendQuoteError={(err): void => {
                      quoteToastHook.showToast(
                        'error',
                        ServerErrorMessage(err) ?? 'Unable to send quote',
                      );
                    }}
                    onCreateQuote={(): void => quoteDrawerProps.setIsOpen(true)}
                  />
                ),
              },
              {
                label: 'Contractors',
                element: (
                  <CompanyTable
                    projectId={project.id}
                    isContractorsOnly
                    onEditCompanies={(): void => {
                      setInitialSection('Contractors');
                      projectDrawerProps.setIsOpen(true);
                    }}
                  />
                ),
              },
              {
                label: 'Other companies',
                element: (
                  <CompanyTable
                    projectId={project.id}
                    isContractorsOnly={false}
                    onEditCompanies={(): void => {
                      setInitialSection('Other companies');
                      projectDrawerProps.setIsOpen(true);
                    }}
                  />
                ),
              },
              {
                label: 'Products',
                element: (
                  <ProductTable
                    projectId={project.id}
                    onEditProducts={(): void => {
                      setInitialSection('Product(s) info');
                      projectDrawerProps.setIsOpen(true);
                    }}
                  />
                ),
              },
              {
                label: 'Forecasts',
                element: (
                  <ForecastTable
                    projectId={project.id}
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                    onEditForecasts={(): void => {
                      setInitialSection('Forecasts');
                      projectDrawerProps.setIsOpen(true);
                    }}
                  />
                ),
              },
              {
                label: 'Activities',
                element: <ProjectActivities project={project} />,
              },
              {
                label: 'Directions',
                element: (
                  <ProjectMap projectLocation={projectLocation} plantLocation={plantLocation} />
                ),
              },
            ]}
          />
        </Box>
      </Box>
    </Page>
  );
};
