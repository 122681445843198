import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { DateTimeToString } from '../../../components/DataTable/TableDataModel';
import Enums from '../../../generated-types/Enums';
import { QuotePriceEscalation } from '../../../generated-types/QuotePriceEscalation/QuotePriceEscalation';
import { NullableCurrencyString } from '../../../utils/Currency';
import { randomUUID } from '../../../utils/UUID';
import {
  PdfColumnConfig,
  PdfColumnFromConfig,
  PdfTable,
  PdfTableProps,
  Styles,
} from '../../components/PdfTable';

type QuotePriceEscalationRow = {
  id: string;
  escalationDate: string;
  amount: string;
};

type QuotePriceEscalationColumn = {
  id: keyof QuotePriceEscalationRow;
  label?: string;
  width?: Style['width'];
  headerViewStyle?: Style;
  headerTextStyle?: Style;
  rowViewStyle?: Style;
  rowTextStyle?: Style;
};

const makeHeaderRenderer =
  ({ headerViewStyle = {}, headerTextStyle = {} }: QuotePriceEscalationColumn) =>
  (label: string): JSX.Element => (
    <View style={headerViewStyle}>
      <Text style={[Styles.tableCellHeader, headerTextStyle].flat()}>{label}</Text>
    </View>
  );

const makeCellRenderer =
  ({ rowViewStyle = {}, rowTextStyle = {} }: QuotePriceEscalationColumn) =>
  (value: string): JSX.Element => (
    <View style={rowViewStyle}>
      <Text style={[Styles.tableCell, rowTextStyle].flat()}>{value}</Text>
    </View>
  );

type PdfPriceEscalationTableProps = {
  quotePriceEscalations: QuotePriceEscalation[];
  columnConfigs: QuotePriceEscalationColumn[];
} & Omit<PdfTableProps<any>, 'columns' | 'rows'>;

export const PdfPriceEscalationTable = (props: PdfPriceEscalationTableProps): JSX.Element => {
  const { quotePriceEscalations, columnConfigs, ...tableProps } = props;

  const rows: QuotePriceEscalationRow[] = quotePriceEscalations
    .sort((qpe1, qpe2) => qpe1.escalationDate.toMillis() - qpe2.escalationDate.toMillis())
    .map((qpe) => {
      const amount =
        qpe.kind === Enums.EscalationKind.Flat
          ? NullableCurrencyString({ cur: qpe.flatChange })
          : `${Number(qpe.changeRatio) * 100}%`;
      const escalationDate = DateTimeToString(qpe.escalationDate);
      return { id: randomUUID(), escalationDate, amount };
    });

  const cols: PdfColumnConfig<QuotePriceEscalationRow>[] = columnConfigs.map(
    (config): PdfColumnConfig<QuotePriceEscalationRow> => ({
      id: config.id,
      // `isDisplayed` is ignored when working with PDF tables
      isDisplayed: true,
      label: config.label ?? '',
      type: 'string',
      widthOverride: config.width,
      formatValueForPdf: makeCellRenderer(config),
      formatColumnHeaderForPdf: makeHeaderRenderer(config),
    }),
  );

  return (
    <PdfTable columns={cols.map((col) => PdfColumnFromConfig(col))} rows={rows} {...tableProps} />
  );
};
