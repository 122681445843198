// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { DateTime, Duration } from 'luxon';
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import * as DateHelpers from '../../utils/DateHelpers';
import { ExcludeMethodsDeep } from '../../utils/Types';
import Enums from '../Enums';

export class Forecast {
  readonly kind: Enums.ForecastKind;
  readonly projectId: string | null;
  readonly plantId: string | null;
  readonly value: string;
  readonly unit: Enums.Unit;
  readonly intervalLength: Duration;
  readonly intervalStart: DateTime;

  constructor(data: { [key: string]: any } = {}) {
    this.kind = data.kind === undefined ? Enums.ForecastKind.Budget : data.kind;
    this.projectId = data.projectId === undefined ? null : data.projectId;
    this.plantId = data.plantId === undefined ? null : data.plantId;
    this.value = data.value === undefined ? '0' : data.value;
    this.unit = data.unit === undefined ? Enums.Unit.Ac : data.unit;
    this.intervalLength = DateHelpers.ParseServerDuration(data.intervalLength);
    this.intervalStart = DateHelpers.ParseServerDate(data.intervalStart);
  }

  static zero(): Forecast {
    const zeroValues: ExcludeMethodsDeep<Forecast> = {
      kind: Enums.ForecastKind.Budget,
      projectId: null,
      plantId: null,
      value: '0',
      unit: Enums.Unit.Ac,
      intervalLength: DateHelpers.ParseServerDuration(DateHelpers.ZERO_DURATION_STRING),
      intervalStart: DateHelpers.ParseServerDate(DateHelpers.ZERO_DATE_STRING),
    };
    return new Forecast(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /**
   * Given a start and end number of years + current forecasts, return a fully filled
   * forecast list with existing values filled in.
   */
  public static fillForecasts = ({
    currentForecasts,
    kind,
    from = DateHelpers.DEFAULT_FORECAST_YEAR_FROM,
    to = DateHelpers.DEFAULT_FORECAST_YEAR_TO,
  }: {
    /** The current forecasts which will override blank "fill" values. */
    currentForecasts: Forecast[];
    kind: Enums.ForecastKind;
    /**
     * The number of years previous to the current year.
     * @default DateHelpers.DEFAULT_FORECAST_YEAR_FROM
     */
    from?: number;
    /**
     * The number of years in the future to fill up-to.
     * @default DateHelpers.DEFAULT_FORECAST_YEAR_TO
     */
    to?: number;
  }): Forecast[] => {
    let fromDate = DateTime.now().plus({ years: from }).startOf('year');
    const toDate = DateTime.now()
      .plus({ years: to + 1 })
      .startOf('year');

    const forecasts: Forecast[] = [];
    for (
      ;
      fromDate.startOf('month') < toDate.startOf('month');
      fromDate = fromDate.plus({ month: 1 })
    ) {
      const existingForecast = currentForecasts.find(
        // eslint-disable-next-line no-loop-func
        (f) => f.kind === kind && f.intervalStart.toISO() === fromDate.toISO(),
      );
      if (existingForecast !== undefined) {
        forecasts.push(existingForecast);
      } else {
        const forecastData: Partial<ExcludeMethodsDeep<Forecast>> = {
          kind,
          intervalLength: DateHelpers.ParseServerDuration('P1M'),
          intervalStart: DateHelpers.ParseServerDate(fromDate.startOf('month').toISO()),
          unit: Enums.Unit.CuYd,
          value: '',
        };
        forecasts.push(new Forecast(forecastData));
      }
    }
    return forecasts;
  };

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewForecast = (props: PartialDeep<Forecast, { recurseIntoArrays: true }>): Forecast =>
  new Forecast(props);

export const NewForecastFromDomainObject = (
  props: PartialDeep<DomainObject<Forecast>, { recurseIntoArrays: true }>,
): Forecast => new Forecast(props);
