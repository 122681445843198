import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

export type PdfTextBlockProps = {
  content: string;
  viewStyle?: Style;
  textStyle?: Style;
};

export const PdfTextBlock = ({ content, viewStyle, textStyle }: PdfTextBlockProps): JSX.Element => (
  <View style={viewStyle}>
    <Text style={textStyle}>{content}</Text>
  </View>
);
