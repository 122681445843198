// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import Enums from '../Enums';

export class ProductCategoryAgg {
  readonly category: Enums.ProductCategory;
  readonly count: number;

  constructor(data: { [key: string]: any } = {}) {
    this.category = data.category === undefined ? Enums.ProductCategory.Other : data.category;
    this.count = data.count === undefined ? 0 : data.count;
  }

  static zero(): ProductCategoryAgg {
    const zeroValues: ExcludeMethodsDeep<ProductCategoryAgg> = {
      category: Enums.ProductCategory.Other,
      count: 0,
    };
    return new ProductCategoryAgg(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewProductCategoryAgg = (
  props: PartialDeep<ProductCategoryAgg, { recurseIntoArrays: true }>,
): ProductCategoryAgg => new ProductCategoryAgg(props);

export const NewProductCategoryAggFromDomainObject = (
  props: PartialDeep<DomainObject<ProductCategoryAgg>, { recurseIntoArrays: true }>,
): ProductCategoryAgg => new ProductCategoryAgg(props);
