// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { Base64 } from '../../types/Base64';
import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class PdfPart {
  readonly template: string | null;
  readonly staticContent: Base64;

  constructor(data: { [key: string]: any } = {}) {
    this.template = data.template === undefined ? null : data.template;
    this.staticContent = data.staticContent === undefined ? '' : data.staticContent;
  }

  static zero(): PdfPart {
    const zeroValues: ExcludeMethodsDeep<PdfPart> = {
      template: null,
      staticContent: '',
    };
    return new PdfPart(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewPdfPart = (props: PartialDeep<PdfPart, { recurseIntoArrays: true }>): PdfPart =>
  new PdfPart(props);

export const NewPdfPartFromDomainObject = (
  props: PartialDeep<DomainObject<PdfPart>, { recurseIntoArrays: true }>,
): PdfPart => new PdfPart(props);
