import ReactPDF, { Document, Page, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import Enums from '../../../../../generated-types/Enums';
import { Quote } from '../../../../../generated-types/Quote/Quote';
import { PdfTextBlock } from '../../../../components/PdfTextBlock';
import { PdfNotes } from '../../../components/PdfNotes';
import { PdfPriceEscalationTable } from '../../../components/PdfPriceEscalationTable';
import { PdfProductsTable } from '../../../components/PdfProductsTable';
import { PdfSpacer } from '../../../components/PdfSpacer';
import { CarewPdfHeaderTable } from '../../components/PdfHeaderTable';

const pageStyle: ReactPDF.PageProps['style'] = {
  padding: '0.5in',
};

const headerViewStyle: Style = {
  textAlign: 'center',
  fontFamily: 'Helvetica-Bold',
};
const headerTextStyle: Style = {
  fontSize: '10px',
};

const rowViewStyle: Style = {
  textAlign: 'center',
};
const rowTextStyle: Style = {
  fontSize: '10px',
};

type CarewDefaultInitialSectionProps = {
  quote: Quote;
};

export const CarewDefaultInitialSection = ({
  quote,
}: CarewDefaultInitialSectionProps): JSX.Element => (
  <Document title={quote.quoteNumber}>
    <Page style={pageStyle}>
      <CarewPdfHeaderTable quote={quote} />
      <PdfSpacer height='0.25in' />
      <PdfTextBlock
        content='Ready Mixed Concrete Pricing'
        textStyle={{
          fontFamily: 'Helvetica-Bold',
          fontSize: '12px',
        }}
        viewStyle={{
          alignItems: 'center',
        }}
      />
      <PdfSpacer height='0.1in' />
      <PdfProductsTable
        products={quote.filterProducts(Enums.QuoteProductKind.Primary)}
        columnConfigs={[
          {
            id: 'name',
            label: 'Product Description',
            type: 'string',
            headerTextStyle,
            headerViewStyle,
            rowTextStyle,
            rowViewStyle,
            width: '40%',
          },
          {
            id: 'usage',
            label: 'Usage',
            type: 'string',
            headerTextStyle,
            headerViewStyle,
            rowTextStyle,
            rowViewStyle,
            width: '20%',
          },
          {
            id: 'price',
            label: 'Price',
            type: 'Currency',
            headerTextStyle,
            headerViewStyle,
            rowTextStyle,
            rowViewStyle,
            width: '20%',
          },
          {
            id: 'measurementUnit',
            label: 'Unit',
            type: 'string',
            headerTextStyle,
            headerViewStyle,
            rowTextStyle,
            rowViewStyle,
            width: '20%',
          },
        ]}
        displayHeaders
        bordered
      />
      {quote.priceEscalations.length === 0 ? null : (
        <View>
          <PdfSpacer height='0.25in' />
          <PdfTextBlock
            content='Price Escalations'
            textStyle={{
              fontFamily: 'Helvetica-Bold',
              fontSize: '12px',
            }}
            viewStyle={{
              alignItems: 'center',
            }}
          />
          <PdfSpacer height='0.1in' />
          <PdfPriceEscalationTable
            quotePriceEscalations={quote.priceEscalations}
            columnConfigs={[
              {
                id: 'escalationDate',
                label: 'Escalation Date',
                headerTextStyle,
                headerViewStyle,
                rowViewStyle,
                rowTextStyle,
              },
              {
                id: 'amount',
                label: 'Amount',
                headerTextStyle,
                headerViewStyle,
                rowViewStyle,
                rowTextStyle,
              },
            ]}
            displayHeaders
            bordered
          />
        </View>
      )}
      <PdfSpacer height='0.25in' />
      {quote.notes !== null && (
        <View>
          <PdfNotes quote={quote} fontSize={10} />
          <PdfSpacer height='0.25in' />
        </View>
      )}
      <PdfTextBlock
        content={`Terms: PRICES WILL BE HELD FIRM THIRTY (30) DAYS FROM BID DATE.\n
        CUSTOMER IS TO PROVIDE SUITABLE ACCESS TO POINT OF DELIVERY`}
        textStyle={{
          fontFamily: 'Helvetica-Bold',
          fontSize: '12px',
        }}
        viewStyle={{
          alignItems: 'center',
        }}
      />
      <PdfSpacer height='0.1in' />
      <PdfProductsTable
        products={quote.filterProducts(Enums.QuoteProductKind.Additional)}
        columnConfigs={[
          {
            id: 'name',
            label: 'Additional Charges',
            type: 'string',
            headerTextStyle,
            headerViewStyle,
            rowTextStyle,
            rowViewStyle,
            width: '70%',
          },
          {
            id: 'price',
            label: 'Price',
            type: 'Currency',
            headerTextStyle,
            headerViewStyle,
            rowTextStyle,
            rowViewStyle,
            width: '15%',
          },
          {
            id: 'measurementUnit',
            label: 'UOM',
            type: 'string',
            headerTextStyle,
            headerViewStyle,
            rowTextStyle,
            rowViewStyle,
            width: '15%',
          },
        ]}
        displayHeaders
        bordered
      />
    </Page>
  </Document>
);
