// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.
/* eslint-disable camelcase */ // webgen:keep
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import {
  AutocompleteMatchedSubstring,
  NewAutocompleteMatchedSubstring,
} from '../AutocompleteMatchedSubstring/AutocompleteMatchedSubstring';
import {
  AutocompleteStructuredFormatting,
  NewAutocompleteStructuredFormatting,
} from '../AutocompleteStructuredFormatting/AutocompleteStructuredFormatting';
import {
  AutocompleteTermOffset,
  NewAutocompleteTermOffset,
} from '../AutocompleteTermOffset/AutocompleteTermOffset';

export class AutocompletePrediction {
  readonly description?: string;
  readonly distance_meters?: number;
  readonly place_id?: string;
  readonly types?: string[];
  readonly matched_substrings?: AutocompleteMatchedSubstring[];
  readonly terms?: AutocompleteTermOffset[];
  readonly structured_formatting?: AutocompleteStructuredFormatting;

  constructor(data: { [key: string]: any } = {}) {
    this.description = data.description;
    this.distance_meters = data.distance_meters;
    this.place_id = data.place_id;
    this.types = data.types ?? [];
    this.matched_substrings = (data.matched_substrings ?? []).map((matched_substrings: any) =>
      NewAutocompleteMatchedSubstring(matched_substrings),
    );
    this.terms = (data.terms ?? []).map((terms: any) => NewAutocompleteTermOffset(terms));
    this.structured_formatting = NewAutocompleteStructuredFormatting(data.structured_formatting);
  }

  static zero(): AutocompletePrediction {
    const zeroValues: ExcludeMethodsDeep<AutocompletePrediction> = {};
    return new AutocompletePrediction(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewAutocompletePrediction = (
  props: PartialDeep<AutocompletePrediction, { recurseIntoArrays: true }>,
): AutocompletePrediction => new AutocompletePrediction(props);

export const NewAutocompletePredictionFromDomainObject = (
  props: PartialDeep<DomainObject<AutocompletePrediction>, { recurseIntoArrays: true }>,
): AutocompletePrediction => new AutocompletePrediction(props);
