import { Box, Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { Input } from '../../../components/Input/Input';
import { YearSelector } from '../../../components/YearSelector/YearSelector';
import { ParseServerDate } from '../../../utils/DateHelpers';
import { PlantFormikType } from '../PlantFormik';

type FilteredForecastType = PlantFormikType['budgetForecasts'][number] & {
  formikIdx: number;
};

type ForecastsSectionProps = {
  initialForecastYear: number;
  type: 'budgetForecasts' | 'capacityForecasts';
};

type ForecastRowProps = {
  filteredForecasts: FilteredForecastType[];
  type: ForecastsSectionProps['type'];
  ffi: number;
};

/** Given a formik forecast, return the year of that forecast (based on intervalStart) */
const getForecastYear = (formikForecast: PlantFormikType['budgetForecasts'][number]): number =>
  ParseServerDate(formikForecast.intervalStart).year;

const ForecastRow = ({ filteredForecasts, type, ffi }: ForecastRowProps): JSX.Element => {
  const formikBag = useFormikContext<PlantFormikType>();

  const { oppositeType, oppositePersonifier } =
    type === 'budgetForecasts'
      ? { oppositeType: 'capacityForecasts', oppositePersonifier: 'Capacity' }
      : { oppositeType: 'budgetForecasts', oppositePersonifier: 'Budget' };

  const oppositeValue1 = formikBag.getFieldMeta<
    PlantFormikType['budgetForecasts'][number]['value'] | undefined
  >(`${oppositeType}.${filteredForecasts[ffi].formikIdx}.value`).value;
  const oppositeValue1Display =
    (oppositeValue1 ?? '') === '' ? ' ' : `${oppositePersonifier}: ${oppositeValue1}`;

  const oppositeValue2 = formikBag.getFieldMeta<
    PlantFormikType['budgetForecasts'][number]['value'] | undefined
  >(`${oppositeType}.${filteredForecasts[ffi + 1].formikIdx}.value`).value;
  const oppositeValue2Display =
    (oppositeValue2 ?? '') === '' ? ' ' : `${oppositePersonifier}: ${oppositeValue2}`;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box pb='0.5rem'>
          <Typography variant='body3'>
            {oppositeValue1Display}
            &nbsp;
          </Typography>
        </Box>
        <Input
          name={`${type}.${filteredForecasts[ffi].formikIdx}.value`}
          label={ParseServerDate(filteredForecasts[ffi].intervalStart).monthLong ?? ''}
          type='number'
        />
      </Grid>
      <Grid item xs={6}>
        <Box pb='0.5rem'>
          <Typography variant='body3'>
            {oppositeValue2Display}
            &nbsp;
          </Typography>
        </Box>
        <Input
          name={`${type}.${filteredForecasts[ffi + 1].formikIdx}.value`}
          label={ParseServerDate(filteredForecasts[ffi + 1].intervalStart).monthLong ?? ''}
          type='number'
        />
      </Grid>
    </Grid>
  );
};

const ForecastBlock = ({
  filteredForecasts,
  type,
}: Pick<ForecastRowProps, 'filteredForecasts' | 'type'>): JSX.Element => (
  <>
    {filteredForecasts.map((ff, ffi) => {
      if (ffi % 2 === 0) {
        return (
          <ForecastRow
            filteredForecasts={filteredForecasts}
            ffi={ffi}
            type={type}
            key={`${type}-forecast-${ff.kind}-row-${ff.intervalStart}`}
          />
        );
      }
      return null;
    })}
  </>
);

export const ForecastsSection = ({
  initialForecastYear,
  type,
}: ForecastsSectionProps): JSX.Element => {
  const formikBag = useFormikContext<PlantFormikType>();
  const [selectedYear, setSelectedYear] = useState(initialForecastYear);

  // If the initialForecastYear prop changes, set it to the selected year.
  useEffect(() => {
    setSelectedYear(initialForecastYear);
  }, [initialForecastYear]);

  const formikForecasts =
    type === 'budgetForecasts'
      ? formikBag.values.budgetForecasts
      : formikBag.values.capacityForecasts;

  // Construct an array of objects with unique years, and if each year contains at least one value
  const possibleYears = _.uniqBy(formikForecasts, (f) => getForecastYear(f)).map((uniqYr) => ({
    year: getForecastYear(uniqYr),
    hasValues: formikForecasts.some(
      (f) => f.value !== '' && getForecastYear(f) === getForecastYear(uniqYr),
    ),
  }));

  const filteredForecasts: FilteredForecastType[] = formikForecasts
    .map((f, formikIdx) => ({ ...f, formikIdx }))
    .filter((f) => getForecastYear(f) === selectedYear);

  return (
    <Box display='flex' flexDirection='column' gap='1rem'>
      <YearSelector
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        yearOpts={possibleYears}
        selectWidth='fullWidth'
      />

      <Box pt='1rem'>
        <ForecastBlock filteredForecasts={filteredForecasts} type={type} />
      </Box>
    </Box>
  );
};
