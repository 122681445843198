// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { Base64 } from '../../types/Base64';
import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class UploadFileInput {
  readonly fileName: string;
  readonly file: Base64;

  constructor(data: { [key: string]: any } = {}) {
    this.fileName = data.fileName === undefined ? '' : data.fileName;
    this.file = data.file === undefined ? '' : data.file;
  }

  static zero(): UploadFileInput {
    const zeroValues: ExcludeMethodsDeep<UploadFileInput> = {
      fileName: '',
      file: '',
    };
    return new UploadFileInput(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewUploadFileInput = (
  props: PartialDeep<UploadFileInput, { recurseIntoArrays: true }>,
): UploadFileInput => new UploadFileInput(props);

export const NewUploadFileInputFromDomainObject = (
  props: PartialDeep<DomainObject<UploadFileInput>, { recurseIntoArrays: true }>,
): UploadFileInput => new UploadFileInput(props);
