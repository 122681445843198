import { View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import Enums from '../../../../generated-types/Enums';
import { Quote } from '../../../../generated-types/Quote/Quote';
import { PdfImageProps } from '../../../components/PdfImage';
import { PdfTextBlock } from '../../../components/PdfTextBlock';
import { QuotePdf } from '../../../QuotePdf';
import { PdfNotes } from '../../components/PdfNotes';
import { PdfPriceEscalationTable } from '../../components/PdfPriceEscalationTable';
import { PdfProductsTable } from '../../components/PdfProductsTable';
import { PdfSpacer } from '../../components/PdfSpacer';
import { PdfHeaderTable } from '../components/PdfHeaderTable';
import { PdfSignatures } from '../components/PdfSignatures';

export const ColumnViewStyle: Style = {
  textAlign: 'center',
};

const customUnitLabels = (label: string): string => {
  switch (label) {
    case 'CUYD':
      return 'cy';
    case '/CUYD':
      return '/cy';
    default:
      return label;
  }
};

type DefaultTemplateProps = {
  quote: Quote;
  tenantSpecificProps: {
    logo: PdfImageProps;
    tenantName: string;
    mainProductColumns?: Parameters<typeof PdfProductsTable>[0]['columnConfigs'];
    infoTableProps?: Parameters<typeof PdfHeaderTable>[0]['infoTableProps'];
  };
};

export const DefaultTemplate = ({
  quote,
  tenantSpecificProps,
}: DefaultTemplateProps): JSX.Element => (
  <QuotePdf quote={quote} disableHyphenation>
    <PdfHeaderTable
      quote={quote}
      logo={tenantSpecificProps.logo}
      infoTableProps={tenantSpecificProps.infoTableProps}
    />
    <PdfSpacer height='0.25in' />
    <PdfTextBlock
      content='Ready Mixed Concrete'
      viewStyle={{
        alignItems: 'center',
      }}
    />
    <PdfSpacer height='0.1in' />
    <PdfProductsTable
      products={quote.filterProducts(Enums.QuoteProductKind.Primary)}
      displayHeaders
      columnConfigs={
        tenantSpecificProps.mainProductColumns ?? [
          {
            id: 'name',
            label: 'Product Description',
            type: 'string',
            headerViewStyle: ColumnViewStyle,
            rowViewStyle: ColumnViewStyle,
            width: '60%',
          },
          {
            id: 'price',
            label: 'Price',
            type: 'Currency',
            headerViewStyle: ColumnViewStyle,
            rowViewStyle: ColumnViewStyle,
            width: '20%',
          },
          {
            id: 'measurementUnit',
            label: 'Unit',
            type: 'string',
            headerViewStyle: ColumnViewStyle,
            rowViewStyle: ColumnViewStyle,
            width: '20%',
            formatValueToString: customUnitLabels,
          },
        ]
      }
      striped
      bordered
    />
    <PdfSpacer height='0.25in' />
    <PdfTextBlock
      content='Additional Products and Services'
      viewStyle={{
        alignItems: 'center',
      }}
    />
    <PdfSpacer height='0.1in' />
    <PdfProductsTable
      products={quote.filterProducts(Enums.QuoteProductKind.Additional)}
      displayHeaders
      columnConfigs={[
        {
          id: 'name',
          label: 'Product Description',
          type: 'string',
          headerViewStyle: ColumnViewStyle,
          rowViewStyle: ColumnViewStyle,
          width: '60%',
        },
        {
          id: 'price',
          label: 'Price',
          type: 'Currency',
          headerViewStyle: ColumnViewStyle,
          rowViewStyle: ColumnViewStyle,
          width: '20%',
        },
        {
          id: 'measurementUnit',
          label: 'Unit',
          type: 'string',
          headerViewStyle: ColumnViewStyle,
          rowViewStyle: ColumnViewStyle,
          width: '20%',
          formatValueToString: customUnitLabels,
        },
      ]}
      striped
      bordered
    />
    {quote.priceEscalations.length === 0 ? null : (
      <View>
        <PdfSpacer height='0.25in' />
        <PdfTextBlock
          content='Price Escalations'
          viewStyle={{
            alignItems: 'center',
          }}
        />
        <PdfSpacer height='0.1in' />
        <PdfPriceEscalationTable
          quotePriceEscalations={quote.priceEscalations}
          columnConfigs={[
            {
              id: 'escalationDate',
              label: 'Escalation Date',
              headerViewStyle: ColumnViewStyle,
              rowViewStyle: ColumnViewStyle,
              width: '50%',
            },
            {
              id: 'amount',
              label: 'Amount',
              headerViewStyle: ColumnViewStyle,
              rowViewStyle: ColumnViewStyle,
              width: '50%',
            },
          ]}
          displayHeaders
          striped
          bordered
        />
      </View>
    )}
    <PdfSpacer height='0.25in' />
    {quote.notes !== null && (
      <View>
        <PdfNotes quote={quote} fontSize={10} />
        <PdfSpacer height='0.25in' />
      </View>
    )}
    <PdfSignatures quote={quote} tenantName={tenantSpecificProps.tenantName} />
  </QuotePdf>
);
