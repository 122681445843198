// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class AccountInfoOutput {
  readonly id: string;
  readonly accountId: string;
  readonly emailAddress: string;
  readonly linkedAt?: number;
  readonly name: string;
  readonly object: string;
  readonly organizationUnit?: string;
  readonly provider: string;
  readonly syncState: string;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? '' : data.id;
    this.accountId = data.accountId === undefined ? '' : data.accountId;
    this.emailAddress = data.emailAddress === undefined ? '' : data.emailAddress;
    this.linkedAt = data.linkedAt;
    this.name = data.name === undefined ? '' : data.name;
    this.object = data.object === undefined ? '' : data.object;
    this.organizationUnit = data.organizationUnit;
    this.provider = data.provider === undefined ? '' : data.provider;
    this.syncState = data.syncState === undefined ? '' : data.syncState;
  }

  static zero(): AccountInfoOutput {
    const zeroValues: ExcludeMethodsDeep<AccountInfoOutput> = {
      id: '',
      accountId: '',
      emailAddress: '',
      name: '',
      object: '',
      provider: '',
      syncState: '',
    };
    return new AccountInfoOutput(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewAccountInfoOutput = (
  props: PartialDeep<AccountInfoOutput, { recurseIntoArrays: true }>,
): AccountInfoOutput => new AccountInfoOutput(props);

export const NewAccountInfoOutputFromDomainObject = (
  props: PartialDeep<DomainObject<AccountInfoOutput>, { recurseIntoArrays: true }>,
): AccountInfoOutput => new AccountInfoOutput(props);
