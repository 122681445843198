import { Quote } from '../generated-types/Quote/Quote';
import { CarewDefaultInitialSection } from './templates/carew/templates/default/InitialSection';
import { CarewDefaultSigningSection } from './templates/carew/templates/default/SigningSection';
import { CarraraAggregateTemplate } from './templates/carrara/templates/AggregateTemplate';
import { CarraraDefaultTemplate as CarraraDefaultTemplateNew } from './templates/carrara/templates/DefaultTemplateNew';
import { CityTransitMixDefaultInitialSection } from './templates/city-transit-mix/templates/default/InitialSection';
import { CityTransitMixDefaultSigningSection } from './templates/city-transit-mix/templates/default/SigningSection';
import { DauphinaisDefaultTemplate } from './templates/dauphinais/templates/DefaultTemplate';
import { ColumnViewStyle, DefaultTemplate } from './templates/default/templates/DefaultTemplate';
import { LaurenDefaultTemplate } from './templates/lauren/templates/DefaultTemplate';

type TemplateByNameOpts = {
  name: string;
  quote: Quote;
};

export const TemplateByName = ({ name, quote }: TemplateByNameOpts): JSX.Element => {
  // In the future, these logos (and possibly its dimensions) would be
  // passed via the PDF template (or tenant).

  let tenantSpecificProps: Parameters<typeof DefaultTemplate>[0]['tenantSpecificProps'] = {
    logo: {
      src: '../logo-words-grey.png',
      imgStyle: {
        width: '2.5in',
      },
    },
    tenantName: 'Slabstack Software, Inc.',
  };

  switch (name) {
    case 'AMCorpDefaultQuote':
      tenantSpecificProps = {
        logo: {
          src: '../amcorp-logo.png',
          imgStyle: {
            width: '2.5in',
          },
        },
        tenantName: 'AMCorp',
      };
      break;
    case 'ConcreteSupplyDefaultQuote':
      tenantSpecificProps = {
        logo: {
          src: '../concrete-supply-logo.png',
          imgStyle: {
            width: '2.5in',
          },
        },
        tenantName: 'Central Carolina Concrete, Inc.',
      };
      break;
    case 'TrueGritDefaultQuote':
      tenantSpecificProps = {
        logo: {
          src: '../true-grit-logo.png',
          imgStyle: {
            width: '2.5in',
          },
        },
        tenantName: 'True Grit',
      };
      break;
    case 'PreferredMaterialsDefaultQuote':
      tenantSpecificProps = {
        logo: {
          src: '../preferred_logo.jpeg',
          imgStyle: {
            width: '1.25in',
          },
        },
        tenantName: 'Preferred Materials',
        mainProductColumns: [
          {
            id: 'name',
            label: 'Product Description',
            type: 'string',
            headerViewStyle: ColumnViewStyle,
            rowViewStyle: ColumnViewStyle,
            width: '50%',
          },
          {
            id: 'usage',
            label: 'Usage',
            type: 'string',
            headerViewStyle: ColumnViewStyle,
            rowViewStyle: ColumnViewStyle,
            width: '25%',
          },
          {
            id: 'price',
            label: 'Price/CUYD',
            type: 'Currency',
            headerViewStyle: ColumnViewStyle,
            rowViewStyle: ColumnViewStyle,
            width: '25%',
          },
        ],
        infoTableProps: {
          leftValue: {
            widthOverride: '30%',
          },
          rightValue: {
            widthOverride: '70%',
          },
        },
      };
      break;
    case 'PortCityDefaultQuote':
      tenantSpecificProps = {
        logo: {
          src: '../port_city_logo.jpeg',
          imgStyle: {
            width: '2.0in',
          },
        },
        tenantName: 'Port City Concrete',
        mainProductColumns: [
          {
            id: 'alternateID',
            label: 'Mix Code',
            type: 'string',
            headerViewStyle: ColumnViewStyle,
            rowViewStyle: ColumnViewStyle,
            width: '20%',
          },
          {
            id: 'name',
            label: 'Mix Description',
            type: 'string',
            headerViewStyle: ColumnViewStyle,
            rowViewStyle: ColumnViewStyle,
            width: '30%',
          },
          {
            id: 'usage',
            label: 'Usage',
            type: 'string',
            headerViewStyle: ColumnViewStyle,
            rowViewStyle: ColumnViewStyle,
            width: '25%',
          },
          {
            id: 'price',
            label: 'Pricing',
            type: 'Currency',
            headerViewStyle: ColumnViewStyle,
            rowViewStyle: ColumnViewStyle,
            width: '25%',
          },
        ],
      };
      break;
    case 'DickinsonDefaultQuote':
      tenantSpecificProps = {
        logo: {
          src: '../dickinson_logo.jpeg',
          imgStyle: {
            width: '1.5in',
          },
        },
        tenantName: 'Dickinson Ready Mix',
      };
      break;
    case 'WillbeeDefaultQuote':
      tenantSpecificProps = {
        logo: {
          src: '../willbee_logo.png',
          imgStyle: {
            width: '2.5in',
          },
        },
        tenantName: 'Willbee Transit Mix Co., Inc.',
      };
      break;
    case 'DefaultQuote':
    default:
  }

  // Based on the template name, conditionally select a new template
  switch (name) {
    case 'CarewDefaultInitialSection':
      return <CarewDefaultInitialSection quote={quote} />;
    case 'CarewDefaultSigningSection':
      return <CarewDefaultSigningSection quote={quote} />;
    case 'CarraraDefaultQuoteNew':
      return <CarraraDefaultTemplateNew quote={quote} />;
    case 'CarraraAggregate':
      return <CarraraAggregateTemplate quote={quote} />;
    case 'CityTransitMixDefaultInitialSection':
      return <CityTransitMixDefaultInitialSection quote={quote} />;
    case 'CityTransitMixDefaultSigningSection':
      return <CityTransitMixDefaultSigningSection quote={quote} />;
    case 'DauphinaisDefaultQuote':
      return <DauphinaisDefaultTemplate quote={quote} />;
    case 'LaurenDefaultQuote':
      return <LaurenDefaultTemplate quote={quote} />;
    default:
      return <DefaultTemplate quote={quote} tenantSpecificProps={tenantSpecificProps} />;
  }
};
