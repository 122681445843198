import _ from 'lodash';

import { LookupInputOption } from '../components/LookupInput/LookupInputSharedComponents';
import Enums from '../generated-types/Enums';
import { Categories, UnitDefinition, Units } from '../generated-types/generated-units';

/**
 * Finds the UnitDefinition from an arbitary string (or Enums.Unit) value.
 * Useful for finding the full definition of a unit from the wire or Formik
 * value.
 *
 * @returns a UnitDefinition, or null if the value is null or undefined or does
 *          not correspond to an existing UnitDefinition
 */
export const UnitFromString = (unitValue: string | null | undefined): UnitDefinition | null => {
  const enumUnit = Object.values(Enums.Unit).find((unit) => unit === unitValue);
  if (enumUnit === undefined) {
    return null;
  }
  return Units[enumUnit];
};

export const sortByGroupAndLabel = (
  groups: string[],
  opts: LookupInputOption[],
): LookupInputOption[] =>
  // Ensure that non-alphabetic labels (e.g. the slash prefix on per-unit) sort last.
  _.sortBy(opts, (opt) => [groups.indexOf(opt.group ?? ''), opt.label.replace(/^\W+/, 'zzz')]);

/**
 * A precalculated list of LookupInputOptions for unit typeaheads, grouped by
 * category (in order of the generated category definitions) and sorted by unit
 * display value within the categories.
 */
export const UnitOptions: LookupInputOption[] = sortByGroupAndLabel(
  Object.keys(Categories),
  Object.values(Units).map((unit) => ({
    label: `${unit.label}`,
    sublabels: [`${unit.description}`],
    value: unit.value,
    group: unit.category.label,
  })),
);

/**
 * Given a currently selected unit, return a set of unit options for compatible
 * units (those that belong to the same category). If no unit definition is
 * found for the unit, return all available unit options.
 */
export const DependentUnitOptions = (unitValue: string | null | undefined): LookupInputOption[] => {
  const unitDef = UnitFromString(unitValue);
  if (unitDef === null) {
    return UnitOptions;
  }
  return UnitOptions.filter((opt) => opt.group === unitDef.category.label);
};
