import { createTheme } from '@mui/material/styles';

const SlabYellow = {
  300: '#FFE7A8',
  400: '#FFDE80',
  500: '#FFD641',
} as const;

const SlabGreen = {
  300: '#EBFFF4',
  400: '#3FC79A',
  500: '#0B815A',
} as const;

const SlabStatus = {
  Amaranth: '#EE1D52',
  MountainMeadow: '#1EB649',
  FlushOrange: '#FF7B01',
} as const;

const SlabCalendar = {
  Karry: '#FFE9D9',
  Ottoman: '#E6F7ED',
  WhiteIce: '#E4F7FB',
};

const SlabPurpure = {
  50: '#EFECFD',
  100: '#7468E4',
};

const SlabChart = {
  Turquoise: {
    100: '#EFEFEF',
    200: '#00B4D8',
    300: '#0077B6',
    400: '#023E8A',
    500: '#03045E',
  },
  Indigo: {
    100: '#CDD5DF',
    200: '#6B8AFC',
    300: '#3E5CC6',
  },
} as const;

const SlabBlue = {
  0: '#FFFFFF',
  100: '#F9FAFC',
  200: '#C5C6CA',
  300: '#A1A2AA',
  400: '#727382',
  500: '#091747',
  700: '#061136',
  800: '#050E2C',
  Stroke: '#EAEDF9',
  Table: '#F3F4F6',
} as const;

// Extend the MUI theme palette to include SlabColor
declare module '@mui/material/styles' {
  interface Palette {
    SlabYellow: typeof SlabYellow;
    SlabGreen: typeof SlabGreen;
    SlabCalendar: typeof SlabCalendar;
    SlabPurpure: typeof SlabPurpure;
    SlabChart: typeof SlabChart;
    SlabBlue: typeof SlabBlue;
  }
  interface PaletteOptions {
    SlabYellow: typeof SlabYellow;
    SlabGreen: typeof SlabGreen;
    SlabCalendar: typeof SlabCalendar;
    SlabPurpure: typeof SlabPurpure;
    SlabChart: typeof SlabChart;
    SlabBlue: typeof SlabBlue;
  }
  interface TypographyVariants {
    body3: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
  interface ZIndex {
    tableHead?: number;
    tableRow?: number;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    pill: true;
  }
}

declare module '@mui/material/Drawer' {
  interface DrawerPropsVariantOverrides {
    pill: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

/**
 * Custom slab themes that are used throughout the app, and located
 * in one place. Used to overwrite MUI defaults
 */
export const SlabTheme = createTheme({
  palette: {
    success: {
      main: SlabStatus.MountainMeadow,
    },
    warning: {
      main: SlabStatus.FlushOrange,
    },
    error: {
      main: SlabStatus.Amaranth,
    },
    SlabYellow,
    SlabGreen,
    SlabCalendar,
    SlabPurpure,
    SlabChart,
    SlabBlue,
  },
  zIndex: {
    tableHead: 2,
    tableRow: 1,
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Helvetica Neue"',
      'Roboto',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '3rem',
      fontWeight: 500,
      letterSpacing: 0,
      color: SlabBlue[800],
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 400,
      letterSpacing: 0,
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 400,
      color: SlabBlue[800],
    },
    body2: {
      fontSize: '1rem',
    },
    body3: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.125rem',
      color: SlabBlue[400],
    },
  },
  components: {
    // Section headings in edit drawers
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fill: SlabChart.Indigo[200],
        },
      },
    },
    // DataTable column headings
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          color: SlabBlue[800],
        },
      },
    },
    // Forecast totals - row positioning
    MuiTableFooter: {
      styleOverrides: {
        root: {
          position: 'sticky',
          bottom: 0,
          fontSize: 'inherit',
          backgroundColor: SlabBlue[0],
        },
      },
    },
    // Forecast totals - cell styles
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: SlabBlue[500],
        },
        footer: {
          border: '0',
          borderTop: '1px solid #E0E0E0',
          fontSize: 'inherit',
          fontWeight: 'bold',
        },
      },
    },
    // Dividing lines in main navigation (and in dropdown menus)
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: SlabBlue[300],
          marginTop: '0.5rem',
          marginBottom: '1rem',
          opacity: '0.5',
        },
      },
    },
    // Slabstack-standard large button variant
    MuiButton: {
      variants: [
        {
          props: { variant: 'pill' },
          style: {
            borderRadius: '15rem',
            padding: '0.875rem 2rem 0.875rem 2rem',
          },
        },
      ],
    },
    // Top bar with Slabstack logo and tenant switcher menu
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: SlabBlue[100],
          color: SlabBlue[700],
        },
      },
    },
    // Tab panels (used on many single-item detail pages)
    MuiTab: {
      styleOverrides: {
        root: {
          color: SlabBlue[400],
          '&.Mui-selected': {
            color: SlabBlue[500],
          },
        },
      },
    },
  },
});
