// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.
/* eslint-disable camelcase */ // webgen:keep
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { EmailRecipient, NewEmailRecipient } from '../EmailRecipient/EmailRecipient';
import { NewUploadFileOutput, UploadFileOutput } from '../UploadFileOutput/UploadFileOutput';

export class SendMessageOutput {
  readonly body: string;
  readonly subject: string;
  readonly to: EmailRecipient[];
  readonly from: EmailRecipient[];
  readonly cc?: EmailRecipient[];
  readonly bcc?: EmailRecipient[];
  readonly reply_to?: EmailRecipient[];
  readonly file?: UploadFileOutput;

  constructor(data: { [key: string]: any } = {}) {
    this.body = data.body === undefined ? '' : data.body;
    this.subject = data.subject === undefined ? '' : data.subject;
    this.to = (data.to ?? []).map((to: any) => NewEmailRecipient(to));
    this.from = (data.from ?? []).map((from: any) => NewEmailRecipient(from));
    this.cc = (data.cc ?? []).map((cc: any) => NewEmailRecipient(cc));
    this.bcc = (data.bcc ?? []).map((bcc: any) => NewEmailRecipient(bcc));
    this.reply_to = (data.reply_to ?? []).map((reply_to: any) => NewEmailRecipient(reply_to));
    this.file = NewUploadFileOutput(data.file);
  }

  static zero(): SendMessageOutput {
    const zeroValues: ExcludeMethodsDeep<SendMessageOutput> = {
      body: '',
      subject: '',
      to: [],
      from: [],
    };
    return new SendMessageOutput(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewSendMessageOutput = (
  props: PartialDeep<SendMessageOutput, { recurseIntoArrays: true }>,
): SendMessageOutput => new SendMessageOutput(props);

export const NewSendMessageOutputFromDomainObject = (
  props: PartialDeep<DomainObject<SendMessageOutput>, { recurseIntoArrays: true }>,
): SendMessageOutput => new SendMessageOutput(props);
