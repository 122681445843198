// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';

export class QuoteStatus {
  readonly id: string;
  readonly name: string;
  readonly priority: number;
  readonly isArchived: boolean;
  readonly isExportable: boolean;
  readonly isDraft: boolean;
  readonly isApprovalRequest: boolean;
  readonly isRejected: boolean;
  readonly isApprovedToSend: boolean;
  readonly isSent: boolean;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.priority = data.priority === undefined ? 0 : data.priority;
    this.isArchived = data.isArchived === undefined ? false : data.isArchived;
    this.isExportable = data.isExportable === undefined ? false : data.isExportable;
    this.isDraft = data.isDraft === undefined ? false : data.isDraft;
    this.isApprovalRequest = data.isApprovalRequest === undefined ? false : data.isApprovalRequest;
    this.isRejected = data.isRejected === undefined ? false : data.isRejected;
    this.isApprovedToSend = data.isApprovedToSend === undefined ? false : data.isApprovedToSend;
    this.isSent = data.isSent === undefined ? false : data.isSent;
  }

  static zero(): QuoteStatus {
    const zeroValues: ExcludeMethodsDeep<QuoteStatus> = {
      id: NIL_UUID,
      name: '',
      priority: 0,
      isArchived: false,
      isExportable: false,
      isDraft: false,
      isApprovalRequest: false,
      isRejected: false,
      isApprovedToSend: false,
      isSent: false,
    };
    return new QuoteStatus(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewQuoteStatus = (
  props: PartialDeep<QuoteStatus, { recurseIntoArrays: true }>,
): QuoteStatus => new QuoteStatus(props);

export const NewQuoteStatusFromDomainObject = (
  props: PartialDeep<DomainObject<QuoteStatus>, { recurseIntoArrays: true }>,
): QuoteStatus => new QuoteStatus(props);
