// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { Duration } from 'luxon';
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import * as DateHelpers from '../../utils/DateHelpers';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { Cost, NewCost } from '../Cost/Cost';
import { Currency, NewCurrency } from '../Currency/Currency';
import Enums from '../Enums';

export class DeliveryCost {
  readonly deliveryCostOverride: Cost | null;
  readonly perMinCost: Currency;
  readonly loadTime: Duration;
  readonly loadedInYardTime: Duration;
  readonly driveToJobTime: Duration;
  readonly waitTime: Duration;
  readonly pourTime: Duration;
  readonly unloadedAtJobTime: Duration;
  readonly driveToPlantTime: Duration;
  readonly drivingTimeAdjustmentPercentage: string;
  readonly unengagedTimeAdjustmentPercentage: string;
  readonly fuelVolumeUnit: Enums.Unit;
  readonly fuelCostPerUnit: Currency;
  readonly driveDistanceUnit: Enums.Unit;
  readonly driveDistancePerFuelVolume: string;
  readonly driveDistanceToJob: string;
  readonly driveDistanceToPlant: string;
  readonly loadSizeUnit: Enums.Unit;
  readonly loadSize: string;
  readonly totalDeliveryCost: Cost;

  constructor(data: { [key: string]: any } = {}) {
    this.deliveryCostOverride =
      (data.deliveryCostOverride ?? null) === null ? null : NewCost(data.deliveryCostOverride);
    this.perMinCost = NewCurrency(data.perMinCost);
    this.loadTime = DateHelpers.ParseServerDuration(data.loadTime);
    this.loadedInYardTime = DateHelpers.ParseServerDuration(data.loadedInYardTime);
    this.driveToJobTime = DateHelpers.ParseServerDuration(data.driveToJobTime);
    this.waitTime = DateHelpers.ParseServerDuration(data.waitTime);
    this.pourTime = DateHelpers.ParseServerDuration(data.pourTime);
    this.unloadedAtJobTime = DateHelpers.ParseServerDuration(data.unloadedAtJobTime);
    this.driveToPlantTime = DateHelpers.ParseServerDuration(data.driveToPlantTime);
    this.drivingTimeAdjustmentPercentage =
      data.drivingTimeAdjustmentPercentage === undefined
        ? '0'
        : data.drivingTimeAdjustmentPercentage;
    this.unengagedTimeAdjustmentPercentage =
      data.unengagedTimeAdjustmentPercentage === undefined
        ? '0'
        : data.unengagedTimeAdjustmentPercentage;
    this.fuelVolumeUnit = data.fuelVolumeUnit === undefined ? Enums.Unit.Ac : data.fuelVolumeUnit;
    this.fuelCostPerUnit = NewCurrency(data.fuelCostPerUnit);
    this.driveDistanceUnit =
      data.driveDistanceUnit === undefined ? Enums.Unit.Ac : data.driveDistanceUnit;
    this.driveDistancePerFuelVolume =
      data.driveDistancePerFuelVolume === undefined ? '0' : data.driveDistancePerFuelVolume;
    this.driveDistanceToJob = data.driveDistanceToJob === undefined ? '0' : data.driveDistanceToJob;
    this.driveDistanceToPlant =
      data.driveDistanceToPlant === undefined ? '0' : data.driveDistanceToPlant;
    this.loadSizeUnit = data.loadSizeUnit === undefined ? Enums.Unit.Ac : data.loadSizeUnit;
    this.loadSize = data.loadSize === undefined ? '0' : data.loadSize;
    this.totalDeliveryCost = NewCost(data.totalDeliveryCost);
  }

  static zero(): DeliveryCost {
    const zeroValues: ExcludeMethodsDeep<DeliveryCost> = {
      deliveryCostOverride: null,
      perMinCost: Currency.zero(),
      loadTime: DateHelpers.ParseServerDuration(DateHelpers.ZERO_DURATION_STRING),
      loadedInYardTime: DateHelpers.ParseServerDuration(DateHelpers.ZERO_DURATION_STRING),
      driveToJobTime: DateHelpers.ParseServerDuration(DateHelpers.ZERO_DURATION_STRING),
      waitTime: DateHelpers.ParseServerDuration(DateHelpers.ZERO_DURATION_STRING),
      pourTime: DateHelpers.ParseServerDuration(DateHelpers.ZERO_DURATION_STRING),
      unloadedAtJobTime: DateHelpers.ParseServerDuration(DateHelpers.ZERO_DURATION_STRING),
      driveToPlantTime: DateHelpers.ParseServerDuration(DateHelpers.ZERO_DURATION_STRING),
      drivingTimeAdjustmentPercentage: '0',
      unengagedTimeAdjustmentPercentage: '0',
      fuelVolumeUnit: Enums.Unit.Ac,
      fuelCostPerUnit: Currency.zero(),
      driveDistanceUnit: Enums.Unit.Ac,
      driveDistancePerFuelVolume: '0',
      driveDistanceToJob: '0',
      driveDistanceToPlant: '0',
      loadSizeUnit: Enums.Unit.Ac,
      loadSize: '0',
      totalDeliveryCost: Cost.zero(),
    };
    return new DeliveryCost(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /**
   * calculateTotalDeliveryCost is a helper function that is used to get the calculated value of the total delivery cost
   * independently of the backend calculated value. This is useful for displaying the total cost prior to the creation
   * or saving of an object with a delivery cost or for using the total delivery cost in another calculation prior to
   * the backend having calculated the total delivery cost. This function is not intended to be the source of truth or
   * final word regarding the total delivery cost, but it should return the same value as the source of truth
   * regardless.
   *
   * @param {DeliveryCost} this - The delivery cost object for which the total delivery cost should be calculated.
   * @returns the total delivery cost represented by the delivery cost object.
   */
  calculateTotalDeliveryCost(): Cost | null {
    // TODO: deliveryCostOverride can be undefined because this was parsed from a Plant,
    // where the deliveryCosts field is nullable, so Types.Plant.zero() has that field as null
    // rather than providing default values for the sub-fields, so NewPlantFromDomainObject
    // doesn't coerce undefined to zero values for subfields thereof.  Ugh.
    if (this.deliveryCostOverride !== null && this.deliveryCostOverride !== undefined) {
      return new Cost(this.deliveryCostOverride ?? undefined);
    }
    if (this.loadSize === undefined || this.loadSize === '' || Number(this.loadSize) === 0) {
      return null;
    }

    // Time cost calculation.
    const drivingTimeSum = this.driveToJobTime.plus(this.driveToPlantTime);
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const drivingTimeFactor = 1 + Number(this.drivingTimeAdjustmentPercentage || '0');
    const drivingTime = Duration.fromObject({
      minutes: drivingTimeSum.as('minutes') * drivingTimeFactor,
    });

    const totalTimeSum = [
      drivingTime,
      this.loadTime,
      this.loadedInYardTime,
      this.waitTime,
      this.pourTime,
      this.unloadedAtJobTime,
    ]
      .filter((duration) => duration.isValid)
      .reduce((sum, addend) => sum.plus(addend), Duration.fromMillis(0));
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const totalTimeDivisor = 1 - Number(this.unengagedTimeAdjustmentPercentage || '0');
    const totalTime = totalTimeSum.as('minutes') / totalTimeDivisor;

    const timeCost = this.perMinCost.multiply(totalTime) ?? Currency.zero();

    // Fuel cost calculation.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const driveDistancePerFuelVolume = Number(this.driveDistancePerFuelVolume || '0');
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fuelCost: Currency = (() => {
      if (driveDistancePerFuelVolume === 0) {
        return Currency.zero();
      }
      const drivingDistance = // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        Number(this.driveDistanceToJob || '0') + Number(this.driveDistanceToPlant || '0');
      const currencyPerDistanceUnit = this.fuelCostPerUnit.divide(driveDistancePerFuelVolume);
      return currencyPerDistanceUnit?.multiply(drivingDistance) ?? Currency.zero();
    })();

    // Sum costs and calculate per unit.
    const perUnitCost = fuelCost.add(timeCost)?.divide(Number(this.loadSize)) ?? Currency.zero();
    const totalDeliveryCostData: ExcludeMethodsDeep<Cost> = {
      amount: perUnitCost,
      unit: this.loadSizeUnit,
    };
    return new Cost(totalDeliveryCostData);
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewDeliveryCost = (
  props: PartialDeep<DeliveryCost, { recurseIntoArrays: true }>,
): DeliveryCost => new DeliveryCost(props);

export const NewDeliveryCostFromDomainObject = (
  props: PartialDeep<DomainObject<DeliveryCost>, { recurseIntoArrays: true }>,
): DeliveryCost => new DeliveryCost(props);
