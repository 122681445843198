import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Field, FieldAttributes, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { NestedKeyOf } from '../../utils/Types';
import { YupSchemaReferenceType } from '../../utils/YupHelpers';
import {
  AlertDupeOptions,
  GetOptFromValue,
  LookupInputAutocompleteOption,
  LookupInputInput,
  LookupInputOption,
  SharedLookupInputProps,
} from './LookupInputSharedComponents';

// Typing in the autocomplete box will match against the label or the sublabel
const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: LookupInputOption) => `${option.label}${(option.sublabels ?? []).join('')}`,
});

/**
 * A Slabstack formik-friendly datalist.
 *
 * Must be used inside `<Formik><Form>{component}</Form></Formik>`.
 *
 * @example
 * <Formik {your_values_here} >
 * {(formikBag): JSX.Element => (
 *   <Form>
 *     <LocalLookupInput
 *       label='Company name'
 *       name='company.id'
 *       options={companyOpts}
 *       onMatchChange={(companyId: string | null): void => {
 *         // InputLookup has empty text (no match)
 *         if (companyId === null) {}
 *         // InputLookup has text that matches a value
 *         if (companyId !== null) {}
 *       }}
 *     />
 *   </Form>
 * </Formik>
 */
export const LocalLookupInput = <
  FState extends Record<string, any>,
  FPath extends NestedKeyOf<FState>,
>({
  label,
  name,
  options,
  grouped = false,
  onMatchChange,
  placeholder,
  disabled,
  tip,
}: SharedLookupInputProps<FState, FPath>): JSX.Element => {
  AlertDupeOptions(options);
  const labelsByValue = new Map(options.map((o) => [o.value, o.label]));

  const { getFieldMeta } = useFormikContext();
  const formikObject = getFieldMeta(name).value;

  const isFormReference =
    Object.keys(formikObject ?? ({} as YupSchemaReferenceType)).includes('id') &&
    Object.keys(formikObject ?? ({} as YupSchemaReferenceType)).includes('option');

  // If the value has an 'id' field, consider that to be the main value used.
  const formikValue = isFormReference ? (formikObject as YupSchemaReferenceType).id : formikObject;

  const [stateOpt, setStateOpt] = useState<LookupInputOption | null>(
    GetOptFromValue(formikValue, options),
  );
  const [stateValue, setStateValue] = useState<string>(labelsByValue.get(formikValue) ?? '');

  const groupBy = !grouped ? undefined : (opt: LookupInputOption): string => opt.group ?? '';

  // Ensure that the stateValue is in sync with the field value at all times.
  useEffect(() => {
    if (formikValue === null) {
      setStateOpt(null);
      setStateValue('');
      return;
    }
    const match = labelsByValue.get(formikValue);
    if (match !== undefined) {
      setStateOpt(GetOptFromValue(formikValue, options));
      setStateValue(match);
    }
  }, [formikValue]);

  return (
    <Field id={name} name={name}>
      {({
        field: { onBlur },
        form: { setFieldTouched, setFieldValue },
        meta,
      }: FieldAttributes<any>): JSX.Element => {
        const errorMessage = isFormReference ? meta.error?.id : meta.error;
        return (
          <Autocomplete
            id={name}
            onBlur={onBlur}
            disabled={disabled}
            isOptionEqualToValue={(option, value): boolean =>
              value === undefined || option.value === value.value
            }
            inputValue={stateValue}
            onInputChange={(_, inputText): void => {
              setStateValue(inputText);
            }}
            value={stateOpt}
            onChange={(_, selectedOpt): void => {
              setStateOpt(selectedOpt);

              setFieldTouched(name);

              // Input has no text
              if (selectedOpt === null) {
                onMatchChange?.(null);
                setFieldValue(name, isFormReference ? { id: null, option: null } : null);
                return;
              }
              // Input has text that matches an LookupInputOption

              onMatchChange?.(selectedOpt.value);
              // Update the value if it is a reference, or a direct value.
              const newValue = isFormReference
                ? {
                    id: selectedOpt.value,
                    option: selectedOpt,
                  }
                : selectedOpt.value;
              setFieldValue(name, newValue);
            }}
            autoComplete={false}
            disablePortal
            fullWidth
            options={options}
            groupBy={groupBy}
            renderInput={(params): JSX.Element => (
              <LookupInputInput
                hasError={meta.touched === true && meta.error !== undefined}
                helperText={errorMessage}
                // LocalLookupInput will **always** have its options supplied.
                isLoading={false}
                name={name}
                params={params}
                stateValue={stateValue}
                label={label}
                placeholder={placeholder}
                tip={tip}
              />
            )}
            filterOptions={filterOptions}
            renderOption={LookupInputAutocompleteOption}
          />
        );
      }}
    </Field>
  );
};
