// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class MonthlyForecastAgg {
  readonly year: string;
  readonly month: string;
  readonly totalProjectCuyd: string;
  readonly budgetCuyd: string;
  readonly capacityCuyd: string;

  constructor(data: { [key: string]: any } = {}) {
    this.year = data.year === undefined ? '' : data.year;
    this.month = data.month === undefined ? '' : data.month;
    this.totalProjectCuyd = data.totalProjectCuyd === undefined ? '0' : data.totalProjectCuyd;
    this.budgetCuyd = data.budgetCuyd === undefined ? '0' : data.budgetCuyd;
    this.capacityCuyd = data.capacityCuyd === undefined ? '0' : data.capacityCuyd;
  }

  static zero(): MonthlyForecastAgg {
    const zeroValues: ExcludeMethodsDeep<MonthlyForecastAgg> = {
      year: '',
      month: '',
      totalProjectCuyd: '0',
      budgetCuyd: '0',
      capacityCuyd: '0',
    };
    return new MonthlyForecastAgg(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewMonthlyForecastAgg = (
  props: PartialDeep<MonthlyForecastAgg, { recurseIntoArrays: true }>,
): MonthlyForecastAgg => new MonthlyForecastAgg(props);

export const NewMonthlyForecastAggFromDomainObject = (
  props: PartialDeep<DomainObject<MonthlyForecastAgg>, { recurseIntoArrays: true }>,
): MonthlyForecastAgg => new MonthlyForecastAgg(props);
