import { Box } from '@mui/material';
import { DateTime } from 'luxon';

import { ButtonPill } from '../../../components/ButtonPill/ButtonPill';
import { DataTable } from '../../../components/DataTable/DataTable';
import { ColumnConfig } from '../../../components/DataTable/TableDataModel';
import {
  InitialTableData,
  useLocalTableData,
} from '../../../components/DataTable/useLocalTableData';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { YearSelector } from '../../../components/YearSelector/YearSelector';
import Enums from '../../../generated-types/Enums';
import { Forecast } from '../../../generated-types/Forecast/Forecast';
import { MonthlyForecastCells } from '../../../generated-types/row-types';
import { useSlabQuery } from '../../../hooks/useSlabQuery';
import { PossiblyAsyncResult } from '../../../utils/Query';
import { NestedKeyOf } from '../../../utils/Types';
import { randomUUID } from '../../../utils/UUID';
import { filteredMappedForecasts, possibleYears } from '../../Forecasts/ForecastHelpers';

type PlantForecastListRow = MonthlyForecastCells & {
  id: string;
  name: string;
  year: number;
  total: number;
};

// Abusing aggregations to calculate the set of year options for the filter menu.
type YearAggregation = {
  id: 'yearOpts';
  years: ReturnType<typeof possibleYears>;
};

const constructForecastListRow = (
  name: string,
  forecasts: Forecast[],
  selectedYear: number,
): PlantForecastListRow => {
  const fmForecasts = filteredMappedForecasts(forecasts, selectedYear);
  const total = Object.values(fmForecasts).reduce((acc, f) => acc + Number(f ?? 0), 0);

  return {
    id: randomUUID(),
    name,
    year: selectedYear,
    total,
    ...fmForecasts,
  };
};

const buildTableDataModelConfig = ({
  plantForecastsResult,
  selectedYear,
}: {
  plantForecastsResult: PossiblyAsyncResult<Forecast[] | undefined>;
  selectedYear: number;
}): InitialTableData<PlantForecastListRow, YearAggregation, Forecast[]> => {
  const transformRows = (forecasts: Forecast[]): PlantForecastListRow[] => {
    const budgetForecasts = Forecast.fillForecasts({
      currentForecasts: forecasts ?? [],
      kind: Enums.ForecastKind.Budget,
    });
    const capacityForecasts = Forecast.fillForecasts({
      currentForecasts: forecasts ?? [],
      kind: Enums.ForecastKind.Capacity,
    });
    return [
      constructForecastListRow('Budget', budgetForecasts, selectedYear),
      constructForecastListRow('Capacity', capacityForecasts, selectedYear),
    ];
  };

  const columns: ColumnConfig<PlantForecastListRow, NestedKeyOf<PlantForecastListRow>>[] = [
    {
      id: 'name',
      isDisplayed: true,
      type: 'string',
      label: 'Type',
    },
    {
      id: 'total',
      isDisplayed: true,
      type: 'number',
      label: 'Total',
    },
    {
      id: '1',
      label: DateTime.fromObject({ year: selectedYear, month: 1 }).monthShort ?? 'Jan',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '2',
      label: DateTime.fromObject({ year: selectedYear, month: 2 }).monthShort ?? 'Feb',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '3',
      label: DateTime.fromObject({ year: selectedYear, month: 3 }).monthShort ?? 'Mar',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '4',
      label: DateTime.fromObject({ year: selectedYear, month: 4 }).monthShort ?? 'Apr',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '5',
      label: DateTime.fromObject({ year: selectedYear, month: 5 }).monthShort ?? 'May',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '6',
      label: DateTime.fromObject({ year: selectedYear, month: 6 }).monthShort ?? 'Jun',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '7',
      label: DateTime.fromObject({ year: selectedYear, month: 7 }).monthShort ?? 'Jul',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '8',
      label: DateTime.fromObject({ year: selectedYear, month: 8 }).monthShort ?? 'Aug',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '9',
      label: DateTime.fromObject({ year: selectedYear, month: 9 }).monthShort ?? 'Sep',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '10',
      label: DateTime.fromObject({ year: selectedYear, month: 10 }).monthShort ?? 'Oct',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '11',
      label: DateTime.fromObject({ year: selectedYear, month: 11 }).monthShort ?? 'Nov',
      type: 'number',
      isDisplayed: true,
    },
    {
      id: '12',
      label: DateTime.fromObject({ year: selectedYear, month: 12 }).monthShort ?? 'Dec',
      type: 'number',
      isDisplayed: true,
    },
  ];

  const aggregateRows = ({
    rawData,
  }: {
    rawData: Forecast[] | undefined;
  }): { [id: string]: YearAggregation } => {
    const forecasts = rawData ?? [];
    const years = new Set(forecasts.map((f) => f.intervalStart.year));
    return {
      yearOpts: {
        id: 'yearOpts',
        years: possibleYears({ yearsWithForecasts: years }),
      },
    };
  };

  return {
    rowData: plantForecastsResult,
    transformRows,
    columns,
    initialSortPath: 'total',
    aggregateRows,
    filters: [{ operation: Enums.FilterOperation.Equals, name: 'year', value: selectedYear }],
  };
};

type ForecastTableProps = {
  plantId: string;

  // NOTE: We pass the year from useState of the parent ([set]selectedYear) so the child useState
  // will not default to "now" when the ForecastTable component is re-rendered.
  selectedYear: number;
  setSelectedYear: React.Dispatch<React.SetStateAction<number>>;

  onEditForecasts: () => void;
};

export const ForecastTable = ({
  plantId,
  selectedYear,
  setSelectedYear,
  onEditForecasts,
}: ForecastTableProps): JSX.Element => {
  const plantForecastsResult = useSlabQuery('GET plant forecasts by plant ID', {
    pathParams: {
      id: plantId,
    },
  });

  const tableModel = useLocalTableData(
    buildTableDataModelConfig({
      plantForecastsResult,
      selectedYear,
    }),
  );

  if (plantForecastsResult.isLoading) {
    return <LoadingSpinner />;
  }

  if (plantForecastsResult.isError) {
    return <div>ERROR</div>;
  }

  tableModel.addFilters({
    operation: Enums.FilterOperation.Equals,
    name: 'year',
    value: selectedYear,
  });

  const yearOpts = tableModel.aggregations.data.yearOpts.years;

  return (
    <Box p='1rem'>
      <Box display='flex' justifyContent='space-between' paddingBottom='1rem'>
        <YearSelector
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          yearOpts={yearOpts}
        />
        <ButtonPill
          text='edit forecasts'
          size='small'
          onClick={onEditForecasts}
          variant='primary'
          icon='edit'
        />
      </Box>

      <DataTable tableModel={tableModel} />
    </Box>
  );
};
