import { Box, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import _ from 'lodash';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { SlabDrawer } from '../../components/SlabDrawer/SlabDrawer';
import { Mix, NewMix } from '../../generated-types/Mix/Mix';
import { SharedDrawerOverrideProps } from '../../hooks/useDrawerManager';
import { useSlabMutation } from '../../hooks/useSlabMutation';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { DefaultEnsureDefined } from '../../utils/DomainHelpers';
import { FormErrorNotification } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import { MixDesignDrawerSections, MixDesignLabelOpts } from './components/MixDesignDrawerSections';
import { FormikMixDesign, MixDesignFormikType, MixDesignSchema } from './MixDesignFormik';

export const formikMixDesignToWire = (values: MixDesignFormikType): Mix => {
  const cleanWaterRatio = (values.waterCementRatio ?? '').replaceAll(',', '');
  const waterCementRatio = Number.isNaN(parseFloat(cleanWaterRatio))
    ? null
    : (parseFloat(cleanWaterRatio) / 100).toFixed(3);

  // TODO #2219 Need a NewMixFromForm wrapper for this case
  const wireMix = new Mix(
    _.merge(Mix.zero(), {
      ...values,
      waterCementRatio,
    }),
  );
  return wireMix;
};

type MixDrawerProps = Omit<SharedDrawerOverrideProps<Mix>, 'resourceId' | 'onSave'> & {
  mixId: string | null;
  onSuccess: (mix: Mix) => void;
  /** @default 'Mix Information' */
  initialSection?: MixDesignLabelOpts;
};

const MixDrawerPage = ({
  mixId,
  setIsOpen,
  initialSection = 'Mix design details*',
  onSuccess,
  onError,
  onClose,
  ensureDefined = DefaultEnsureDefined,
}: MixDrawerProps): JSX.Element | null => {
  const isMixIdNull = mixId === null;

  const createNew = useSlabMutation('POST mix', {
    onSuccess,
    onError,
  });
  const updateExisting = useSlabMutation('PUT mix by ID', {
    onSuccess,
    onError,
  });

  const {
    isLoading: isLoadingMix,
    isError: isErrorMix,
    data: maybeMix,
  } = useSlabQuery(
    'GET mix by ID',
    {
      pathParams: {
        id: mixId ?? '',
      },
    },
    { enabled: !isMixIdNull },
  );

  const {
    isLoading: isLoadingMixMaterials,
    isError: isErrorMixMaterials,
    data: mixMaterials,
  } = useSlabQuery(
    'GET materials by mix ID',
    {
      pathParams: {
        id: mixId ?? '',
      },
    },
    { enabled: !isMixIdNull },
  );

  const isLoading = isLoadingMix || isLoadingMixMaterials;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isError = isErrorMix || isErrorMixMaterials;

  if (isError) {
    return <div>ERROR</div>;
  }

  const mix = ensureDefined(
    maybeMix === undefined || mixMaterials === undefined
      ? undefined
      : NewMix({
          ...maybeMix,
          mixMaterials,
        }),
  );

  const formikMix = FormikMixDesign(mix);

  const isNew = formikMix.id === NIL_UUID;

  return (
    <Formik
      validationSchema={MixDesignSchema}
      initialValues={formikMix}
      onSubmit={async (values): Promise<void> => {
        const wireMix = formikMixDesignToWire(values);
        if (formikMix.id === NIL_UUID) {
          createNew.mutate({
            args: {
              body: wireMix,
            },
            schema: MixDesignSchema,
          });
        } else {
          updateExisting.mutate({
            args: {
              pathParams: {
                id: formikMix.id,
              },
              body: wireMix,
            },
            schema: MixDesignSchema,
          });
        }
      }}
    >
      {(formikBag): JSX.Element => (
        <Box padding='5rem 3.5rem 5rem 3.5rem'>
          <FormErrorNotification />
          <Typography variant='h1' fontSize='2rem'>
            {isNew ? 'Create ' : 'Edit '}a mix design
          </Typography>
          <MixDesignDrawerSections initialSection={initialSection} />
          <Grid container spacing={2} sx={{ '& button': { width: '100%' } }}>
            <Grid item xs={4}>
              <ButtonPill
                text='cancel'
                variant='secondary'
                onClick={(): void => {
                  formikBag.resetForm({ values: formikMix });
                  setIsOpen(false);
                  onClose?.();
                }}
                disabled={createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='reset'
                variant='secondary'
                onClick={(): void => formikBag.resetForm({ values: formikMix })}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
            <Grid item xs={4}>
              <ButtonPill
                text='save'
                variant='primary'
                onClick={formikBag.submitForm}
                disabled={!formikBag.dirty || createNew.isPending || updateExisting.isPending}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
};

export const MixDrawer = (props: MixDrawerProps): JSX.Element => (
  <SlabDrawer isOpen={props.isOpen}>
    <MixDrawerPage {...props} />
  </SlabDrawer>
);
