import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { FormatLinkCell } from '../../components/DataTable/components/SlabTableRowCells';
import { DataTable } from '../../components/DataTable/DataTable';
import { ColumnConfig } from '../../components/DataTable/TableDataModel';
import {
  ApiTableDataWithoutAggregationsParams,
  useApiTableDataWithoutAggregations,
} from '../../components/DataTable/useApiTableData';
import { Page } from '../../components/Page/Page';
import Enums from '../../generated-types/Enums';
import { MaterialSummary } from '../../generated-types/MaterialSummary/MaterialSummary';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { QueryRouteBarrelTypes } from '../../utils/ApiClient';
import { NestedKeyOf } from '../../utils/Types';
import { MaterialDrawer } from './MaterialDrawer';

const columns: ColumnConfig<MaterialSummary, NestedKeyOf<MaterialSummary>>[] = [
  {
    id: 'alternateID',
    label: 'Material ID',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'name',
    label: 'Name',
    type: 'string',
    isDisplayed: true,
    formatValueForWeb: (label, row) => FormatLinkCell({ label, link: `/materials/${row.id}` }),
  },
  {
    id: 'materialType',
    label: 'Material Type',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'inProductCost',
    label: 'Cost',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'plantName',
    label: 'Plant',
    type: 'string',
    isDisplayed: true,
  },
  {
    id: 'supplierCompany',
    label: 'Supplier',
    type: 'string',
    isDisplayed: false,
  },
  {
    id: 'rawMaterialCost',
    label: 'Raw Material Cost',
    type: 'string',
    isDisplayed: false,
  },
  {
    id: 'haulingCost',
    label: 'Hauling Cost',
    type: 'string',
    isDisplayed: false,
  },
  {
    id: 'plantLoadingCost',
    label: 'Plant Loading Cost',
    type: 'string',
    isDisplayed: false,
  },
];

const buildApiTableDataModelConfig = (): ApiTableDataWithoutAggregationsParams<
  MaterialSummary,
  'GET material summaries',
  QueryRouteBarrelTypes['GET material summaries']
> => ({
  rowsRouteKey: 'GET material summaries',
  extractRows: (materialList) => materialList.items,
  initialSortBy: {
    direction: Enums.SortDirection.Ascending,
    name: 'name',
  },
  makeColumnConfigs: () => columns,
});

export const MaterialList = (): JSX.Element => {
  const navigate = useNavigate();

  const [materialId, setMaterialId] = useState<string | null>(null);

  const { toastHook: materialToastHook, ...materialDrawerProps } = useDrawerManager({
    baseUrl: '/materials',
    resourceTypeName: 'material',
    drawerProps: {
      resourceId: materialId,
    },
  });

  const tableModel = useApiTableDataWithoutAggregations(buildApiTableDataModelConfig());

  return (
    <Page title='Materials'>
      {materialToastHook.toast}
      <MaterialDrawer
        {...materialDrawerProps}
        materialId={materialId}
        onSuccess={(material): void => {
          if (materialId === null) {
            navigate(`/materials/${material.id}`);
          } else {
            materialDrawerProps.setIsOpen(false);
            materialToastHook.showToast('success');
          }
        }}
      />
      <Box display='flex' justifyContent='space-between' paddingBottom='3.5rem'>
        <Typography variant='h1'>Materials</Typography>
        <ButtonPill
          text='create material'
          variant='primary'
          onClick={(): void => {
            setMaterialId(null);
            materialDrawerProps.setIsOpen(true);
          }}
          icon='add'
        />
      </Box>

      <Box height='400px'>
        <DataTable
          tableModel={tableModel}
          onEditPress={(row): void => {
            setMaterialId(row.id);
            materialDrawerProps.setIsOpen(true);
          }}
          includePrint={{ title: 'Materials' }}
          marketSelectorOpts={{ enabled: true }}
          plantSelectorOpts={{ enabled: true }}
        />
      </Box>
    </Page>
  );
};
