import * as Yup from 'yup';

import Enums from '../../generated-types/Enums';
import { NewProduct, Product } from '../../generated-types/Product/Product';
import { DomainObject } from '../../utils/ApiClient';
import { NullableCurrencyString } from '../../utils/Currency';
import { FormikNullableCurrency } from '../../utils/FormikHelpers';
import { NIL_UUID } from '../../utils/UUID';
import {
  marginPercentage,
  TestForUndefined,
  YupEnum,
  YupNullableCurrency,
  YupNullableNumber,
  YupNullableReference,
  YupPercentage,
  YupReference,
  YupSchemaNullableReferenceType,
  YupSchemaReferenceType,
  YupString,
} from '../../utils/YupHelpers';

export type ProductFormikType = DomainObject<
  Omit<
    Product,
    'mix' | 'plant' | 'resaleMaterial' | 'materialCost' | 'otherCost' | 'incompatibleMixBatchUnits'
  >
> & {
  mix: YupSchemaNullableReferenceType;
  plant: YupSchemaReferenceType;
  resaleMaterial: YupSchemaNullableReferenceType;

  // Display-only formik-embedded fields. These are omitted from the Yup validation schema
  displayOnly: {
    materialCost: string | null;
    otherCost: string | null;
    minimumMargin: string | null;
    targetMargin: string | null;
    suggestedPrice: string | null;
    marginOverMaterials: string | null;
    actualMargin: string | null;

    // Needed for matching mix or resaleMaterial name on plant change
    materialName: string | null;
    mixName: string | null;
  };
};

export const ProductSchema: Yup.SchemaOf<
  Omit<ProductFormikType, 'id' | 'externalID' | 'displayOnly'>
> = Yup.object({
  alternateID: YupString('Alternate ID'),
  name: YupString('Name'),
  category: YupEnum(Enums.ProductCategory, 'Category'),
  cuydVolume: YupNullableNumber({ label: 'Volume' }),
  measurementUnit: YupEnum(Enums.Unit, 'Measurement unit'),
  listPrice: YupNullableCurrency('List price'),
  targetMargin: YupPercentage('Target margin')
    .test(marginPercentage)
    .test(
      'target-higher-or-equal-to-minimum',
      'Must be more than or equal to minimum',
      (value, ctx) => parseFloat(value ?? '0') >= ctx.parent.minimumMargin,
    ),
  minimumMargin: YupPercentage('Minimum margin')
    .test(marginPercentage)
    .test(
      'minimum-lower-or-equal-to-target',
      'Must be less than or equal to target',
      (value, ctx) =>
        parseFloat(value ?? String(parseFloat(ctx.parent.targetMargin) + 1)) <=
        ctx.parent.targetMargin,
    ),
  materialCostOverride: YupNullableCurrency('Material cost'),
  otherCostOverride: YupNullableCurrency('Other cost'),
  mix: YupNullableReference('Mix'),
  resaleMaterial: YupNullableReference('Resale material'),
  plant: YupReference('Plant'),
}).test(TestForUndefined('ProductSchema'));

export const FormikProduct = (productData: Partial<Product> | undefined): ProductFormikType => {
  const maybeUnitKeyVal = Object.entries(Enums.Unit).find(
    ([, v]) => v === productData?.measurementUnit,
  );
  const maybeCategoryKeyVal = Object.entries(Enums.ProductCategory).find(
    ([, v]) => v === productData?.category,
  );

  // Construct a temporary model instance so we can call its calculation methods.
  const tempProduct = productData === undefined ? Product.zero() : NewProduct(productData);

  return {
    id: productData?.id ?? NIL_UUID,
    alternateID: productData?.alternateID ?? '',
    externalID: productData?.externalID ?? null,
    name: productData?.name ?? '',
    category:
      maybeCategoryKeyVal !== undefined ? maybeCategoryKeyVal[1] : Enums.ProductCategory.Mix,
    cuydVolume: productData?.cuydVolume ?? null,
    measurementUnit: maybeUnitKeyVal !== undefined ? maybeUnitKeyVal[1] : Enums.Unit.CuYd,
    listPrice: FormikNullableCurrency(productData?.listPrice),
    // Wrap in two parseFloats to remove redundant trailing zeroes
    targetMargin:
      productData?.targetMargin !== undefined
        ? String(parseFloat((parseFloat(productData.targetMargin) * 100).toFixed(3)))
        : '',
    minimumMargin:
      productData?.minimumMargin !== undefined
        ? String(parseFloat((parseFloat(productData.minimumMargin) * 100).toFixed(3)))
        : '',
    materialCostOverride: FormikNullableCurrency(productData?.materialCostOverride),
    otherCostOverride: FormikNullableCurrency(productData?.otherCostOverride),
    mix: {
      id: productData?.mix?.id ?? null,
      option:
        productData?.mix === undefined || productData.mix === null
          ? null
          : {
              value: productData.mix.id,
              label: productData.mix.name,
            },
    },
    resaleMaterial: {
      id: productData?.resaleMaterial?.id ?? null,
      option:
        productData?.resaleMaterial === undefined || productData.resaleMaterial === null
          ? null
          : {
              value: productData.resaleMaterial.id,
              label: productData.resaleMaterial.name,
            },
    },
    plant: {
      id: productData?.plant?.id ?? null,
      option:
        productData?.plant === undefined
          ? null
          : {
              value: productData.plant.id,
              label: productData.plant.name,
            },
    },

    displayOnly: {
      materialCost:
        productData?.category === Enums.ProductCategory.Mix ||
        productData?.category === Enums.ProductCategory.Resale
          ? productData?.suggestedMaterialCostDisplay?.() ?? null
          : null,
      otherCost:
        productData?.category === Enums.ProductCategory.Mix
          ? NullableCurrencyString({ cur: tempProduct.calculateOtherCost() })
          : null,
      minimumMargin:
        productData?.plant?.readyMixConstants?.minimumMarginDisplay()?.replace('%', '') ?? null,
      targetMargin:
        productData?.plant?.readyMixConstants?.targetMarginDisplay()?.replace('%', '') ?? null,
      suggestedPrice: NullableCurrencyString({ cur: tempProduct.calculateSuggestedPrice() }),
      marginOverMaterials: productData?.marginOverMaterialDisplay?.() ?? null,
      actualMargin: productData?.marginDisplay?.() ?? null,
      materialName: productData?.resaleMaterial?.name ?? null,
      mixName: productData?.mix?.name ?? null,
    },
  };
};
