// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

// webgen:import { "defaultName": "Enums", "path": "../Enums" }
// webgen:import { "names": ["List"], "path": "../../utils/List" }
// webgen:import { "wildcardName": "CurrencyHelpers", "path": "../../utils/Currency" }

import { DateTime, Duration } from 'luxon';
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import * as CurrencyHelpers from '../../utils/Currency';
import * as DateHelpers from '../../utils/DateHelpers';
import { List } from '../../utils/List';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';
import { Address, NewAddress } from '../Address/Address';
import { Currency, NewCurrency } from '../Currency/Currency';
import Enums from '../Enums';
import { Forecast, NewForecast } from '../Forecast/Forecast';
import { NewPlant, Plant } from '../Plant/Plant';
import { NewProjectCompany, ProjectCompany } from '../ProjectCompany/ProjectCompany';
import { NewProjectConfig, ProjectConfig } from '../ProjectConfig/ProjectConfig';
import {
  NewProjectCustomField,
  ProjectCustomField,
} from '../ProjectCustomField/ProjectCustomField';
import { NewProjectProduct, ProjectProduct } from '../ProjectProduct/ProjectProduct';
import { NewProjectStatus, ProjectStatus } from '../ProjectStatus/ProjectStatus';
import { NewSegment, Segment } from '../Segment/Segment';
import { NewTaxCode, TaxCode } from '../TaxCode/TaxCode';
import { NewUser, User } from '../User/User';

export class Project {
  readonly id: string;
  readonly name: string;
  readonly address: Address;
  readonly winningCompany: ProjectCompany | null;
  readonly companies: ProjectCompany[];
  readonly projectStatus: ProjectStatus;
  readonly totalCostsOverride: Currency | null;
  readonly estimatedVolumeOverride: number | null;
  readonly revenueOverride: Currency | null;
  readonly products: ProjectProduct[];
  readonly customFields: ProjectCustomField[];
  readonly plant: Plant;
  readonly plantDistanceMiles: string | null;
  readonly plantDriveTime: Duration | null;
  readonly estimatedStartDate: DateTime | null;
  readonly estimatedEndDate: DateTime | null;
  readonly expirationDate: DateTime | null;
  readonly bidDate: DateTime | null;
  readonly confidence: string | null;
  readonly notes: string | null;
  readonly owner: string | null;
  readonly competitor: string | null;
  readonly taxCode: TaxCode | null;
  readonly segment: Segment | null;
  readonly user: User;
  readonly projectConfig: ProjectConfig | null;
  readonly forecasts: Forecast[];
  readonly totalCosts: Currency;
  readonly estimatedVolume: number;
  readonly revenue: Currency;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
    this.address = NewAddress(data.address);
    this.winningCompany =
      // eslint-disable-next-line no-nested-ternary
      (data.winningCompany ?? null) === null ? null : NewProjectCompany(data.winningCompany);
    this.companies = (data.companies ?? []).map((companies: any) => NewProjectCompany(companies));
    this.projectStatus = NewProjectStatus(data.projectStatus);
    this.totalCostsOverride =
      (data.totalCostsOverride ?? null) === null ? null : NewCurrency(data.totalCostsOverride);
    this.estimatedVolumeOverride =
      data.estimatedVolumeOverride === undefined ? null : data.estimatedVolumeOverride;
    this.revenueOverride =
      (data.revenueOverride ?? null) === null ? null : NewCurrency(data.revenueOverride);
    this.products = (data.products ?? []).map((products: any) => NewProjectProduct(products));
    this.customFields = (data.customFields ?? []).map((customFields: any) =>
      NewProjectCustomField(customFields),
    );
    this.plant = NewPlant(data.plant);
    this.plantDistanceMiles =
      data.plantDistanceMiles === undefined ? null : data.plantDistanceMiles;
    this.plantDriveTime = DateHelpers.ParseServerNullableDuration(data.plantDriveTime);
    this.estimatedStartDate = DateHelpers.ParseServerNullableDate(data.estimatedStartDate);
    this.estimatedEndDate = DateHelpers.ParseServerNullableDate(data.estimatedEndDate);
    this.expirationDate = DateHelpers.ParseServerNullableDate(data.expirationDate);
    this.bidDate = DateHelpers.ParseServerNullableDate(data.bidDate);
    this.confidence = data.confidence === undefined ? null : data.confidence;
    this.notes = data.notes === undefined ? null : data.notes;
    this.owner = data.owner === undefined ? null : data.owner;
    this.competitor = data.competitor === undefined ? null : data.competitor;
    this.taxCode =
      // eslint-disable-next-line no-nested-ternary
      (data.taxCode ?? null) === null ? null : NewTaxCode(data.taxCode);
    this.segment =
      // eslint-disable-next-line no-nested-ternary
      (data.segment ?? null) === null ? null : NewSegment(data.segment);
    this.user = NewUser(data.user);
    this.projectConfig =
      // eslint-disable-next-line no-nested-ternary
      (data.projectConfig ?? null) === null ? null : NewProjectConfig(data.projectConfig);
    this.forecasts = (data.forecasts ?? []).map((forecasts: any) => NewForecast(forecasts));
    this.totalCosts = NewCurrency(data.totalCosts);
    this.estimatedVolume = data.estimatedVolume === undefined ? 0 : data.estimatedVolume;
    this.revenue = NewCurrency(data.revenue);
  }

  static zero(): Project {
    const zeroValues: ExcludeMethodsDeep<Project> = {
      id: NIL_UUID,
      name: '',
      address: Address.zero(),
      winningCompany: null,
      companies: [],
      projectStatus: ProjectStatus.zero(),
      totalCostsOverride: null,
      estimatedVolumeOverride: null,
      revenueOverride: null,
      products: [],
      customFields: [],
      plant: Plant.zero(),
      plantDistanceMiles: null,
      plantDriveTime: null,
      estimatedStartDate: null,
      estimatedEndDate: null,
      expirationDate: null,
      bidDate: null,
      confidence: null,
      notes: null,
      owner: null,
      competitor: null,
      taxCode: null,
      segment: null,
      user: User.zero(),
      projectConfig: null,
      forecasts: [],
      totalCosts: Currency.zero(),
      estimatedVolume: 0,
      revenue: Currency.zero(),
    };
    return new Project(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /** Given a ProjectList and a ForecastList, return a copy of the ProjectList that is filled with those forecasts. */
  static fillForecasts(projectList: List<Project>, forecastList: List<Forecast>): List<Project> {
    const newProject = (props: PartialDeep<Project, { recurseIntoArrays: true }>): Project =>
      new Project(props);

    const filledProjects = projectList.items.map((p) => {
      const matchingProjectForecasts = forecastList.items.filter((f) => f.projectId === p.id);
      return newProject({
        ...p,
        forecasts: Forecast.fillForecasts({
          currentForecasts: matchingProjectForecasts,
          kind: Enums.ForecastKind.Project,
        }),
      });
    });
    const projectListData: ExcludeMethodsDeep<List<Project>> = {
      items: filledProjects,
      count: filledProjects.length,
    };
    return new List(newProject, projectListData);
  }

  fillForecasts(forecasts: List<Forecast> | Forecast[]): Project {
    const forecastList =
      forecasts instanceof List
        ? forecasts
        : new List(NewForecast, { items: forecasts, count: forecasts.length });
    return Project.fillForecasts(
      new List((props: PartialDeep<Project, { recurseIntoArrays: true }>) => new Project(props), {
        items: [this],
        count: 1,
      }),
      forecastList,
    ).items[0];
  }

  winningCompanyName(): string | null {
    if (this.winningCompany === null) {
      return null;
    }

    if (this.winningCompany.contact === null) {
      return this.winningCompany.company.name;
    }

    return `${this.winningCompany.company.name} - ${this.winningCompany.contact.fullName()}`;
  }

  confidenceDisplay(): string | null {
    if (this.confidence === null) {
      return null;
    }
    // TODO: Replace this with decimal precision in #492
    return `${String(parseFloat((parseFloat(this.confidence) * 100).toFixed(3)))}%`;
  }

  estimatedStartDateDisplay(): string | null {
    if (this.estimatedStartDate === null) {
      return null;
    }
    return DateHelpers.FormatDisplayDateTime(this.estimatedStartDate);
  }

  estimatedEndDateDisplay(): string | null {
    if (this.estimatedEndDate === null) {
      return null;
    }
    return DateHelpers.FormatDisplayDateTime(this.estimatedEndDate);
  }

  bidDateDisplay(): string | null {
    if (this.bidDate === null) {
      return null;
    }
    return DateHelpers.FormatDisplayDateTime(this.bidDate);
  }

  expirationDateDisplay(): string | null {
    if (this.expirationDate === null) {
      return null;
    }
    return DateHelpers.FormatDisplayDateTime(this.expirationDate);
  }

  estimatedMargin(): number {
    const revenue = CurrencyHelpers.CurrencyDinero(this.revenue).toUnit();
    const totalCosts = CurrencyHelpers.CurrencyDinero(this.totalCosts).toUnit();
    return revenue - totalCosts;
  }

  /** (margin / revenue) * 100 */
  estimatedMarginPercent(opts?: {
    /** @default true */
    includePercent?: boolean;
    /** @default 2 */
    fractionDigits?: number;
  }): string {
    const { includePercent, fractionDigits } = {
      includePercent: opts?.includePercent ?? true,
      fractionDigits: opts?.fractionDigits ?? 2,
    };

    const revenue = CurrencyHelpers.CurrencyDinero(this.revenue).toUnit();

    const estimatedMarginNumber = revenue === 0 ? 0 : (this.estimatedMargin() / revenue) * 100;

    const marginString = estimatedMarginNumber.toLocaleString(undefined, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
    return `${marginString}${includePercent ? '%' : ''}`;
  }

  /** revenue / volume */
  pricePerYardDisplay(): string {
    const revenue = CurrencyHelpers.CurrencyDinero(this.revenue).toUnit();
    const estVolume = this.estimatedVolumeOverride ?? this.estimatedVolume;
    if (revenue === 0 || estVolume === 0) {
      return CurrencyHelpers.CurrencyString({
        cur: Currency.zero(),
      });
    }
    const pricePerYardData: ExcludeMethodsDeep<Currency> = {
      number: String(revenue / estVolume),
      currency: 'USD',
    };
    return CurrencyHelpers.CurrencyString({ cur: new Currency(pricePerYardData) });
  }

  estimatedMarginDisplay(): string {
    const marginData: ExcludeMethodsDeep<Currency> = {
      number: String(this.estimatedMargin()),
      currency: 'USD',
    };
    return CurrencyHelpers.CurrencyString({ cur: new Currency(marginData) });
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewProject = (props: PartialDeep<Project, { recurseIntoArrays: true }>): Project =>
  new Project(props);

export const NewProjectFromDomainObject = (
  props: PartialDeep<DomainObject<Project>, { recurseIntoArrays: true }>,
): Project => new Project(props);
