import { Text, View } from '@react-pdf/renderer';
import { DateTime } from 'luxon';

import { Quote } from '../../../../generated-types/Quote/Quote';
import { randomUUID } from '../../../../utils/UUID';
import { PdfHeader } from '../../../components/PdfHeader';
import {
  PdfColumnConfig,
  PdfColumnFromConfig,
  PdfTable,
  Styles,
} from '../../../components/PdfTable';
import { PdfTextBlock } from '../../../components/PdfTextBlock';
import { PdfSpacer } from '../../components/PdfSpacer';

const formatCenterValueForPdf = (
  value: string,
  fontSize?: string,
  fontFamily?: string,
): JSX.Element => (
  <View style={{ textAlign: 'center', paddingBottom: '4pt' }}>
    <Text
      style={[
        Styles.tableCell,
        {
          fontFamily: fontFamily ?? 'Helvetica-Bold',
          fontSize,
          padding: '0',
        },
      ]}
    >
      {value}
    </Text>
  </View>
);

const formatLeftValueForPdf = (value: string): JSX.Element => (
  <View style={{ textAlign: 'left', paddingRight: '8pt' }}>
    <Text style={[Styles.tableCell, { fontFamily: 'Helvetica-Bold', padding: '0' }]}>{value}</Text>
  </View>
);

const formatRightValueForPdf = (value: string): JSX.Element => (
  <View style={{ textAlign: 'right', paddingLeft: '8pt' }}>
    <Text style={[Styles.tableCell, { fontFamily: 'Helvetica-Bold', padding: '0' }]}>{value}</Text>
  </View>
);

type SingleValue = {
  id: string;
  value: string | JSX.Element;
};

type DoubleValue = {
  id: string;
  leftValue: string | JSX.Element;
  rightValue: string | JSX.Element;
};

const StaticTopTable = (): JSX.Element => {
  const logoImg = (
    <View style={{ alignItems: 'center' }}>
      <PdfHeader image={{ src: '../carew_logo.jpg', imgStyle: { width: '2.0in' } }} />
    </View>
  );

  const rightTopCols: PdfColumnConfig<SingleValue>[] = [
    {
      id: 'value',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: (v) => v,
    },
  ];
  const rightTopRows: SingleValue[] = [
    {
      id: randomUUID(),
      value: formatCenterValueForPdf('Carew Concrete & Supply Co., Inc.', '16pt'),
    },
    {
      id: randomUUID(),
      value: formatCenterValueForPdf(
        '"A Solid Name For Concrete"',
        undefined,
        'Helvetica-BoldOblique',
      ),
    },
    {
      id: randomUUID(),
      value: formatCenterValueForPdf('1811 West Edgewood Drive'),
    },
    {
      id: randomUUID(),
      value: formatCenterValueForPdf('Appleton, WI 54913-9708'),
    },
  ];
  const rightTopTable = (
    <PdfTable columns={rightTopCols.map((col) => PdfColumnFromConfig(col))} rows={rightTopRows} />
  );

  const rightBottomCols: PdfColumnConfig<DoubleValue>[] = [
    {
      id: 'leftValue',
      label: '',
      type: 'string',
      isDisplayed: false,
      formatValueForPdf: formatLeftValueForPdf,
    },
    {
      id: 'rightValue',
      label: '',
      type: 'string',
      isDisplayed: false,
      formatValueForPdf: formatRightValueForPdf,
    },
  ];
  const rightBottomRows: DoubleValue[] = [
    {
      id: randomUUID(),
      leftValue: 'Phone: (920) 731-9771',
      rightValue: 'Fax: (920) 731-9246',
    },
  ];
  const rightBottomTable = (
    <PdfTable
      columns={rightBottomCols.map((col) => PdfColumnFromConfig(col))}
      rows={rightBottomRows}
    />
  );

  const rightCols: PdfColumnConfig<SingleValue>[] = [
    {
      id: 'value',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: (v) => v,
    },
  ];
  const rightRows: SingleValue[] = [
    {
      id: randomUUID(),
      value: rightTopTable,
    },
    {
      id: randomUUID(),
      value: rightBottomTable,
    },
  ];
  const rightTable = (
    <PdfTable
      columns={rightCols.map((col) => PdfColumnFromConfig(col))}
      rows={rightRows}
      cellPadding='tight'
    />
  );

  const tableCols: PdfColumnConfig<DoubleValue>[] = [
    {
      id: 'leftValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: (v) => v,
    },
    {
      id: 'rightValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: (v) => v,
    },
  ];
  const tableRows: DoubleValue[] = [
    {
      id: randomUUID(),
      leftValue: logoImg,
      rightValue: rightTable,
    },
  ];

  return (
    <PdfTable
      columns={tableCols.map((col) => PdfColumnFromConfig(col))}
      rows={tableRows}
      cellPadding='tight'
    />
  );
};

type CarewPdfHeaderTableProps = {
  quote: Quote;
};

const formatLabelledValueForPdf = (label: string, value: string): JSX.Element => (
  <View style={{ textAlign: 'left', paddingRight: '8pt' }}>
    <Text style={[Styles.tableCell, { fontFamily: 'Helvetica-Bold', padding: '2pt' }]}>
      {label}: <Text style={[Styles.tableCell, { fontFamily: 'Helvetica' }]}>{value}</Text>
    </Text>
  </View>
);

const DynamicBottomTable = ({ quote }: CarewPdfHeaderTableProps): JSX.Element => {
  const cols: PdfColumnConfig<DoubleValue>[] = [
    {
      id: 'leftValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: (v) => v,
    },
    {
      id: 'rightValue',
      label: '',
      type: 'custom',
      isDisplayed: false,
      formatValueForPdf: (v) => v,
    },
  ];

  const rows: DoubleValue[] = [
    {
      id: randomUUID(),
      leftValue: formatLabelledValueForPdf('Date', DateTime.now().toFormat('LLL dd, yyyy')),
      rightValue: formatLabelledValueForPdf('Project Name', quote.project.name),
    },
    {
      id: randomUUID(),
      leftValue: formatLabelledValueForPdf('To', quote.company?.name ?? ' '),
      rightValue: formatLabelledValueForPdf('Address', quote.project.address.basicDisplay()),
    },
    {
      id: randomUUID(),
      leftValue: formatLabelledValueForPdf('Contact', quote.contact?.fullName() ?? ' '),
      rightValue: formatLabelledValueForPdf('City', quote.project.address.basicStateDisplay()),
    },
    {
      id: randomUUID(),
      leftValue: formatLabelledValueForPdf('Email', quote.contact?.emailAddress ?? ' '),
      rightValue: formatLabelledValueForPdf('Expiration', quote.expirationDateDisplay() ?? ' '),
    },
  ];

  return <PdfTable columns={cols.map((col) => PdfColumnFromConfig(col))} rows={rows} />;
};

export const CarewPdfHeaderTable = ({ quote }: CarewPdfHeaderTableProps): JSX.Element => (
  <View>
    <StaticTopTable />
    <PdfSpacer height='0.1in' />
    <PdfTextBlock
      content='Bid Quotation'
      textStyle={{
        fontFamily: 'Helvetica-Bold',
        fontSize: '12px',
      }}
      viewStyle={{
        alignItems: 'center',
      }}
    />
    <PdfSpacer height='0.1in' />
    <DynamicBottomTable quote={quote} />
  </View>
);
