import * as Yup from 'yup';

import Enums from '../../generated-types/Enums';
import { Product } from '../../generated-types/Product/Product';
import { ProjectProduct } from '../../generated-types/ProjectProduct/ProjectProduct';
import { QuoteProduct } from '../../generated-types/QuoteProduct/QuoteProduct';
import { DomainObject } from '../../utils/ApiClient';
import { DineroString, NullableCurrencyString } from '../../utils/Currency';
import { FormikCurrency, FormikDeliveryCosts } from '../../utils/FormikHelpers';
import {
  nonNegativeCurrency,
  YupCurrency,
  YupDeliveryCosts,
  YupEnum,
  YupNullableNumber,
  YupNullableString,
  YupNumber,
  YupReference,
  YupSchemaDeliveryCostsType,
  YupSchemaReferenceType,
} from '../../utils/YupHelpers';

/**
 * ProjectOrQuoteProductFormikType is a shared formik type that contains data
 * required for both ProjectProduct and QuoteProduct.
 */
export type ProjectOrQuoteProductFormikType = {
  // Fields shared between ProjectProduct and QuoteProduct
  usage: ProjectProduct['usage'] | QuoteProduct['usage'];
  quantity: ProjectProduct['quantity'] | QuoteProduct['quantity'];
  price: DomainObject<ProjectProduct['price'] | QuoteProduct['price']>;

  // QuoteProduct-specific fields
  kind: QuoteProduct['kind'];
  class: QuoteProduct['class'];
  externalName: QuoteProduct['externalName'];

  // Fields shared between ProjectProduct and QuoteProduct, with different form vs domain types
  product: YupSchemaReferenceType & {
    incompatibleMixBatchUnits: boolean;
    allowIncompatibleMixBatchUnits: boolean;
  };
  deliveryCosts: YupSchemaDeliveryCostsType;

  // Manual form-state lever for toggling between money and percentage display.
  // Specifically out of displayOnly to avoid recalculation logic.
  isMoneySwitchOn: boolean;

  // Display-only formik fields
  displayOnly: {
    suggestedPrice: string | null;
    listPrice: string | null;
    unitCost: string | null;
    actualMarginPercentage: string | null;
    actualMarginCurrency: string | null;
    marginOverMaterialsPercentage: string | null;
    marginOverMaterialsCurrency: string | null;
    targetMargin: string | null;
  } | null;
};

/**
 * MainProjectOrQuoteProductsFormikSchema is the formik schema for the main product
 * section of a Quote or Project. It is used to validate the form data.
 * It operates properly for Primary products.
 */
export const MainProjectOrQuoteProductsFormikSchema: Yup.SchemaOf<
  Omit<ProjectOrQuoteProductFormikType, 'displayOnly' | 'isMoneySwitchOn'>[]
> = Yup.array().of(
  Yup.object({
    product: YupReference('Product', {
      incompatibleMixBatchUnits: Yup.bool().default(false),
      allowIncompatibleMixBatchUnits: Yup.bool().default(false),
    }).test({
      name: 'products have incompatible mix batch units',
      test: (item) => !item.incompatibleMixBatchUnits || item.allowIncompatibleMixBatchUnits,
    }),
    kind: YupEnum(Enums.QuoteProductKind, 'Kind'),
    externalName: YupNullableString('External name'),
    class: YupNullableNumber({ label: 'Class' }),
    quantity: YupNumber({
      label: 'Quantity',
      integer: true,
      positive: true,
    }),
    price: YupCurrency('Price').test(nonNegativeCurrency),
    deliveryCosts: YupDeliveryCosts('Delivery costs'),
    usage: YupNullableString('Usage'),
  }),
);

/**
 * AdditionalProjectOrQuoteProductsFormikSchema is the formik schema for the additional service
 * section of a Quote. It is used to validate the form data.
 * It operates properly for non-Primary products.
 */
export const AdditionalProjectOrQuoteProductsFormikSchema: Yup.SchemaOf<
  Omit<ProjectOrQuoteProductFormikType, 'displayOnly' | 'isMoneySwitchOn'>[]
> = Yup.array().of(
  Yup.object({
    product: YupReference('Product', {
      incompatibleMixBatchUnits: Yup.bool().default(false),
      allowIncompatibleMixBatchUnits: Yup.bool().default(false),
    }).test({
      name: 'products have incompatible mix batch units',
      test: (item) => !item.incompatibleMixBatchUnits || item.allowIncompatibleMixBatchUnits,
    }),
    kind: YupEnum(Enums.QuoteProductKind, 'Kind'),
    externalName: YupNullableString('External name'),
    class: YupNullableNumber({ label: 'Class' }),
    quantity: YupNullableNumber({ label: 'Quantity' }),
    price: YupCurrency('Price').test(nonNegativeCurrency),
    deliveryCosts: YupDeliveryCosts('Delivery costs'),
    usage: YupNullableString('Usage'),
  }),
);

/** Returns the form data for a QuoteProduct's reference to its underlying Product. */
export const FormikProductReference = (
  product: Product,
): ProjectOrQuoteProductFormikType['product'] => {
  const labelPrefix = product.incompatibleMixBatchUnits ? '[UOM issue] ' : '';
  const optionLabel = `${labelPrefix}${product.alternateID}`;
  return {
    id: product.id,
    // If you change this option data, you'll probably also want to make corresponding changes
    // to the lookup transform in ProductSection.
    option: {
      value: product.id,
      label: optionLabel,
      sublabels: [product.name],
    },
    incompatibleMixBatchUnits: product.incompatibleMixBatchUnits,
    allowIncompatibleMixBatchUnits: false,
  };
};

/** Returns the form data for a QuoteProduct. */
export const QuoteProductToProjectOrQuoteProductFormik = (
  qp: QuoteProduct,
): ProjectOrQuoteProductFormikType => ({
  product: FormikProductReference(qp.product),
  kind: qp.kind,
  externalName: qp.externalName,
  class: qp.class,
  quantity: qp.quantity,
  price: FormikCurrency(qp.price),
  deliveryCosts: FormikDeliveryCosts(qp.deliveryCosts ?? undefined),
  isMoneySwitchOn: false,
  displayOnly: {
    suggestedPrice: NullableCurrencyString({ cur: qp.calculateSuggestedPrice() }),
    listPrice: NullableCurrencyString({ cur: qp.product.listPrice }),
    actualMarginPercentage: qp.marginAsPercentage(),
    targetMargin: qp.product.targetMarginDisplay(),
    unitCost: DineroString({ dinero: qp.costPerUnit() }),
    actualMarginCurrency: qp.marginAsFormattedCurrency(),
    marginOverMaterialsCurrency: qp.marginOverMaterialAsFormattedCurrency(),
    marginOverMaterialsPercentage: qp.marginOverMaterialAsPercentage(),
  },
  usage: qp.usage,
});
