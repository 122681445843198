import { Primitive } from 'type-fest';

/**
 * A set that treats objects as equal when their hashes are equal, rather than using object reference equality (as is
 * the case for native Sets).
 *
 * If the elements of a HashSet are mutable, its users are responsible for ensuring that the hash function always
 * returns the same hash when provided with the same object.  If this invariant is not maintained, the behavior of this
 * class is undefined and will likely not match programmer expectations.
 *
 * Aside from the constructor, HashSet instances implement all of the methods of the native Set implementation (based
 * on the native Set specification in ES2023) using the same call signatures.
 */
export class HashSet<H, V extends H = H> {
  private readonly valuesMap = new Map<Primitive, V>();
  private readonly hash: (value: H) => Primitive;

  constructor(hash: (value: H) => Primitive, items: Iterable<V> = []) {
    this.hash = hash;
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      this.add(item);
    }
  }

  values(): IterableIterator<V> {
    return this.valuesMap.values();
  }

  *entries(): IterableIterator<[V, V]> {
    // eslint-disable-next-line no-restricted-syntax
    for (const value of this.valuesMap.values()) {
      yield [value, value];
    }
  }

  keys(): IterableIterator<V> {
    return this.values();
  }

  [Symbol.iterator](): IterableIterator<V> {
    return this.values();
  }

  forEach(callback: (value: V, key: V, set: HashSet<V>) => void, thisArg: any = this): void {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.valuesMap.values()) {
      callback.call(thisArg, item, item, this);
    }
  }

  get size(): number {
    return this.valuesMap.size;
  }

  has(item: H): boolean {
    return this.valuesMap.has(this.hash(item));
  }

  /** Get the item in this set that hashes to the same value as `item`, if any. */
  get(item: H): V | undefined {
    return this.valuesMap.get(this.hash(item));
  }

  add(item: V): this {
    this.valuesMap.set(this.hash(item), item);
    return this;
  }

  delete(item: H): boolean {
    return this.valuesMap.delete(this.hash(item));
  }

  clear(): void {
    this.valuesMap.clear();
  }
}
