// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { Currency, NewCurrency } from '../Currency/Currency';
import Enums from '../Enums';

export class CostRule {
  readonly kind: Enums.CostRuleKind;
  readonly markupRatio: string | null;
  readonly flatChange: Currency | null;

  constructor(data: { [key: string]: any } = {}) {
    this.kind = data.kind === undefined ? Enums.CostRuleKind.Flat : data.kind;
    this.markupRatio = data.markupRatio === undefined ? null : data.markupRatio;
    this.flatChange = (data.flatChange ?? null) === null ? null : NewCurrency(data.flatChange);
  }

  static zero(): CostRule {
    const zeroValues: ExcludeMethodsDeep<CostRule> = {
      kind: Enums.CostRuleKind.Flat,
      markupRatio: null,
      flatChange: null,
    };
    return new CostRule(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewCostRule = (props: PartialDeep<CostRule, { recurseIntoArrays: true }>): CostRule =>
  new CostRule(props);

export const NewCostRuleFromDomainObject = (
  props: PartialDeep<DomainObject<CostRule>, { recurseIntoArrays: true }>,
): CostRule => new CostRule(props);
