// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class AutocompleteTermOffset {
  readonly value?: string;
  readonly offset: number;

  constructor(data: { [key: string]: any } = {}) {
    this.value = data.value;
    this.offset = data.offset === undefined ? 0 : data.offset;
  }

  static zero(): AutocompleteTermOffset {
    const zeroValues: ExcludeMethodsDeep<AutocompleteTermOffset> = {
      offset: 0,
    };
    return new AutocompleteTermOffset(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewAutocompleteTermOffset = (
  props: PartialDeep<AutocompleteTermOffset, { recurseIntoArrays: true }>,
): AutocompleteTermOffset => new AutocompleteTermOffset(props);

export const NewAutocompleteTermOffsetFromDomainObject = (
  props: PartialDeep<DomainObject<AutocompleteTermOffset>, { recurseIntoArrays: true }>,
): AutocompleteTermOffset => new AutocompleteTermOffset(props);
