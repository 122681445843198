import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { ButtonPill } from '../../components/ButtonPill/ButtonPill';
import { DisplayField } from '../../components/DisplayField/DisplayField';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Page } from '../../components/Page/Page';
import { TabBar } from '../../components/TabBar/TabBar';
import Enums from '../../generated-types/Enums';
import { useDrawerManager } from '../../hooks/useDrawerManager';
import { useSlabQuery } from '../../hooks/useSlabQuery';
import { NIL_UUID } from '../../utils/UUID';
import { ProjectTable } from '../Projects/ProjectTable';
import { QuoteTable } from '../Quotes/QuoteTable';
import { ContactActivities } from './components/ContactActivities';
import { ContactDrawer } from './ContactDrawer';

export const ContactDetailsPage = (): JSX.Element => {
  const params = useParams();

  const contactId = params?.id ?? NIL_UUID;

  const { toastHook: contactToastHook, ...contactDrawerProps } = useDrawerManager({
    baseUrl: '/contacts',
    resourceTypeName: 'contact',
    drawerProps: {
      resourceId: contactId,
    },
  });

  const {
    isLoading,
    isError,
    data: contact,
  } = useSlabQuery('GET contact by ID', {
    pathParams: {
      id: contactId,
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || contact === undefined) {
    return <div>ERROR</div>;
  }

  const title = contact.fullName();

  const displayContact = {
    ...contact,
    fullName: contact.fullName(),
    companyName: contact.company.name,
  };

  return (
    <Page title={title}>
      {contactToastHook.toast}
      <ContactDrawer {...contactDrawerProps} />
      <Box display='flex' justifyContent='space-between' paddingBottom='2.5rem'>
        <Typography variant='h1'>{title}</Typography>
        <Box display='flex' gap='1rem'>
          <ButtonPill
            text='edit contact'
            variant='primary'
            onClick={(): void => contactDrawerProps.setIsOpen(true)}
            icon='edit'
          />
        </Box>
      </Box>
      <Box display='flex'>
        <Box width='fit-content'>
          <DisplayField
            title='Personal Details'
            onEditClick={(): void => contactDrawerProps.setIsOpen(true)}
            value={displayContact}
            type='singleField'
            displayMatrix={[
              [
                { label: 'Name', key: 'fullName' },
                { label: 'Job title', key: 'jobTitle' },
              ],
              [
                {
                  label: 'Company name',
                  key: 'companyName',
                  type: 'uri',
                  uri: `/companies/${contact.company.id}`,
                },
              ],
            ]}
          />
          <Box paddingTop='1.25rem' />
          <DisplayField
            title='Contact Details'
            onEditClick={(): void => contactDrawerProps.setIsOpen(true)}
            value={contact}
            type='singleField'
            displayMatrix={[
              [{ label: 'Phone number', key: 'phoneNumber', type: 'phone' }],
              [{ label: 'Email', key: 'emailAddress', type: 'email' }],
            ]}
          />
        </Box>
        <Box paddingLeft='2.5rem' flexGrow={1} sx={{ overflowX: 'clip', overflowY: 'visible' }}>
          <TabBar
            tabs={[
              { label: 'Activity', element: <ContactActivities contact={contact} /> },
              {
                label: 'Projects',
                element: (
                  <Box padding='1rem'>
                    <Box height='400px'>
                      <ProjectTable
                        typeAndReferences={{
                          type: 'contact projects',
                          contactId: contact.id,
                        }}
                      />
                    </Box>
                  </Box>
                ),
              },
              {
                label: 'Quotes',
                element: (
                  <Box padding='1rem'>
                    <Box height='400px'>
                      <QuoteTable
                        initialSortBy={{
                          name: 'name',
                          direction: Enums.SortDirection.Ascending,
                        }}
                        initialFilterBy={[
                          {
                            operation: Enums.FilterOperation.Equals,
                            name: 'contactId',
                            value: contact.id,
                          },
                        ]}
                        // Empty function passed in because the "send message" column is omitted.
                        onSendClick={(): void => {}}
                        optionalColumnRemovals={['id']}
                      />
                    </Box>
                  </Box>
                ),
              },
            ]}
          />
        </Box>
      </Box>
    </Page>
  );
};
