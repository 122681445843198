// This is ideally generated from an API spec,
// but for now we're sticking with manual management.

import { DateTime, Duration } from 'luxon';

import {
  AccountInfoOutput,
  NewAccountInfoOutputFromDomainObject,
} from '../generated-types/AccountInfoOutput/AccountInfoOutput';
import { Activity, NewActivityFromDomainObject } from '../generated-types/Activity/Activity';
import { ActivitySummary } from '../generated-types/ActivitySummary/ActivitySummary';
import {
  AuthorizationOutput,
  NewAuthorizationOutputFromDomainObject,
} from '../generated-types/AuthorizationOutput/AuthorizationOutput';
import { AuthorizationTokenInput } from '../generated-types/AuthorizationTokenInput/AuthorizationTokenInput';
import {
  AuthorizationTokenOutput,
  NewAuthorizationTokenOutputFromDomainObject,
} from '../generated-types/AuthorizationTokenOutput/AuthorizationTokenOutput';
import {
  AutocompletePrediction,
  NewAutocompletePredictionFromDomainObject,
} from '../generated-types/AutocompletePrediction/AutocompletePrediction';
import { Company, NewCompanyFromDomainObject } from '../generated-types/Company/Company';
import {
  CompanyCategoryAgg,
  NewCompanyCategoryAggFromDomainObject,
} from '../generated-types/CompanyCategoryAgg/CompanyCategoryAgg';
import {
  CompanySummary,
  NewCompanySummary,
} from '../generated-types/CompanySummary/CompanySummary';
import { Contact, NewContactFromDomainObject } from '../generated-types/Contact/Contact';
import { ContactSummary } from '../generated-types/ContactSummary/ContactSummary';
import {
  CustomFieldDefinition,
  NewCustomFieldDefinitionFromDomainObject,
} from '../generated-types/CustomFieldDefinition/CustomFieldDefinition';
import {
  DispatchCustomer,
  NewDispatchCustomerFromDomainObject,
} from '../generated-types/DispatchCustomer/DispatchCustomer';
import {
  DispatchCustomerSummary,
  NewDispatchCustomerSummaryFromDomainObject,
} from '../generated-types/DispatchCustomerSummary/DispatchCustomerSummary';
import {
  DistanceResult,
  NewDistanceResultFromDomainObject,
} from '../generated-types/DistanceResult/DistanceResult';
import Enums from '../generated-types/Enums';
import { Forecast, NewForecastFromDomainObject } from '../generated-types/Forecast/Forecast';
import {
  ForecastAgg,
  NewForecastAggFromDomainObject,
} from '../generated-types/ForecastAgg/ForecastAgg';
import { ForecastSummary } from '../generated-types/ForecastSummary/ForecastSummary';
import {
  GeneratedPDF,
  NewGeneratedPDFFromDomainObject,
} from '../generated-types/GeneratedPDF/GeneratedPDF';
import {
  LocationResult,
  NewLocationResultFromDomainObject,
} from '../generated-types/LocationResult/LocationResult';
import { Market, NewMarketFromDomainObject } from '../generated-types/Market/Market';
import { Material, NewMaterialFromDomainObject } from '../generated-types/Material/Material';
import {
  MaterialSummary,
  NewMaterialSummary,
} from '../generated-types/MaterialSummary/MaterialSummary';
import {
  MaterialTypeAgg,
  NewMaterialTypeAggFromDomainObject,
} from '../generated-types/MaterialTypeAgg/MaterialTypeAgg';
import { Mix, NewMixFromDomainObject } from '../generated-types/Mix/Mix';
import {
  MixMaterial,
  NewMixMaterialFromDomainObject,
} from '../generated-types/MixMaterial/MixMaterial';
import { MixSummary, NewMixSummary } from '../generated-types/MixSummary/MixSummary';
import {
  NewPdfTemplateFromDomainObject,
  PdfTemplate,
} from '../generated-types/PdfTemplate/PdfTemplate';
import { PdfTemplateSummary } from '../generated-types/PdfTemplateSummary/PdfTemplateSummary';
import { NewPlantFromDomainObject, Plant } from '../generated-types/Plant/Plant';
import { PlantSummary } from '../generated-types/PlantSummary/PlantSummary';
import { NewProductFromDomainObject, Product } from '../generated-types/Product/Product';
import {
  NewProductCategoryAggFromDomainObject,
  ProductCategoryAgg,
} from '../generated-types/ProductCategoryAgg/ProductCategoryAgg';
import {
  NewProductSummary,
  ProductSummary,
} from '../generated-types/ProductSummary/ProductSummary';
import { NewProjectFromDomainObject, Project } from '../generated-types/Project/Project';
import {
  NewProjectCompanyFromDomainObject,
  ProjectCompany,
} from '../generated-types/ProjectCompany/ProjectCompany';
import {
  NewProjectConfigFromDomainObject,
  ProjectConfig,
} from '../generated-types/ProjectConfig/ProjectConfig';
import {
  NewProjectConfigProductFromDomainObject,
  ProjectConfigProduct,
} from '../generated-types/ProjectConfigProduct/ProjectConfigProduct';
import {
  NewProjectConfigSummary,
  ProjectConfigSummary,
} from '../generated-types/ProjectConfigSummary/ProjectConfigSummary';
import {
  NewProjectForecastSummaryFromDomainObject,
  ProjectForecastSummary,
} from '../generated-types/ProjectForecastSummary/ProjectForecastSummary';
import {
  NewProjectProductFromDomainObject,
  ProjectProduct,
} from '../generated-types/ProjectProduct/ProjectProduct';
import {
  NewProjectStatusFromDomainObject,
  ProjectStatus,
} from '../generated-types/ProjectStatus/ProjectStatus';
import {
  NewProjectStatusAggFromDomainObject,
  ProjectStatusAgg,
} from '../generated-types/ProjectStatusAgg/ProjectStatusAgg';
import {
  NewProjectSummary,
  ProjectSummary,
} from '../generated-types/ProjectSummary/ProjectSummary';
import { NewQuoteFromDomainObject, Quote } from '../generated-types/Quote/Quote';
import {
  NewQuoteConfigFromDomainObject,
  QuoteConfig,
} from '../generated-types/QuoteConfig/QuoteConfig';
import { QuoteConfigSummary } from '../generated-types/QuoteConfigSummary/QuoteConfigSummary';
import {
  NewQuoteProductFromDomainObject,
  QuoteProduct,
} from '../generated-types/QuoteProduct/QuoteProduct';
import {
  NewQuoteStatusFromDomainObject,
  QuoteStatus,
} from '../generated-types/QuoteStatus/QuoteStatus';
import {
  NewQuoteStatusAggFromDomainObject,
  QuoteStatusAgg,
} from '../generated-types/QuoteStatusAgg/QuoteStatusAgg';
import { NewQuoteSummary, QuoteSummary } from '../generated-types/QuoteSummary/QuoteSummary';
import {
  NewRevokeTokenOutputFromDomainObject,
  RevokeTokenOutput,
} from '../generated-types/RevokeTokenOutput/RevokeTokenOutput';
import { NewRoleFromDomainObject, Role } from '../generated-types/Role/Role';
import { NewSegmentFromDomainObject, Segment } from '../generated-types/Segment/Segment';
import { SendMessageInput } from '../generated-types/SendMessageInput/SendMessageInput';
import {
  NewSendMessageOutputFromDomainObject,
  SendMessageOutput,
} from '../generated-types/SendMessageOutput/SendMessageOutput';
import {
  NewSignedDashboardFromDomainObject,
  SignedDashboard,
} from '../generated-types/SignedDashboard/SignedDashboard';
import { NewTaxCodeFromDomainObject, TaxCode } from '../generated-types/TaxCode/TaxCode';
import { NewTenantFromDomainObject, Tenant } from '../generated-types/Tenant/Tenant';
import { NewUserFromDomainObject, User } from '../generated-types/User/User';
import { NewUserInfoFromDomainObject, UserInfo } from '../generated-types/UserInfo/UserInfo';
import { UserSummary } from '../generated-types/UserSummary/UserSummary';
import { List } from './List';
import { ExcludeMethods, NestedKeyOf } from './Types';

export type DomainObject<T> = ExcludeMethods<{
  -readonly [P in keyof T]: T[P] extends (infer ET)[]
    ? DomainObject<ET>[]
    : T[P] extends NonNullable<infer ET>[] | null
      ? DomainObject<ET>[] | null
      : T[P] extends NonNullable<infer ET>[] | undefined
        ? DomainObject<ET>[] | undefined
        : T[P] extends DateTime
          ? string
          : T[P] extends DateTime | null
            ? string | null
            : T[P] extends Duration
              ? string
              : T[P] extends Duration | null
                ? string | null
                : T[P] extends string
                  ? string // Enum types are strings in Formik state
                  : T[P] extends number | string | boolean | Function | null
                    ? T[P]
                    : T[P] extends NonNullable<infer ET>
                      ? DomainObject<ET>
                      : T[P] extends NonNullable<infer ET> | null
                        ? DomainObject<ET> | null
                        : T[P] extends NonNullable<infer ET> | undefined
                          ? DomainObject<ET> | undefined
                          : T[P];
}>;

type NamedFilterOpts<T extends {}> = {
  name: NestedKeyOf<T>;
  readonly operation:
    | Enums.FilterOperation.Equals
    | Enums.FilterOperation.EqualsOrNull
    | Enums.FilterOperation.NotEquals
    | Enums.FilterOperation.NotEqualsAndNotNull;
  readonly value: unknown;
};

type SearchFilterOpts = {
  operation: Enums.FilterOperation.Search;
  value: string;
};

type LookupFilterOpts = {
  operation: Enums.FilterOperation.Lookup;
  value: string;
};

export type FilterByOpts<T extends {}> = NamedFilterOpts<T> | SearchFilterOpts | LookupFilterOpts;

export type StatsURLParams<T extends {}> = {
  filterBy?: FilterByOpts<T>[];
};

export type ListURLParams<T extends {}> = {
  perPage: number;
  page: number;
  sortBy?: {
    name: NestedKeyOf<T>;
    direction: Enums.SortDirection;
  };
  filterBy?: FilterByOpts<T>[];
};

export type QueryRouteBarrelKeys =
  | 'GET activities by company ID'
  | 'GET activities by contact ID'
  | 'GET activities by project ID'
  | 'GET activities by user ID'
  | 'GET activities'
  | 'GET activity by ID'
  | 'GET companies by activity ID'
  | 'GET companies'
  | 'GET company by ID'
  | 'GET company category aggregations'
  | 'GET company contacts'
  | 'GET company summaries'
  | 'GET contact by ID'
  | 'GET contacts by activity ID'
  | 'GET contacts by company IDs'
  | 'GET contacts'
  | 'GET custom field definitions'
  | 'GET dashboards'
  | 'GET dispatch customer summaries'
  | 'GET dispatch customers'
  | 'GET forecast aggregations'
  | 'GET forecasts'
  | 'GET maps autocomplete'
  | 'GET maps conversion'
  | 'GET maps distance from points'
  | 'GET maps distance'
  | 'GET maps place'
  | 'GET markets'
  | 'GET material by ID'
  | 'GET material summaries'
  | 'GET material type aggregations'
  | 'GET materials by mix ID'
  | 'GET materials by plant ID'
  | 'GET materials'
  | 'GET mix by ID'
  | 'GET mix summaries'
  | 'GET mixes by plant ID'
  | 'GET mixes'
  | 'GET nylas account info'
  | 'GET nylas v3 grant info'
  | 'GET nylas login'
  | 'GET nylas v3 login'
  | 'GET pdf templates'
  | 'GET plant by ID'
  | 'GET plant forecasts by plant ID'
  | 'GET plant materials by plant ID'
  | 'GET plant products by plant ID'
  | 'GET plants'
  | 'GET product by ID'
  | 'GET product category aggregations'
  | 'GET product summaries'
  | 'GET products'
  | 'GET project by ID'
  | 'GET project config by ID'
  | 'GET project config products by project config ID'
  | 'GET project config summaries'
  | 'GET project configs'
  | 'GET project companies by project ID'
  | 'GET project forecast summaries'
  | 'GET project forecasts by project ID'
  | 'GET project products by project ID'
  | 'GET project quotes by project ID'
  | 'GET project status aggregations'
  | 'GET project statuses'
  | 'GET project statuses as admin'
  | 'GET project summaries by company ID'
  | 'GET project summaries by contact ID'
  | 'GET project summaries'
  | 'GET projects'
  | 'GET quote by ID'
  | 'GET quote config by ID'
  | 'GET quote configs'
  | 'GET quote pdf by quote ID and template ID'
  | 'GET quote products by quote ID'
  | 'GET quote status aggregations'
  | 'GET quote statuses'
  | 'GET quote summaries'
  | 'GET quotes'
  | 'GET roles'
  | 'GET segments'
  | 'GET tax codes'
  | 'GET tenants as admin'
  | 'GET user info'
  | 'GET users by role ID'
  | 'GET users by tenant ID as admin'
  | 'GET users';

export type MutationRouteBarrelKeys =
  | 'POST activity'
  | 'POST company'
  | 'POST contact'
  | 'POST mark quote as sent'
  | 'POST material'
  | 'POST mix'
  | 'POST nylas code'
  | 'POST nylas v3 code'
  | 'POST nylas message'
  | 'POST nylas revoke token'
  | 'POST nylas v3 revoke grant'
  | 'POST plant'
  | 'POST product'
  | 'POST project config'
  | 'POST project'
  | 'POST quote config'
  | 'POST quote'
  | 'PUT activity by ID'
  | 'PUT company by ID'
  | 'PUT contact by ID'
  | 'PUT material by ID'
  | 'PUT mix by ID'
  | 'PUT plant by ID'
  | 'PUT product by ID'
  | 'PUT project by ID'
  | 'PUT project config by ID'
  | 'PUT quote by ID'
  | 'PUT quote config by ID';

// useSlabQuery uses this to limit query key options + parameter arguments
export type QueryRouteBarrelTypes = {
  'GET activities': {
    args: {
      queryParams?: ListURLParams<ActivitySummary>;
    };
    returns: List<Activity>;
  };
  'GET activities by company ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Activity[];
  };
  'GET activities by contact ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Activity[];
  };
  'GET activities by project ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Activity[];
  };
  'GET activities by user ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Activity[];
  };
  'GET activity by ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Activity;
  };
  'GET companies': {
    args: {
      queryParams?: ListURLParams<CompanySummary>;
    };
    returns: List<Company>;
  };
  'GET companies by activity ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Company[];
  };
  'GET company by ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Company;
  };
  'GET company category aggregations': {
    args: {
      queryParams?: StatsURLParams<CompanySummary>;
    };
    returns: CompanyCategoryAgg[];
  };
  'GET company contacts': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Contact[];
  };
  'GET company summaries': {
    args: {
      queryParams?: ListURLParams<CompanySummary>;
    };
    returns: List<CompanySummary>;
  };
  'GET contact by ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Contact;
  };
  'GET contacts': {
    args: {
      queryParams?: ListURLParams<ContactSummary>;
    };
    returns: List<Contact>;
  };
  'GET contacts by activity ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Contact[];
  };
  'GET contacts by company IDs': {
    args: {
      queryParams: { id: string[] } & (ListURLParams<ContactSummary> | {});
    };
    returns: List<Contact>;
  };
  'GET custom field definitions': {
    args: {
      queryParams?: ListURLParams<CustomFieldDefinition>;
    };
    returns: List<CustomFieldDefinition>;
  };
  'GET dashboards': {
    args: {
      pathParams: {
        kind: Enums.DashboardKind;
      };
    };
    returns: SignedDashboard[];
  };
  'GET dispatch customer summaries': {
    args: {
      queryParams?: ListURLParams<DispatchCustomerSummary>;
    };
    returns: List<DispatchCustomerSummary>;
  };
  'GET dispatch customers': {
    args: {
      queryParams?: ListURLParams<DispatchCustomerSummary>;
    };
    returns: List<DispatchCustomer>;
  };
  'GET forecast aggregations': {
    args: {
      queryParams?: StatsURLParams<ProjectForecastSummary>;
    };
    returns: ForecastAgg;
  };
  'GET forecasts': {
    args: {
      queryParams?: ListURLParams<ForecastSummary>;
    };
    returns: List<Forecast>;
  };
  'GET maps autocomplete': {
    args: {
      queryParams: {
        query: string;
      };
    };
    returns: AutocompletePrediction[];
  };
  'GET maps conversion': {
    args: {
      queryParams: {
        lat: string;
        long: string;
        address: string;
      };
    };
    returns: LocationResult;
  };
  'GET maps distance': {
    args: {
      queryParams: {
        origins: string;
        destinations: string;
      };
    };
    returns: DistanceResult;
  };
  'GET maps distance from points': {
    args: {
      queryParams: {
        origins: string;
        destination: string;
      };
    };
    returns: DistanceResult;
  };
  'GET maps place': {
    args: {
      queryParams: {
        id: string;
      };
    };
    returns: LocationResult;
  };
  'GET markets': {
    args: {
      queryParams?: ListURLParams<Market>;
    };
    returns: List<Market>;
  };
  'GET material by ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Material;
  };
  'GET material summaries': {
    args: {
      queryParams?: ListURLParams<MaterialSummary>;
    };
    returns: List<MaterialSummary>;
  };
  'GET material type aggregations': {
    args: {
      queryParams?: StatsURLParams<MaterialSummary>;
    };
    returns: MaterialTypeAgg[];
  };
  'GET materials': {
    args: {
      queryParams?: ListURLParams<MaterialSummary>;
    };
    returns: List<Material>;
  };
  'GET materials by mix ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: MixMaterial[];
  };
  'GET materials by plant ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Material[];
  };
  'GET mix by ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Mix;
  };
  'GET mix summaries': {
    args: {
      queryParams?: ListURLParams<MixSummary>;
    };
    returns: List<MixSummary>;
  };
  'GET mixes': {
    args: {
      queryParams?: ListURLParams<MixSummary>;
    };
    returns: List<Mix>;
  };
  'GET mixes by plant ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Mix[];
  };
  'GET nylas account info': {
    args: {};
    returns: AccountInfoOutput;
  };
  'GET nylas v3 grant info': {
    args: {};
    returns: AccountInfoOutput;
  };
  'GET nylas login': {
    args: {};
    returns: AuthorizationOutput;
  };
  'GET nylas v3 login': {
    args: {};
    returns: AuthorizationOutput;
  };
  'GET pdf templates': {
    args: {
      queryParams?: ListURLParams<PdfTemplateSummary>;
    };
    returns: List<PdfTemplate>;
  };
  'GET plant by ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Plant;
  };
  'GET plant forecasts by plant ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Forecast[];
  };
  'GET plant materials by plant ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Material[];
  };
  'GET plant products by plant ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Product[];
  };
  'GET plants': {
    args: {
      queryParams?: ListURLParams<PlantSummary>;
    };
    returns: List<Plant>;
  };
  'GET product by ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Product;
  };
  'GET product category aggregations': {
    args: {
      queryParams?: StatsURLParams<ProductSummary>;
    };
    returns: ProductCategoryAgg[];
  };
  'GET product summaries': {
    args: {
      queryParams?: ListURLParams<ProductSummary>;
    };
    returns: List<ProductSummary>;
  };
  'GET products': {
    args: {
      queryParams?: ListURLParams<ProductSummary>;
    };
    returns: List<Product>;
  };
  'GET project by ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Project;
  };
  'GET project config by ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: ProjectConfig;
  };
  'GET project config products by project config ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: ProjectConfigProduct[];
  };
  'GET project config summaries': {
    args: {
      queryParams?: ListURLParams<ProjectConfigSummary>;
    };
    returns: List<ProjectConfigSummary>;
  };
  'GET project configs': {
    args: {
      queryParams?: ListURLParams<ProjectConfig>;
    };
    returns: List<ProjectConfig>;
  };
  'GET project companies by project ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: ProjectCompany[];
  };
  'GET project forecast summaries': {
    args: {
      queryParams?: ListURLParams<ProjectForecastSummary>;
    };
    returns: List<ProjectForecastSummary>;
  };
  'GET project forecasts by project ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Forecast[];
  };
  'GET project products by project ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: ProjectProduct[];
  };
  'GET project quotes by project ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Quote[];
  };
  'GET project status aggregations': {
    args: {
      queryParams?: StatsURLParams<ProjectSummary>;
    };
    returns: ProjectStatusAgg[];
  };
  'GET project statuses': {
    args: {
      queryParams?: ListURLParams<ProjectStatus>;
    };
    returns: List<ProjectStatus>;
  };
  'GET project statuses as admin': {
    args: {
      pathParams: {
        tenantID: string;
      };
      queryParams?: ListURLParams<ProjectStatus>;
    };
    returns: List<ProjectStatus>;
  };
  'GET project summaries': {
    args: {
      queryParams?: ListURLParams<ProjectSummary>;
    };
    returns: List<ProjectSummary>;
  };
  'GET project summaries by company ID': {
    args: {
      pathParams: {
        id: string;
      };
      queryParams?: ListURLParams<ProjectSummary>;
    };
    returns: List<ProjectSummary>;
  };
  'GET project summaries by contact ID': {
    args: {
      pathParams: {
        id: string;
      };
      queryParams?: ListURLParams<ProjectSummary>;
    };
    returns: List<ProjectSummary>;
  };
  'GET projects': {
    args: {
      queryParams?: ListURLParams<ProjectSummary>;
    };
    returns: List<Project>;
  };
  'GET quote by ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: Quote;
  };
  'GET quote config by ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: QuoteConfig;
  };
  'GET quote configs': {
    args: {
      queryParams?: ListURLParams<QuoteConfigSummary>;
    };
    returns: List<QuoteConfig>;
  };
  'GET quote pdf by quote ID and template ID': {
    args: {
      pathParams: {
        quoteId: string;
        templateId: string;
      };
    };
    returns: GeneratedPDF;
  };
  'GET quote products by quote ID': {
    args: {
      pathParams: {
        id: string;
      };
    };
    returns: QuoteProduct[];
  };
  'GET quote status aggregations': {
    args: {
      queryParams?: StatsURLParams<QuoteSummary>;
    };
    returns: QuoteStatusAgg[];
  };
  'GET quote statuses': {
    args: {
      queryParams?: ListURLParams<QuoteStatus>;
    };
    returns: List<QuoteStatus>;
  };
  'GET quote summaries': {
    args: {
      queryParams?: ListURLParams<QuoteSummary>;
    };
    returns: List<QuoteSummary>;
  };
  'GET quotes': {
    args: {
      queryParams?: ListURLParams<QuoteSummary>;
    };
    returns: List<Quote>;
  };
  'GET roles': {
    args: {
      queryParams?: ListURLParams<Role>;
    };
    returns: List<Role>;
  };
  'GET segments': {
    args: {
      queryParams?: ListURLParams<Segment>;
    };
    returns: List<Segment>;
  };
  'GET tax codes': {
    args: {
      queryParams?: ListURLParams<TaxCode>;
    };
    returns: List<TaxCode>;
  };
  'GET tenants as admin': {
    args: {
      queryParams?: ListURLParams<Tenant>;
    };
    returns: List<Tenant>;
  };
  'GET user info': {
    args: {};
    returns: UserInfo;
  };
  'GET users': {
    args: {
      queryParams?: ListURLParams<UserSummary>;
    };
    returns: List<User>;
  };
  'GET users by role ID': {
    args: {
      pathParams: {
        id: string;
      };
      queryParams?: ListURLParams<UserSummary>;
    };
    returns: User[];
  };
  'GET users by tenant ID as admin': {
    args: {
      pathParams: {
        tenantID: string;
      };
      queryParams?: ListURLParams<UserSummary>;
    };
    returns: List<User>;
  };
};

// useSlabMutation uses this to limit mutation key options + parameter arguments
export type MutationRouteBarrelTypes = {
  'POST activity': {
    args: {
      body: Activity;
    };
    returns: Activity;
  };
  'POST company': {
    args: {
      body: Company;
    };
    returns: Company;
  };
  'POST contact': {
    args: {
      body: Contact;
    };
    returns: Contact;
  };
  'POST material': {
    args: {
      body: Material;
    };
    returns: Material;
  };
  'POST mix': {
    args: {
      body: Mix;
    };
    returns: Mix;
  };
  'POST nylas code': {
    args: {
      body: ExcludeMethods<AuthorizationTokenInput>;
    };
    returns: AuthorizationTokenOutput;
  };
  'POST nylas v3 code': {
    args: {
      body: ExcludeMethods<AuthorizationTokenInput>;
    };
    returns: AuthorizationTokenOutput;
  };
  'POST nylas message': {
    args: {
      body: SendMessageInput;
    };
    returns: SendMessageOutput;
  };
  'POST nylas revoke token': {
    args: {
      body: null;
    };
    returns: RevokeTokenOutput;
  };
  'POST nylas v3 revoke grant': {
    args: {
      body: null;
    };
    returns: RevokeTokenOutput;
  };
  'POST plant': {
    args: {
      body: Plant;
    };
    returns: Plant;
  };
  'POST product': {
    args: {
      body: Product;
    };
    returns: Product;
  };
  'POST project config': {
    args: {
      body: ProjectConfig;
    };
    returns: ProjectConfig;
  };
  'POST project': {
    args: {
      body: Project;
    };
    returns: Project;
  };
  'POST quote': {
    args: {
      body: Quote;
    };
    returns: Quote;
  };
  'POST quote config': {
    args: {
      body: QuoteConfig;
    };
    returns: QuoteConfig;
  };
  'PUT activity by ID': {
    args: {
      pathParams: {
        id: string;
      };
      body: Activity;
    };
    returns: Activity;
  };
  'PUT company by ID': {
    args: {
      pathParams: {
        id: string;
      };
      body: Company;
    };
    returns: Company;
  };
  'PUT contact by ID': {
    args: {
      pathParams: {
        id: string;
      };
      body: Contact;
    };
    returns: Contact;
  };
  'POST mark quote as sent': {
    args: {
      pathParams: {
        id: string;
      };
      // HACK: ApiClient typings require 'body' to exist, even though this API
      // doesn't want or care about a request body.
      body: {};
    };
    returns: Quote;
  };
  'PUT material by ID': {
    args: {
      pathParams: {
        id: string;
      };
      body: Material;
    };
    returns: Material;
  };
  'PUT mix by ID': {
    args: {
      pathParams: {
        id: string;
      };
      body: Mix;
    };
    returns: Mix;
  };
  'PUT plant by ID': {
    args: {
      pathParams: {
        id: string;
      };
      body: Plant;
    };
    returns: Plant;
  };
  'PUT product by ID': {
    args: {
      pathParams: {
        id: string;
      };
      body: Product;
    };
    returns: Product;
  };
  'PUT project by ID': {
    args: {
      pathParams: {
        id: string;
      };
      body: Project;
    };
    returns: Project;
  };
  'PUT project config by ID': {
    args: {
      pathParams: {
        id: string;
      };
      body: ProjectConfig;
    };
    returns: ProjectConfig;
  };
  'PUT quote by ID': {
    args: {
      pathParams: {
        id: string;
      };
      body: Quote;
    };
    returns: Quote;
  };
  'PUT quote config by ID': {
    args: {
      pathParams: {
        id: string;
      };
      body: QuoteConfig;
    };
    returns: QuoteConfig;
  };
};

type QueryBarrelVals = {
  // split URL path to allow for substituting path parameters
  urlSplits: string[];
  new: (args: any) => any;
};

type MutationBarrelVals = {
  method: 'POST' | 'PUT';
  urlSplits: string[];
  invalidations: QueryRouteBarrelKeys[];
  new: (args: any) => any;
};

// The connective route info to populate useQuery
export const QueryRouteBarrelVals: Record<QueryRouteBarrelKeys, QueryBarrelVals> = {
  'GET activities': {
    urlSplits: ['/slab/activities'],
    new: (args) => new List(NewActivityFromDomainObject, args),
  },
  'GET activities by company ID': {
    urlSplits: ['/slab/companies/', 'id', '/activities'],
    new: (args) => (args ?? []).map((a: any) => NewActivityFromDomainObject(a)),
  },
  'GET activities by contact ID': {
    urlSplits: ['/slab/contacts/', 'id', '/activities'],
    new: (args) => (args ?? []).map((a: any) => NewActivityFromDomainObject(a)),
  },
  'GET activities by project ID': {
    urlSplits: ['/slab/projects/', 'id', '/activities'],
    new: (args) => (args ?? []).map((a: any) => NewActivityFromDomainObject(a)),
  },
  'GET activities by user ID': {
    urlSplits: ['/slab/users/', 'id', '/activities'],
    new: (args) => (args ?? []).map((a: any) => NewActivityFromDomainObject(a)),
  },
  'GET activity by ID': {
    urlSplits: ['/slab/activities/', 'id'],
    new: (args) => NewActivityFromDomainObject(args),
  },
  'GET companies': {
    urlSplits: ['/slab/companies'],
    new: (args) => new List(NewCompanyFromDomainObject, args),
  },
  'GET companies by activity ID': {
    urlSplits: ['/slab/activities/', 'id', '/companies'],
    new: (args) => (args ?? []).map((a: any) => NewCompanyFromDomainObject(a)),
  },
  'GET company by ID': {
    urlSplits: ['/slab/companies/', 'id'],
    new: (args) => NewCompanyFromDomainObject(args),
  },
  'GET company category aggregations': {
    urlSplits: ['/slab/companies/stats'],
    new: (args) => (args ?? []).map((a: any) => NewCompanyCategoryAggFromDomainObject(a)),
  },
  'GET company contacts': {
    urlSplits: ['/slab/companies/', 'id', '/contacts'],
    new: (args) => (args ?? []).map((a: any) => NewContactFromDomainObject(a)),
  },
  'GET company summaries': {
    urlSplits: ['/slab/companies/summaries'],
    new: (args) => new List(NewCompanySummary, args),
  },
  'GET contact by ID': {
    urlSplits: ['/slab/contacts/', 'id'],
    new: (args) => NewContactFromDomainObject(args),
  },
  'GET contacts': {
    urlSplits: ['/slab/contacts'],
    new: (args) => new List(NewContactFromDomainObject, args),
  },
  'GET contacts by activity ID': {
    urlSplits: ['/slab/activities/', 'id', '/contacts'],
    new: (args) => (args ?? []).map((a: any) => NewContactFromDomainObject(a)),
  },
  'GET contacts by company IDs': {
    urlSplits: ['/slab/companies/contacts/by'],
    new: (args) => new List(NewContactFromDomainObject, args),
  },
  'GET custom field definitions': {
    urlSplits: ['/slab/customfielddefinitions'],
    new: (args) => new List(NewCustomFieldDefinitionFromDomainObject, args),
  },
  'GET dashboards': {
    urlSplits: ['/slab/dashboards/', 'kind'],
    new: (args) => (args ?? []).map((a: any) => NewSignedDashboardFromDomainObject(a)),
  },
  'GET dispatch customer summaries': {
    urlSplits: ['/slab/dispatchcustomers/summaries'],
    new: (args) => new List(NewDispatchCustomerSummaryFromDomainObject, args),
  },
  'GET dispatch customers': {
    urlSplits: ['/slab/dispatchcustomers'],
    new: (args) => new List(NewDispatchCustomerFromDomainObject, args),
  },
  'GET forecast aggregations': {
    urlSplits: ['/slab/forecasts/stats'],
    new: (args) => NewForecastAggFromDomainObject(args),
  },
  'GET forecasts': {
    urlSplits: ['/slab/forecasts'],
    new: (args) => new List(NewForecastFromDomainObject, args),
  },
  'GET maps autocomplete': {
    urlSplits: ['/slab/maps/autocomplete'],
    new: (args) => (args ?? []).map((a: any) => NewAutocompletePredictionFromDomainObject(a)),
  },
  'GET maps conversion': {
    urlSplits: ['/slab/maps/convert'],
    new: (args) => NewLocationResultFromDomainObject(args),
  },
  'GET maps distance': {
    urlSplits: ['/slab/maps/distance'],
    new: (args) => NewDistanceResultFromDomainObject(args),
  },
  'GET maps distance from points': {
    urlSplits: ['/slab/maps/distancefrompoints'],
    new: (args) => NewDistanceResultFromDomainObject(args),
  },
  'GET maps place': {
    urlSplits: ['/slab/maps/place'],
    new: (args) => NewLocationResultFromDomainObject(args),
  },
  'GET markets': {
    urlSplits: ['/slab/markets'],
    new: (args) => new List(NewMarketFromDomainObject, args),
  },
  'GET material by ID': {
    urlSplits: ['/slab/materials/', 'id'],
    new: (args) => NewMaterialFromDomainObject(args),
  },
  'GET material summaries': {
    urlSplits: ['/slab/materials/summaries'],
    new: (args) => new List(NewMaterialSummary, args),
  },
  'GET material type aggregations': {
    urlSplits: ['/slab/materials/stats'],
    new: (args) => (args ?? []).map((a: any) => NewMaterialTypeAggFromDomainObject(a)),
  },
  'GET materials': {
    urlSplits: ['/slab/materials'],
    new: (args) => new List(NewMaterialFromDomainObject, args),
  },
  'GET materials by mix ID': {
    urlSplits: ['/slab/mix/', 'id', '/materials'],
    new: (args) => (args ?? []).map((a: any) => NewMixMaterialFromDomainObject(a)),
  },
  'GET materials by plant ID': {
    urlSplits: ['/slab/plants/', 'id', '/materials'],
    new: (args) => (args ?? []).map((a: any) => NewMaterialFromDomainObject(a)),
  },
  'GET mix by ID': {
    urlSplits: ['/slab/mixes/', 'id'],
    new: (args) => NewMixFromDomainObject(args),
  },
  'GET mix summaries': {
    urlSplits: ['/slab/mixes/summaries'],
    new: (args) => new List(NewMixSummary, args),
  },
  'GET mixes': {
    urlSplits: ['/slab/mixes'],
    new: (args) => new List(NewMixFromDomainObject, args),
  },
  'GET mixes by plant ID': {
    urlSplits: ['/slab/plants/', 'id', '/mixes'],
    new: (args) => (args ?? []).map((a: any) => NewMixFromDomainObject(a)),
  },
  'GET nylas account info': {
    urlSplits: ['/slab/nylas/account/info'],
    new: (args) => NewAccountInfoOutputFromDomainObject(args),
  },
  'GET nylas v3 grant info': {
    urlSplits: ['/slab/nylas/v3/grant/info'],
    new: (args) => NewAccountInfoOutputFromDomainObject(args),
  },
  'GET nylas login': {
    urlSplits: ['/slab/nylas/login/init'],
    new: (args) => NewAuthorizationOutputFromDomainObject(args),
  },
  'GET nylas v3 login': {
    urlSplits: ['/slab/nylas/v3/login/init'],
    new: (args) => NewAuthorizationOutputFromDomainObject(args),
  },
  'GET pdf templates': {
    urlSplits: ['/slab/pdftemplates'],
    new: (args) => new List(NewPdfTemplateFromDomainObject, args),
  },
  'GET plant by ID': {
    urlSplits: ['/slab/plants/', 'id'],
    new: (args) => NewPlantFromDomainObject(args),
  },
  'GET plant forecasts by plant ID': {
    urlSplits: ['/slab/plants/', 'id', '/forecasts'],
    new: (args) => (args ?? []).map((a: any) => NewForecastFromDomainObject(a)),
  },
  'GET plant materials by plant ID': {
    urlSplits: ['/slab/plants/', 'id', '/materials'],
    new: (args) => (args ?? []).map((a: any) => NewMaterialFromDomainObject(a)),
  },
  'GET plant products by plant ID': {
    urlSplits: ['/slab/plants/', 'id', '/products'],
    new: (args) => (args ?? []).map((a: any) => NewProductFromDomainObject(a)),
  },
  'GET plants': {
    urlSplits: ['/slab/plants'],
    new: (args) => new List(NewPlantFromDomainObject, args),
  },
  'GET product by ID': {
    urlSplits: ['/slab/products/', 'id'],
    new: (args) => NewProductFromDomainObject(args),
  },
  'GET product category aggregations': {
    urlSplits: ['/slab/products/stats'],
    new: (args) => (args ?? []).map((a: any) => NewProductCategoryAggFromDomainObject(a)),
  },
  'GET product summaries': {
    urlSplits: ['/slab/products/summaries'],
    new: (args) => new List(NewProductSummary, args),
  },
  'GET products': {
    urlSplits: ['/slab/products'],
    new: (args) => new List(NewProductFromDomainObject, args),
  },
  'GET project by ID': {
    urlSplits: ['/slab/projects/', 'id'],
    new: (args) => NewProjectFromDomainObject(args),
  },
  'GET project config by ID': {
    urlSplits: ['/slab/projectconfigs/', 'id'],
    new: (args) => NewProjectConfigFromDomainObject(args),
  },
  'GET project config products by project config ID': {
    urlSplits: ['/slab/projectconfigs/', 'id', '/products'],
    new: (args) => (args ?? []).map((a: any) => NewProjectConfigProductFromDomainObject(a)),
  },
  'GET project config summaries': {
    urlSplits: ['/slab/projectconfigs/summaries'],
    new: (args) => new List(NewProjectConfigSummary, args),
  },
  'GET project configs': {
    urlSplits: ['/slab/projectconfigs'],
    new: (args) => new List(NewProjectConfigFromDomainObject, args),
  },
  'GET project companies by project ID': {
    urlSplits: ['/slab/projects/', 'id', '/companies'],
    new: (args) => (args ?? []).map((a: any) => NewProjectCompanyFromDomainObject(a)),
  },
  'GET project forecast summaries': {
    urlSplits: ['/slab/forecasts/summaries'],
    new: (args) => new List(NewProjectForecastSummaryFromDomainObject, args),
  },
  'GET project forecasts by project ID': {
    urlSplits: ['/slab/projects/', 'id', '/forecasts'],
    new: (args) => (args ?? []).map((a: any) => NewForecastFromDomainObject(a)),
  },
  'GET project products by project ID': {
    urlSplits: ['/slab/projects/', 'id', '/products'],
    new: (args) => (args ?? []).map((a: any) => NewProjectProductFromDomainObject(a)),
  },
  'GET project quotes by project ID': {
    urlSplits: ['/slab/projects/', 'id', '/quotes'],
    new: (args) => (args ?? []).map((a: any) => NewQuoteFromDomainObject(a)),
  },
  'GET project status aggregations': {
    urlSplits: ['/slab/projects/stats'],
    new: (args) => (args ?? []).map((a: any) => NewProjectStatusAggFromDomainObject(a)),
  },
  'GET project statuses': {
    urlSplits: ['/slab/projectstatuses'],
    new: (args) => new List(NewProjectStatusFromDomainObject, args),
  },
  'GET project statuses as admin': {
    urlSplits: ['/slabadmin/tenants/', 'tenantID', '/projectstatuses'],
    new: (args) => new List(NewProjectStatusFromDomainObject, args),
  },
  'GET project summaries': {
    urlSplits: ['/slab/projects/summaries'],
    new: (args) => new List(NewProjectSummary, args),
  },
  'GET project summaries by company ID': {
    urlSplits: ['/slab/companies/', 'id', '/projects/summaries'],
    new: (args) => new List(NewProjectSummary, args),
  },
  'GET project summaries by contact ID': {
    urlSplits: ['/slab/contacts/', 'id', '/projects/summaries'],
    new: (args) => new List(NewProjectSummary, args),
  },
  'GET projects': {
    urlSplits: ['/slab/projects'],
    new: (args) => new List(NewProjectFromDomainObject, args),
  },
  'GET quote by ID': {
    urlSplits: ['/slab/quotes/', 'id'],
    new: (args) => NewQuoteFromDomainObject(args),
  },
  'GET quote config by ID': {
    urlSplits: ['/slab/quoteconfigs/', 'id'],
    new: (args) => NewQuoteConfigFromDomainObject(args),
  },
  'GET quote configs': {
    urlSplits: ['/slab/quoteconfigs'],
    new: (args) => new List(NewQuoteConfigFromDomainObject, args),
  },
  'GET quote pdf by quote ID and template ID': {
    urlSplits: ['/slab/pdfgeneratorapi/', 'templateId', '/quote/', 'quoteId'],
    new: (args) => NewGeneratedPDFFromDomainObject(args),
  },
  'GET quote products by quote ID': {
    urlSplits: ['/slab/quotes/', 'id', '/products'],
    new: (args) => (args ?? []).map((a: any) => NewQuoteProductFromDomainObject(a)),
  },
  'GET quote status aggregations': {
    urlSplits: ['/slab/quotes/stats'],
    new: (args) => (args ?? []).map((a: any) => NewQuoteStatusAggFromDomainObject(a)),
  },
  'GET quote statuses': {
    urlSplits: ['/slab/quotestatuses'],
    new: (args) => new List(NewQuoteStatusFromDomainObject, args),
  },
  'GET quote summaries': {
    urlSplits: ['/slab/quotes/summaries'],
    new: (args) => new List(NewQuoteSummary, args),
  },
  'GET quotes': {
    urlSplits: ['/slab/quotes'],
    new: (args) => new List(NewQuoteFromDomainObject, args),
  },
  'GET roles': {
    urlSplits: ['/slab/roles'],
    new: (args) => new List(NewRoleFromDomainObject, args),
  },
  'GET segments': {
    urlSplits: ['/slab/segments'],
    new: (args) => new List(NewSegmentFromDomainObject, args),
  },
  'GET tax codes': {
    urlSplits: ['/slab/taxcodes'],
    new: (args) => new List(NewTaxCodeFromDomainObject, args),
  },
  'GET tenants as admin': {
    urlSplits: ['/slabadmin/tenants'],
    new: (args) => new List(NewTenantFromDomainObject, args),
  },
  'GET user info': {
    urlSplits: ['/slab/userinfo'],
    new: (args) => NewUserInfoFromDomainObject(args),
  },
  'GET users': {
    urlSplits: ['/slab/users'],
    new: (args) => new List(NewUserFromDomainObject, args),
  },
  'GET users by role ID': {
    urlSplits: ['/slab/roles/', 'id', '/users'],
    new: (args) => (args ?? []).map((a: any) => NewUserFromDomainObject(a)),
  },
  'GET users by tenant ID as admin': {
    urlSplits: ['/slabadmin/tenants/', 'tenantID', '/users'],
    new: (args) => new List(NewUserFromDomainObject, args),
  },
};

// The connective route info to populate useMutation
export const MutationRouteBarrelVals: Record<MutationRouteBarrelKeys, MutationBarrelVals> = {
  'POST activity': {
    method: 'POST',
    urlSplits: ['/slab/activities'],
    invalidations: [
      'GET activities',
      'GET activities by contact ID',
      'GET activities by company ID',
      'GET activities by project ID',
      'GET activities by user ID',
    ],
    new: (args) => NewActivityFromDomainObject(args),
  },
  'POST company': {
    method: 'POST',
    urlSplits: ['/slab/companies'],
    invalidations: [
      'GET companies',
      'GET company summaries',
      'GET company by ID',
      'GET company contacts',
      'GET company category aggregations',
      'GET dispatch customer summaries',
    ],
    new: (args) => NewCompanyFromDomainObject(args),
  },
  'POST contact': {
    method: 'POST',
    urlSplits: ['/slab/contacts'],
    invalidations: ['GET contacts', 'GET contacts by company IDs', 'GET company contacts'],
    new: (args) => NewContactFromDomainObject(args),
  },
  'POST mark quote as sent': {
    method: 'POST',
    urlSplits: ['/slab/quotes/', 'id', '/marksent'],
    invalidations: [
      'GET quotes',
      'GET quote summaries',
      'GET quote status aggregations',
      'GET quote by ID',
      'GET quote pdf by quote ID and template ID',
      'GET projects',
      'GET project summaries',
      'GET project summaries by company ID',
      'GET project summaries by contact ID',
      'GET project by ID',
      'GET project quotes by project ID',
    ],
    new: (args) => NewQuoteFromDomainObject(args),
  },
  'POST material': {
    method: 'POST',
    urlSplits: ['/slab/materials'],
    invalidations: [
      'GET materials',
      'GET material summaries',
      'GET material type aggregations',
      'GET materials by plant ID',
      'GET plant materials by plant ID',
    ],
    new: (args) => NewMaterialFromDomainObject(args),
  },
  'POST mix': {
    method: 'POST',
    urlSplits: ['/slab/mixes'],
    invalidations: ['GET mixes', 'GET mix summaries', 'GET mixes by plant ID'],
    new: (args) => NewMixFromDomainObject(args),
  },
  'POST nylas code': {
    method: 'POST',
    urlSplits: ['/slab/nylas/login/token'],
    invalidations: ['GET nylas login', 'GET nylas account info'],
    new: (args) => NewAuthorizationTokenOutputFromDomainObject(args),
  },
  'POST nylas v3 code': {
    method: 'POST',
    urlSplits: ['/slab/nylas/v3/login/token'],
    invalidations: ['GET nylas v3 login', 'GET nylas v3 grant info'],
    new: (args) => NewAuthorizationTokenOutputFromDomainObject(args),
  },
  'POST nylas message': {
    method: 'POST',
    urlSplits: ['/slab/nylas/message/send'],
    invalidations: [],
    new: (args) => NewSendMessageOutputFromDomainObject(args),
  },
  'POST nylas revoke token': {
    method: 'POST',
    urlSplits: ['/slab/nylas/account/revoke'],
    invalidations: ['GET nylas account info'],
    new: (args) => NewRevokeTokenOutputFromDomainObject(args),
  },
  'POST nylas v3 revoke grant': {
    method: 'POST',
    urlSplits: ['/slab/nylas/v3/grant/revoke'],
    invalidations: ['GET nylas v3 grant info'],
    new: (args) => NewRevokeTokenOutputFromDomainObject(args),
  },
  'POST plant': {
    method: 'POST',
    urlSplits: ['/slab/plants'],
    invalidations: [
      'GET plants',
      'GET forecasts',
      'GET project forecast summaries',
      'GET forecast aggregations',
      'GET materials',
      'GET material summaries',
      'GET material type aggregations',
      'GET materials by plant ID',
      'GET mixes',
      'GET mix summaries',
      'GET mixes by plant ID',
      'GET products',
      'GET product summaries',
      'GET product category aggregations',
      'GET product by ID',
      'GET quotes',
      'GET quote summaries',
      'GET quote status aggregations',
      'GET quote by ID',
      'GET quote pdf by quote ID and template ID',
    ],
    new: (args) => NewPlantFromDomainObject(args),
  },
  'POST product': {
    method: 'POST',
    urlSplits: ['/slab/products'],
    invalidations: [
      'GET products',
      'GET product summaries',
      'GET product category aggregations',
      'GET plant products by plant ID',
    ],
    new: (args) => NewProductFromDomainObject(args),
  },
  'POST project config': {
    method: 'POST',
    urlSplits: ['/slab/projectconfigs'],
    invalidations: [
      'GET project configs',
      'GET project config by ID',
      'GET project config products by project config ID',
      'GET project config summaries',
    ],
    new: (args) => NewProjectConfigFromDomainObject(args),
  },
  'POST project': {
    method: 'POST',
    urlSplits: ['/slab/projects'],
    invalidations: [
      'GET projects',
      'GET project summaries',
      'GET project summaries by company ID',
      'GET project summaries by contact ID',
      'GET project status aggregations',
      'GET forecasts',
      'GET project forecast summaries',
      'GET forecast aggregations',
    ],
    new: (args) => NewProjectFromDomainObject(args),
  },
  'POST quote': {
    method: 'POST',
    urlSplits: ['/slab/quotes'],
    invalidations: [
      'GET quotes',
      'GET quote summaries',
      'GET quote status aggregations',
      'GET projects',
      'GET project summaries',
      'GET project summaries by company ID',
      'GET project summaries by contact ID',
      'GET project by ID',
      'GET project quotes by project ID',
    ],
    new: (args) => NewQuoteFromDomainObject(args),
  },
  'POST quote config': {
    method: 'POST',
    urlSplits: ['/slab/quoteconfigs'],
    invalidations: ['GET quote configs'],
    new: (args) => NewQuoteConfigFromDomainObject(args),
  },
  'PUT activity by ID': {
    method: 'PUT',
    urlSplits: ['/slab/activities/', 'id'],
    invalidations: [
      'GET activities',
      'GET activities by contact ID',
      'GET activities by company ID',
      'GET activities by project ID',
      'GET activities by user ID',
      'GET activity by ID',
      'GET companies by activity ID',
      'GET contacts by activity ID',
    ],
    new: (args) => NewActivityFromDomainObject(args),
  },
  'PUT company by ID': {
    method: 'PUT',
    urlSplits: ['/slab/companies/', 'id'],
    invalidations: [
      'GET contacts',
      'GET contacts by company IDs',
      'GET contact by ID',
      'GET companies',
      'GET company summaries',
      'GET company by ID',
      'GET company contacts',
      'GET company category aggregations',
      'GET dispatch customer summaries',
      'GET projects',
      'GET project summaries',
      'GET project summaries by company ID',
      'GET project summaries by contact ID',
      'GET project quotes by project ID',
      'GET project companies by project ID',
      'GET project config by ID',
      'GET project config summaries',
      'GET project configs',
      'GET quotes',
      'GET quote summaries',
      'GET quote status aggregations',
      'GET quote by ID',
      'GET quote pdf by quote ID and template ID',
    ],
    new: (args) => NewCompanyFromDomainObject(args),
  },
  'PUT contact by ID': {
    method: 'PUT',
    urlSplits: ['/slab/contacts/', 'id'],
    invalidations: [
      'GET contacts',
      'GET contacts by company IDs',
      'GET contact by ID',
      'GET company contacts',
      'GET project quotes by project ID',
      'GET project companies by project ID',
      'GET project config by ID',
      'GET project configs',
    ],
    new: (args) => NewContactFromDomainObject(args),
  },
  'PUT material by ID': {
    method: 'PUT',
    urlSplits: ['/slab/materials/', 'id'],
    invalidations: [
      'GET materials',
      'GET material summaries',
      'GET material type aggregations',
      'GET materials by plant ID',
      'GET material by ID',
      'GET materials by mix ID',
      'GET mixes',
      'GET mix summaries',
      'GET mixes by plant ID',
      'GET mix by ID',
      'GET plant materials by plant ID',
      'GET products',
      'GET product summaries',
      'GET product category aggregations',
      'GET product by ID',
      'GET plant products by plant ID',
      'GET project config products by project config ID',
      'GET project products by project ID',
      'GET quote products by quote ID',
    ],
    new: (args) => NewMaterialFromDomainObject(args),
  },
  'PUT mix by ID': {
    method: 'PUT',
    urlSplits: ['/slab/mixes/', 'id'],
    invalidations: [
      'GET mixes',
      'GET mix summaries',
      'GET mixes by plant ID',
      'GET mix by ID',
      'GET materials by mix ID',
      'GET products',
      'GET product summaries',
      'GET product category aggregations',
      'GET product by ID',
      'GET plant products by plant ID',
      'GET project config products by project config ID',
      'GET project products by project ID',
      'GET quote products by quote ID',
      'GET quote pdf by quote ID and template ID',
    ],
    new: (args) => NewMixFromDomainObject(args),
  },
  'PUT plant by ID': {
    method: 'PUT',
    urlSplits: ['/slab/plants/', 'id'],
    invalidations: [
      'GET plants',
      'GET plant by ID',
      'GET plant forecasts by plant ID',
      'GET plant materials by plant ID',
      'GET plant products by plant ID',
      'GET maps distance',
      'GET maps distance from points',
      'GET materials',
      'GET material summaries',
      'GET material type aggregations',
      'GET materials by plant ID',
      'GET material by ID',
      'GET mixes',
      'GET mix summaries',
      'GET mixes by plant ID',
      'GET mix by ID',
      'GET products',
      'GET product summaries',
      'GET product category aggregations',
      'GET product by ID',
      'GET projects',
      'GET project configs',
      'GET project config summaries',
      'GET project config products by project config ID',
      'GET project config by ID',
      'GET project summaries',
      'GET project summaries by company ID',
      'GET project summaries by contact ID',
      'GET project by ID',
      'GET quotes',
      'GET quote summaries',
      'GET quote status aggregations',
      'GET quote by ID',
      'GET quote pdf by quote ID and template ID',
      'GET quote products by quote ID',
      'GET quote configs',
      'GET quote config by ID',
      'GET forecasts',
      'GET project forecast summaries',
      'GET forecast aggregations',
    ],
    new: (args) => NewPlantFromDomainObject(args),
  },
  'PUT product by ID': {
    method: 'PUT',
    urlSplits: ['/slab/products/', 'id'],
    invalidations: [
      'GET products',
      'GET product summaries',
      'GET product category aggregations',
      'GET product by ID',
      'GET project config products by project config ID',
      'GET project products by project ID',
      'GET quote products by quote ID',
      'GET project quotes by project ID',
      'GET plant products by plant ID',
      'GET quote configs',
      'GET quote config by ID',
    ],
    new: (args) => NewProductFromDomainObject(args),
  },
  'PUT project by ID': {
    method: 'PUT',
    urlSplits: ['/slab/projects/', 'id'],
    invalidations: [
      'GET projects',
      'GET project summaries',
      'GET project summaries by company ID',
      'GET project summaries by contact ID',
      'GET project by ID',
      'GET project products by project ID',
      'GET project companies by project ID',
      'GET project status aggregations',
      'GET quotes',
      'GET quote summaries',
      'GET quote status aggregations',
      'GET quote by ID',
      'GET quote pdf by quote ID and template ID',
      'GET project quotes by project ID',
      'GET maps distance',
      'GET maps distance from points',
      'GET project forecasts by project ID',
      'GET forecasts',
      'GET project forecast summaries',
      'GET forecast aggregations',
    ],
    new: (args) => NewProjectFromDomainObject(args),
  },
  'PUT project config by ID': {
    method: 'PUT',
    urlSplits: ['/slab/projectconfigs/', 'id'],
    invalidations: [
      'GET project configs',
      'GET project config by ID',
      'GET project config products by project config ID',
      'GET project config summaries',
    ],
    new: (args) => NewProjectConfigFromDomainObject(args),
  },
  'PUT quote by ID': {
    method: 'PUT',
    urlSplits: ['/slab/quotes/', 'id'],
    invalidations: [
      'GET quotes',
      'GET quote summaries',
      'GET quote status aggregations',
      'GET quote by ID',
      'GET quote pdf by quote ID and template ID',
      'GET quote products by quote ID',
      'GET project quotes by project ID',
    ],
    new: (args) => NewQuoteFromDomainObject(args),
  },
  'PUT quote config by ID': {
    method: 'PUT',
    urlSplits: ['/slab/quoteconfigs/', 'id'],
    invalidations: ['GET quote configs', 'GET quote config by ID'],
    new: (args) => NewQuoteConfigFromDomainObject(args),
  },
};
