// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

// webgen:import { "defaultName": "Dinero", "path": "dinero.js" }
// webgen:import { "wildcardName": "CurrencyHelpers", "path": "../../utils/Currency" }

import Dinero from 'dinero.js';
import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import * as CurrencyHelpers from '../../utils/Currency';
import { ExcludeMethodsDeep } from '../../utils/Types';

export class Currency {
  readonly number: string;
  readonly currency: string;

  constructor(data: { [key: string]: any } = {}) {
    this.number = data.number === undefined ? '0.00' : data.number;
    this.currency = data.currency === undefined ? 'USD' : data.currency;
  }

  static zero(): Currency {
    const zeroValues: ExcludeMethodsDeep<Currency> = {
      number: '0.00',
      currency: 'USD',
    };
    return new Currency(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  /**
   * Returns the sum of this Currency with the provided values.
   *
   * For now, all of the Currency objects being added must have the same currency (e.g. they could
   * all be "USD"), except that zero-quantity `values` can use a mismatched currency.
   */
  add(...values: Currency[]): Currency | null {
    const currencies = [
      this.currency,
      ...values.filter((v) => Number(v.number) !== 0).map((v) => v.currency),
    ];
    if (new Set(currencies).size !== 1) {
      // TODO handle mismatched currency
      return null;
    }

    const dineros = [this, ...values]
      .filter((value) => Number.isFinite(Number(value.number)))
      .map((item) => CurrencyHelpers.CurrencyDinero(item));
    const total = dineros.reduce((sum, value) => sum.add(value), Dinero({ amount: 0 }));
    return CurrencyHelpers.DineroCurrency(total);
  }

  /**
   * Returns a new Currency whose number consists of this Currency's number multiplied by
   * the specified factor.
   */
  multiply(factor: number): Currency | null {
    if (!Number.isFinite(factor)) {
      // factor is NaN or +/- Infinity
      return null;
    }
    let dinero = CurrencyHelpers.CurrencyDinero(this);
    // When performing multiplication, Dinero.js rounds the result to the existing precision.  If the existing
    // precision is less granular than cents, losslessly increase precision to ensure we get an accurate result.
    // TODO: minimum precision of 2 will be inaccurate for some non-USD currencies.
    if (dinero.getPrecision() < 2) {
      dinero = dinero.convertPrecision(2);
    }
    return CurrencyHelpers.DineroCurrency(dinero.multiply(factor));
  }

  /**
   * Returns a new Currency whose number consists of this Currency's number divided by
   * the specified denominator.
   */
  divide(denominator: number): Currency | null {
    if (denominator === 0 || Number.isNaN(denominator)) {
      // denominator is zero or NaN
      return null;
    }
    let dinero = CurrencyHelpers.CurrencyDinero(this);
    // When performing division, Dinero.js rounds the result to the existing precision.  If the existing
    // precision is less granular than cents, losslessly increase precision to ensure we get an accurate result.
    // TODO: minimum precision of 2 will be inaccurate for some non-USD currencies.
    if (dinero.getPrecision() < 2) {
      dinero = dinero.convertPrecision(2);
    }
    return CurrencyHelpers.DineroCurrency(dinero.divide(denominator));
  }

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewCurrency = (props: PartialDeep<Currency, { recurseIntoArrays: true }>): Currency =>
  new Currency(props);

export const NewCurrencyFromDomainObject = (
  props: PartialDeep<DomainObject<Currency>, { recurseIntoArrays: true }>,
): Currency => new Currency(props);
