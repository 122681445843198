import ReactPDF, { Document, Font, Page, StyleSheet, Text } from '@react-pdf/renderer';
import React from 'react';

import { Quote } from '../generated-types/Quote/Quote';
import defaultHyphenation from './components/defaultHyphenation';
import { RegisterFonts } from './Fonts';

RegisterFonts();

const styles = StyleSheet.create({
  page: {
    padding: '0.75in',
  },
  pageNumber: {
    alignSelf: 'center',
    width: '100%',
    paddingTop: '0.5in',
    textAlign: 'right',
    fontSize: '8pt',
    fontFamily: 'Helvetica-Bold',
    position: 'absolute',
  },
});

type QuotePdfProps = {
  children: React.ReactNode;
  quote: Quote;
  pageStyle?: ReactPDF.PageProps;
  /** @default false */
  disableHyphenation?: boolean;
  /** @default false */
  includePageNumbers?: boolean;
};

export const QuotePdf = ({
  children,
  quote,
  pageStyle,
  disableHyphenation = false,
  includePageNumbers = false,
}: QuotePdfProps): JSX.Element => {
  if (disableHyphenation) {
    Font.registerHyphenationCallback((word) => [word]);
  } else {
    Font.registerHyphenationCallback(defaultHyphenation());
  }
  const ps = [pageStyle?.style ?? {}].flat();
  return (
    <Document title={quote.name ?? undefined}>
      <Page style={[styles.page, ...ps]}>
        {includePageNumbers && (
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }): string => `page ${pageNumber} of ${totalPages}`}
            fixed
          />
        )}
        {children}
      </Page>
    </Document>
  );
};
