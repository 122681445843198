// This file is automatically generated by webgen from the struct descriptions in /generated-json/structs.
// Files in /generated-json are created from the structs configured in the 'main' function of /cmd/struct2json/main.go.
//
// DO NOT EDIT THIS FILE except where designated below.

import { PartialDeep } from 'type-fest';

import { DomainObject } from '../../utils/ApiClient';
import { ExcludeMethodsDeep } from '../../utils/Types';
import { NIL_UUID } from '../../utils/UUID';

export class Segment {
  readonly id: string;
  readonly name: string;

  constructor(data: { [key: string]: any } = {}) {
    this.id = data.id === undefined ? NIL_UUID : data.id;
    this.name = data.name === undefined ? '' : data.name;
  }

  static zero(): Segment {
    const zeroValues: ExcludeMethodsDeep<Segment> = {
      id: NIL_UUID,
      name: '',
    };
    return new Segment(zeroValues);
  }

  // ************* DO NOT EDIT ABOVE THIS LINE *************

  // ************* DO NOT EDIT BELOW THIS LINE *************
}

export const NewSegment = (props: PartialDeep<Segment, { recurseIntoArrays: true }>): Segment =>
  new Segment(props);

export const NewSegmentFromDomainObject = (
  props: PartialDeep<DomainObject<Segment>, { recurseIntoArrays: true }>,
): Segment => new Segment(props);
