import Dinero from 'dinero.js';
import { numericFormatter as reactNumericFormatter } from 'react-number-format';

import { Cost } from '../generated-types/Cost/Cost';
import {
  Currency,
  NewCurrency,
  NewCurrencyFromDomainObject,
} from '../generated-types/Currency/Currency';
import { DomainObject } from './ApiClient';
import { UnitFromString } from './UnitHelpers';
import { YupSchemaNullableCurrencyType } from './YupHelpers';

/** Convert a Currency to Dinero */
export const CurrencyDinero = (cur: Currency): Dinero.Dinero => {
  const cleanedCurNumber = cur.number === '' ? '0' : cur.number.replace(',', '');
  const precision =
    cleanedCurNumber.indexOf('.') === -1
      ? 0
      : cleanedCurNumber.length - 1 - cleanedCurNumber.indexOf('.');

  return Dinero({
    amount: parseInt(cleanedCurNumber.replace('.', ''), 10),
    precision,
  });
};

/** Convert Currency to Dinero. If null or empty string, Dinero is 0. */
export const NullableCurrencyDinero = (cur: Currency | null): Dinero.Dinero => {
  if (cur === null || cur.number === '') {
    return Dinero({ amount: 0 });
  }
  return CurrencyDinero(cur);
};

/** Convert a Dinero to Currency. */
export const DineroCurrency = (dinero: Dinero.Dinero): Currency => {
  const number = dinero.toUnit().toLocaleString(undefined, {
    minimumFractionDigits: dinero.getPrecision(),
    maximumFractionDigits: dinero.getPrecision(),
    useGrouping: false,
  });
  return NewCurrency({ number, currency: dinero.getCurrency() });
};

type CurrencyStringOpts = {
  cur: Currency;

  /** If true, prepend '$', else do not. Defaults to true. */
  includeSign?: boolean;
};

/** Given a Currency, return a human readable locale string */
export const CurrencyString = ({ cur, includeSign = true }: CurrencyStringOpts): string =>
  CurrencyDinero(cur).toFormat(includeSign ? '$0,0.00' : '0,0.00');

type NullableCurrencyStringOpts = {
  cur: Currency | null;

  /** If true, prepend '$', else do not. Defaults to true. */
  includeSign?: boolean;
  /**
   * If true, empty string is returned. Else '0' or '$0' is returned.
   * Defaults to true.
   */
  omitNull?: boolean;
};

/** Given a Currency | null, return a human readable locale string */
export const NullableCurrencyString = ({
  cur,
  includeSign = true,
  omitNull = true,
}: NullableCurrencyStringOpts): string => {
  if (cur !== null) {
    return CurrencyString({ cur, includeSign });
  }
  return omitNull ? '' : CurrencyString({ cur: Currency.zero(), includeSign });
};

type FormattedCurrencyOpts = {
  cur: DomainObject<Currency>;
};

/**
 * Given a `DomainObject<Currency>` from formik state,
 * format it such that the number is a human readable locale string.
 * By using reactNumericFormatter, it also allows the user to pass in
 * current-changed values that allow for incomplete currency amounts.
 */
export const FormattedYupCurrency = ({ cur }: FormattedCurrencyOpts): DomainObject<Currency> => {
  const curNumber = reactNumericFormatter(cur.number, {
    thousandSeparator: true,
    prefix: undefined,
  });
  return {
    number: curNumber,
    currency: cur.currency,
  };
};

type FormattedNullableYupCurrencyOpts = {
  cur: YupSchemaNullableCurrencyType;
};

/**
 * Given a `YupSchemaNullableCurrencyType` from formik state,
 * format it such that the number is a human readable locale string.
 */
export const FormattedNullableYupCurrency = ({
  cur,
}: FormattedNullableYupCurrencyOpts): YupSchemaNullableCurrencyType => {
  if (cur === null) {
    return null;
  }
  return FormattedYupCurrency({ cur });
};

type CostStringOpts = {
  cost: DomainObject<Cost>;

  /** If true, prepend '$', else do not. Defaults to true. */
  includeSign?: boolean;
};

/** Given a Cost, return a human readable locale string */
export const CostString = ({ cost, includeSign = true }: CostStringOpts): string => {
  const currencyString = CurrencyString({
    cur: NewCurrencyFromDomainObject(cost.amount),
    includeSign,
  });
  const unitLabel = UnitFromString(cost.unit);
  return unitLabel === null ? currencyString : `${currencyString}/${unitLabel.label}`;
};

type NullableCostStringOpts = {
  cost: Cost | null;

  /**
   * If true, prepend '$', else do not.
   * @default true
   */
  includeSign?: boolean;
  /**
   * If true, empty string is returned. Else '0' or '$0' is returned.
   * @default true
   */
  omitNull?: boolean;
  /**
   * If defined, this is the string that is returned if cost is null.
   * @default undefined
   */
  nullPlaceholder?: string;
};

/** Given a Cost | null, return a human readable locale string */
export const NullableCostString = ({
  cost,
  includeSign = true,
  omitNull = true,
  nullPlaceholder = undefined,
}: NullableCostStringOpts): string => {
  if (cost !== null) {
    return CostString({ cost, includeSign });
  }
  if (omitNull) {
    return '';
  }
  if (nullPlaceholder !== undefined) {
    return nullPlaceholder;
  }
  return CostString({
    cost: Cost.zero(),
    includeSign,
  });
};

type DineroStringOpts = {
  dinero: Dinero.Dinero;

  /** If true, prepend '$', else do not. Defaults to true. */
  includeSign?: boolean;
};

/** Given a Dinero, return a human readable locale string */
export const DineroString = ({ dinero, includeSign = true }: DineroStringOpts): string =>
  dinero.toFormat(includeSign ? '$0,0.00' : '0,0.00');

/** Given a Dinero, return a number */
export const DineroNumber = (dinero: Dinero.Dinero): number => parseFloat(dinero.toFormat('0.00'));

export const numericFormatter = reactNumericFormatter;
